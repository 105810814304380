import React, { useEffect, useState } from "react";
import "./ViewFile.css";
import Header from "../../Components/Header/Header";
import DataTable from "react-data-table-component";
import { customTableStyles } from "../../utils/Files/tableStyle";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { LuDownload } from "react-icons/lu";
import EditIcon from "@mui/icons-material/Edit";
import Form from "react-bootstrap/Form";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { GlobalConstants } from "../../utils/globalconstant";
import Loader2 from "../../Components/Loader2/Loader2";
import {
  GLOBAL_URL_DOMAIN_File_Upload_Live,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../../utils/Files/url";
import cIDImg from "../../utils/images/letter-a.png";

import axios from "axios";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Tooltip } from "@mui/material";
import Cookie from "js-cookie";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import * as dwv from "dwv";
import Loader3 from "../../utils/Files/data";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { errorToast, InfoToast } from "../../utils/Files/toasterMsgs";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { IoReload } from "react-icons/io5";
import Viewer from "../../Components/Viewer/Viewer";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const ViewFile = () => {
  //variable
  const navigate = useNavigate();
  const location = useLocation();

  const [Web_server_text, setWeb_server_text] = useState(
    "Upload to Web Server in progress"
  );
  const [Atom_text, setAtom_text] = useState(
    "Upload to Atom Storage in progress"
  );
  const [Atom_upload_err, setAtom_upload_err] = useState(
    "Error occurred while uploading to Atom Storage"
  );
  const [AWS_text, setAWS_text] = useState("Upload to AWS S3 in progress");
  const [AWS_upload_err, setAWS_upload_err] = useState(
    "Error occurred while uploading to AWS S3"
  );
  const [text_copied, setText_copied] = useState("Copied");
  const [text_filenotice, setText_filenotice] = useState(
    "This file and its metadata are marked to never be deleted."
  );
  const [text_filenotice1, setText_filenotice1] = useState(
    "This file and its metadata are marked to be deleted on"
  );
  const [web_server, setWeb_server] = useState(false);
  const [Atom, setAtom] = useState(false);
  const [AWS, setAWS] = useState(false);
  const [isawsErr, setIsawsErr] = useState(false);
  const [isipfsErr, setIsipfsErr] = useState(false);

  // console.log("location+++++", location);
  const [amazonLink, setAmazonLink] = useState("");
  const [amazonKey, setAmazonKey] = useState("");
  const [ipfsCID, setIpfsCID] = useState("");
  const [filenameUuid, setFileNameUUid] = useState("");
  const [fileUniqueId, setFileUniqueId] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const [fileType, setFileType] = useState("");
  const [desc, setDesc] = useState("");
  const [fileTags, setFileTags] = useState("");
  const [keywords, setKeywords] = useState("");
  const [orgName, setOrgName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [awsFolder, setAwsFolder] = useState("");
  const [ipfsFolder, setIpfsFolder] = useState("");
  const [uploadDate, setUploadDate] = useState("");
  const [amazonCheck, setAmazonCheck] = useState(false);
  const [ipfsCheck, setIpfsCheck] = useState(false);
  const [isMedData, setIsMedData] = useState(false);
  const [selectedValue, setSelectedValue] = useState("Amazon S3");
  const [selecttab, setSelecttab] = useState("");
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openTooltipcid, setOpenTooltipcid] = useState(false);
  const [selectId1, setSelectId1] = useState("");
  const [selectId2, setSelectId2] = useState("");
  const [selectId3, setSelectId3] = useState("");
  const [selectIdValue1, setSelectIdValue1] = useState("");
  const [selectIdValue2, setSelectIdValue2] = useState("");
  const [selectIdValue3, setSelectIdValue3] = useState("");
  const [selectIdValue, setSelectIdValue] = useState(
    location?.state?.fileData?.h_id_1?.value
  );
  const [bucketACLOption, setBucketACLOption] = useState("");
  const [awsBkt, setAwsBkt] = useState("");
  const [fileExtention, setFileextention] = useState("");
  const [fileKey, setFileKey] = useState("");
  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [downloadAccess, setDownloadAccess] = useState(false);
  const [loader, setLoader] = useState(false);
  const [loaderAtom_storage, setLoaderAtom_storage] = useState(false);
  const [openViewer, setOpenViewer] = useState(false);
  const [viewerLoader, setViewerLoader] = useState(false);
  const handleOpenViewer = () => setOpenViewer(true);
  const handleCloseViewer = () => setOpenViewer(false);
  const [fileURL, setFileURL] = useState("");
  const [dwvApp, setDwvApp] = useState(null);
  const [showDWVBtn, setShowDWVBtn] = useState(false);
  const [loaderRefresh, setLoaderRefresh] = useState(false);
  const [deleteDate, setDeleteDate] = useState(null);
  const [openGenViewer, setOpenGenViewer] = useState(false);
  const handleOpenGenViewer = () => setOpenGenViewer(true);
  const handleCloseGenViewer = () => setOpenGenViewer(false);
  const [genFileURL, setGenFileURL] = useState("");
  const [showGenViewer, setShowGenViewer] = useState(false);
  const [jbrowserViewer, setJbrowserViewer] = useState(false);
  const [openohifViewer, setopenOhifViewer] = useState(false);

  const [isProcessing, setIsProcessing] = useState(false);
  // const [isFileReady, setIsFileReady] = useState(false);
  const [processingMessage, setProcessingMessage] = useState(
    "Starting file processing..."
  );
  // ----- language starts -----//
  const [text_amazons3folder, setText_amazons3folder] =
    useState("AMAZON S3 FOLDER");
  const [text_filetype, setText_filetype] = useState("FILE TYPE");
  const [text_description1, setText_description] = useState("DESCRIPTION");
  const [text_filestag, setText_filetag] = useState("FILE TAGS");
  const [text_keyword, setText_keyword] = useState("KEYWORDS");
  const [text_org, setText_org] = useState("ORGANIZATION");
  const [text_filename1, setText_filename] = useState("FILE NAME");
  const [text_filesize, setText_filesize] = useState("FILE SIZE");
  const [text_dateofupload, setText_dateofupload] = useState("DATE OF UPLOAD");
  const [text_filedetails, setText_filedetails] = useState("File Details Of");
  const [text_filedata, setText_filedata] = useState("File Data");
  const [text_edit, setText_edit] = useState("Edit");
  const [text_amazons3link1, setText_amazons3link1] =
    useState("Amazon S3 Link");
  const [text_atomfileid, setText_atomfileid] = useState("Atom File Id");
  const [text_atomstore, setText_atomstore] = useState(
    "Atom Storage Network CID"
  );
  const [text_medicaldata, setText_medicaldata] = useState("Medical Data");
  const [text_downfromamazons3, setText_downfromamazons3] = useState(
    "DOWNLOAD FROM AMAZON S3"
  );
  const [text_atomstoragenetwork, setText_atomstoragenetwork] = useState(
    "DOWNLOAD FROM ATOM STORAGE NETWORK"
  );
  const [text_serverdownload, setText_serverdownload] = useState(
    "DOWNLOAD FROM WEB SERVER"
  );
  const [text_asn, setText_asn] = useState("Atom Storage Network");
  const [text_amazons3, setText_amazons3] = useState("Amazon S3");
  const [text_downloadfrom, setText_downloadfrom] = useState("Download From");
  const [text_back, setText_back] = useState("Back");
  const [text_dob1, setText_dob1] = useState("DATE OF BIRTH");
  const [text_age, setText_age] = useState("AGE");
  const [text_gender1, setTextgender1] = useState("GENDER");
  const [text_email, setText_email] = useState("EMAIL");
  const [text_phoneno, setText_phoneno] = useState("PHONE NUMBER");
  const [text_patientname, setText_patientname] = useState("Patient Name");
  const [text_id, setText_id] = useState("ID");
  const [text_amazonlocation, setText_amazonLocation] =
    useState("Amazon S3 Location");
  const [text_opendicom, setText_opendicom] = useState("Open DICOM Viewer");
  const [text_close, setText_close] = useState("Close");
  const [text_Refresh, setText_Refresh] = useState("Refresh");
  const [text_atomfolder, setText_atomfolder] = useState("Atom Folder");
  // ----- language starts -----//

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 690,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const style_2 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    height: "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  useEffect(() => {
    // console.log(
    //   "*************view file id*******************************",
    //   location?.state?.fileData
    // );
    let fileName = location?.state?.fileData?.fileName;
    let dot = fileName.lastIndexOf(".");
    let ext = fileName.slice(dot);
    setSelecttab(location?.state?.selecttab);
    setFileextention(dot === -1 ? "" : ext);
    setFileKey(location?.state?.fileData?.amazonKey);
    setAmazonLink(
      location?.state?.fileData?.amazonUrl === ""
        ? "NA"
        : location?.state?.fileData?.amazonUrl
    );
    setAmazonKey(
      location?.state?.fileData?.amazonKey === ""
        ? "NA"
        : location?.state?.fileData?.amazonKey
    );
    setIpfsCID(
      location?.state?.fileData?.fileCId === ""
        ? "NA"
        : location?.state?.fileData?.fileCId
    );
    setFileNameUUid(
      location?.state?.fileData?.fileNameWithUuid === ""
        ? "NA"
        : location?.state?.fileData?.fileNameWithUuid
    );
    setFileUniqueId(
      location?.state?.fileData?.file_unique_id === ""
        ? "NA"
        : location?.state?.fileData?.file_unique_id
    );
    checkStatusUploadInfo(
      location?.state?.fileData?.file_unique_id === ""
        ? "NA"
        : location?.state?.fileData?.file_unique_id
    );
    setFileName(
      location?.state?.fileData?.fileName === ""
        ? "NA"
        : location?.state?.fileData?.fileName
    );
    setBucketACLOption(
      location?.state?.fileData?.bucketACLOption === ""
        ? "NA"
        : location?.state?.fileData?.bucketACLOption
    );
    setAwsBkt(
      location?.state?.fileData?.bucketName === ""
        ? "NA"
        : location?.state?.fileData?.bucketName
    );
    setFileSize(
      location?.state?.fileData?.fileSize === ""
        ? "NA"
        : location?.state?.fileData?.fileSize
    );
    setFileType(
      location?.state?.fileData?.fileType === ""
        ? "NA"
        : location?.state?.fileData?.fileType
    );
    setDesc(
      location?.state?.fileData?.description === ""
        ? "NA"
        : location?.state?.fileData?.description
    );
    setFileTags(
      location?.state?.fileData?.fileTags === ""
        ? "NA"
        : location?.state?.fileData?.fileTags
    );
    setKeywords(
      location?.state?.fileData?.keywords === ""
        ? "NA"
        : location?.state?.fileData?.keywords
    );
    setOrgName(
      location?.state?.fileData?.orgName === ""
        ? "NA"
        : location?.state?.fileData?.orgName
    );
    setFirstName(
      location?.state?.fileData?.pFName === ""
        ? "NA"
        : location?.state?.fileData?.pFName
    );
    setLastName(
      location?.state?.fileData?.pLName === ""
        ? "NA"
        : location?.state?.fileData?.pLName
    );
    setDob(
      location?.state?.fileData?.pDOB === ""
        ? "NA"
        : location?.state?.fileData?.pDOB
    );
    setAge(
      location?.state?.fileData?.pAge === ""
        ? "NA"
        : location?.state?.fileData?.pAge
    );
    setGender(
      location?.state?.fileData?.pGender === ""
        ? "NA"
        : location?.state?.fileData?.pGender
    );
    setEmail(
      location?.state?.fileData?.pEmail === ""
        ? "NA"
        : location?.state?.fileData?.pEmail
    );
    setPhone(
      location?.state?.fileData?.pPhone === ""
        ? "NA"
        : location?.state?.fileData?.pPhone
    );

    setUploadDate(location?.state?.fileData?.uploadDateAndTime);
    // console.log(location?.state?.fileData?.ipfsFolderName);
    const get_index = location?.state?.fileData?.folderName.lastIndexOf("/");
    const get_folder = location?.state?.fileData?.folderName.slice(
      0,
      get_index
    );
    const data = get_folder.lastIndexOf("/");
    const final_data = get_folder.slice(data);
    // console.log(final_data, data);
    setAwsFolder(
      Number(final_data) === 0 || Number(data) === -1 ? "/" : final_data
    );

    const path = location?.state?.fileData?.ipfsFolderName;
    // console.log(path);
    function extractContentBetweenLastTwoSlashes(path) {
      const lastSlashIndex = path.lastIndexOf("/");
      const secondLastSlashIndex = path.lastIndexOf("/", lastSlashIndex - 1);
      const result = path.substring(secondLastSlashIndex, lastSlashIndex);
      // console.log(result);
      // Check if the result matches the specific string
      const alphanumericRegex = /^\/[a-zA-Z0-9]+$/;
      if (alphanumericRegex.test(result) && result.startsWith("/6")) {
        return "/";
      }
      return result;
    }
    setIpfsFolder(extractContentBetweenLastTwoSlashes(path));

    setAmazonCheck(location?.state?.fileData?.isAmazon);
    setIpfsCheck(location?.state?.fileData?.isIPFS);
    setIsMedData(location?.state?.fileData?.isMedData);
    setSelectId1(location?.state?.fileData?.h_id_1?.name);
    setSelectId2(location?.state?.fileData?.h_id_2?.name);
    setSelectId3(location?.state?.fileData?.h_id_3?.name);
    setSelectIdValue1(location?.state?.fileData?.h_id_1?.value);
    setSelectIdValue2(location?.state?.fileData?.h_id_2?.value);
    setSelectIdValue3(location?.state?.fileData?.h_id_3?.value);
    if (location?.state?.AccessData !== undefined) {
      setReadAccess(location?.state?.AccessData?.read_access);
      setWriteAccess(location?.state?.AccessData?.write_access);
      setDownloadAccess(location?.state?.AccessData?.download_access);
    }
  }, []);

  useEffect(() => {
    if (
      location?.state?.fileData?.deleteAt === null ||
      location?.state?.fileData?.deleteAt === undefined ||
      location?.state?.fileData?.deleteAt === ""
    ) {
      setDeleteDate(text_filenotice);
    } else {
      const deleteAt = location?.state?.fileData?.deleteAt;
      const date = new Date(deleteAt);
      const options = { year: "numeric", month: "long", day: "numeric" };
      const requiredDate = date.toLocaleDateString("en-US", options);
      setDeleteDate(`${text_filenotice1} ${requiredDate}.`);
    }
  }, [location?.state?.fileData]);

  useEffect(() => {
    // console.log(location?.state?.fileData?.fileType);
    if (
      location?.state?.fileData?.fileType === "text/html" ||
      location?.state?.fileData?.fileType === "text/csv" ||
      location?.state?.fileData?.fileType === "text/plain" ||
      location?.state?.fileData?.fileType === "image/webp" ||
      location?.state?.fileData?.fileType === "image/jpeg" ||
      location?.state?.fileData?.fileType === "image/png" ||
      location?.state?.fileData?.fileType === "image/gif" ||
      location?.state?.fileData?.fileType === "image/bmp" ||
      location?.state?.fileData?.fileType === "application/pdf" ||
      location?.state?.fileData?.fileType === "text/htm" ||
      location?.state?.fileData?.fileType === "image/jpg" ||
      location?.state?.fileData?.fileType === "image/tiff" ||
      location?.state?.fileData?.fileType === "video/mp4"
    ) {
      setShowGenViewer(true);
    }
  }, [
    location?.state?.fileData?.file_unique_id,
    location?.state?.fileData?.fileType,
  ]);
  useEffect(() => {
    const fileName = location?.state?.fileData?.fileName;

    if (
      (fileName && fileName.endsWith(".fa")) ||
      (fileName && fileName.endsWith(".bam")) ||
      (fileName && fileName.endsWith(".gff3"))
    ) {
      setJbrowserViewer(true);
    }
  }, [
    location?.state?.fileData?.file_unique_id,
    location?.state?.fileData?.fileType,
  ]);
  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const HandleChange = (e) => {
    setSelectIdValue(e);
    // console.log(e);
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      // console.log("" + err);
    }
  }, []);

  //****Close Tooltip AWS******/
  const onMouseOutamazon = () => {
    if (openTooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
      // hide tooltip
    }
  };

  //****Close Tooltip Atom storage******/
  const onMouseOutcid = () => {
    if (openTooltipcid) {
      setTimeout(() => {
        setOpenTooltipcid(false);
      }, 1000);
      // hide tooltip
    }
  };

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);

      setText_amazons3folder(
        xpath.select1("/resources/string[@name='text_amazons3folder']", doc)
          .firstChild.data
      );
      setText_filetype(
        xpath.select1("/resources/string[@name='text_filetype']", doc)
          .firstChild.data
      );
      setText_description(
        xpath.select1("/resources/string[@name='text_description1']", doc)
          .firstChild.data
      );
      setText_filetag(
        xpath.select1("/resources/string[@name='text_filestag']", doc)
          .firstChild.data
      );
      setText_keyword(
        xpath.select1("/resources/string[@name='text_keyword']", doc).firstChild
          .data
      );
      setText_org(
        xpath.select1("/resources/string[@name='text_org']", doc).firstChild
          .data
      );
      setText_filename(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );
      setText_filesize(
        xpath.select1("/resources/string[@name='text_filesize']", doc)
          .firstChild.data
      );
      setText_dateofupload(
        xpath.select1("/resources/string[@name='text_dateofupload']", doc)
          .firstChild.data
      );
      setText_filedetails(
        xpath.select1("/resources/string[@name='text_filedetails']", doc)
          .firstChild.data
      );
      setText_filedata(
        xpath.select1("/resources/string[@name='text_filedata']", doc)
          .firstChild.data
      );
      setText_edit(
        xpath.select1("/resources/string[@name='text_edit']", doc).firstChild
          .data
      );
      setText_amazons3link1(
        xpath.select1("/resources/string[@name='text_amazons3link1']", doc)
          .firstChild.data
      );
      setText_atomfileid(
        xpath.select1("/resources/string[@name='text_atomfileid']", doc)
          .firstChild.data
      );
      setText_atomstore(
        xpath.select1("/resources/string[@name='text_atomstore']", doc)
          .firstChild.data
      );
      setText_medicaldata(
        xpath.select1("/resources/string[@name='text_medicaldata']", doc)
          .firstChild.data
      );
      setText_downfromamazons3(
        xpath.select1("/resources/string[@name='text_downfromamazons3']", doc)
          .firstChild.data
      );
      setText_atomstoragenetwork(
        xpath.select1("/resources/string[@name='text_atomstoragenetwork']", doc)
          .firstChild.data
      );
      setText_amazons3(
        xpath.select1("/resources/string[@name='text_amazons3']", doc)
          .firstChild.data
      );
      setText_downloadfrom(
        xpath.select1("/resources/string[@name='text_downloadfrom']", doc)
          .firstChild.data
      );
      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
      setText_dob1(
        xpath.select1("/resources/string[@name='text_dob1']", doc).firstChild
          .data
      );
      setText_age(
        xpath.select1("/resources/string[@name='text_age']", doc).firstChild
          .data
      );
      setTextgender1(
        xpath.select1("/resources/string[@name='text_gender1']", doc).firstChild
          .data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_phoneno(
        xpath.select1("/resources/string[@name='text_phoneno']", doc).firstChild
          .data
      );
      setText_patientname(
        xpath.select1("/resources/string[@name='text_patientname']", doc)
          .firstChild.data
      );

      setText_id(
        xpath.select1("/resources/string[@name='text_id']", doc).firstChild.data
      );
      setText_asn(
        xpath.select1("/resources/string[@name='text_asn']", doc).firstChild
          .data
      );
      setText_amazonLocation(
        xpath.select1("/resources/string[@name='text_amazonlocation']", doc)
          .firstChild.data
      );
      setText_opendicom(
        xpath.select1("/resources/string[@name='text_opendicom']", doc)
          .firstChild.data
      );
      setText_close(
        xpath.select1("/resources/string[@name='text_close']", doc).firstChild
          .data
      );
      setText_serverdownload(
        xpath.select1("/resources/string[@name='text_serverdownload']", doc)
          .firstChild.data
      );
      setWeb_server_text(
        xpath.select1("/resources/string[@name='Web_server_text']", doc)
          .firstChild.data
      );
      setAtom_text(
        xpath.select1("/resources/string[@name='Atom_text']", doc).firstChild
          .data
      );
      setAtom_upload_err(
        xpath.select1("/resources/string[@name='Atom_upload_err']", doc)
          .firstChild.data
      );
      setAWS_text(
        xpath.select1("/resources/string[@name='AWS_text']", doc).firstChild
          .data
      );
      setAWS_upload_err(
        xpath.select1("/resources/string[@name='AWS_upload_err']", doc)
          .firstChild.data
      );
      setText_copied(
        xpath.select1("/resources/string[@name='text_copied']", doc).firstChild
          .data
      );
      setText_Refresh(
        xpath.select1("/resources/string[@name='text_Refresh']", doc).firstChild
          .data
      );
      setText_filenotice(
        xpath.select1("/resources/string[@name='text_filenotice']", doc)
          .firstChild.data
      );
      setText_filenotice1(
        xpath.select1("/resources/string[@name='text_filenotice1']", doc)
          .firstChild.data
      );
      setText_atomfolder(
        xpath.select1("/resources/string[@name='text_atomfolder']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };

  //*************File Folder Detail AWS start ****************/
  const data = [
    {
      fname: awsFolder,
      ipname: ipfsFolder,
      abc: fileName,
      def: fileSize,
      ghi: fileType,
      jkl: desc,
      mno: fileTags,
      pqr: keywords,
      stu: orgName,
      uDate: uploadDate,
    },
  ];
  // console.log(ipfsFolder);
  const columns = [
    {
      name: <>{text_amazons3folder}</>,
      selector: (row) =>
        row?.fname === "" && amazonCheck === true ? (
          "/"
        ) : (
          <span title={row?.fname}>
            {" "}
            {row?.fname === "/" ? "/" : `${row?.fname}/`}
          </span>
        ),
    },
    {
      name: <>{text_atomfolder}</>,
      selector: (row) =>
        row?.ipname === "" && ipfsCheck === true ? (
          "/"
        ) : (
          <span title={row?.ipname}>
            {row?.ipname === "/" ? "/" : `${row?.ipname}/`}
          </span>
        ),
      omit: ipfsCheck === false ? true : false,
    },
    {
      name: <>{text_filename1}</>,
      selector: (row) => row?.abc,
    },
    {
      name: <>{text_filesize}</>,
      selector: (row) => (
        <>
          <div className="datatable-img uf-inputs mt-1">
            <span className="mx-2">{row?.def}</span>
          </div>
        </>
      ),
    },
    {
      name: <>{text_filetype}</>,
      selector: (row) => (
        <>
          <div className="datatable-img uf-inputs mt-1">
            <span className="mx-2">{row?.ghi}</span>
          </div>
        </>
      ),
    },
    {
      name: <>{text_description1}</>,
      selector: (row) => row?.jkl,
    },
    {
      name: <>{text_filestag}</>,
      selector: (row) => row?.mno,
    },
    {
      name: <>{text_keyword}</>,
      selector: (row) => row?.pqr,
    },
    {
      name: <>{text_org}</>,
      selector: (row) => row?.stu,
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => moment(row?.uDate).format("MMM DD, YYYY"),
    },
  ];
  //*************File Folder Detail end****************/
  //*************Medical Detail start****************/
  const data2 = [
    {
      fname: awsFolder,
      fName: firstName,
      LName: lastName,
      DOB: dob,
      Age: age,
      Gender: gender,
      email_id: email,
      Phone: phone,
      Id1: selectId1,
      Id2: selectId2,
      Id3: selectId3,
    },
  ];

  const columns2 = [
    {
      name: <>{text_patientname}</>,
      selector: (row) =>
        row?.fName === "NA" && row?.LName === "NA"
          ? "NA"
          : (row?.fName === "NA" ? "" : row?.fName) +
            " " +
            (row?.LName === "NA" ? "" : row?.LName),
    },
    {
      name: <>{text_dob1}</>,
      selector: (row) =>
        row?.DOB === null ? "NA" : moment(row?.DOB).format("MMM DD, YYYY"),
    },
    {
      name: <>{text_age}</>,
      selector: (row) => (age === 0 ? "" : row?.Age), // change this after validation
    },
    {
      name: <>{text_gender1}</>,
      selector: (row) => row?.Gender,
    },
    {
      name: <>{text_email}</>,
      selector: (row) => row?.email_id,
    },
    {
      name: <>{text_phoneno}</>,
      selector: (row) => (row?.Phone === "NA" ? row?.Phone : "+" + row?.Phone),
    },
    {
      name: (
        <select
          className="select-cardID"
          onChange={(e) => HandleChange(e.target.value)}
        >
          <option value={selectIdValue1}>
            {text_id} 1 {selectId1 === "undefined" ? "" : `(${selectId1})`}
          </option>
          <option value={selectIdValue2}>
            {text_id} 2 {selectId2 === "undefined" ? "" : `(${selectId2})`}
          </option>
          <option value={selectIdValue3}>
            {text_id} 3 {selectId3 === "undefined" ? "" : `(${selectId3})`}
          </option>
        </select>
      ),
      selector: (row) => (selectIdValue === "" ? "NA" : selectIdValue),
      width: "250px",
    },
  ];
  //*************Medical Detail end****************/
  //*************File Folder Detail Atom storage start ****************/
  const data3 = [
    {
      fname: ipfsFolder,
      abc: fileName,
      def: fileSize,
      ghi: fileType,
      jkl: desc,
      mno: fileTags,
      pqr: keywords,
      stu: orgName,
      uDate: uploadDate,
    },
  ];

  const columns3 = [
    {
      name: <>{text_atomfolder}</>,
      selector: (row) =>
        row?.fname === "" && ipfsCheck === true ? (
          "/"
        ) : (
          <span title={row?.fname}>
            {row?.fname === "/" ? "/" : `${row?.fname}/`}
          </span>
        ),
    },
    {
      name: <>{text_filename1}</>,
      selector: (row) => (
        <>
          <div className="datatable-img uf-inputs mt-1">
            <span className="mx-2">{row?.abc}</span>
          </div>
        </>
      ),
    },
    {
      name: <>{text_filesize}</>,
      selector: (row) => row?.def,
    },
    {
      name: <>{text_filetype}</>,
      selector: (row) => (
        <div className="datatable-img uf-inputs mt-1">
          <span className="mx-2">{row?.ghi}</span>
        </div>
      ),
    },
    {
      name: <>{text_description1}</>,
      selector: (row) => (
        <div className="datatable-img uf-inputs mt-1">
          <span className="mx-2">{row?.jkl}</span>
        </div>
      ),
    },
    {
      name: <>{text_filestag}</>,
      selector: (row) => (
        <div className="datatable-img uf-inputs mt-1">
          <span className="mx-2">{row?.mno}</span>
        </div>
      ),
    },
    {
      name: <>{text_keyword}</>,
      selector: (row) => (
        <div className="datatable-img uf-inputs mt-1">
          <span className="mx-2">{row?.pqr}</span>
        </div>
      ),
    },
    {
      name: <>{text_org}</>,
      selector: (row) => (
        <div className="datatable-img uf-inputs mt-1">
          <span className="mx-2">{row?.stu}</span>
        </div>
      ),
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => moment(row?.uDate).format("MMM DD, YYYY"),
    },
  ];

  //*************File Folder Detail Atom storage end ****************/
  //**** Download from s3 Public *****/
  const handleDownloadFile = async (
    File_key,
    FileFinalName,
    file_type,
    bucket_aws,
    forViewer
  ) => {
    forViewer === true ? setViewerLoader(true) : setLoader(true);
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucket_aws; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("response****", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      if (forViewer === true) {
        setFileURL(tempUrl);
        setGenFileURL(tempUrl);
        // console.log("tempUrl aws public***", tempUrl);
      }

      if (forViewer !== true) {
        const link = document.createElement("a");
        link.href = tempUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (link.href !== "") {
          setLoader(false);
        }
      }
    } catch (err) {
      // console.log("stream_s3_download error:", err);

      if (forViewer === true) {
        setViewerLoader(false);
        errorToast(err.message);
      } else {
        setLoader(false);
      }

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  //**** Download from Amazon s3 Private *****/
  const downloadPrivateS3File = async (
    bucketName,
    File_key,
    FileFinalName,
    // i,
    // row_id,
    file_type,
    forViewer
  ) => {
    forViewer === true ? setViewerLoader(true) : setLoader(true);
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucketName; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3-private`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("response****", res);

      const blobData = new Blob([res?.data], { type: fileType });

      const tempUrl = window.URL.createObjectURL(blobData);
      if (forViewer === true) {
        setFileURL(tempUrl);
        setGenFileURL(tempUrl);
        // console.log("tempUrl aws private***", tempUrl);
      }

      if (forViewer !== true) {
        const link = document.createElement("a");
        link.href = tempUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (link.href !== "") {
          setLoader(false);
        }
      }
    } catch (err) {
      // console.log("stream_s3_download error:", err);

      if (forViewer === true) {
        setViewerLoader(false);
        errorToast(err.message);
      } else {
        setLoader(false);
      }

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  //**** Download from  Atom storage*****/
  const handleDownloadFileIPFS = async (
    folder_cid,
    file_format,
    File_type,
    File_Name,
    i,
    row_id,
    forViewer
  ) => {
    forViewer === true ? setViewerLoader(true) : setLoaderAtom_storage(true);
    const filecid = folder_cid;
    const filename = File_Name;
    const fileType = File_type;
    // setLoaderAtom_storage((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-ipfs?filecid=${filecid}&filename=${filename}`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.get(url, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("stream_ipfs_download res***", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      if (forViewer === true) {
        setFileURL(tempUrl);
        setGenFileURL(tempUrl);
        // console.log("tempUrl ipfs***", tempUrl);
      }

      if (forViewer !== true) {
        const link = document.createElement("a");
        link.href = tempUrl;
        link.setAttribute("download", filename);
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (res?.status === 200) {
          setLoaderAtom_storage(false);
        }
      }
    } catch (err) {
      // console.log("stream_ipfs_download error***", err);

      if (forViewer === true) {
        setViewerLoader(false);
        errorToast(err.message);
      } else {
        setLoaderAtom_storage(false);
      }

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  // ********** DICOM viewer begins here **********
  useEffect(() => {
    const dcmNameTypeCheck = location?.state?.fileData?.fileNameWithUuid;
    const dcmFileTypeCheck = location?.state?.fileData?.fileType;

    if (
      dcmNameTypeCheck
        ?.slice(dcmNameTypeCheck?.lastIndexOf(".") + 1)
        ?.toLowerCase() === "dcm" ||
      dcmFileTypeCheck.toLowerCase() === "application/dicom"
    ) {
      setShowDWVBtn(true);
    }
  }, [
    location?.state?.fileData?.fileNameWithUuid,
    location?.state?.fileData?.fileType,
  ]);

  useEffect(() => {
    if (fileURL) {
      const app = new dwv.App();
      setDwvApp(app);
      // initialise
      const viewConfig0 = new dwv.ViewConfig("layerGroup0");
      const viewConfigs = { "*": [viewConfig0] };
      const options = new dwv.AppOptions(viewConfigs);
      options.viewOnFirstLoadItem = false;
      app.init(options);

      app.addEventListener("load", function () {
        // render data #0
        app.render(0);
      });
      // load dicom data
      app.loadURLs([fileURL]);
      setViewerLoader(false);
    }
  }, [fileURL]);

  const handleServerDownload = async (forViewer, uploadLoc) => {
    // ***************************
    forViewer === true && setViewerLoader(true);
    if (uploadLoc === "aws") {
      setLoader(true);
    } else if (uploadLoc === "ipfs") {
      setLoaderAtom_storage(true);
    }

    try {
      const url =
        "https://ipfsuploadapi.moramba.io:5123/api/atom-storage-file/v1/download-file/download-from-server";

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        fileName: filenameUuid,
        fileType: fileType,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("stream_ipfs_server_download res*", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);

      if (forViewer === true) {
        setFileURL(tempUrl);
        setGenFileURL(tempUrl);
        // console.log("tempUrl server***", tempUrl);
      }

      if (forViewer !== true) {
        const link = document.createElement("a");
        link.href = tempUrl;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        if (link.href !== "") {
          if (uploadLoc === "aws") {
            setLoader(false);
          } else if (uploadLoc === "ipfs") {
            setLoaderAtom_storage(false);
          }
        }
      }
    } catch (err) {
      // console.log("server download error***", err);

      forViewer === true && setViewerLoader(false);
      if (uploadLoc === "aws") {
        setLoader(false);
      } else if (uploadLoc === "ipfs") {
        setLoaderAtom_storage(false);
      }

      errorToast(err.message);

      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const handleCloseViewerFunc = () => {
    if (dwvApp) {
      dwvApp.reset();
      setDwvApp(null);
    }
    setFileURL("");
    handleCloseViewer();
  };

  const FileData = () => {
    // console.log(location?.state?.fileData?._id);
    var id = location?.state?.fileData?._id;
    const headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    const API_URL =
      GLOBAL_URL_DOMAIN_LIVE + `/file/filedata-by-id?fileId=${id}`;

    axios
      .get(API_URL, headerConfig)
      .then((res) => {
        // console.log("get data both res***", res?.data?.data);
        setIpfsCID(
          res?.data?.data?.fileCId === "" ? "NA" : res?.data?.data?.fileCId
        );
        setAmazonKey(
          res?.data?.data?.amazonKey === "" ? "NA" : res?.data?.data?.amazonKey
        );
        setAmazonLink(
          res?.data?.data?.amazonUrl === "" ? "NA" : res?.data?.data?.amazonUrl
        );
      })
      .catch((err) => {
        // console.log("get data both err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
        }
      })
      .then(function () {});
  };

  const checkStatusUploadInfo = async (id) => {
    setLoaderRefresh(true);
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: id,
      };

      const res = await axios.post(API_URL, data, headerConfig);
      setLoaderRefresh(false);
      // console.log("refreshFunc res***", res);
      const success = res?.data?.success;
      const isUploadDone_WebServer = res?.data?.data?.isUploadDone_WebServer;
      const uploadEndTime_WebServer = res?.data?.data?.uploadEndTime_WebServer;
      const uploadLocation = res?.data?.data?.uploadLocation;
      const isUploadDone_AWSS3 = res?.data?.data?.isUploadDone_AWSS3;
      const isUploadDone_IPFS = res?.data?.data?.isUploadDone_IPFS;
      const awsErr = res?.data?.data?.awsErr;
      const ipfsErr = res?.data?.data?.ipfsErr;

      if (
        success === true &&
        isUploadDone_WebServer === false &&
        uploadEndTime_WebServer === null
      ) {
        setWeb_server(true);
        // uploading to web server in progress
      } else if (
        success === true &&
        isUploadDone_WebServer === true &&
        uploadEndTime_WebServer !== null
      ) {
        setWeb_server(false);
        // server upload completed

        if (
          uploadLocation === "aws" &&
          isUploadDone_AWSS3 === false &&
          awsErr === false
        ) {
          setAWS(true);
          // uploading to aws s3
        } else if (
          uploadLocation === "aws" &&
          isUploadDone_AWSS3 === true &&
          awsErr === false
        ) {
          FileData(id);
          setAWS(false);
          // upload to aws s3 successful
        } else if (
          uploadLocation === "aws" &&
          isUploadDone_AWSS3 === false &&
          awsErr === true
        ) {
          setIsawsErr(true);
          // error in aws s3 upload
        } else if (
          uploadLocation === "ipfs" &&
          isUploadDone_IPFS === false &&
          ipfsErr === false
        ) {
          setAtom(true);
          // uploading to ipfs
        } else if (
          uploadLocation === "ipfs" &&
          isUploadDone_IPFS === true &&
          ipfsErr === false
        ) {
          FileData(id);
          setAtom(false);
          // ipfs upload successful
        } else if (
          uploadLocation === "ipfs" &&
          isUploadDone_IPFS === false &&
          ipfsErr === true
        ) {
          FileData(id);
          setIsipfsErr(true);
          // error in ipfs upload
        } else if (uploadLocation === "both") {
          if (
            isUploadDone_AWSS3 === false &&
            awsErr === false &&
            isUploadDone_IPFS === false &&
            ipfsErr === false
          ) {
            setAtom(true);
            setAWS(true);
            // uploading to aws s3, uploading to ipfs
          } else if (
            isUploadDone_AWSS3 === false &&
            awsErr === false &&
            isUploadDone_IPFS === true &&
            ipfsErr === false
          ) {
            setAWS(true);
            FileData(id);
            setAtom(false);
            // uploading to aws s3, ipfs upload successful
          } else if (
            isUploadDone_AWSS3 === false &&
            awsErr === false &&
            isUploadDone_IPFS === false &&
            ipfsErr === true
          ) {
            setAWS(true);
            FileData(id);
            setIsipfsErr(true);
            // uploading to aws s3, error in ipfs upload
          } else if (
            isUploadDone_AWSS3 === true &&
            awsErr === false &&
            isUploadDone_IPFS === false &&
            ipfsErr === false
          ) {
            setAWS(false);
            FileData(id);
            setAtom(true);
            // upload to aws s3 successful, uploading to ipfs
          } else if (
            isUploadDone_AWSS3 === true &&
            awsErr === false &&
            isUploadDone_IPFS === true &&
            ipfsErr === false
          ) {
            FileData(id);
            setAWS(false);
            setAtom(false);
            // upload to aws s3 successful, ipfs upload successful
          } else if (
            isUploadDone_AWSS3 === true &&
            awsErr === false &&
            isUploadDone_IPFS === false &&
            ipfsErr === true
          ) {
            FileData(id);
            setAWS(false);
            setIsipfsErr(true);
            // upload to aws s3 successful, error in ipfs upload
          } else if (
            isUploadDone_AWSS3 === false &&
            awsErr === true &&
            isUploadDone_IPFS === false &&
            ipfsErr === false
          ) {
            FileData(id);
            setIsawsErr(true);
            setAtom(true);
            // error in aws s3 upload, uploading to ipfs
          } else if (
            isUploadDone_AWSS3 === false &&
            awsErr === true &&
            isUploadDone_IPFS === true &&
            ipfsErr === false
          ) {
            FileData(id);
            setIsawsErr(true);
            setAtom(false);
            // error in aws s3 upload, ipfs upload successful
          } else if (
            isUploadDone_AWSS3 === false &&
            awsErr === true &&
            isUploadDone_IPFS === false &&
            ipfsErr === true
          ) {
            FileData(id);
            setIsawsErr(true);
            setIsipfsErr(true);
            // error in aws s3 upload, error in ipfs upload
          }
        }
      }
    } catch (err) {
      setLoaderRefresh(false);
      // console.log("handleRefresh catch block:", err);
    }
  };

  const checkFileLocation = async () => {
    setViewerLoader(true);

    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: fileUniqueId,
      };

      const res = await axios.post(API_URL, data, headerConfig);
      // console.log("res?????????", res);

      // (1) file is getting uploaded on web server
      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false
      ) {
        // console.log("Block 1");
        InfoToast(`${Web_server_text}...`);
        setViewerLoader(false);
        handleCloseViewer();
      }

      // (2) upload to web server is completed. file is getting uploaded to aws s3 or ipfs or both
      // (3) upload to aws s3 or ipfs or both is completed. (file is present on server if error occurs in upload to any one or both locations for "both" upload)
      else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null
      ) {
        if (
          res?.data?.data?.uploadLocation === "aws" &&
          res?.data?.data?.isUploadDone_AWSS3 === false
        ) {
          // download from server
          handleServerDownload(true);
          // console.log("Block 2");
        } else if (
          res?.data?.data?.uploadLocation === "ipfs" &&
          res?.data?.data?.isUploadDone_IPFS === false
        ) {
          // download from server
          handleServerDownload(true);
          // console.log("Block 3");
        } else if (
          res?.data?.data?.uploadLocation === "aws" &&
          res?.data?.data?.isUploadDone_AWSS3 === true
        ) {
          // download from aws s3
          if (bucketACLOption === "public") {
            handleDownloadFile(amazonKey, fileName, fileType, awsBkt, true);
          } else if (bucketACLOption === "private") {
            downloadPrivateS3File(awsBkt, amazonKey, fileName, fileType, true);
          }
          // console.log("Block 4");
        } else if (
          res?.data?.data?.uploadLocation === "ipfs" &&
          res?.data?.data?.isUploadDone_IPFS === true
        ) {
          // download from ipfs
          handleDownloadFileIPFS(ipfsCID, "", fileType, fileName, "", "", true);
          // console.log("Block 5");
        } else if (res?.data?.data?.uploadLocation === "both") {
          if (
            res?.data?.data?.isUploadDone_AWSS3 === true &&
            res?.data?.data?.isUploadDone_IPFS === true
          ) {
            // download from ipfs
            handleDownloadFileIPFS(
              ipfsCID,
              "",
              fileType,
              fileName,
              "",
              "",
              true
            );
            // console.log("Block 6");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === true &&
            res?.data?.data?.isUploadDone_IPFS === false
          ) {
            // download from aws s3
            if (bucketACLOption === "public") {
              handleDownloadFile(amazonKey, fileName, fileType, awsBkt, true);
            } else if (bucketACLOption === "private") {
              downloadPrivateS3File(
                awsBkt,
                amazonKey,
                fileName,
                fileType,
                true
              );
            }
            // console.log("Block 7");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === false &&
            res?.data?.data?.isUploadDone_IPFS === true
          ) {
            // download from ipfs
            handleDownloadFileIPFS(
              ipfsCID,
              "",
              fileType,
              filenameUuid,
              "",
              "",
              true
            );
            // console.log("Block 8");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === false &&
            res?.data?.data?.isUploadDone_IPFS === false
          ) {
            // download from server
            handleServerDownload(true);
            // console.log("Block 9");
          }
        }
      }
    } catch (err) {
      // console.log("checkFileLocation catch block:", err);
      setViewerLoader(false);
      errorToast(err.massage);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  // ********** DICOM viewer ends here **********

  const checkFileLocation_2 = async (uploadLoc) => {
    if (uploadLoc === "ipfs") {
      setLoaderAtom_storage(true);
    } else if (uploadLoc === "aws") {
      setLoader(true);
    }

    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: fileUniqueId,
      };

      const res = await axios.post(API_URL, data, headerConfig);
      // console.log("res?????????", res);

      // (1) file is getting uploaded on web server
      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false
      ) {
        // console.log("Block 1");
        InfoToast(`${Web_server_text}...`);
        setViewerLoader(false);
        handleCloseViewer();
      }

      // (2) upload to web server is completed. file is getting uploaded to aws s3 or ipfs or both
      // (3) upload to aws s3 or ipfs or both is completed. (file is present on server if error occurs in upload to any one or both locations for "both" upload)
      else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null
      ) {
        if (
          res?.data?.data?.uploadLocation === "aws" &&
          res?.data?.data?.isUploadDone_AWSS3 === false
        ) {
          // download from server
          handleServerDownload("", uploadLoc);
          // console.log("Block 2");
        } else if (
          res?.data?.data?.uploadLocation === "ipfs" &&
          res?.data?.data?.isUploadDone_IPFS === false
        ) {
          // download from server
          handleServerDownload("", uploadLoc);
          // console.log("Block 3");
        } else if (
          res?.data?.data?.uploadLocation === "aws" &&
          res?.data?.data?.isUploadDone_AWSS3 === true
        ) {
          // download from aws s3
          if (bucketACLOption === "public") {
            handleDownloadFile(amazonKey, fileName, fileType, awsBkt);
          } else if (bucketACLOption === "private") {
            downloadPrivateS3File(awsBkt, amazonKey, fileName, fileType);
          }
          // console.log("Block 4");
        } else if (
          res?.data?.data?.uploadLocation === "ipfs" &&
          res?.data?.data?.isUploadDone_IPFS === true
        ) {
          // download from ipfs
          handleDownloadFileIPFS(ipfsCID, "", fileType, fileName, "", "");
          // console.log("Block 5");
        } else if (res.data.data.uploadLocation === "both") {
          if (
            res?.data?.data?.isUploadDone_AWSS3 === true &&
            res?.data?.data?.isUploadDone_IPFS === true
          ) {
            // download from ipfs
            handleDownloadFileIPFS(ipfsCID, "", fileType, fileName, "", "");
            // console.log("Block 6");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === true &&
            res?.data?.data?.isUploadDone_IPFS === false
          ) {
            // download from aws s3
            if (bucketACLOption === "public") {
              handleDownloadFile(amazonKey, fileName, fileType, awsBkt, true);
            } else if (bucketACLOption === "private") {
              downloadPrivateS3File(awsBkt, amazonKey, fileName, fileType);
            }
            // console.log("Block 7");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === false &&
            res?.data?.data?.isUploadDone_IPFS === true
          ) {
            // download from ipfs
            handleDownloadFileIPFS(ipfsCID, "", fileType, filenameUuid, "", "");
            // console.log("Block 8");
          } else if (
            res?.data?.data?.isUploadDone_AWSS3 === false &&
            res?.data?.data?.isUploadDone_IPFS === false
          ) {
            // download from server
            handleServerDownload("", uploadLoc);
            // console.log("Block 9");
          }
        }
      }
    } catch (err) {
      // console.log("checkFileLocation_2 catch block:", err);
      if (uploadLoc === "ipfs") {
        setLoaderAtom_storage(false);
      } else if (uploadLoc === "aws") {
        setLoader(false);
      }
      errorToast(err.massage);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  // const openOhifViewer = () => {
  //   console.log(location?.state?.fileData?.file_unique_id);
  //   const studyInstanceUID = "1.3.46.670589.11.0.1.1996082307380006";
  //   window.open(`/viewer/${studyInstanceUID}`, "_blank");
  // };

  const openOhifViewer = async () => {
    // console.log(location?.state?.fileData?.file_unique_id);
    setopenOhifViewer(true);
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      const data = {
        fileId: location?.state?.fileData?.file_unique_id,
      };
      let API_URL =
        "https://ohifviewer.moramba.io/api/atom/viewer/v1/restapi/file-view";

      let res = await axios.post(API_URL, data, headerConfig);
      // console.log(res);
      // const studyInstanceUID = res?.data?.ParentStudy;
      setopenOhifViewer(false);
      // console.log(res?.data?.data);
      window.open(`/viewer/${res?.data?.data}`, "_blank");
    } catch (err) {
      setopenOhifViewer(false);
      if (err?.response && err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const handleFileProcessing = () => {
    setIsProcessing(true);
    setProcessingMessage(
      "Processing file, please wait... This may take a few moments."
    );

    try {
      // await processLargeFile();
      // setIsFileReady(true);
      const response = [];
      navigate("/jviewer", { state: { fileData: response?.data } });
    } catch (error) {
      setProcessingMessage(
        "An error occurred during file processing. Please try again."
      );
      console.error("File processing failed", error);
    } finally {
      setIsProcessing(false);
    }
  };
  // const processLargeFile = () => {
  //   // Replace this with your actual file processing logic
  //   return new Promise((resolve) => setTimeout(resolve, 10000)); // Simulate longer processing time
  // };
  return (
    <>
      <div className="vf-viewfile w-100">
        <Header />
        <div className="set-mm"></div>

        {/* General viewer modal begins here */}
        <Modal
          open={openGenViewer}
          onClose={handleCloseGenViewer}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style_2} className="Viewer-modal">
            <HighlightOffOutlinedIcon
              className="viewer-cross-btn"
              onClick={handleCloseGenViewer}
            />
            <Viewer
              fileURL={genFileURL}
              fileType={fileType}
              fileName={fileName}
            />
            <center>
              <button
                className="viewer-close-btn mt-1"
                onClick={handleCloseGenViewer}
              >
                {text_close}
              </button>
            </center>
          </Box>
        </Modal>
        {/* General viewer modal ends here */}

        {/* Viewer modal begins here */}
        <Modal
          open={openViewer}
          onClose={handleCloseViewerFunc}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="Viewer-modal">
            {viewerLoader ? (
              <div className="viewer-loader">
                <Loader3 />
              </div>
            ) : (
              <>
                <HighlightOffOutlinedIcon
                  className="viewer-cross-btn"
                  onClick={handleCloseViewerFunc}
                />
                <b>{fileName}</b>
                <hr />
                <div
                  id="layerGroup0"
                  style={{ width: "100%", height: "88%" }}
                ></div>
                <center>
                  <button
                    className="viewer-close-btn mt-1"
                    onClick={handleCloseViewerFunc}
                  >
                    {text_close}
                  </button>
                </center>
              </>
            )}
          </Box>
        </Modal>
        {/* Viewer modal ends here */}

        <div className="vf-main">
          <div className="vf-title">
            {text_filedetails}
            <span className="title-fileName" title={fileName}>
              {fileName}
            </span>
          </div>
          <hr className="vf-hr" />
          <div className="vf-5">
            <div className="d-flex vf-title vf-title-2 gap-3">
              {/* <div className="vf-title">View File</div> */}
              <div className="">{text_filedata}</div>
              {showDWVBtn && (
                <a
                  onClick={() => {
                    // const studyInstanceUID =
                    //   "1.3.46.670589.11.0.1.1996082307380006";
                    // window.open(`/viewer/${studyInstanceUID}`, "_blank");
                    openOhifViewer();
                  }}
                  className="fs-5 mt-3 viewerText"
                >
                  {openohifViewer === true ? "Opening" : text_opendicom}
                </a>
                // <p
                //   className="fs-5 mt-3 viewerText"
                //   onClick={() => [handleOpenViewer(), checkFileLocation()]}
                // >
                //   {text_opendicom}
                // </p>
              )}

              {showGenViewer && (
                <p
                  className="fs-5 mt-3 viewerText"
                  onClick={() =>
                    navigate("/viewer", {
                      state: {
                        fileUniqueId: fileUniqueId,
                        filenameUuid: filenameUuid,
                        fileType: fileType,
                        bucketACLOption: bucketACLOption,
                        amazonKey: amazonKey,
                        fileName: fileName,
                        awsBkt: awsBkt,
                        ipfsCID: ipfsCID,
                      },
                    })
                  }
                >
                  Open Viewer
                </p>
              )}
              {jbrowserViewer && (
                <p
                  className="fs-5 mt-3 viewerText"
                  // onClick={() => navigate("/jviewer")}
                  onClick={handleFileProcessing}
                  disabled={isProcessing}
                >
                  {isProcessing ? processingMessage : "Open JBrowse Viewer"}
                </p>
              )}
            </div>
            <div className="d-flex justify-content-end gap-4">
              <button
                className="back-btn-view"
                style={{ marginTop: "35px" }}
                onClick={() =>
                  navigate("/my-files", {
                    state: { selectedTab: selecttab },
                  })
                }
              >
                <span>
                  <MdOutlineKeyboardBackspace className="back-btn-icon" />
                </span>
                <span>{text_back}</span>
              </button>
              {(readAccess === true && writeAccess === true) ||
              location?.state?.AccessData === undefined ||
              location?.state?.sharebyMe === "sharebyMe" ? (
                <button
                  className="vf-edit-btn"
                  onClick={() =>
                    navigate("/edit-file", {
                      state: {
                        EditFileData: location?.state?.fileData,
                        selectedTab: selecttab,
                      },
                    })
                  }
                >
                  <EditIcon />
                  {text_edit}
                </button>
              ) : (
                ""
              )}
              {/* {showDWVBtn && (
              <button onClick={() => [handleOpenViewer(), checkFileLocation()]}>
                Open DICOM Viewer
              </button>
            )} */}
            </div>
          </div>
          <div className="vf-1">
            {web_server === true ? (
              <>
                <b className="text-danger fs-5">{Web_server_text}...</b>
                <Tooltip
                  title={text_Refresh}
                  arrow
                  onClick={() => checkStatusUploadInfo(fileUniqueId)}
                >
                  <button className="refreshtooltip" title={text_Refresh}>
                    {loaderRefresh === true ? (
                      <>
                        <Loader2 />
                      </>
                    ) : (
                      <>
                        <IoReload className="text-danger fs-5" />
                      </>
                    )}
                  </button>
                </Tooltip>
              </>
            ) : (
              <>
                {/* -----------  */}

                {amazonCheck && (
                  <div className="uf-inputs-main vf-input">
                    {isawsErr === true ? (
                      <>
                        <b className="text-danger fs-5">{AWS_upload_err}</b>
                      </>
                    ) : (
                      <>
                        {AWS === true ? (
                          <>
                            <b className="text-danger fs-5">{AWS_text}...</b>
                            <Tooltip
                              title={text_Refresh}
                              arrow
                              onClick={() =>
                                checkStatusUploadInfo(fileUniqueId)
                              }
                            >
                              <button
                                className="refreshtooltip"
                                title={text_Refresh}
                              >
                                {loaderRefresh === true ? (
                                  <>
                                    <Loader2 />
                                  </>
                                ) : (
                                  <>
                                    <IoReload className="text-danger fs-5" />
                                  </>
                                )}
                              </button>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            {location?.state?.fileData?.bucketACLOption ===
                            "public" ? (
                              <>
                                {" "}
                                <div className="flex-column">
                                  <label className="login-labels">
                                    {text_amazons3link1}
                                  </label>
                                </div>
                                <div className="inputForm uf-inputs mt-1 vf-input-inner">
                                  <input
                                    type="text"
                                    className="input login-input vf-input-i"
                                    value={amazonLink}
                                    placeholder={text_amazons3link1}
                                    disabled
                                  />
                                  <Tooltip
                                    PopperProps={{
                                      disablePortal: true,
                                    }}
                                    arrow
                                    className="mt-5 ml-2"
                                    open={openTooltipcid}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    title={`${text_copied}!`}
                                  ></Tooltip>
                                  {amazonLink === "NA" ? (
                                    ""
                                  ) : (
                                    <ContentCopyIcon
                                      className="text-black copysize copy-sty"
                                      onClick={() => [
                                        navigator.clipboard.writeText(
                                          amazonLink
                                        ),
                                        setOpenTooltipcid(true),
                                      ]}
                                      onMouseOut={onMouseOutcid}
                                    />
                                  )}
                                </div>
                                <div className="flex-column mt-3">
                                  <label className="login-labels">
                                    {text_amazonlocation}
                                  </label>
                                </div>
                                <div className="inputForm uf-inputs mt-1 vf-input-inner">
                                  <input
                                    type="text"
                                    className="input login-input vf-input-i"
                                    value={amazonKey}
                                    placeholder={text_amazonlocation}
                                    disabled
                                  />
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="flex-column">
                                  <label className="login-labels">
                                    {text_amazonlocation}
                                  </label>
                                </div>
                                <div className="inputForm uf-inputs mt-1 vf-input-inner">
                                  <input
                                    type="text"
                                    className="input login-input vf-input-i"
                                    value={amazonKey}
                                    placeholder={text_amazonlocation}
                                    disabled
                                  />
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                )}

                {/* ---------- */}
                {/* -----------  */}
                {ipfsCheck && (
                  <div className="uf-inputs-main vf-input">
                    {isipfsErr === true ? (
                      <>
                        <b className="text-danger fs-5">{Atom_upload_err}</b>
                      </>
                    ) : (
                      <>
                        {Atom === true ? (
                          <>
                            <b className="text-danger fs-5">{Atom_text}...</b>
                            <Tooltip
                              title={text_Refresh}
                              arrow
                              onClick={() =>
                                checkStatusUploadInfo(fileUniqueId)
                              }
                            >
                              <button
                                className="refreshtooltip"
                                title={text_Refresh}
                              >
                                {loaderRefresh === true ? (
                                  <>
                                    <Loader2 />
                                  </>
                                ) : (
                                  <>
                                    <IoReload className="text-danger fs-5" />
                                  </>
                                )}
                              </button>
                            </Tooltip>
                          </>
                        ) : (
                          <div className="">
                            <div className="flex-column">
                              <label className="login-labels">
                                {text_atomfileid}
                              </label>
                            </div>
                            <div className="inputForm uf-inputs mt-1 vf-input-inner">
                              <img
                                src={cIDImg}
                                alt=""
                                className="login-emailphone"
                              />
                              <input
                                type="text"
                                className="input login-input vf-input-i"
                                value={ipfsCID}
                                placeholder={text_atomstore}
                                disabled
                              />
                              <Tooltip
                                PopperProps={{
                                  disablePortal: true,
                                }}
                                arrow
                                className="mt-5 ml-2"
                                open={openTooltip}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                title={`${text_copied}!`}
                              ></Tooltip>
                              {ipfsCID === "NA" ? (
                                ""
                              ) : (
                                <ContentCopyIcon
                                  className="text-black copysize copy-sty"
                                  onClick={() => [
                                    navigator.clipboard.writeText(ipfsCID),
                                    setOpenTooltip(true),
                                  ]}
                                  onMouseOut={onMouseOutamazon}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}

                {/* ---------- */}
              </>
            )}
          </div>
          <div className="vf-2">
            <DataTable
              columns={
                (ipfsCheck === true && amazonCheck === true) ||
                (ipfsCheck === false && amazonCheck === true)
                  ? columns
                  : columns3
              }
              data={
                (ipfsCheck === true && amazonCheck === true) ||
                (ipfsCheck === false && amazonCheck === true)
                  ? data
                  : data3
              }
              fixedHeader
              selectableRowsHighlight
              highlightOnHover
              customStyles={customTableStyles}
              showPaginationBottom={false}
            />
          </div>
          {/*****If Medical Data have*****/}
          {isMedData && (
            <div className="vf-3">
              <div className="vf-title vf-title-2">{text_medicaldata}</div>
            </div>
          )}

          {isMedData && (
            <div className="vf-4">
              <DataTable
                columns={columns2}
                data={data2}
                fixedHeader
                selectableRowsHighlight
                highlightOnHover
                customStyles={customTableStyles}
                showPaginationBottom={false}
              />
            </div>
          )}

          <h5 style={{ marginTop: "35px", color: "red" }}>{deleteDate}</h5>

          <div className="vf-6 mt-5">
            {(readAccess === true &&
              writeAccess === true &&
              downloadAccess === true) ||
            location?.state?.AccessData === undefined ||
            location?.state?.selecttab === 4 ? (
              <>
                {" "}
                {/*****Amazon S3 Download*/}
                {amazonCheck === true && ipfsCheck === false ? (
                  <button
                    className="vf-btn"
                    onClick={() => [
                      // console.log(location),
                      location?.state?.fileData?.bucketACLOption === "public"
                        ? // ? handleDownloadFile(
                          //     location?.state?.fileData?.amazonKey,
                          //     location?.state?.fileData?.fileName,
                          //     location?.state?.fileData?.fileType,
                          //     location?.state?.fileData?.bucketName
                          //   )
                          checkFileLocation_2("aws")
                        : // : downloadPrivateS3File(
                          //     location?.state?.fileData?.bucketName,
                          //     location?.state?.fileData?.amazonKey,
                          //     location?.state?.fileData?.fileName,
                          //     location?.state?.fileData?.fileType
                          //   ),
                          checkFileLocation_2("aws"),
                    ]}
                  >
                    {loader === true ? (
                      <>
                        <span className="vf-downloadtext">
                          {isawsErr === true
                            ? text_serverdownload
                            : text_downfromamazons3}
                        </span>
                        <Loader2 />
                      </>
                    ) : (
                      <>
                        <LuDownload className="download-btn-icon" />
                        <span className="vf-downloadtext">
                          {isawsErr === true
                            ? text_serverdownload
                            : text_downfromamazons3}
                        </span>
                      </>
                    )}
                  </button>
                ) : amazonCheck === false && ipfsCheck === true ? (
                  <>
                    {/*****Atom storage Download*/}
                    <button
                      className="vf-btn"
                      onClick={() =>
                        // handleDownloadFileIPFS(
                        //   ipfsCID,
                        //   filenameUuid,
                        //   fileType,
                        //   fileName
                        // )
                        checkFileLocation_2("ipfs")
                      }
                    >
                      {loaderAtom_storage ? (
                        <>
                          <span className="vf-downloadtext">
                            {isipfsErr === true
                              ? text_serverdownload
                              : text_atomstoragenetwork}
                          </span>
                          <Loader2 />
                        </>
                      ) : (
                        <>
                          <LuDownload className="download-btn-icon" />
                          <span className="vf-downloadtext">
                            {isipfsErr === true
                              ? text_serverdownload
                              : text_atomstoragenetwork}
                          </span>
                        </>
                      )}
                    </button>
                  </>
                ) : (
                  <div className="vf-both-dl-btn-div">
                    <div className="vf-both-dl-btn-div-1">
                      {text_downloadfrom}
                    </div>
                    <div className="vf-both-dl-btn-div-2">
                      {/* For atom storage or amazon s3 File Download */}
                      <Form>
                        <div className="mb-3 vf-both-dl-btn-div-21">
                          <Form.Check
                            // inline
                            label={text_amazons3}
                            name="group1"
                            type="radio"
                            id="inline-radio-1"
                            value="Amazon S3"
                            checked={selectedValue === "Amazon S3"}
                            onChange={handleRadioChange}
                          />
                          <Form.Check
                            // inline
                            label={text_asn}
                            name="group1"
                            type="radio"
                            id="inline-radio-2"
                            value="Atom Storage Network"
                            checked={selectedValue === "Atom Storage Network"}
                            onChange={handleRadioChange}
                          />
                        </div>
                      </Form>
                    </div>
                    <div className="vf-both-dl-btn-div-3">
                      <button
                        className="vf-btn vf-btn-last"
                        onClick={() =>
                          selectedValue === "Amazon S3"
                            ? location?.state?.fileData?.bucketACLOption ===
                              "public"
                              ? // ? handleDownloadFile(
                                //     location?.state?.fileData?.amazonKey,
                                //     location?.state?.fileData?.fileName,
                                //     location?.state?.fileData?.fileType,
                                //     location?.state?.fileData?.bucketName
                                //   )
                                checkFileLocation_2("aws")
                              : // : downloadPrivateS3File(
                                //     location?.state?.fileData?.bucketName,
                                //     location?.state?.fileData?.amazonKey,
                                //     location?.state?.fileData?.fileName,
                                //     location?.state?.fileData?.fileType
                                //   )
                                checkFileLocation_2("aws")
                            : // : handleDownloadFileIPFS(
                              //     ipfsCID,
                              //     filenameUuid,
                              //     fileType,
                              //     fileName
                              //   )
                              checkFileLocation_2("ipfs")
                        }
                      >
                        <LuDownload className="download-btn-icon" />
                        <span className="vf-downloadtext">
                          {selectedValue === "Amazon S3" ? (
                            <div className="d-flex">
                              <span>
                                {isawsErr === true
                                  ? text_serverdownload
                                  : text_downfromamazons3}
                              </span>{" "}
                              {loader && <Loader2 />}
                            </div>
                          ) : (
                            <div className="d-flex">
                              <span>
                                {isipfsErr === true
                                  ? text_serverdownload
                                  : text_atomstoragenetwork}
                              </span>{" "}
                              {loaderAtom_storage && <Loader2 />}
                            </div>
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ViewFile;

// ------------------
