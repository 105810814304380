import React, { useState, useEffect } from "react";
import FilterComponent from "../../utils/Files/FilterComponent";
import { useMemo } from "react";
import DataTable from "react-data-table-component";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import { customTableStyles } from "../../utils/Files/tableStyle";
import { LuDownload } from "react-icons/lu";
import {
  GLOBAL_URL_DOMAIN_LIVE,
  GLOBAL_URL_DOMAIN_File_Upload_Live,
} from "../../utils/Files/url";
import axios from "axios";
import AWS from "aws-sdk";
import { GlobalConstants } from "../../utils/globalconstant";
import Loader3 from "../../utils/Files/data";
import Loader2 from "../../Components/Loader2/Loader2";
import Cookie from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { getsharewithmedata } from "../../redux/sharewithmeslice";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function ShareWithMe() {
  //variable
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Data = useSelector((state) => state.sharewithmedata.shareWithMeData);
  const folderData = useSelector((state) => state.sharewithmedata.FolderData);
  const ownerData = useSelector((state) => state.sharewithmedata.OwnerData);
  const sharewithmeloader = useSelector(
    (state) => state.sharewithmedata.sharewithmeLoader
  );

  const require_token = sessionStorage.getItem("userToken");
  const userID = sessionStorage.getItem("userID");
  // const [folderData, setFolderData] = useState([]);
  // const [Data, setData] = useState([]);
  // const [ownerData, setOwnerData] = useState([]);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  // const [loaderData, setLoaderData] = useState(false);
  const [loader, setLoader] = useState(false + "");

  //language variable
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_filename1, setText_filename1] = useState("FILE NAME");
  const [text_filesize, setText_filesize] = useState("FILE SIZE");
  const [text_dateofupload, setText_dateofupload] = useState("DATE OF UPLOAD");
  const [text_viewdetails, setText_viewdetails] = useState("VIEW DETAILS");
  const [text_view, setText_view] = useState("View");
  const [text_edit, setText_edit] = useState("EDIT");
  const [text_download, setText_download] = useState("Download");
  const [text_ownername, setText_ownername] = useState("Owner Name");
  const [text_searchhere, setText_searchhere] = useState("Search Here...");

  ///****** get All Shared files */

  ///****** language */
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_ownername(
        xpath.select1("/resources/string[@name='text_ownername']", doc)
          .firstChild.data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_edit(
        xpath.select1("/resources/string[@name='text_edit']", doc).firstChild
          .data
      );
      setText_viewdetails(
        xpath.select1("/resources/string[@name='text_viewdetails']", doc)
          .firstChild.data
      );
      setText_view(
        xpath.select1("/resources/string[@name='text_view']", doc).firstChild
          .data
      );
      setText_searchhere(
        xpath.select1("/resources/string[@name='text_searchhere']", doc)
          .firstChild.data
      );
      setText_filename1(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );
      setText_filesize(
        xpath.select1("/resources/string[@name='text_filesize']", doc)
          .firstChild.data
      );
      setText_dateofupload(
        xpath.select1("/resources/string[@name='text_dateofupload']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };

  useEffect(() => {
    if (Data?.length === 0) {
      dispatch(getsharewithmedata());
    }
  }, [Data?.length]);

  const filteredItems = Data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  // **************************************

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    // console.log(filteredItems);

    return (
      <>
        <div className="row">
          <div className="col-md-6"></div>
          <div className="text-end col-md-6 mb-2">
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
              PlaceHolder={text_searchhere}
              className="fc-input"
              clearClass="fc-x"
            />
          </div>
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, text_searchhere]);

  ////******download file amazon public bucket */
  const handleDownloadFile = async (
    File_key,
    FileFinalName,
    i,
    row_id,
    file_type,
    bucket_aws
  ) => {
    setLoader(true + i);
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucket_aws; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("response****", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (link.href !== "") {
        setLoader(false);
      }
    } catch (err) {
      // console.log("stream_s3_download error:", err);
      setLoader(false);
    }
  };
  ////******download file amazon private bucket */
  const downloadPrivateS3File = async (
    bucketName,
    File_key,
    FileFinalName,
    i,
    row_id,
    file_type
  ) => {
    setLoader(true + i);
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucketName; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3-private`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("response****", res);

      const blobData = new Blob([res?.data], { type: fileType });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (link.href !== "") {
        setLoader(false);
      }
    } catch (err) {
      // console.log("stream_s3_download error:", err);
      setLoader(false);
    }
  };
  ///****** Download from ipfs */
  const handleDownloadFile_ipfs = async (
    folder_cid,
    file_format,
    File_type,
    File_Name,
    i
  ) => {
    setLoader(true + i);

    try {
      const cid = folder_cid;
      const filename = File_Name;
      const fileType = File_type;

      // console.log("my filename*******", filename);
      // console.log("my fileType*****", fileType);

      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-ipfs?filename=${filename}&filecid=${cid}`;

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.get(url, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("my res***********", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove(); // Clean up after download

      if (res?.status === 200) {
        setLoader(false);
      }
    } catch (err) {
      // console.log("downloadFile error:", err);
      setLoader(false);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };

  const columns = [
    {
      name: <>{text_srno}</>,
      sortable: true,
      selector: (row, index) => <div className="yf-srno">{index + 1}</div>,
      width: "100px",
    },
    {
      name: <>{text_filename1}</>,
      selector: (row) => row?.fileName,
    },
    {
      name: <>{text_ownername}</>,
      selector: (row, i) =>
        ownerData[i]?.firstName + " " + ownerData[i]?.lastName,
    },

    {
      name: <>{text_filesize}</>,
      selector: (row) => row?.fileSize,
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => moment(row?.uploadDateAndTime).format("MMM DD, YYYY"),
    },
    // {
    //   name: <>{text_viewdetails}</>,
    //   selector: (row, index) => (
    //     <button
    //       className={
    //         folderData[index]?.read_access === true ||
    //         folderData[index]?.write_access === true ||
    //         folderData[index]?.download_access === true
    //           ? "yf-download-btn"
    //           : "disable-Btn-share"
    //       }
    //       disabled={
    //         folderData[index]?.read_access === true ||
    //         folderData[index]?.write_access === true ||
    //         folderData[index]?.download_access === true
    //           ? false
    //           : true
    //       }
    //       onClick={() => {
    //         console.log(row?.bucketACLOption);
    //         navigate("/view-file", {
    //           state: {
    //             fileData: row,
    //             AccessData: folderData[index],
    //             selecttab: 3,
    //           },
    //         });
    //       }}
    //     >
    //       <span className="yf-download">{text_view}</span>
    //     </button>
    //   ),
    //   width: "150px",
    // },

    {
      name: <>{text_viewdetails}</>,
      selector: (row, index) => {
        const findMatchingFolderData = (fileUniqueId) => {
          return folderData.find(
            (item) => item.file_unique_id === fileUniqueId
          );
        };

        const matchingFolderData = findMatchingFolderData(row?.file_unique_id);
        const hasReadAccess = matchingFolderData?.read_access === true;

        return (
          <button
            className={hasReadAccess ? "yf-download-btn" : "disable-Btn-share"}
            disabled={!hasReadAccess}
            onClick={() => {
              if (hasReadAccess) {
                // console.log(
                //   row?.file_unique_id,
                //   matchingFolderData?.file_unique_id,
                //   matchingFolderData?.write_access,
                //   index
                // );
                navigate("/view-file", {
                  state: {
                    fileData: row,
                    AccessData: matchingFolderData,
                    selecttab: 3,
                  },
                });
              }
            }}
          >
            <span className="yf-download">{text_view}</span>
          </button>
        );
      },
      width: "150px",
    },

    // {
    //   name: <>{text_edit}</>,
    //   selector: (row, index) => (
    //     <button
    //       className={
    //         folderData[index]?.write_access === true ||
    //         folderData[index]?.download_access === true
    //           ? "yf-ed-btn"
    //           : "disable-Btn-share"
    //       }
    //       disabled={
    //         folderData[index]?.write_access === true ||
    //         folderData[index]?.download_access === true
    //           ? false
    //           : true
    //       }
    //       onClick={() =>
    //         navigate("/edit-file", {
    //           state: { EditFileData: row, selectedTab: 3 },
    //         })
    //       }
    //     >
    //       <EditIcon className="yf-ed-icon" />
    //     </button>
    //   ),
    //   width: "150px",
    // },
    {
      name: <>{text_edit}</>,
      selector: (row, index) => {
        const findMatchingFolderData = (fileUniqueId) => {
          return folderData.find(
            (item) => item.file_unique_id === fileUniqueId
          );
        };

        // Get the matching folder data for the current row
        const matchingFolderData = findMatchingFolderData(row?.file_unique_id);
        const hasWriteAccess = matchingFolderData?.write_access === true;

        return (
          <button
            className={hasWriteAccess ? "yf-ed-btn" : "disable-Btn-share"}
            disabled={!hasWriteAccess}
            onClick={() => {
              if (hasWriteAccess) {
                navigate("/edit-file", {
                  state: { EditFileData: row, selectedTab: 3 },
                });
              }
            }}
          >
            <EditIcon className="yf-ed-icon" />
          </button>
        );
      },
      width: "150px",
    },
    // {
    //   name: <>{text_download}</>,
    //   selector: (row, index) => (
    //     <button
    //       className={
    //         folderData[index]?.download_access === true
    //           ? "download-btn"
    //           : "disable-Btn-share"
    //       }
    //       onClick={() =>
    //         row?.amazonKey === "" || row?.amazonKey === null
    //           ? handleDownloadFile_ipfs(
    //               row?.fileCId,
    //               row?.fileNameWithUuid,
    //               row?.fileType,
    //               row?.fileName,
    //               index
    //             )
    //           : row?.bucketACLOption === "public"
    //           ? handleDownloadFile(
    //               row?.amazonKey,
    //               row?.fileName,
    //               index,
    //               row?._id,
    //               row?.fileType,
    //               row?.bucketName
    //             )
    //           : downloadPrivateS3File(
    //               row?.bucketName,
    //               row?.amazonKey,
    //               row?.fileName,
    //               index,
    //               row?._id,
    //               row?.fileType
    //             )
    //       }
    //       disabled={folderData[index]?.download_access === true ? false : true}
    //     >
    //       {loader === true + index ? (
    //         <Loader2 />
    //       ) : (
    //         <LuDownload className="download-btn-icon" />
    //       )}
    //     </button>
    //   ),
    //   width: "150px",
    // },
    {
      name: <>{text_download}</>,
      selector: (row, index) => {
        const findMatchingFolderData = (fileUniqueId) => {
          return folderData.find(
            (item) => item.file_unique_id === fileUniqueId
          );
        };

        const matchingFolderData = findMatchingFolderData(row?.file_unique_id);
        const hasDownloadAccess = matchingFolderData?.download_access === true;

        return (
          <button
            className={hasDownloadAccess ? "download-btn" : "disable-Btn-share"}
            onClick={() => {
              if (hasDownloadAccess) {
                if (row?.amazonKey === "" || row?.amazonKey === null) {
                  handleDownloadFile_ipfs(
                    row?.fileCId,
                    row?.fileNameWithUuid,
                    row?.fileType,
                    row?.fileName,
                    index
                  );
                } else if (row?.bucketACLOption === "public") {
                  handleDownloadFile(
                    row?.amazonKey,
                    row?.fileName,
                    index,
                    row?._id,
                    row?.fileType,
                    row?.bucketName
                  );
                } else {
                  downloadPrivateS3File(
                    row?.bucketName,
                    row?.amazonKey,
                    row?.fileName,
                    index,
                    row?._id,
                    row?.fileType
                  );
                }
              }
            }}
            disabled={!hasDownloadAccess}
          >
            {loader === true + index ? (
              <Loader2 />
            ) : (
              <LuDownload className="download-btn-icon" />
            )}
          </button>
        );
      },
      width: "150px",
    },
  ];
  return (
    <>
      <div className="yf-ipfs-main mt-5">
        <div>
          {subHeaderComponent}
          {sharewithmeloader === true ? (
            <>
              <div className="text-center">
                <Loader3 />
              </div>
            </>
          ) : (
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              fixedHeader
              selectableRowsHighlight
              highlightOnHover
              customStyles={customTableStyles}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default ShareWithMe;
