import { createSlice } from "@reduxjs/toolkit";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";

const BothFilesSlice = createSlice({
  name: "Both File Data",
  initialState: {
    bothData: [],
    bothloader: false,
  },
  reducers: {
    setData(state, action) {
      // console.log("**********", action.payload);
      return { ...state, bothData: action.payload };
    },
    BothLoader(state, action) {
      return { ...state, bothloader: action.payload };
    },
  },
});
const { setData, BothLoader } = BothFilesSlice.actions;
export default BothFilesSlice.reducer;

export function getBothFileData() {
  return async function getBothFileDataThunk(dispatch, getstate) {
    try {
      dispatch(BothLoader(true));
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/filedata?userId=${sessionStorage.getItem("userID")}`;

      axios
        .get(API_URL, headerConfig)
        .then((res) => {
          // console.log("get data both res***", res);
          const sortedData = res?.data?.data?.sort((a, b) => {
            // Assuming you want to sort by a property called "createdAt"
            return new Date(b.createdAt) - new Date(a.createdAt);
          });
          if (sortedData.length > 0) {
            dispatch(setData(sortedData));
          }
          dispatch(BothLoader(false));
          //   setLoaderData(false);
        })
        .catch((err) => {
          dispatch(BothLoader(false));
          //   setLoaderData(false);
          // console.log("get data both err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            window.location.replace("/");
            // navigate("/");
          }
        })
        .then(function () {});
    } catch (err) {
      console.log(err);
    }
  };
}

export function resetBothfileData() {
  return async function resetBothfileDataThunk(dispatch, getState) {
    try {
      dispatch(setData([]));
      dispatch(BothLoader(false));
      // dispatch(setNavigateData(false));
    } catch (err) {
      console.log(err);
    }
  };
}
