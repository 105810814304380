import React, { useEffect, useState } from "react";
import "./Header.css";
import AtomImg from "../../utils/images/atom_1.png";
import AtomImgActive from "../../utils/images/atom_2.png";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import LetterAvatars from "../../Components/LetterAvatar/LetterAvatar";
import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Toolbar,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import moment from "moment";
import { errorToast } from "../../utils/Files/toasterMsgs";
import axios from "axios";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import { GlobalConstants } from "../../utils/globalconstant";
import { useDispatch } from "react-redux";
import { resetSelectBucketData } from "../../redux/SelectBucketSlice";
import { resetShareWithMeData } from "../../redux/sharewithmeslice";
import { resetUserListData } from "../../redux/UserListSlice";
import { resetIPFSrootfileData } from "../../redux/IPFSRootFolderSlice";
import { resetAwsRootFolderData } from "../../redux/AwsRootFolderSlice";
import { resetGetBucketData } from "../../redux/GetBucketdataSlice";
import { resetBothfileData } from "../../redux/BothFilesSlice";
import { resetgetCompanyData } from "../../redux/CompanyProfileSlice";
import { resetgetRegisterdUser } from "../../redux/RegisteredUserSlice";
import { resetEditFileData } from "../../redux/EditFileDataSlice";
import { resetSharedByMeData } from "../../redux/SharedByMeSlice";

const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoggedin, setIsLoggedIn] = useState(true);
  const [headerColorChange, setHeaderColorChange] = useState(false);
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  // new header
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [openLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => setOpenLogout(true);
  const handleCloseLogout = () => setOpenLogout(false);
  //language variable start
  const [text_atomstorage, setText_atomstorage] = useState("Atom Storage"); //logo name//
  const [text_uploadfile, setText_uploadfile] = useState("Upload File");
  const [text_myfiles, setText_myfile] = useState("My Files");
  const [text_signup, setText_signup] = useState("Sign Up");
  const [text_warning, setText_warning] = useState(
    "Are you sure you want to Sign Out?"
  );
  const [text_yes, setText_yes] = useState("Yes");
  const [text_no, setText_no] = useState("No");
  const [text_adduser, setText_adduser] = useState("Add User");
  //language variable end

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 530,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };
  useEffect(() => {
    const pathnames = [
      "/",
      "/signin",
      "/forgot-password",
      "/support",
      "/privacypolicy",
      "/termsofservice",
      "/about-us",
      "/contact-us",
    ];

    if (pathnames.includes(window.location.pathname)) {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);

  useEffect(() => {
    setFName(() => sessionStorage.getItem("fName"));
    setLName(() => sessionStorage.getItem("lName"));
    setEmail(() => sessionStorage.getItem("email"));
  }, []);

  const handleSignout = () => {
    Cookie.remove("userToken");
    Cookie.remove("userID");
    Cookie.remove("fName");
    Cookie.remove("lName");
    Cookie.remove("token_start_time");
    dispatch(resetSelectBucketData());
    dispatch(resetShareWithMeData());
    dispatch(resetUserListData());
    dispatch(resetIPFSrootfileData());
    dispatch(resetAwsRootFolderData());
    dispatch(resetGetBucketData());
    dispatch(resetBothfileData());
    dispatch(resetgetCompanyData());
    dispatch(resetgetRegisterdUser());
    dispatch(resetEditFileData());
    dispatch(resetSharedByMeData());

    // sessionStorage.setItem("ProgressBox", "false");
    const progressBoxValue = sessionStorage.getItem("ProgressBox");
    if (progressBoxValue === "true") {
      sessionStorage.clear();
      localStorage.clear();
      // navigate("/");
      window.location.replace("/");
    } else {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/");
    }
  };
  const languageHandle = (e) => {
    const languageCode = e;
    // console.log(languageCode);
    const request_start_at = performance.now();

    // console.log("first");
    var apiUrl =
      GLOBAL_URL_DOMAIN_LIVE +
      "/language/selectdefaultlanguage?languageCode=" +
      languageCode;

    let headerConfig = {
      headers: {
        accept: "application/json",
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    axios
      .get(apiUrl, headerConfig)
      .then(function (response) {
        const request_end_at = performance.now();
        const request_duration = request_end_at - request_start_at;
        var res = response?.data?.data[0].defaultlanguagescreenlist;
        let xml = "<resources>";

        for (let i = 0; i < res.length; i++) {
          let d = res[i];
          for (let j = 0; j < d.defaultscreenfieldslist.length; j++) {
            var kv = d.defaultscreenfieldslist[j];

            var s = `<string name="${kv.fieldKey}"> ${kv.fieldValue}</string>`;
            xml += s;
          }
        }
        xml += "</resources>";
        GlobalConstants.appXml = xml;
        localStorage.setItem(GlobalConstants.session_lang_xml, xml);
        localStorage.setItem(
          GlobalConstants.session_default_language,
          languageCode
        );
        // console.log(languageCode, "-----------");
        try {
          SetLanguageText();
        } catch (err) {
          errorToast(err);
        }
        if (response?.status === 200) {
          // console.log(
          //   "ID:06101=> " +
          //     moment.utc(request_duration).format("ss.ms") +
          //     " Seconds"
          // );
          window.location.reload();
        }
      })
      .catch(function (error) {
        errorToast(error?.massage);
        if (error?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          window.location.replace("/");
        }
      })
      .then(function () {
        // always executed
      });
  };
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      // console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_uploadfile(
        xpath.select1("/resources/string[@name='text_uploadfile']", doc)
          .firstChild.data
      );
      setText_myfile(
        xpath.select1("/resources/string[@name='text_myfiles']", doc).firstChild
          .data
      );
      setText_signup(
        xpath.select1("/resources/string[@name='text_signup']", doc).firstChild
          .data
      );
      setText_warning(
        xpath.select1("/resources/string[@name='text_warning']", doc).firstChild
          .data
      );
      setText_yes(
        xpath.select1("/resources/string[@name='text_yes']", doc).firstChild
          .data
      );
      setText_no(
        xpath.select1("/resources/string[@name='text_no']", doc).firstChild.data
      );
      setText_adduser(
        xpath.select1("/resources/string[@name='text_adduser']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  const changeHeaderColor = () => {
    if (window.scrollY >= 1) {
      setHeaderColorChange(true);
    } else {
      setHeaderColorChange(false);
    }
  };
  window.addEventListener("scroll", changeHeaderColor);

  let UploadFile,
    YourFiles,
    adduser,
    AboutUs,
    ContactUs = "headerText";
  const pageName = window.location.pathname.split("/");
  if (pageName[pageName?.length - 1] === "upload-file") {
    UploadFile = "active1";
    YourFiles = "headerText";
    AboutUs = "headerText";
    ContactUs = "headerText";
    adduser = "headerText";
  } else if (pageName[pageName?.length - 1] === "my-files") {
    UploadFile = "headerText";
    YourFiles = "active1";
    AboutUs = "headerText";
    ContactUs = "headerText";
    adduser = "headerText";
  } else if (pageName[pageName?.length - 1] === "about-us") {
    UploadFile = "headerText";
    YourFiles = "headerText";
    AboutUs = "active1";
    ContactUs = "headerText";
    adduser = "headerText";
  } else if (pageName[pageName?.length - 1] === "contact-us") {
    UploadFile = "headerText";
    YourFiles = "headerText";
    AboutUs = "headerText";
    ContactUs = "active1";
    adduser = "headerText";
  } else if (pageName[pageName?.length - 1] === "adduser") {
    UploadFile = "headerText";
    YourFiles = "headerText";
    AboutUs = "headerText";
    ContactUs = "headerText";
    adduser = "active1";
  }

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const MobRedirect = (path) => {
    navigate("/" + path);
    handleCloseNavMenu();
  };
  const redirect = (pathname) => {
    navigate("/" + pathname);
  };
  const redirectMyfile = () => {
    let path = `/my-files`;
    navigate(path, { state: { selectedTab: 0 } });
  };
  return (
    <>
      <AppBar
        position="static"
        className={headerColorChange ? "Header-Style-active" : "Header-Style"}
        // style={{ backgroundColor: "white", color: "black" }}
      >
        <Toolbar disableGutters>
          <IconButton
            disableelevation="true"
            disableRipple
            sx={{
              mr: 2,
              ml: 2,
              display: { xs: "none", md: "flex" },
            }}
          >
            <div className="header-1" onClick={() => navigate("/selectbucket")}>
              <div className="header-groupimg-div">
                {headerColorChange ? (
                  <img src={AtomImgActive} alt="" className="header-groupimg" />
                ) : (
                  <img src={AtomImg} alt="" className="header-groupimg" />
                )}
              </div>
              <div className="header-12">{text_atomstorage}</div>
            </div>
          </IconButton>
          <Box>
            <Typography
              variant="h5"
              sx={{
                mr: 2,
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
              }}
            >
              <div
                className="header-1"
                onClick={() => navigate("/selectbucket")}
              >
                <div className="header-groupimg-div">
                  {headerColorChange ? (
                    <img
                      src={AtomImgActive}
                      alt=""
                      className="header-groupimg"
                    />
                  ) : (
                    <img src={AtomImg} alt="" className="header-groupimg" />
                  )}
                </div>
                <div className="header-12">{text_atomstorage}</div>
              </div>
            </Typography>
          </Box>
          <Box
            sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            className="responsive-button"
          >
            <Button
              className={
                headerColorChange
                  ? UploadFile === "active1"
                    ? "color-change-true-active-true-i"
                    : "color-change-true-active-false-i"
                  : UploadFile === "active1"
                  ? "color-change-false-active-true-i"
                  : "color-change-false-active-false-i"
              }
              onClick={() => redirect("upload-file")}
              style={{
                marginInlineStart: 50,
                color: "black",
                fontWeight: 550,
                fontSize: "medium",
                textTransform: "capitalize",
              }}
              id={UploadFile}
            >
              {text_uploadfile}
            </Button>

            <Button
              className={
                headerColorChange
                  ? YourFiles === "active1"
                    ? "color-change-true-active-true-i"
                    : "color-change-true-active-false-i"
                  : YourFiles === "active1"
                  ? "color-change-false-active-true-i"
                  : "color-change-false-active-false-i"
              }
              onClick={redirectMyfile}
              style={{
                marginInlineStart: 50,
                color: "black",
                fontWeight: 550,
                fontSize: "medium",
                textTransform: "capitalize",
              }}
              id={YourFiles}
            >
              {text_myfiles}
            </Button>
            {sessionStorage.getItem("isorg") === "true" &&
            sessionStorage.getItem("role") === "superAdmin" ? (
              <>
                <Button
                  className={
                    headerColorChange
                      ? adduser === "active1"
                        ? "color-change-true-active-true-i"
                        : "color-change-true-active-false-i"
                      : adduser === "active1"
                      ? "color-change-false-active-true-i"
                      : "color-change-false-active-false-i"
                  }
                  onClick={() => redirect("adduser")}
                  style={{
                    marginInlineStart: 50,
                    color: "black",
                    fontWeight: 550,
                    fontSize: "medium",
                    textTransform: "capitalize",
                  }}
                  id={adduser}
                >
                  {text_adduser}
                </Button>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box
            sx={{
              flexGrow: { sx: 0, md: 0, xl: 0.06 },
              display: { md: "flex" },
            }}
            className="responsive-header-css"
            // style={{ marginInlineStart: 50 }}
          >
            <select
              className="accessinput1 lang-sty-letter mt-3 mx-2"
              onChange={(e) => languageHandle(e.target.value)}
              defaultValue={localStorage.getItem("default_language")}
            >
              {" "}
              <option disabled>language</option>
              <option value="en" defaultValue>
                English
              </option>
              <option value="hi">Hindi</option>
              <option value="es">Spanish</option>
              <option value="guj">Gujarati</option>
              <option value="ar">Arabic</option>
              <option value="tam">Tamil</option>
              <option value="ita">Italian</option>
            </select>
            <div className="">
              {" "}
              {isLoggedin && (
                <>
                  <div
                    className={
                      headerColorChange
                        ? "header-3-logout-1-active"
                        : "header-3-logout-1 header-letter"
                    }
                  >
                    <LetterAvatars
                      letter={fName[0]?.toUpperCase()}
                      fullName={fName + " " + lName}
                      email={email}
                      signoutFunc={handleOpenLogout}
                    />
                  </div>
                </>
              )}
            </div>
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                className={
                  headerColorChange
                    ? UploadFile === "active1"
                      ? "color-change-true-active-true-i"
                      : "color-change-true-active-false-i"
                    : UploadFile === "active1"
                    ? "color-change-false-active-true-i"
                    : "color-change-false-active-false-i"
                }
                onClick={() => MobRedirect("upload-file")}
                id={UploadFile}
              >
                <Typography textAlign="center">{text_uploadfile}</Typography>
              </MenuItem>
              <MenuItem
                className={
                  headerColorChange
                    ? YourFiles === "active1"
                      ? "color-change-true-active-true-i"
                      : "color-change-true-active-false-i"
                    : YourFiles === "active1"
                    ? "color-change-false-active-true-i"
                    : "color-change-false-active-false-i"
                }
                onClick={redirectMyfile}
                id={YourFiles}
              >
                <Typography textAlign="center">{text_myfiles}</Typography>
              </MenuItem>
              {sessionStorage.getItem("isorg") === "true" &&
              sessionStorage.getItem("role") === "superAdmin" ? (
                <>
                  <MenuItem
                    className={
                      headerColorChange
                        ? adduser === "active1"
                          ? "color-change-true-active-true-i"
                          : "color-change-true-active-false-i"
                        : adduser === "active1"
                        ? "color-change-false-active-true-i"
                        : "color-change-false-active-false-i"
                    }
                    onClick={() => MobRedirect("adduser")}
                    id={adduser}
                  >
                    <Typography textAlign="center">{text_adduser}</Typography>
                  </MenuItem>
                </>
              ) : (
                <></>
              )}
              <select
                className="accessinput1 "
                onChange={(e) => languageHandle(e.target.value)}
                defaultValue={localStorage.getItem("default_language")}
              >
                {" "}
                <option disabled>language</option>
                <option value="en" defaultValue>
                  English
                </option>
                <option value="hi">Hindi</option>
                <option value="es">Spanish</option>
                <option value="guj">Gujarati</option>
                <option value="ar">Arabic</option>
                <option value="tam">Tamil</option>
                <option value="ita">Italian</option>
              </select>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <div>
        <Modal
          open={openLogout}
          onClose={() => [handleCloseLogout()]}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="uf-mui-box">
            <HighlightOffOutlinedIcon
              className="uf-modal-close"
              onClick={() => [handleCloseLogout()]}
            />
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              className="uf-modal-1"
            >
              {text_warning}
            </Typography>
            <hr />
            <div className="box-btn-div box-btn-div-logout">
              <button
                className="logout-btn-y"
                onClick={() => [handleSignout()]}
              >
                {text_yes}
              </button>
              <button
                className="logout-btn-n"
                onClick={() => [handleCloseLogout()]}
              >
                {text_no}
              </button>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default Header;
