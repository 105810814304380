import React, { useEffect, useState } from "react";
import "./Register.css";
import UserImg from "../../utils/images/user (5).png";
import EmailImg from "../../utils/images/email.png";
import PassImg from "../../utils/images/lock.png";
import CPassImg from "../../utils/images/lock (2).png";
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../utils/Files/validation";
import ReactFlagsSelect from "react-flags-select";
import { countriesList } from "../../utils/Files/data";
import atomImg from "../../utils/images/atom_1.png";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Loader2 from "../../Components/Loader2/Loader2";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import CompImg from "../../utils/images/enterprise.png";
import PhoneInput from "react-phone-input-2";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FcFeedback } from "react-icons/fc";
import Form from "react-bootstrap/Form";
import PasswordChecklist from "react-password-checklist";
import { GoDotFill } from "react-icons/go";
import { FaCheck } from "react-icons/fa";
const Register = () => {
  const theme = useTheme();
  useEffect(() => {
    if (sessionStorage.getItem("ProgressBox") === "true") {
      sessionStorage.setItem("ProgressBox", false);
    }
  }, []);
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pass, setPass] = useState("");
  const [CPass, setCPass] = useState("");
  const [country, setCountry] = useState("United States");
  const [CountryCode, setCountryCode] = useState("US");
  const [passVisibility, setPassVisibility] = useState(false);
  const [CPassVisibility, setCPassVisibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [companyName, setCompanyName] = useState("");
  const [emailerror, setemailerror] = useState("");
  const [phoneerror, setphoneerror] = useState("");
  const [fanmeerror, setfnameerror] = useState("");
  const [lnameerror, setlnameerror] = useState("");
  const [companynameErr, setCompanynameErr] = useState("");
  const [passvalidation, setpassvalidation] = useState("");
  const [cpassvalidation, setcpassvalidation] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [asCompany, setAsCompany] = useState(false);
  const [termsCondition, setTermsCondition] = useState(false);
  const [termsConditionErr, setTermsConditionErr] = useState("");

  const navigate = useNavigate();
  // Function to handle closing of a modal or dialog
  const handleClose = () => {
    setOpen(false); // Set the state variable 'open' to false to close the modal or dialog
  };
  // ----- remember me starts -----
  // Function to retrieve user data from cookies and set it in session storage
  const setDataToSessionFromCookie = async () => {
    var fName_InCookie = Cookie.get("fName");
    var lName_InCookie = Cookie.get("lName");
    var userID_InCookie = Cookie.get("userID");
    var userToken_InCookie = Cookie.get("userToken");

    // Set user data to session storage
    sessionStorage.setItem("fName", fName_InCookie);
    sessionStorage.setItem("lName", lName_InCookie);
    sessionStorage.setItem("userID", userID_InCookie);
    sessionStorage.setItem("userToken", userToken_InCookie);
  };

  // Function to validate email, phone, first name, last name, company name (if applicable), password, and confirm password
  const emailvalidation = () => {
    let signupvalidation = true; // Flag to indicate if the validation passes, initially set to true

    // Check if both email and phone are empty
    if (email === "" && phone === "") {
      signupvalidation = false;
      setemailerror("*Please Enter Email!");
      setphoneerror("*Please Enter Phone Number!");
    }

    // Check if phone is not empty
    if (phone !== "") {
      signupvalidation = true;
      setemailerror("");
    }

    // Check if email is not empty and it does not match the email regex pattern
    if (email !== "" && EMAIL_REGEX.test(email) === false) {
      signupvalidation = false;
      setemailerror("*Please Enter Valid Email!");
      setphoneerror("");
    }

    // Check if first name is empty
    if (fName === "") {
      signupvalidation = false;
      setfnameerror("*Please enter first name!");
    }

    // Check if asCompany is true and company name is empty
    if (asCompany === true) {
      if (companyName === "") {
        signupvalidation = false;
        setCompanynameErr("*Please enter company name!");
      }
    }

    // Check if last name is empty
    if (lName === "") {
      signupvalidation = false;
      setlnameerror("*Please enter last name!");
    }

    // Check if password does not match the password regex pattern
    if (!PASSWORD_REGEX.test(pass) === true) {
      signupvalidation = false;
      if (pass === "") {
        signupvalidation = false;
        setpassvalidation("*Please enter password!");
      } else {
        setpassvalidation("*Please Enter Strong Password!");
      }
    }

    // Check if confirm password does not match password
    if (CPass !== pass) {
      signupvalidation = false;
      if (CPass === "") {
        signupvalidation = false;
        setcpassvalidation("*Please enter confirm password!");
      } else {
        setcpassvalidation("*Confirm Password Does Not Match With Password!");
      }
    }
    return signupvalidation; // Return the final validation flag
  };

  // useEffect hook to run logic when the component mounts
  useEffect(() => {
    // Retrieve token and token start time from cookies
    var tokenInCookie = Cookie.get("userToken");
    var token_start_timeInCookie = Cookie.get("token_start_time");

    // Check if token exists in cookie
    if (tokenInCookie) {
      // Check if the token is still valid (less than 24 hours old)
      if (Date.now() - token_start_timeInCookie < 86400) {
        // If token is valid, retrieve user data from cookies
        var fName_InCookie = Cookie.get("fName");
        var lName_InCookie = Cookie.get("lName");
        var userID_InCookie = Cookie.get("userID");
        // Set user data to session storage from cookies
        setDataToSessionFromCookie().then(() => {
          // Redirect user to "/my-files" route along with user data in state
          navigate("/my-files", {
            state: {
              tokenInCookie,
              fName_InCookie,
              lName_InCookie,
              userID_InCookie,
            },
          });
        });
      } else {
        // If token is expired, remove user-related cookies and clear session storage
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("user_id");
        Cookie.remove("token_start_time");
        sessionStorage.clear();
        localStorage.clear();
        // Redirect user to the homepage
        navigate("/");
      }
    }
  }, []);

  // ----- remember me ends -----
  // Function to toggle password visibility
  const changeVisibility = (e) => {
    e.preventDefault();
    setPassVisibility(!passVisibility);
  };

  // Function to toggle confirm password visibility
  const changeCVisibility = (e) => {
    e.preventDefault();
    setCPassVisibility(!CPassVisibility);
  };

  // Function to set country data based on the selected country code
  const setCountyData = (d) => {
    var countryName = countriesList[d];
    setCountry(countryName);
    setCountryCode(d);
  };

  // Function to handle user signup
  const handleSignup = () => {
    // Validate user input
    if (emailvalidation()) {
      // Check if email validation passes
      if (termsCondition === true) {
        // Check if terms and conditions are accepted
        setIsLoading(true);

        // Prepare data object with user details
        var data = {
          firstName: fName,
          lastName: lName,
          email: email,
          mobileNumber: phone,
          country: country,
          password: pass,
          isEmail: true,
          isOrg: false,
          companyName: "",
        };

        var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/signup"; //  API URL for user signup

        axios
          .post(API_URL, data)
          .then((res) => {
            // console.log("reg res***", res);
            setIsLoading(false);
            if (res?.data?.success === true) {
              // navigate("/");
              setOpen(true);
              // Create default and root folders for the user
              handleCreateDefaultFolder(
                res?.data?.data?.companyId,
                res?.data?.data?.userId
              );
              handleCreateRootFolderS3(
                res?.data?.data?.companyId,
                res?.data?.data?.userId
              );
              handleCreateRootFolderS3_Private(
                res?.data?.data?.companyId,
                res?.data?.data?.userId
              );
            }
          })
          .catch((err) => {
            // console.log("reg err***", err);
            setIsLoading(false);
            setErrorMessage(err.response?.data?.message);
            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          })
          .then(function () {});
      } else {
        setTermsConditionErr("*Please Accept Terms And Condition!"); // Set error message for terms and conditions acceptance
      }
    }
  };

  // Function to create a default folder for the user on IPFS
  const handleCreateDefaultFolder = (companyId, userID) => {
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage/${userID}/`,

      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };
    // Send POST request to create default folder on IPFS
    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("reg res***", res);
      })
      .catch((err) => {
        // console.log("reg err***", err);
        setIsLoading(false);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  // Function to create a root folder for the user on AWS S3
  const handleCreateRootFolderS3 = (companyId, userID) => {
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage/${userID}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("root folder aws res***", res);
      })
      .catch((err) => {
        // console.log("root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      });
  };

  // Function to create a private root folder for the user on AWS S3
  const handleCreateRootFolderS3_Private = (companyId, userID) => {
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage-private/${userID}/`,
      bucketId: "d969487f-09fc-41b6-be90-2af9a16bd804",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-private-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("private root folder aws res***", res);
      })
      .catch((err) => {
        // console.log("private root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      });
  };

  // Function to handle organization signup
  const handleSignupOrg = () => {
    if (emailvalidation()) {
      // Check if email validation passes
      if (termsCondition === true) {
        // Check if terms and conditions are accepted
        setIsLoading(true);
        // Prepare data object with user details for organization signup
        var data = {
          firstName: fName,
          lastName: lName,
          email: email,
          mobileNumber: phone,
          country: country,
          password: pass,
          isEmail: true,
          isOrg: true,
          companyName: companyName,
        };

        var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/user/signup";

        axios
          .post(API_URL, data)
          .then((res) => {
            // console.log("reg res***", res);
            setIsLoading(false);
            if (res?.data?.success === true) {
              setOpen(true);
              handleCreateDefaultFolder(
                res?.data?.data?.companyId,
                res?.data?.data?.userId
              );
              handleCreateRootFolderS3(
                res?.data?.data?.companyId,
                res?.data?.data?.userId
              );
              handleCreateRootFolderS3_Private(
                res?.data?.data?.companyId,
                res?.data?.data?.userId
              );
            }
          })
          .catch((err) => {
            // console.log("reg err***", err);
            setIsLoading(false);
            setErrorMessage(err?.response?.data?.message);
            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          })
          .then(function () {});
      } else {
        setTermsConditionErr("*Please Accept Terms And Condition!"); // Set error message for terms and conditions acceptance
      }
    }
  };
  // Function to handle key press events
  const keyHandler = (e) => {
    if (e.key === "Enter") {
      // Check if the pressed key is Enter
      if (isLoading) {
        return;
      }
      handleSignup();
      setIsLoading(false);
    }
  };
  return (
    <div className="login-main reg-main" onKeyPress={keyHandler}>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <FcFeedback className="Email-icon" />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <h3 className="text-center">Please Verify Your Account</h3>
            <h5 className="text-center">
              We have sent you a link on your registered email or phone no. to
              verify your Account.
            </h5>
            <h5 className="text-center">
              Please check your Spam or Junk folder.
            </h5>
            <br />
          </DialogContentText>
          <Divider />
        </DialogContent>
        <DialogActions>
          <button
            className="confirm-btn mx-4"
            onClick={() => navigate("/signin")}
          >
            Ok
          </button>
        </DialogActions>
      </Dialog>
      <div className="form login-form reg-form ">
        <div className="login-title-main">
          <div className="login-title-logo">
            <img src={atomImg} alt="" className="login-atom-img" />
          </div>
          <div className="login-title-text">Sign Up</div>
        </div>
        <div className="login-subtitle">
          <span className="login-subtitle-1">
            Join Us Now to Experience Seamless and Secure File Upload and
            Retrieval.
          </span>
        </div>
        <div className="justify-content-center d-flex">
          <Form>
            <div className="mt-3 vf-both-dl-btn-div-21">
              <Form.Check
                // inline
                label="Register As User"
                name="group1"
                type="radio"
                id="inline-radio-2"
                style={{ fontSize: "large" }}
                value="Atom Storage Network"
                checked={asCompany === false}
                onChange={() => [
                  setAsCompany(!asCompany),
                  setfnameerror(""),
                  setlnameerror(""),
                  setCompanynameErr(""),
                  setemailerror(""),
                  setphoneerror(""),
                  setcpassvalidation(""),
                  setpassvalidation(""),
                  setLName(""),
                  setFName(""),
                  setCompanyName(""),
                  setPhone(""),
                  setEmail(""),
                  setCPass(""),
                  setPass(""),
                  setCountry("US"),
                  setTermsConditionErr(""),
                  setTermsCondition(false),
                ]}
              />
              <Form.Check
                // inline
                label="Register As Organization"
                name="group1"
                type="radio"
                id="inline-radio-1"
                style={{ fontSize: "large" }}
                checked={asCompany === true}
                onChange={() => [
                  setAsCompany(!asCompany),
                  setfnameerror(""),
                  setlnameerror(""),
                  setCompanynameErr(""),
                  setemailerror(""),
                  setphoneerror(""),
                  setcpassvalidation(""),
                  setpassvalidation(""),
                  setLName(""),
                  setFName(""),
                  setCompanyName(""),
                  setPhone(""),
                  setEmail(""),
                  setCPass(""),
                  setPass(""),
                  setCountry("US"),
                  setTermsConditionErr(""),
                  setTermsCondition(false),
                ]}
              />
            </div>
          </Form>
        </div>
        <div className="login-content mt-0">
          <div className="reg-firstdiv">
            <div className="reg-f1">
              <label className="login-labels reg-labels">First Name</label>
              <div className="inputForm reg-inputForm">
                <img src={UserImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  placeholder="Enter your First Name"
                  className="input login-input reg-input"
                  onChange={(e) => [
                    setFName(e.target.value),
                    setfnameerror(""),
                    setErrorMessage(""),
                  ]}
                  value={fName}
                />
              </div>
              <span className="errMsg">{fanmeerror}</span>
            </div>
            <div className="reg-f2">
              <label className="login-labels reg-labels">Last Name</label>
              <div className="inputForm reg-inputForm">
                <img src={UserImg} alt="" className="login-emailphone" />
                <input
                  type="text"
                  value={lName}
                  placeholder="Enter your Last Name"
                  className="input login-input"
                  onChange={(e) => [
                    setLName(e.target.value),
                    setlnameerror(""),
                    setErrorMessage(""),
                  ]}
                />
              </div>
              <span className="errMsg">{lnameerror}</span>
            </div>
          </div>
          {asCompany && (
            <>
              {" "}
              <div className="reg-firstdiv">
                <div className="reg-f1">
                  <label className="login-labels reg-labels">
                    Company Name
                  </label>
                  <div className="inputForm reg-inputForm">
                    <img src={CompImg} alt="" className="login-emailphone" />
                    <input
                      type="text"
                      placeholder="Enter your Company Name"
                      className="input login-input reg-input"
                      onChange={(e) => [
                        setCompanyName(e.target.value),
                        setErrorMessage(""),
                        setCompanynameErr(""),
                      ]}
                    />
                  </div>
                  <span className="errMsg">{companynameErr}</span>
                </div>{" "}
                <div className="test1">
                  <label className="login-labels reg-labels">
                    Select Country
                  </label>
                  <div className="inputForm reg-inputForm country-input">
                    <ReactFlagsSelect
                      className="input login-input login-input-2 reg-input-2 "
                      selected={CountryCode}
                      onSelect={(code) => [
                        setCountyData(code),
                        setErrorMessage(""),
                      ]}
                      searchable={true}
                    />
                  </div>
                </div>
              </div>
            </>
          )}
          <div className="reg-firstdiv">
            <div className="reg-f1 reg-f1-res">
              <label className="login-labels reg-labels">Email</label>
              <div className="inputForm reg-inputForm">
                <img src={EmailImg} alt="" className="login-emailphone" />
                <input
                  type="email"
                  value={email}
                  placeholder="Enter your Email"
                  className="input login-input reg-input"
                  onChange={(e) => [
                    setEmail(e.target.value.toLowerCase()),
                    setemailerror(""),
                    setphoneerror(""),
                    setErrorMessage(""),
                  ]}
                />
              </div>
              <span className="errMsg">{emailerror}</span>
            </div>
            <div className="reg-f2">
              <label className="login-labels reg-labels">Phone Number</label>
              <div className="inputForm reg-inputForm">
                <PhoneInput
                  country={CountryCode.toLowerCase()}
                  onChange={(e) => [
                    setPhone(e),
                    setphoneerror(""),
                    setemailerror(""),
                    setErrorMessage(""),
                  ]}
                  inputStyle={{
                    background: "transparent",
                    height: "40px",
                    width: "300px",
                    border: "none",
                  }}
                  inputClass="Register-input"
                  placeholder={"Enter Phone Number"}
                  value={phone}
                />
              </div>
              <span className="errMsg">{phoneerror}</span>
            </div>
          </div>
          <div className="reg-firstdiv">
            <div className="reg-f1 reg-eye-outer">
              <label className="login-labels reg-labels">Password</label>
              <div className="inputForm reg-inputForm">
                <img src={PassImg} alt="" className="login-emailphone" />
                <input
                  type={passVisibility ? "text" : "password"}
                  placeholder="Enter your Password"
                  className="input login-input reg-input login-input-2 reg-input-2"
                  onChange={(e) => [
                    setPass(e.target.value),
                    setpassvalidation(""),
                    setcpassvalidation(""),
                    setErrorMessage(""),
                  ]}
                  value={pass}
                />
                <button
                  className="reg-eye"
                  onClick={(e) => changeVisibility(e)}
                >
                  {passVisibility === true ? <BsEyeFill /> : <BsEyeSlashFill />}
                </button>
              </div>
              <span className="errMsg">{passvalidation}</span>

              <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital"]}
                minLength={8}
                value={pass}
                messages={{
                  minLength: "Password has at least 8 characters.",
                  specialChar: "Password has special characters.",
                  number: "Password has a number.",
                  capital: "Password has a capital letter.",
                }}
                iconComponents={{
                  ValidIcon: (
                    <FaCheck
                      style={{
                        color: "green",
                        marginRight: "7px",
                      }}
                    />
                  ),
                  InvalidIcon: <GoDotFill style={{ marginRight: "7px" }} />,
                }}
              />
            </div>
            <div className="reg-f2 reg-eye-outer">
              <label className="login-labels reg-labels">
                Confirm Password
              </label>
              <div className="inputForm reg-inputForm">
                <img src={CPassImg} alt="" className="login-emailphone" />
                <input
                  type={CPassVisibility ? "text" : "password"}
                  placeholder="Confirm your Password"
                  className="input login-input login-input-2 reg-input-2"
                  onChange={(e) => [
                    setCPass(e.target.value),
                    setcpassvalidation(""),
                    setErrorMessage(""),
                  ]}
                  value={CPass}
                />
                <button
                  className="reg-eye"
                  onClick={(e) => changeCVisibility(e)}
                >
                  {CPassVisibility === true ? (
                    <BsEyeFill />
                  ) : (
                    <BsEyeSlashFill />
                  )}
                </button>
              </div>
              <span className="errMsg">{cpassvalidation}</span>
            </div>
          </div>
          {asCompany === false && (
            <>
              <div className="test1 mb-5">
                <label className="login-labels reg-labels">
                  Select Country
                </label>
                <div className="inputForm reg-country ">
                  <ReactFlagsSelect
                    className="input login-input login-input-2 reg-input-2 "
                    selected={CountryCode}
                    onSelect={(code) => [
                      setCountyData(code),
                      setErrorMessage(""),
                    ]}
                    searchable={true}
                  />
                </div>
              </div>
            </>
          )}
          <center>
            <p className="regErr-p mb-2">{errorMessage}</p>
            <p className="mt-3">
              {" "}
              <input
                type="checkbox"
                style={{ cursor: "pointer" }}
                checked={termsCondition}
                className="me-2"
                onClick={(e) => [
                  setTermsCondition(e.target.checked),
                  setTermsConditionErr(""),
                  setErrorMessage(""),
                ]}
              />
              I agree to the{" "}
              <Link to={"/termsofservice"}>
                <span className="registertext">Terms of Services</span>
              </Link>{" "}
              and{" "}
              <Link to={"/privacypolicy"}>
                <span className="registertext">Privacy Policy.</span>
              </Link>{" "}
            </p>
            <p className="errMsg">{termsConditionErr}</p>

            {isLoading ? (
              <button className="button-submit reg-loading" disabled>
                Signing Up... <Loader2 />
              </button>
            ) : (
              <button
                className="button-submit reg-loading-2"
                onClick={asCompany === true ? handleSignupOrg : handleSignup}
              >
                Sign Up
              </button>
            )}
          </center>
          <h5 className="p login-last-text">
            Already have an account?{" "}
            <span className="span">
              <Link to="/signin" className="login-fp-inner">
                Sign In
              </Link>
            </span>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Register;
