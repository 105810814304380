import React, { useEffect, useState } from "react";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
import { useNavigate } from "react-router-dom";
import F1Img from "../../utils/images/file.png";
import { GlobalConstants } from "../../utils/globalconstant";
import FolImg from "../../utils/images/folder (1).png";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import FileImg from "../../utils/images/file_1.png";
import Loader3 from "../../utils/Files/data";
import { Toast } from "react-bootstrap";
import { InfoToast } from "../../utils/Files/toasterMsgs";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

function UploadMultipleAtomStorage({
  folderIDIPFS,
  folderNameIPFS,
  bucketName,
  bucketId,
  FileData,
  FolderData,
  urlForIpfs,
  folderList,
}) {
  //variable
  const navigate = useNavigate();
  const userID = sessionStorage.getItem("userID");
  const [showCreateFolderInput, setShowCreateFolderInput] = useState(false);
  const [sfvErr, setSfvErr] = useState("");
  const [loader, setLoader] = useState(false);
  const [folderNameInputIPFS, setFolderNameInputIPFS] = useState("");
  const [allFolderIPFS, setAllFolderIPFS] = useState([]);
  const [existingFoldersIPFS, setExistingFoldersIPFS] = useState([]);
  const [ipfsBucketName, setIpfsBucketName] = useState("atom-storage");
  const [reqUrlCreate, setReqUrlCreate] = useState("");
  const [viewBackBtnIPFS, setViewBackBtnIPFS] = useState(false);
  const [urlToShow, setUrlToShow] = useState("");
  const [fileFolderCommon, setFileFolderCommon] = useState();
  const [d_rootFiles, set_d_rootFiles] = useState(FileData);
  const [dragdropFiles, setDragdropFiles] = useState([]);
  const [showFolderFileCommon, setShowfolderfilecommon] = useState(true);
  const [showbackforFile, setShowBackForFile] = useState(false);
  const [uploadFolder, setUploadFolder] = useState([]);

  //language variable
  const [text_currentlocation, setText_currentlocation] =
    useState("Current Location :");
  const [text_back, setText_back] = useState("Back");
  const [text_create, setText_create] = useState("Create");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_createnewfolder, setText_createnewfolder] =
    useState("Create New Folder");
  const [text_enterfoldername, setText_enterfoldername] =
    useState("Enter Folder Name");
  const [text_nofolder, setText_nofolder] = useState("No Folders Here");
  const [text_err_folder_name, setText_err_folder_name] = useState(
    "Folder name can not be empty"
  );
  const [text_validation_folder, set_validation_folder] = useState(
    "A folder name can't contain any of the following characters:"
  );
  //language variable end

  function truncate(string, n) {
    // console.log(string.slice(string.length - 3));
    return string?.length > n
      ? string.substr(0, n - 1) + ". . . " + string.slice(string.length - 3)
      : string;
  }
  useEffect(() => {
    setFileFolderCommon(FolderData);
  }, [FolderData]);
  useEffect(() => {
    set_d_rootFiles(FileData);
  }, [FileData]);
  useEffect(() => {
    if (folderList.length !== 0) {
      // console.log(folderList);

      const dragdropFolderName = allFolderIPFS.map((v, i) => {
        const parts = v.folderName.split("/");
        return parts[parts.length - 2];
      });
      // console.log(dragdropFolderName);
      const commonElements = folderList.filter((element) =>
        dragdropFolderName.includes(element)
      );
      // console.log(commonElements);
      if (commonElements.length !== 0) {
        InfoToast("Folder Already Exists In Atom Storage");
      }
    }
  }, [folderList, allFolderIPFS]);
  //validation folder creation
  const createSubFolderValidationIPFS = () => {
    var sfv = true;
    const disallowedChars = /[#\\/:*?"<>|]/; // Regular expression to match disallowed characters
    if (folderNameInputIPFS === "") {
      sfv = false;
      setSfvErr(<>*{text_err_folder_name}!</>);
    } else if (disallowedChars.test(folderNameInputIPFS)) {
      // Check if folderNameInputIPFS contains disallowed characters
      sfv = false;
      setSfvErr(<>*{text_validation_folder + '#\\ / : * ? " < > |'} </>);
    } else {
      // checking whether a folder already exists or not from folderApiState
      for (let i = 0; i < allFolderIPFS?.length; i++) {
        existingFoldersIPFS.push(
          (allFolderIPFS[i]?.folderName).slice(
            allFolderIPFS[i]?.folderName.lastIndexOf(
              "/",
              allFolderIPFS[i]?.folderName.lastIndexOf("/") - 1
            ) + 1,
            allFolderIPFS[i]?.folderName.length - 1
          )
        );
      }
      for (let j = 0; j < existingFoldersIPFS?.length; j++) {
        if (
          existingFoldersIPFS[j]?.toLowerCase() ===
          folderNameInputIPFS.toLowerCase()
        ) {
          sfv = false;
          setSfvErr("Folder already exists!");
          break;
        }
      }
    }
    return sfv;
  };
  //***create ipfs folder start*****/
  const handleCreateSubFolderIPFS = (folderPath) => {
    var b_name = "";
    reqUrlCreate === ""
      ? (b_name = "atom-storage" + "/" + userID + "/" + folderPath)
      : (b_name = folderPath);
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: b_name,
      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/add-folder-ipfs";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        getAllSubDir(
          b_name.slice(
            0,
            b_name.lastIndexOf("/", b_name.lastIndexOf("/") - 1) + 1
          )
        );
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };

  const handleCreateSubFolder = (urlCreate) => {
    let Folder_name = folderNameInputIPFS.trim();
    if (createSubFolderValidationIPFS()) {
      setFolderNameInputIPFS("");
      setShowCreateFolderInput(false);
      const folderPath = reqUrlCreate + Folder_name + "/";
      handleCreateSubFolderIPFS(folderPath);
    }
  };
  //***create ipfs folder end*****/
  const getAllSubDir = (Folder_name, main_Folder) => {
    // console.log(Folder_name);
    // setLoader(true);
    var MainFolderData = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName:
        Folder_name === "" || Folder_name === undefined || Folder_name === null
          ? "atom-storage/"
          : Folder_name,
      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName:
        Folder_name === "" || Folder_name === undefined || Folder_name === null
          ? `atom-storage/${userID}/`
          : Folder_name,
      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };
    var FinalData = main_Folder === "OneTimeGetData" ? MainFolderData : data;

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/all-child-folders-ipfs";

    axios
      .post(API_URL, FinalData, headerConfig)
      .then((res) => {
        // console.log("add_folder_IPFS res***", res);
        setAllFolderIPFS(res?.data?.data);
        setLoader(false);
        if (Folder_name === `atom-storage/${userID}/`) {
          setViewBackBtnIPFS(false);
        } else {
          setViewBackBtnIPFS(true);
        }
        if (res?.data?.data.length > 0) {
          setReqUrlCreate(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? `atom-storage/`
              : Folder_name
          );

          addSpaceInUrl(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? `atom-storage/`
              : Folder_name
          );
        } else {
          setReqUrlCreate(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? `atom-storage/`
              : Folder_name
          );

          addSpaceInUrl(
            Folder_name === "" ||
              Folder_name === undefined ||
              Folder_name === null
              ? `atom-storage/`
              : Folder_name
          );
        }
        addSpaceInUrl(
          Folder_name === "" ||
            Folder_name === undefined ||
            Folder_name === null
            ? `atom-storage/`
            : Folder_name
        );
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        setLoader(false);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  // console.log(ipfsBucketName);
  //***create ipfs root folder *****/
  useEffect(() => {
    handleRootFolder("6e2f3b90-1dd9-4b7c-9be8-904bd4801e55", ipfsBucketName);
  }, [1000]);
  const handleRootFolder = (bucket_id, bucket_name) => {
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: bucket_name + "/" + userID + "/",
      bucketId: bucket_id,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        var DataFinal = res?.data?.data;
        // console.log("res***", res?.data);
        if (res?.data?.root_folder_added === true) {
          // getIPFSFolders(bucket_name);
          getAllSubDir(bucket_name + "/" + userID + "/", "OneTimeGetData");
          folderIDIPFS(DataFinal.folderId);
          folderNameIPFS(DataFinal.folderName);
          bucketName("atom-storage");
          bucketId("6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          // getIPFSFolders(bucket_name); // atom-storage/123456/
          getAllSubDir(bucket_name + "/" + userID + "/", "OneTimeGetData");
          folderIDIPFS(DataFinal.folderId);
          bucketName("atom-storage");
          folderNameIPFS(DataFinal.folderName);
          bucketId("6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  //***currunt location amd back button *****/
  const addSpaceInUrl = (url) => {
    var newUrl = url.split("/");
    // console.log(newUrl.length > 2);
    const urlWithImages = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {index > 1 && " > "}{" "}
            <img src={F1Img} alt="" style={{ height: "22px", width: "22px" }} />{" "}
            {folder}
          </>
        )}
      </span>
    ));
    const url_dragdrop = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {index > 1 && " > "}{" "}
            {/* <img src={F1Img} alt="" style={{ height: "22px", width: "22px" }} />{" "} */}
            {folder}
          </>
        )}
      </span>
    ));
    if (newUrl.length > 0) {
      urlWithImages.push(newUrl[newUrl.length - 1]);
      url_dragdrop.push(newUrl[newUrl.length - 1]);
    }
    setUrlToShow(urlWithImages);
    urlForIpfs(url_dragdrop);
  };

  const handleBackIPFS = () => {
    setSfvErr("");
    setShowCreateFolderInput(false);
    if (
      reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) !== -1
    ) {
      if (
        reqUrlCreate.slice(
          0,
          reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) + 1
        ) === `atom-storage/${userID}/`
      ) {
        setUploadFolder([]);
        setShowfolderfilecommon(false);
        getAllSubDir(`atom-storage/${userID}/`);
        folderNameIPFS(`atom-storage/${userID}/`);
      } else {
        getAllSubDir(
          reqUrlCreate.slice(
            0,
            reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) + 1
          )
        );
        setViewBackBtnIPFS(false);
      }
    } else {
      getAllSubDir("");
      setViewBackBtnIPFS(false);
    }
  };
  //***currunt location amd back button *****/

  //language
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      // console.log("" + err);
    }
  }, []);
  useEffect(() => {
    // console.log(reqUrlCreate);
    sessionStorage.setItem("requrlIpfs", reqUrlCreate);
  }, [reqUrlCreate]);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_currentlocation(
        xpath.select1("/resources/string[@name='text_currentlocation']", doc)
          .firstChild.data
      );
      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
      setText_create(
        xpath.select1("/resources/string[@name='text_create']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_createnewfolder(
        xpath.select1("/resources/string[@name='text_createnewfolder']", doc)
          .firstChild.data
      );
      setText_nofolder(
        xpath.select1("/resources/string[@name='text_nofolder']", doc)
          .firstChild.data
      );
      setText_enterfoldername(
        xpath.select1("/resources/string[@name='text_enterfoldername']", doc)
          .firstChild.data
      );
      setText_err_folder_name(
        xpath.select1("/resources/string[@name='text_err_folder_name']", doc)
          .firstChild.data
      );
      set_validation_folder(
        xpath.select1("/resources/string[@name='text_validation_folder']", doc)
          .firstChild.data
      );
    } catch (ert) {}
  };
  useEffect(() => {
    setLoader(true);
  }, []);
  return (
    <div className="uf-folders-main-wrapper">
      <div className="uf-folders-main">
        {/**** currunt location ******/}
        {reqUrlCreate === "" && reqUrlCreate !== "atom-storage" ? (
          <div className="uf-url">
            <span className="uf-url-s1">
              <b>{text_currentlocation}</b>
            </span>
            <span className="uf-url-s2">
              <img
                src={F1Img}
                alt=""
                style={{ height: "22px", width: "22px" }}
              />
              {"atom-storage > "}
              {urlToShow}
            </span>
          </div>
        ) : (
          <div className="uf-url">
            <span className="uf-url-s1">
              <b>{text_currentlocation}</b>
            </span>
            <span className="uf-url-s2">{urlToShow}</span>
          </div>
        )}
        <div
          className={
            viewBackBtnIPFS === true || showbackforFile === true
              ? "uf-folders-back-create"
              : "uf-folders-notback-create"
          }
        >
          {/**** back button ******/}
          {viewBackBtnIPFS === true && (
            <div className="uf-folders-back">
              <button
                className="back-btn"
                onClick={handleBackIPFS}
                style={{ marginTop: "-10px" }}
              >
                <span>
                  <MdOutlineKeyboardBackspace className="back-btn-icon" />
                </span>
                <span>{text_back}</span>
              </button>
            </div>
          )}
          {/**** Create folder ******/}
          <div className="uf-folders-create">
            {showCreateFolderInput === true ? (
              <div className="uf-folders-in f-input">
                {/* -----------  */}
                <div className="uf-inputs-main">
                  <div className="inputForm uf-inputs mt-1">
                    <img src={FolImg} alt="" className="login-emailphone" />
                    <input
                      type="text"
                      className="input login-input"
                      style={{ backgroundColor: "transparent" }}
                      onChange={(e) => [
                        setFolderNameInputIPFS(e.target.value),
                        setSfvErr(""),
                      ]}
                      placeholder={text_enterfoldername}
                      value={folderNameInputIPFS}
                    />
                  </div>
                </div>
                {/* ---------- */}
                <button
                  className="box-btn-save"
                  onClick={() => handleCreateSubFolder(reqUrlCreate)}
                >
                  {text_create}
                </button>
                <button
                  onClick={() => [
                    setShowCreateFolderInput(false),
                    setSfvErr(""),
                    setFolderNameInputIPFS(""),
                  ]}
                  className="box-btn"
                >
                  {text_cancel}
                </button>
              </div>
            ) : (
              <button
                onClick={() => setShowCreateFolderInput(true)}
                className="create-btn"
              >
                {text_createnewfolder}
              </button>
            )}
            <p className="sfv-err">{sfvErr}</p>
          </div>
        </div>

        <div className="uf-folders-outer">
          <div className="uf-folders">
            {uploadFolder.length === 0 &&
              allFolderIPFS?.map((v, i) => {
                return (
                  <div
                    key={i}
                    className="uf-folder text-center"
                    onClick={() => [
                      getAllSubDir(v?.folderName),
                      // setViewBackBtnIPFS(true),
                      setTimeout(() => {
                        setViewBackBtnIPFS(true);
                      }, 1000),
                      setSfvErr(""),
                      setShowCreateFolderInput(false),
                      folderIDIPFS(v?.folderId),
                      folderNameIPFS(v?.folderName),
                      bucketName("atom-storage"),
                    ]}
                    title={v?.folderName.slice(
                      v?.folderName.lastIndexOf(
                        "/",
                        v?.folderName.lastIndexOf("/") - 1
                      ) + 1,
                      v?.folderName?.length - 1
                    )}
                  >
                    <img src={F1Img} alt="" className="uf-folder-img" />
                    <p className="uf-folder-name">
                      {v?.folderName.slice(
                        v?.folderName.lastIndexOf(
                          "/",
                          v?.folderName.lastIndexOf("/") - 1
                        ) + 1,
                        v?.folderName?.length - 1
                      )}
                    </p>
                  </div>
                );
              })}
            {allFolderIPFS?.length === 0 &&
            fileFolderCommon?.length === 0 &&
            d_rootFiles.length === 0 ? (
              <>
                {loader === true ? (
                  <div className="uf-emptyfolder">
                    <Loader3 />
                  </div>
                ) : (
                  <div className="uf-emptyfolder">
                    <h3 className="as">{text_nofolder}</h3>
                  </div>
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        {/* ------------------------------------- */}
      </div>
    </div>
  );
}

export default UploadMultipleAtomStorage;
