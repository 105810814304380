import * as React from "react";
import { useEffect } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import "./LetterAvatar.css";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Logout from "@mui/icons-material/Logout";
import { useState } from "react";
import PersonAdd from "@mui/icons-material/PersonAdd";
import { Link } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import SettingsIcon from "@mui/icons-material/Settings";
import { GlobalConstants } from "../../utils/globalconstant";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
export default function LetterAvatars({
  letter,
  fullName,
  email,
  signoutFunc,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [headerColorChange, setHeaderColorChange] = useState(false);

  const changeHeaderColor = () => {
    if (window.scrollY >= 1) {
      setHeaderColorChange(true);
    } else {
      setHeaderColorChange(false);
    }
  };
  window.addEventListener("scroll", changeHeaderColor);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const [text_compprofile, setText_compprofile] = useState("Company Profile");
  const [text_signout, setText_signout] = useState("Sign Out");
  const [text_settings, setText_settings] = useState("Settings");
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      // console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_compprofile(
        xpath.select1("/resources/string[@name='text_compprofile']", doc)
          .firstChild.data
      );
      setText_settings(
        xpath.select1("/resources/string[@name='text_settings']", doc)
          .firstChild.data
      );
      setText_signout(
        xpath.select1("/resources/string[@name='text_signout']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  return (
    <>
      <Stack direction="row" spacing={2}>
        <Avatar
          className={
            headerColorChange
              ? "avatar-letter avatar-letter-active"
              : "avatar-letter"
          }
        >
          <Tooltip className="avatar-tooltip" title={fullName} arrow>
            <IconButton
              onClick={handleClick}
              className={
                headerColorChange
                  ? "avatar-iconbtn-active avatar-iconbtn"
                  : "avatar-iconbtn"
              }
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              {letter}
            </IconButton>
          </Tooltip>
        </Avatar>
      </Stack>
      <Menu
        anchorEl={anchorEl}
        className="asd"
        id="account-menu"
        open={open}
        onClose={handleClose}
        // onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem className="la-menu-1">
          <Avatar className="menu-letter">{letter} </Avatar>{" "}
          <div className="la-username">
            <span className="la-username-1">{fullName}</span>
            <span className="la-username-2">
              {email} <VerifiedIcon className="la-username-21" />
            </span>
          </div>
        </MenuItem>
        <hr className="avatar-hr" />
        {sessionStorage.getItem("isorg") === "true" &&
        sessionStorage.getItem("role") === "superAdmin" ? (
          <>
            <Link to="/company-profile" className="la-compprofile">
              <MenuItem>
                <ListItemIcon>
                  <PersonAdd fontSize="small" />
                </ListItemIcon>
                {text_compprofile}
              </MenuItem>
            </Link>
          </>
        ) : (
          <></>
        )}

        <Link to="/settings" className="la-compprofile">
          <MenuItem>
            <ListItemIcon>
              <SettingsIcon fontSize="small" />
            </ListItemIcon>
            <span className="la-signout">{text_settings}</span>
          </MenuItem>
        </Link>

        <MenuItem onClick={signoutFunc}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <span className="la-signout">{text_signout}</span>
        </MenuItem>
      </Menu>
    </>
  );
}
