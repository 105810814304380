import { createSlice } from "@reduxjs/toolkit";
import { GLOBAL_URL_DOMAIN_LIVE } from "../utils/Files/url";
import axios from "axios";
import Cookie from "js-cookie";
import { errorToast } from "../../src/utils/Files/toasterMsgs";
const GetBucketDataSlice = createSlice({
  name: "GetBucketData",
  initialState: {
    AtomS3Data: [],
    // EmpCount: [],
  },
  reducers: {
    setAtomS3Data: (state, action) => {
      // console.log(action.payload);
      return { ...state, AtomS3Data: action.payload };
    },
    // setEmpCount: (state, action) => {
    //   return { ...state, EmpCount: action.payload };
    // },
  },
});
const {
  setAtomS3Data,

  // setEmpCount
} = GetBucketDataSlice.actions;
export default GetBucketDataSlice.reducer;

export function getBucketData() {
  return async function getBucketDataThunk(dispatch, getstate) {
    const UserID = sessionStorage.getItem("userID");
    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE +
      `/company/get-buckets?userId=${sessionStorage.getItem("userID")}`;

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    axios
      .get(API_URL, headerConfig)
      .then(function (response) {
        var data = response?.data?.data;
        // setBucketAll(data);
        // setAtomS3Data(data);
        dispatch(setAtomS3Data(data));
        // console.log("get bucket data", data);
        // setEmpCount(data);
        // dispatch(setEmpCount(data));
      })
      .catch(function (error) {
        console.log(error);
        // errorToast(err);
        if (error?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          window.location.replace("/");
        }
        errorToast(error?.response?.data?.message);
      })
      .then(function () {});
  };
}
export function resetGetBucketData() {
  return async function resetGetBucketDataThunk(dispatch, getState) {
    try {
      dispatch(setAtomS3Data([]));
    } catch (err) {
      console.log(err);
    }
  };
}
