import React, { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { customTableStylesFile } from "../../utils/Files/tableStyle";
import { customTableStylesFileBoth } from "../../utils/Files/tableStyle";
import Cookie from "js-cookie";
import axios from "axios";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";

import FilterComponent from "../../utils/Files/FilterComponent";
import Loader3 from "../../utils/Files/data";
import DataTable from "react-data-table-component";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import moment from "moment";
import { GlobalConstants } from "../../utils/globalconstant";
import Loader2 from "../../Components/Loader2/Loader2";
import { CgCloseO } from "react-icons/cg";
import { errorToast, successToast } from "../../utils/Files/toasterMsgs";
import { ToastContainer } from "react-toastify";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Tooltip,
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import folderImg from "../../utils/images/folderAWS.png";
import { getBucketData } from "../../redux/GetBucketdataSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchSharedByMeFiles,
  fetchSharedByMeFolders,
} from "../../redux/SharedByMeSlice";
// import { useDispatch } from "react-redux";
// import { resetSelectBucketData } from "../../redux/SelectBucketSlice";
// import { resetShareWithMeData } from "../../redux/sharewithmeslice";
// import { resetUserListData } from "../../redux/UserListSlice";
// import { resetIPFSrootfileData } from "../../redux/IPFSRootFolderSlice";
// import { resetAwsRootFolderData } from "../../redux/AwsRootFolderSlice";
// import { resetGetBucketData } from "../../redux/GetBucketdataSlice";
// import { resetBothfileData } from "../../redux/BothFilesSlice";
// import { resetgetCompanyData } from "../../redux/CompanyProfileSlice";
// import { resetgetRegisterdUser } from "../../redux/RegisteredUserSlice";
// import { resetEditFileData } from "../../redux/EditFileDataSlice";
import {
  foderwiseAwsFiless,
  getAwsRootData,
} from "../../redux/AwsRootFolderSlice";
import {
  getIPFSFileData,
  getIPFSRootData,
} from "../../redux/IPFSRootFolderSlice";
import { customTableStyles } from "../../utils/Files/tableStyle";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
function ShareByMe() {
  const dispatch = useDispatch();
  const atomS3Data = useSelector((state) => state.getBucketData.AtomS3Data);
  // const awsrootdata = useSelector((state) => state.awsRootData.folderData);
  const {
    dataAwsFolder,
    dataIpfsFolder,
    dataBothFolder,
    dataAwsFile,
    dataIpfsFile,
    dataBothFile,
    isLoading,
    error,
  } = useSelector((state) => state.sharedByMe);
  var CompanyID = sessionStorage.getItem("companyID");
  const [showContent, setShowContent] = useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [Data, setData] = useState([]);
  const [DataFiles, setDataFiles] = useState([]);

  const [loaderData, setLoaderData] = useState(false);
  // const [loader, setLoader] = useState(false);
  const userID = sessionStorage.getItem("userID");
  // const [folderData, setFolderData] = useState([]);
  const [dataIdArray, setDataIdArray] = useState([]);
  const [dataIdArrayAws, setDataIdArrayAws] = useState([]);
  const [dataIdArrayIpfs, setDataIdArrayIpfs] = useState([]);
  const [dataIdArrayFolderAws, setDataIdArrayFolderAws] = useState([]);
  const [dataIdArrayFolderIpfs, setDataIdArrayFolderIpfs] = useState([]);
  const [opentooltip, setOpenTooltip] = useState(false);
  const [opentooltipamazon, setOpenTooltipamazon] = useState(false);
  const [accessPopup, setAccessPopup] = useState(false);
  const [accessPopupFolder, setAccessPopupFolder] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const [combinedDataFolder, setCombinedDataFolder] = useState([]);
  const [combinedDataFolderAws, setCombinedDataFolderAws] = useState([]);
  const [combinedDataFolderIpfs, setCombinedDataFolderIpfs] = useState([]);
  const [dbVersion, setDbVersion] = useState("");
  const [allBucket, setAllBucket] = useState([]);
  const [folderId, setFolderId] = useState("");
  const [folderIdAws, setFolderIdAws] = useState("");
  const [folderIdIpfs, setFolderIdIpfs] = useState("");
  const [readAccess, setReadAccess] = useState(false);
  const [writeAccess, setWriteAccess] = useState(false);
  const [downloadAccess, setDownloadAccess] = useState(false);
  const [folderTable, setFolderTable] = useState(false);
  const [rowFileId, setRowFileId] = useState();
  const [datavalueArray, setDatavalueArray] = useState([]);
  const [datavalueArrayAws, setDatavalueArrayAws] = useState([]);
  const [datavalueArrayIpfs, setDatavalueArrayIpfs] = useState([]);
  const [datavalueArrayFolderAws, setDatavalueArrayFolderAws] = useState([]);
  const [datavalueArrayFolderIpfs, setDatavalueArrayFolderIpfs] = useState([]);
  const [other_data_2, setother_data_2] = useState([]);
  const [other_data_2Aws, setother_data_2Aws] = useState([]);
  const [other_data_2Ipfs, setother_data_2Ipfs] = useState([]);
  const [folderArr, setfolderArr] = useState([]);
  const [folderArrIpfs, setfolderArrIpfs] = useState([]);
  const [folderArrAws, setfolderArrAws] = useState([]);
  const [loaderPopup, setLoaderPopup] = useState(false + "");
  const [alignment, setAlignment] = useState("aws");
  const [alignmentFolder, setAlignmentFolder] = useState("file");
  const [removeEvent, setRemoveEvent] = useState("");
  const [bucketId, setBucketId] = useState("");
  const [awsBucketName, setAwsBucketName] = useState("");
  const [folderNameForBackButton, setFolderNameForBackButton] = useState("");
  const [folderNameForBackButtonIpfs, setFolderNameForBackButtonIpfs] =
    useState("");
  const [userIdForBackButton, setUserIdForBackButton] = useState("");
  const [bck, setBck] = useState(false);
  const [viewBackBtn, setViewBackBtn] = useState(false);
  const [bucketIdToTrue, setBucketIdToTrue] = useState(false);
  const [showFilesTable, setShowFilesTable] = useState(false);
  const [bucketIdForChildFolders, setBucketIdForChildFolders] = useState("");
  const [bucketNameForChildFolders, setBucketNameForChildFolders] =
    useState("");
  const [combinedDataAws, setCombinedDataAws] = useState([]);
  const [combinedDataIpfs, setCombinedDataIpfs] = useState([]);
  const [noRecordsFoundaws, setNoRecordsFoundAws] = useState("");
  const [noRecordsFoundIpfs, setNoRecordsFoundIpfs] = useState("");

  //language variable
  const [text_searchhere, setText_searchhere] = useState("Search Here...");
  const [text_srno, setText_srno] = useState("Sr No.");
  const [text_filename1, setText_filename1] = useState("FILE NAME");
  const [text_filesize, setText_filesize] = useState("FILE SIZE");
  const [text_dateofupload, setText_dateofupload] = useState("DATE OF UPLOAD");
  const [text_Location, setText_Location] = useState("LOCATION");
  const [text_atomfileid, setText_atomfileid] = useState("ATOM FILE ID");
  const [text_viewdetails, setText_viewdetails] = useState("VIEW DETAILS");
  const [text_access, setText_access] = useState("Access");
  const [text_view, setText_view] = useState("View");
  const [text_edit, setText_edit] = useState("Edit");

  const [text_amazons3link1, setText_amazons3link1] =
    useState("Amazon S3 Link");
  const [text_amazons3folder, setText_amazons3folder] =
    useState("AMAZON S3 FOLDER");
  const [text_ipfsfolder, setText_ipfsfolder] = useState("ATOM STORAGE FOLDER");
  const [text_amazons3, setText_amazons3] = useState("Amazon S3");
  const [text_bucket, setText_bucket] = useState("Bucket");
  const [text_atomstorage, setText_atomstorage] = useState("Atom Storage");
  const [text_both, setText_both] = useState("Both");
  const [text_file, setText_file] = useState("File");
  const [text_folder, setText_folder] = useState("Folder");
  const [text_peoplewithaccess, setText_peoplewithaccess] =
    useState("People With Access");
  const [text_read, setText_read] = useState("Read");
  const [text_write, setText_write] = useState("Write");
  const [text_download, setText_download] = useState("Download");
  const [text_removeaccess, setText_removeaccess] = useState("Remove Access");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_done, setText_done] = useState("Done");
  const [text_save, setText_save] = useState("Save");
  const [text_selectbucket, setText_selectbucket] = useState(
    "Select Amazon S3 Bucket"
  );
  const [text_back, setText_back] = useState("Back");
  const [text_foldername, setText_foldername] = useState("FOLDER NAME");
  const [text_atomfolder, setText_atomfolder] = useState("Atom Folder");
  const navigate = useNavigate();
  useEffect(() => {
    getBucket();
  }, [atomS3Data?.length]);
  const getBucket = () => {
    if (atomS3Data?.length === 0) {
      dispatch(getBucketData());
    } else {
      var Val = atomS3Data;
      // console.log("read file res***", Val[0]?.bucketId);
      if (Val.length > 0) {
        setAllBucket(Val);
        setAwsBucketName(Val[0]?.bucketName);
        if (dataAwsFile.length === 0) {
          dispatch(fetchSharedByMeFiles("aws", Val[0]?.bucketId));
        }
        setAlignment("aws");
        setBucketId(Val[0]?.bucketId);
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowContent(true);
    }, 1100);

    return () => clearTimeout(timer);
  }, []);
  useEffect(() => {
    if (alignmentFolder === "folder") {
      if (alignment === "aws") {
        setData(dataAwsFolder);
      } else if (alignment === "ipfs") {
        setData(dataIpfsFolder);
      } else if (alignment === "both") {
        setData(dataBothFolder);
      }
    } else if (alignmentFolder === "file") {
      if (alignment === "aws") {
        setData(dataAwsFile);
      } else if (alignment === "ipfs") {
        setData(dataIpfsFile);
      } else if (alignment === "both") {
        setData(dataBothFile);
      }
    }
  }, [
    dataAwsFolder,
    dataIpfsFolder,
    dataBothFolder,
    dataAwsFile,
    dataIpfsFile,
    dataBothFile,
  ]);
  const shared_by_me_folders_func = async (storageType) => {
    if (dataAwsFolder.length === 0 && storageType === "aws") {
      dispatch(fetchSharedByMeFolders(storageType, bucketId, awsBucketName));
    } else if (dataIpfsFolder.length === 0 && storageType === "ipfs") {
      dispatch(fetchSharedByMeFolders(storageType, bucketId, awsBucketName));
    } else if (dataBothFolder.length === 0 && storageType === "both") {
      dispatch(fetchSharedByMeFolders(storageType, bucketId, awsBucketName));
    } else {
      if (storageType === "aws") {
        setData(dataAwsFolder);
      } else if (storageType === "ipfs") {
        setData(dataIpfsFolder);
      } else if (storageType === "both") {
        setData(dataBothFolder);
      }
    }
  };

  const shared_by_me_files_func = async (storageType) => {
    if (dataAwsFile.length === 0 && storageType === "aws") {
      dispatch(fetchSharedByMeFiles(storageType, bucketId));
    } else if (dataIpfsFile.length === 0 && storageType === "ipfs") {
      dispatch(fetchSharedByMeFiles(storageType, bucketId));
    } else if (dataBothFile.length === 0 && storageType === "both") {
      dispatch(fetchSharedByMeFiles(storageType, bucketId));
    } else {
      if (storageType === "aws") {
        setData(dataAwsFile);
      } else if (storageType === "ipfs") {
        setData(dataIpfsFile);
      } else {
        setData(dataBothFile);
      }
    }
  };
  const handleOpenPopup = async (row) => {
    // console.log(row);
    setNoRecordsFoundAws("");
    setNoRecordsFoundIpfs("");
    if (alignment == "ipfs") {
      // console.log("ipfs");
      // console.log(row?.file_unique_id);
      setRowFileId(row?.file_unique_id);
      // console.log(row?.folder_id);
      setFolderIdIpfs(row?.ipfsFolderId);
      try {
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };
        const data = {
          file_unique_id: row?.file_unique_id,
          sender_user_id: userID,
        };
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/users-with-file-access`;

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("userAccessData res*", res);
        if (res?.status === 200) {
          setLoaderPopup(false);
        }
        if (res?.data?.data !== null) {
          setAccessPopup(true);
          var FinalData = [];
          for (let i = 0; res?.data?.data?.length > i; i++) {
            // console.log(res?.data?.userData[i]);
            FinalData.push({
              userEmail: res?.data?.userData[i]?.email,
              accessDataread: res?.data?.data[i]?.read_access,
              accessDatawrite: res?.data?.data[i]?.write_access,
              accessDatadownload: res?.data?.data[i]?.download_access,
              accessUserId: res?.data?.data[i]?.user_id,
              folderId: res?.data?.data[i]?.folder_id,
            });
          }
          // console.log(FinalData);
          setCombinedData(FinalData);
        }
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          window.location.replace("/");
        }
      }
    } else if (alignment === "aws") {
      // console.log("aws");
      // console.log(row?.file_unique_id);
      setRowFileId(row?.file_unique_id);
      setFolderIdAws(row?.folderId);
      try {
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };
        const data = {
          file_unique_id: row?.file_unique_id,
          sender_user_id: userID,
        };
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE + `/file-access/users-with-file-access`;

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("userAccessData res*", res?.data);
        if (res?.status === 200) {
          setLoaderPopup(false);
        }
        if (res?.data?.data !== null) {
          setAccessPopup(true);
          var FinalData = [];
          for (let i = 0; res?.data?.data?.length > i; i++) {
            // console.log(res?.data?.userData[i]);
            FinalData.push({
              userEmail: res?.data?.userData[i]?.email,
              accessDataread: res?.data?.data[i]?.read_access,
              accessDatawrite: res?.data?.data[i]?.write_access,
              accessDatadownload: res?.data?.data[i]?.download_access,
              accessUserId: res?.data?.data[i]?.user_id,
              folderId: res?.data?.data[i]?.folder_id,
            });
            setReadAccess(res?.data?.data[i]?.read_access);
            setWriteAccess(res?.data?.data[i]?.write_access);
            setDownloadAccess(res?.data?.data[i]?.download_access);
          }

          // console.log(FinalData);
          setCombinedData(FinalData);
        }
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          window.location.replace("/");
        }
      }
    } else if (alignment === "both") {
      // console.log("both files");
      // console.log(row?.file_unique_id);
      setRowFileId(row?.file_unique_id);

      setFolderIdAws(row?.folderId);
      setFolderIdIpfs(row?.ipfsFolderId);

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      const data = {
        file_unique_id: row?.file_unique_id,
        sender_user_id: userID,
      };
      try {
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE + `/file-access/users-with-file-access`;

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("userAccessData res***", res);
        var FinalDataAws = [];
        for (let i = 0; res?.data?.data?.length > i; i++) {
          // console.log(res?.data?.userData[i]);
          FinalDataAws.push({
            userEmail: res?.data?.userData[i]?.email,
            accessDataread: res?.data?.data[i]?.read_access,
            accessDatawrite: res?.data?.data[i]?.write_access,
            accessDatadownload: res?.data?.data[i]?.download_access,
            accessUserId: res?.data?.data[i]?.user_id,
            folderId: res?.data?.data[i]?.folder_id,
          });
        }
        setCombinedDataAws(FinalDataAws);

        if (FinalDataAws.length === 0) {
          setNoRecordsFoundAws("There are no records to display");
        }
      } catch (err) {
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          window.location.replace("/");
        }
      }
      try {
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/users-with-file-access`;

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("userAccessData res***", res);
        var FinalDataIpfs = [];
        for (let i = 0; res?.data?.data?.length > i; i++) {
          // console.log(res?.data?.userData[i]);
          FinalDataIpfs.push({
            userEmail: res?.data?.userData[i]?.email,
            accessDataread: res?.data?.data[i]?.read_access,
            accessDatawrite: res?.data?.data[i]?.write_access,
            accessDatadownload: res?.data?.data[i]?.download_access,
            accessUserId: res?.data?.data[i]?.user_id,
            folderId: res?.data?.data[i]?.folder_id,
          });
        }
        setCombinedDataIpfs(FinalDataIpfs);
        setAccessPopup(true);
        setLoaderPopup(false);

        if (FinalDataIpfs.length === 0) {
          setNoRecordsFoundIpfs("There are no records to display");
        }
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          window.location.replace("/");
        }
      }
    }
  };
  const handleOpenPopupFolder = async (row) => {
    // console.log(row);
    setNoRecordsFoundAws("");
    setNoRecordsFoundIpfs("");
    if (alignment === "ipfs") {
      // console.log("ipfs");

      try {
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE +
          `/ipfs-access/users-with-folder-access?f_id=${row.folder_id}`;

        let res = await axios.get(API_URL, headerConfig);
        // console.log("userAccessData res*", res);
        if (res?.status === 200) {
          setLoaderPopup(false);
        }
        if (res?.data?.data_2 !== null) {
          setAccessPopupFolder(true);
          var FinalData = [];
          for (let i = 0; res?.data?.data_2?.length > i; i++) {
            // console.log(res?.data?.userData_2[i]);
            FinalData.push({
              userEmail: res?.data?.userData_2[i]?.email,
              accessDataread: res?.data?.data_2[i]?.read_access,
              accessDatawrite: res?.data?.data_2[i]?.write_access,
              accessDatadownload: res?.data?.data_2[i]?.download_access,
              accessUserId: res?.data?.data_2[i]?.user_id,
              bucketID: res?.data?.data_2[i]?.bucket_id,
              userID: res?.data?.data_2[i]?.user_id,
            });
            setReadAccess(res?.data?.data_2[i]?.read_access);
            setWriteAccess(res?.data?.data_2[i]?.write_access);
            setDownloadAccess(res?.data?.data_2[i]?.download_access);
          }
          // console.log(FinalData);
          setCombinedDataFolder(FinalData);
          setDbVersion(res?.data?.data_2[0].db_version);
          // console.log(res?.data?.data_2[0].db_version);
        }
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          navigate("/");
        }
      }
    } else if (alignment === "aws") {
      // console.log("aws");

      try {
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE +
          `/file-access/users-with-folder-access?f_id=${row.folder_id}`;

        let res = await axios.get(API_URL, headerConfig);
        // console.log("userAccessData res*", res);
        if (res?.status === 200) {
          setLoaderPopup(false);
        }
        if (res?.data?.data_2 !== null) {
          setAccessPopupFolder(true);
          var FinalData = [];
          for (let i = 0; res?.data?.data_2?.length > i; i++) {
            // console.log(res?.data?.userData_2[i]);
            FinalData.push({
              userEmail: res?.data?.userData_2[i]?.email,
              accessDataread: res?.data?.data_2[i]?.read_access,
              accessDatawrite: res?.data?.data_2[i]?.write_access,
              accessDatadownload: res?.data?.data_2[i]?.download_access,
              accessUserId: res?.data?.data_2[i]?.user_id,
              bucketID: res?.data?.data_2[i]?.bucket_id,
              userID: res?.data?.data_2[i]?.user_id,
            });
            setReadAccess(res?.data?.data_2[i]?.read_access);
            setWriteAccess(res?.data?.data_2[i]?.write_access);
            setDownloadAccess(res?.data?.data_2[i]?.download_access);
          }

          // console.log(FinalData);
          setCombinedDataFolder(FinalData);
          setDbVersion(res?.data?.data_2[0].db_version);
        }
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          navigate("/");
        }
      }
    } else if (alignment === "both") {
      // console.log("both");

      try {
        const data = {
          sender_user_id: userID,
          aws_folder_name: row?.awsData?.folder_name
            ? row?.awsData?.folder_name
            : row?.awsFolder,
          atom_folder_name: row?.atomData?.folder_name
            ? row?.atomData?.folder_name
            : row?.atomFolder,
          bucket_id: row?.awsData?.bucket_id
            ? row?.awsData?.bucket_id
            : bucketId,
        };

        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE +
          `/file-access/users-with-folder-access-aws-atom`;

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("userAccessData res*", res?.data?.awsFolderAccessData);
        if (res?.status === 200) {
          setLoaderPopup(false);
          setAccessPopupFolder(true);
          var FinalDataAws = [];
          var FinalDataIpfs = [];

          for (let i = 0; res?.data?.awsFolderAccessData?.length > i; i++) {
            FinalDataAws.push({
              userEmailaws: res?.data?.awsFolderAccessData[i]?.email,
              accessDatareadaws: res?.data?.awsFolderAccessData[i]?.read_access,
              accessDatawriteaws:
                res?.data?.awsFolderAccessData[i]?.write_access,
              accessDatadownloadaws:
                res?.data?.awsFolderAccessData[i]?.download_access,
              userIdaws: res?.data?.awsFolderAccessData[i]?.user_id,
            });
          }
          for (let i = 0; res?.data?.atomFolderAccessData?.length > i; i++) {
            FinalDataIpfs.push({
              userEmailipfs: res?.data?.atomFolderAccessData[i]?.email,
              accessDatareadipfs:
                res?.data?.atomFolderAccessData[i]?.read_access,
              accessDatawriteipfs:
                res?.data?.atomFolderAccessData[i]?.write_access,
              accessDatadownloadipfs:
                res?.data?.atomFolderAccessData[i]?.download_access,
              userIdipfs: res?.data?.atomFolderAccessData[i]?.user_id,
            });
          }
          if (FinalDataAws.length === 0) {
            setNoRecordsFoundAws("There are no records to display");
          }
          if (FinalDataIpfs.length === 0) {
            setNoRecordsFoundIpfs("There are no records to display");
          }
          // console.log(FinalData);
          setCombinedDataFolderAws(FinalDataAws);
          setCombinedDataFolderIpfs(FinalDataIpfs);
          setDbVersion(
            res?.data?.awsFolderAccessData[0]?.db_version
              ? res?.data?.awsFolderAccessData[0]?.db_version
              : res?.data?.atomFolderAccessData[0]?.db_version
          );
        }
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("username");
          Cookie.remove("user_id");
          Cookie.remove("AdminFName");
          Cookie.remove("AdminLName");
          Cookie.remove("token");
          navigate("/");
        }
      }
    }
    if (alignment === "ipfs" || alignment === "both") {
      try {
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE + `/ipfs-folder/all-child-folders-files-ipfs`;

        const data = {
          companyId: CompanyID,
          userId: userID, // user who is logged in currently
          folderName:
            alignment === "ipfs"
              ? [row?.folder_name]
              : row?.atomData
              ? [row?.atomData?.folder_name]
              : [row?.atomFolder],
          bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        };

        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        let res = await axios.post(API_URL, data, headerConfig);

        // console.log("get_required_folder_data res***", res);
        setother_data_2(res?.data?.other_data_2);
        setother_data_2Ipfs(res?.data?.other_data_2);
        setfolderArr(res?.data?.folderArr);
        setfolderArrIpfs(res?.data?.folderArr);
        setfolderArrIpfs(res?.data?.folderArr);
      } catch (err) {
        // console.log("get_required_folder_data err***", err);
      }
    }
    if (alignment === "aws" || alignment === "both") {
      try {
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE + `/folder/all-child-folders-files-aws`;

        const data = {
          companyId: CompanyID,
          userId: userID, // user who is logged in currently
          // folderName: [`${row.folder_name}`],
          folderName:
            alignment === "aws"
              ? [`${row?.folder_name}`]
              : row?.awsData
              ? [`${row?.awsData?.folder_name}`]
              : [`${row?.awsFolder}`],
          bucketId: bucketId,
          bucketName: awsBucketName, // atom-storage
        };

        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        let res = await axios.post(API_URL, data, headerConfig);

        // console.log("get_required_folder_data res***", res);
        setother_data_2(res?.data?.other_data_2);
        setother_data_2Aws(res?.data?.other_data_2);
        setfolderArr(res?.data?.folderArr);
        setfolderArrAws(res?.data?.folderArr);
        setfolderArrAws(res?.data?.folderArr);
      } catch (err) {
        // console.log("get_required_folder_data err***", err);
      }
    }
  };

  const ColumnFolder = [
    {
      name: <>{text_srno}</>,
      sortable: true,
      selector: (row, index) => <div className="yf-srno">{index + 1}</div>,
      width: "100px",
    },

    {
      name: <>{text_foldername}</>,
      selector: (row) => (
        <>
          <div
            className="show-view-div test__table-icon-selected"
            onClick={() => handleChangeFolder(row)}
          >
            <img src={folderImg} alt="" className="yf-aws2-folderImg" />{" "}
            {row?.folder_name?.slice(
              row?.folder_name?.lastIndexOf(
                "/",
                row?.folder_name?.lastIndexOf("/") - 1
              ) + 1,
              row?.folder_name?.length - 1
            )}
          </div>
        </>
      ),
      width: "465px",
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => (
        <>
          <div className="show-view-div">
            {moment(row?.createdAt).format("MMM DD, yyyy")}
          </div>
        </>
      ),
      width: "380px",
    },

    {
      name: <>{text_Location}</>,
      selector: (row) => (
        <>
          {alignment === "ipfs"
            ? "Atom Storage"
            : alignment === "aws"
            ? "Amazon S3"
            : "Amazon S3/Atom Storage"}
        </>
      ),
    },
    {
      name: <>{text_access}</>,
      selector: (row, index) => (
        <button
          className="yf-edit-btn"
          onClick={() => [
            handleOpenPopupFolder(row),
            setLoaderPopup(true + index),
          ]}
        >
          {loaderPopup === true + index ? (
            <Loader2 />
          ) : (
            <span>
              <span style={{ marginRight: "5px" }}>{text_edit}</span>
              <span>{text_access}</span>
            </span>
          )}
        </button>
      ),
      width: "155px",
    },
  ];

  const ColumnFolderBoth = [
    {
      name: <>{text_srno}</>,
      sortable: true,
      selector: (row, index) => <div className="yf-srno">{index + 1}</div>,
      width: "55px",
    },

    {
      name: <>{text_foldername}</>,
      selector: (row) => (
        <>
          <div
            className="show-view-div test__table-icon-selected"
            onClick={() => handleChangeFolder(row)}
          >
            <img src={folderImg} alt="" className="yf-aws2-folderImg" />{" "}
            {row?.folder?.slice(
              row?.folder?.lastIndexOf("/", row?.folder?.lastIndexOf("/") - 1) +
                1,
              row?.folder?.length - 1
            )}
          </div>
        </>
      ),
      width: "200px",
    },
    {
      name: <>{text_atomfolder}</>,
      selector: (row) => (
        <div
          className=""
          title={
            row?.atomData?.folder_name
              ? `${row?.atomData?.folder_name
                  .split(`/`)
                  .slice(2)
                  .join("/")}` === row?.folder
                ? "/"
                : `/${row?.atomData?.folder_name.split(`/`).slice(2).join("/")}`
              : `/${row?.atomFolder?.split(`/`).slice(2).join("/")}`
          }
        >
          {row?.atomData?.folder_name
            ? `${row?.atomData?.folder_name.split(`/`).slice(2).join("/")}` ===
              row?.folder
              ? "/"
              : `/${row?.atomData?.folder_name.split(`/`).slice(2).join("/")}`
            : `/${row?.atomFolder?.split(`/`).slice(2).join("/")}`}
        </div>
      ),
    },
    {
      name: <>{text_amazons3folder}</>,
      selector: (row) => (
        <div
          className=""
          title={
            row?.awsData?.folder_name
              ? `${row?.awsData?.folder_name.split(`/`).slice(2).join("/")}` ===
                row?.folder
                ? "/"
                : `/${row?.awsData?.folder_name.split(`/`).slice(2).join("/")}`
              : `/${row?.awsFolder?.split(`/`).slice(2).join("/")}`
          }
        >
          {row?.awsData?.folder_name
            ? `${row?.awsData?.folder_name.split(`/`).slice(2).join("/")}` ===
              row?.folder
              ? "/"
              : `/${row?.awsData?.folder_name.split(`/`).slice(2).join("/")}`
            : `/${row?.awsFolder?.split(`/`).slice(2).join("/")}`}
        </div>
      ),
    },
    {
      name: (
        <>
          {text_amazons3} {text_bucket}
        </>
      ),
      selector: (row) => (
        <div
          className=""
          title={row?.awsBkt ? row?.awsBkt : bucketNameForChildFolders}
        >
          {row?.awsBkt ? row?.awsBkt : bucketNameForChildFolders}
        </div>
      ),
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => (
        <>
          <div className="show-view-div">
            {moment(row?.atomData?.createdAt).format("MMM DD, yyyy")}
          </div>
        </>
      ),
      width: "120px",
    },

    {
      name: <>{text_Location}</>,
      selector: (row) => (
        <>
          {alignment === "ipfs"
            ? "Atom Storage"
            : alignment === "aws"
            ? "Amazon S3"
            : "Amazon S3/Atom Storage"}
        </>
      ),
    },
    {
      name: <>{text_access}</>,
      selector: (row, index) => (
        <button
          className="yf-edit-btn"
          onClick={() => [
            handleOpenPopupFolder(row),
            setLoaderPopup(true + index),
          ]}
        >
          {loaderPopup === true + index ? (
            <Loader2 />
          ) : (
            <span>
              <span style={{ marginRight: "5px" }}>{text_edit}</span>
              <span>{text_access}</span>
            </span>
          )}
        </button>
      ),
      width: "155px",
    },
  ];
  const columns = [
    {
      name: <>{text_srno}</>,
      sortable: true,
      selector: (row, index) => <div className="yf-srno">{index + 1}</div>,
      width: "100px",
    },

    {
      name: <>{text_filename1}</>,
      selector: (row) => row?.fileName,
    },

    {
      name: <>{text_dateofupload}</>,
      selector: (row) => moment(row?.uploadDateAndTime).format("MMM DD, YYYY"),
    },

    {
      name: <>{text_Location}</>,
      selector: (row) => (
        <>
          {alignment === "ipfs"
            ? "Atom Storage"
            : alignment === "aws"
            ? "Amazon S3"
            : "Amazon S3/Atom Storage"}
        </>
      ),
    },
    {
      name: <>{text_access}</>,
      selector: (row, index) => (
        <button
          className="yf-edit-btn"
          onClick={() => [handleOpenPopup(row), setLoaderPopup(true + index)]}
        >
          {loaderPopup === true + index ? (
            <Loader2 />
          ) : (
            <span>
              <span style={{ marginRight: "5px" }}>{text_edit}</span>
              <span>{text_access}</span>
            </span>
          )}
        </button>
      ),
      width: "155px",
    },
  ];

  const onMouseOutamazon = () => {
    if (opentooltipamazon) {
      setTimeout(() => {
        setOpenTooltipamazon(false);
      }, 1000);
    }
  };

  const filteredItems = Data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const filteredFileItems = DataFiles.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    // console.log(filteredItems);

    return (
      <>
        <div className="">
          <div className="">
            <FilterComponent
              onFilter={(e) => setFilterText(e.target.value)}
              onClear={handleClear}
              filterText={filterText}
              PlaceHolder={text_searchhere}
              className="fc-input"
              clearClass="fc-x"
            />
          </div>
        </div>
      </>
    );
  }, [filterText, resetPaginationToggle, text_searchhere]);
  // console.log(filterText);

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);

  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_searchhere(
        xpath.select1("/resources/string[@name='text_searchhere']", doc)
          .firstChild.data
      );
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_filename1(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );
      setText_filesize(
        xpath.select1("/resources/string[@name='text_filesize']", doc)
          .firstChild.data
      );
      setText_dateofupload(
        xpath.select1("/resources/string[@name='text_dateofupload']", doc)
          .firstChild.data
      );
      setText_atomfileid(
        xpath.select1("/resources/string[@name='text_atomfileid']", doc)
          .firstChild.data
      );
      setText_viewdetails(
        xpath.select1("/resources/string[@name='text_viewdetails']", doc)
          .firstChild.data
      );
      setText_view(
        xpath.select1("/resources/string[@name='text_view']", doc).firstChild
          .data
      );
      setText_edit(
        xpath.select1("/resources/string[@name='text_edit']", doc).firstChild
          .data
      );
      setText_amazons3link1(
        xpath.select1("/resources/string[@name='text_amazons3link1']", doc)
          .firstChild.data
      );
      setText_bucket(
        xpath.select1("/resources/string[@name='text_bucket']", doc).firstChild
          .data
      );
      setText_atomfolder(
        xpath.select1("/resources/string[@name='text_atomfolder']", doc)
          .firstChild.data
      );
      setText_amazons3(
        xpath.select1("/resources/string[@name='text_amazons3']", doc)
          .firstChild.data
      );
      setText_atomstorage(
        xpath.select1("/resources/string[@name='text_atomstorage']", doc)
          .firstChild.data
      );
      setText_both(
        xpath.select1("/resources/string[@name='text_both']", doc).firstChild
          .data
      );
      setText_file(
        xpath.select1("/resources/string[@name='text_file']", doc).firstChild
          .data
      );
      setText_folder(
        xpath.select1("/resources/string[@name='text_folder']", doc).firstChild
          .data
      );
      setText_peoplewithaccess(
        xpath.select1("/resources/string[@name='text_peoplewithaccess']", doc)
          .firstChild.data
      );
      setText_read(
        xpath.select1("/resources/string[@name='text_read']", doc).firstChild
          .data
      );
      setText_write(
        xpath.select1("/resources/string[@name='text_write']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_removeaccess(
        xpath.select1("/resources/string[@name='text_removeaccess']", doc)
          .firstChild.data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_done(
        xpath.select1("/resources/string[@name='text_done']", doc).firstChild
          .data
      );
      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );
      setText_selectbucket(
        xpath.select1("/resources/string[@name='text_selectbucket']", doc)
          .firstChild.data
      );
      setText_access(
        xpath.select1("/resources/string[@name='text_access']", doc).firstChild
          .data
      );

      setText_Location(
        xpath.select1("/resources/string[@name='text_Location']", doc)
          .firstChild.data
      );
      setText_foldername(
        xpath.select1("/resources/string[@name='text_foldername']", doc)
          .firstChild.data
      );
      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  function truncate(string, n) {
    return string?.length > n
      ? string.substr(0, n - 1) +
          ". . . . . . ." +
          string.slice(string.length - 7)
      : string;
  }

  const onMouseOutcid = () => {
    if (opentooltip) {
      setTimeout(() => {
        setOpenTooltip(false);
      }, 1000);
    }
  };
  var DataVlaue = [...datavalueArray];
  var DataVlaueAws = [...datavalueArrayAws];
  var DataVlaueIpfs = [...datavalueArrayIpfs];
  var DataId = [...dataIdArray];
  var DataIdAws = [...dataIdArrayAws];
  var DataIdIpfs = [...dataIdArrayIpfs];
  const handleChange = (id, event, uploadLocation, folder_id) => {
    // console.log(typeof id);
    // console.log(event);

    let readAccess = false;
    let writeAccess = false;
    let downloadAccess = false;

    if (event === "read") {
      readAccess = true;
    } else if (event === "write") {
      readAccess = true;
      writeAccess = true;
    } else if (event === "download") {
      readAccess = true;
      writeAccess = true;
      downloadAccess = true;
    }

    if (event !== "remove") {
      if (uploadLocation === "aws") {
        DataVlaueAws.push({
          user_id: id,
          read_access: readAccess,
          write_access: writeAccess,
          download_access: downloadAccess,
          folder_id: folder_id,
        });
      }
      if (uploadLocation === "ipfs") {
        DataVlaueIpfs.push({
          user_id: id,
          read_access: readAccess,
          write_access: writeAccess,
          download_access: downloadAccess,
          folder_id: folder_id,
        });
      }

      // console.log(DataVlaueAws, DataVlaueIpfs);
      setDatavalueArrayAws(DataVlaueAws);
      setDatavalueArrayIpfs(DataVlaueIpfs);
    } else {
      if (uploadLocation === "aws") {
        setRemoveEvent(event);
        DataIdAws.push(`${id}`);
        // console.log(DataIdAws);
        setDataIdArrayAws(DataIdAws);
      }
      if (uploadLocation === "ipfs") {
        setRemoveEvent(event);
        DataIdIpfs.push(`${id}`);
        // console.log(DataIdIpfs);
        setDataIdArrayIpfs(DataIdIpfs);
      }
    }
  };
  useEffect(() => {
    console.log(datavalueArrayAws);
    console.log(dataIdArrayAws);
    console.log(datavalueArrayIpfs);
    console.log(dataIdArrayIpfs);
  }, [datavalueArrayAws, dataIdArrayAws, datavalueArrayIpfs, dataIdArrayIpfs]);
  var DataVlaueFolderAws = [...datavalueArrayFolderAws];
  var DataVlaueFolderIpfs = [...datavalueArrayFolderIpfs];
  var DataIdFolderAws = [...dataIdArrayFolderAws];
  var DataIdFolderIpfs = [...dataIdArrayFolderIpfs];
  const handleChangeFolderValue = (id, event, bucketID, uploadLocation) => {
    // console.log(typeof id);
    // console.log(event);

    let readAccess = false;
    let writeAccess = false;
    let downloadAccess = false;

    if (event === "read") {
      readAccess = true;
    } else if (event === "write") {
      readAccess = true;
      writeAccess = true;
    } else if (event === "download") {
      readAccess = true;
      writeAccess = true;
      downloadAccess = true;
    }

    if (event !== "remove") {
      if (uploadLocation === "aws") {
        DataVlaueFolderAws.push({
          user_id: id,
          read_access: readAccess,
          write_access: writeAccess,
          download_access: downloadAccess,
          bucket_id: bucketID,
        });
        // console.log(DataVlaueFolderAws);
        setDatavalueArrayFolderAws(DataVlaueFolderAws);
      }
      if (uploadLocation === "ipfs") {
        DataVlaueFolderIpfs.push({
          user_id: id,
          read_access: readAccess,
          write_access: writeAccess,
          download_access: downloadAccess,
          bucket_id: bucketID,
        });
        // console.log(DataVlaueFolderIpfs);
        setDatavalueArrayFolderIpfs(DataVlaueFolderIpfs);
      }
    } else {
      if (uploadLocation === "aws") {
        setRemoveEvent(event);
        DataIdFolderAws.push(`${id}`);
        // console.log(DataIdFolderAws);
        setDataIdArrayFolderAws(DataIdFolderAws);
      }
      if (uploadLocation === "ipfs") {
        setRemoveEvent(event);
        DataIdFolderIpfs.push(`${id}`);
        // console.log(DataIdFolderIpfs);
        setDataIdArrayFolderIpfs(DataIdFolderIpfs);
      }
    }
  };
  useEffect(() => {
    console.log(datavalueArrayFolderAws);
    console.log(datavalueArrayFolderIpfs);
    console.log(dataIdArrayFolderAws);
    console.log(dataIdArrayFolderIpfs);
  }, [
    datavalueArrayFolderAws,
    dataIdArrayFolderAws,
    dataIdArrayFolderIpfs,
    dataIdArrayFolderIpfs,
  ]);

  const handleEdit = () => {
    setLoaderData(true);
    if (alignment === "ipfs") {
      var is_aws = false;
      var is_ipfs = true;
    } else if (alignment === "aws") {
      var is_aws = true;
      var is_ipfs = false;
    } else if (alignment === "both") {
      var is_aws = true;
      var is_ipfs = true;
    }
    // console.log(is_aws);
    // console.log(is_ipfs);
    // console.log(folderId);
    setAccessPopup(false);
    if (removeEvent !== "remove") {
      // console.log(0);
    } else if (removeEvent === "remove") {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      if (dataIdArrayAws.length !== 0) {
        var data = {
          sender_user_id: userID,
          file_unique_id: rowFileId,
          folder_id: folderIdAws,
          user_id_arr: dataIdArrayAws,
        };
        var Api_url = GLOBAL_URL_DOMAIN_LIVE + `/file-access/delete-record`;
        axios
          .delete(Api_url, {
            headers: headerConfig.headers,
            data: data,
          })
          .then((res) => {
            // console.log(res);
            successToast(res?.data?.message);
            setAccessPopup(false);

            dispatch(fetchSharedByMeFiles(alignment, bucketId));
            dispatch(fetchSharedByMeFiles("aws", bucketId));
            dispatch(foderwiseAwsFiless(bucketId));
          })
          .catch((err) => {
            // console.log(err.message);
            errorToast(err.message);

            if (err?.response && err?.response.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          });
      }
      if (dataIdArrayIpfs.length !== 0) {
        var data = {
          sender_user_id: userID,
          file_unique_id: rowFileId,
          folder_id: folderIdIpfs,
          user_id_arr: dataIdArrayIpfs,
        };
        var Api_url = GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/delete-record`;
        axios
          .delete(Api_url, {
            headers: headerConfig.headers,
            data: data,
          })
          .then((res) => {
            // console.log(res);
            successToast(res?.data?.message);

            dispatch(fetchSharedByMeFiles(alignment, bucketId));
            dispatch(fetchSharedByMeFiles("ipfs", bucketId));
            dispatch(
              getIPFSFileData(
                `atom-storage/${userID}/`,
                "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
              )
            );
          })
          .catch((err) => {
            // console.log(err.message);
            errorToast(err.message);

            if (err?.response && err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          });
      }
    }

    if (datavalueArrayAws.length !== 0 || datavalueArrayIpfs.length !== 0) {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file-access/edit-record`;
      var data = {
        sender_user_id: userID,
        file_unique_id: rowFileId,

        edit_data_aws: datavalueArrayAws,
        edit_data_atom: datavalueArrayIpfs,
        is_aws: is_aws,
        is_atom: is_ipfs,
      };
      axios
        .put(API_URL, data, headerConfig)
        .then((res) => {
          // console.log(res);
          successToast(res?.data?.message);
          setFolderIdAws("");
          setFolderIdIpfs("");
          setRowFileId("");
          setAccessPopup(false);

          if (rowFileId.startsWith("1_0")) {
            // console.log("aws");
            dispatch(foderwiseAwsFiless(bucketId));
          } else if (rowFileId.startsWith("0_1")) {
            // console.log("ipfs");
            dispatch(
              getIPFSFileData(
                `atom-storage/${userID}/`,
                "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
              )
            );
          }

          setAlignmentFolder("file");
          setAccessPopup(false);
        })
        .catch((err) => {
          // console.log("add_folder_id_func err*", err);
          errorToast(err.message);
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        });
    }
    setDatavalueArray([]);
    setDatavalueArrayAws([]);
    setDatavalueArrayIpfs([]);
    setDataIdArray([]);
    setDataIdArrayAws([]);
    setDataIdArrayIpfs([]);

    setLoaderData(false);
  };
  const handleEditFolder = () => {
    setLoaderPopup(true);
    if (alignment === "aws") {
      var is_aws = false;
      var is_ipfs = true;
    } else if (alignment === "ipfs") {
      var is_aws = true;
      var is_ipfs = false;
    } else if (alignment === "both") {
      var is_aws = true;
      var is_ipfs = true;
    }
    // console.log(is_aws);
    // console.log(is_ipfs);
    // console.log(folderId);

    if (removeEvent !== "remove") {
      // console.log(0);
    } else if (removeEvent === "remove") {
      const userToken = sessionStorage.getItem("userToken");

      if (!userToken) {
        console.error("Token not provided");
        return;
      }

      let headerConfig = {
        headers: {
          authorization: "Bearer " + userToken,
        },
      };

      if (dataIdArrayFolderAws.length !== 0) {
        var data = {
          company_id: CompanyID,
          company_user_id: userID,
          user_id: dataIdArrayFolderAws,
          other_data_2: other_data_2Aws,
          bucketId: bucketId,
          folderArr: folderArrAws,
        };
        var Api_url =
          GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/remove-folder-access-aws`;
        axios
          .put(Api_url, data, headerConfig)
          .then((res) => {
            // console.log(res);
            setLoaderPopup(false);
            setAccessPopupFolder(false);
            successToast(res?.data?.message);
            setDataIdArrayFolderAws([]);
            dispatch(getAwsRootData(bucketId, awsBucketName));
            dispatch(
              fetchSharedByMeFolders(alignment, bucketId, awsBucketName)
            );
          })
          .catch((err) => {
            setLoaderPopup(false);

            // console.log(err.message);
            errorToast(err.message);

            if (err?.response && err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          });
      }
      if (dataIdArrayFolderIpfs.length !== 0) {
        var data = {
          company_id: CompanyID,
          company_user_id: userID,
          user_id: dataIdArrayFolderIpfs,
          other_data_2: other_data_2Ipfs,
          bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
          folderArr: folderArrIpfs,
        };
        var Api_url =
          GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/remove-folder-access-ipfs`;
        axios
          .put(Api_url, data, headerConfig)
          .then((res) => {
            // console.log(res);
            setAccessPopupFolder(false);
            successToast(res?.data?.message);
            setDataIdArrayFolderIpfs([]);
            setLoaderPopup(false);
            dispatch(getIPFSRootData());
            dispatch(
              fetchSharedByMeFolders(alignment, bucketId, awsBucketName)
            );
          })
          .catch((err) => {
            setLoaderPopup(false);

            // console.log(err.message);
            errorToast(err.message);

            if (err?.response && err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          });
      }
    }

    if (datavalueArrayFolderAws.length === 0) {
      // console.log("empty");
    } else {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/edit-folder-access-aws`;
      var data = {
        company_id: CompanyID,
        company_user_id: userID,
        db_version: dbVersion,
        other_data_2: other_data_2Aws,
        bucketId: bucketId,
        folderArr: folderArrAws,
        editData: datavalueArrayFolderAws,
      };

      axios
        .put(API_URL, data, headerConfig)
        .then((res) => {
          // console.log(res);
          successToast(res?.data?.message);
          setAccessPopupFolder(false);
          setDatavalueArrayFolderAws([]);
        })
        .catch((err) => {
          // console.log("add_folder_id_func err*", err);
          errorToast(err.message);
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        });
    }

    if (datavalueArrayFolderIpfs.length === 0) {
      // console.log("empty");
    } else {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/edit-folder-access-ipfs`;
      var data = {
        company_id: CompanyID,
        company_user_id: userID,
        db_version: dbVersion,
        other_data_2: other_data_2Ipfs,
        bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        folderArr: folderArrIpfs,
        editData: datavalueArrayFolderIpfs,
      };

      axios
        .put(API_URL, data, headerConfig)
        .then((res) => {
          // console.log(res.message);
          successToast(res?.data?.message);
          setAccessPopupFolder(false);
          setDatavalueArrayFolderIpfs([]);
        })
        .catch((err) => {
          // console.log("add_folder_id_func err*", err);
          errorToast(err.message);
          if (err?.response && err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        });
    }
    setDatavalueArrayFolderAws([]);
    setDatavalueArrayFolderIpfs([]);
    setDataIdArrayFolderAws([]);
    setDataIdArrayFolderIpfs([]);
  };
  const handButtonToggle = (event, newAlignment) => {
    setShowFilesTable(false);
    setViewBackBtn(false);
    // console.log(newAlignment, alignment);
    if (
      newAlignment !== alignment &&
      newAlignment !== null &&
      alignmentFolder !== "folder"
    ) {
      // console.log(newAlignment);
      setAlignment(newAlignment);
      shared_by_me_files_func(newAlignment);
    } else if (newAlignment !== alignment && newAlignment !== null) {
      setAlignment(newAlignment);
      shared_by_me_folders_func(newAlignment);
    }
  };
  useEffect(() => {
    // console.log(alignmentFolder, alignment);
  }, [alignmentFolder]);
  const handButtonToggleFolder = (event, newAlignment) => {
    setShowFilesTable(false);
    setViewBackBtn(false);
    if (newAlignment !== alignmentFolder && newAlignment !== null) {
      // console.log(newAlignment);
      setAlignmentFolder(newAlignment);
      if (newAlignment === "folder") {
        setFolderTable(true);
        shared_by_me_folders_func(alignment);
      } else {
        setFolderTable(false);
        shared_by_me_files_func(alignment);
      }
    }
  };
  const handleChangeOnFileRowClick = (state) => {
    navigate("/view-file", {
      state: {
        fileData: state,
        selecttab: 4,
      },
    });
  };

  const handleSelectBucket = (val) => {
    // console.log(allBucket);
    // console.log(val);
    setBucketId("");
    setAwsBucketName("");
    setBucketId(val);
    if (allBucket.length > 0) {
      var BucketFinalName = allBucket.filter((e, i) => e.bucketId === val);
      // console.log(allBucket[0].bucketACLOption);
      setBucketIdToTrue(true);
      if (BucketFinalName.length > 0) {
        // console.log(BucketFinalName[0].bucketName);
        setAwsBucketName(BucketFinalName[0].bucketName);
      }
    }
  };
  useEffect(() => {
    if (alignmentFolder === "folder") {
      dispatch(fetchSharedByMeFolders(alignment, bucketId, awsBucketName));
    }
    if (alignmentFolder === "file" && bucketIdToTrue === true) {
      dispatch(fetchSharedByMeFiles(alignment, bucketId));
    }
  }, [awsBucketName, bucketId]);
  const handleChangeFolder = async (state) => {
    // console.log(state);

    var companyID = sessionStorage.getItem("companyID");
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    if (alignment === "aws") {
      try {
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE +
          `/file-access/shared-by-me-child-folders
          `;

        const data = {
          folder_name: state.folder_name,
          company_id: companyID,
          bucket_id: bucketId,
          sender_user_id: userID,
          user_id: state.user_id,
        };

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("nestedFolderData res***", res);
        setData(res?.data?.data);
        setDataFiles(res?.data?.fileData);
        setViewBackBtn(true);
        setFolderNameForBackButton(state.folder_name);
        setUserIdForBackButton(state.user_id);
        setShowFilesTable(true);
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      }
    } else if (alignment === "ipfs") {
      try {
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE +
          `/ipfs-access/shared-by-me-child-folders
          `;

        const data = {
          folder_name: state.folder_name,
          company_id: companyID,
          bucket_id: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
          sender_user_id: userID,
          user_id: state.user_id,
          folder_id: state.folder_id,
        };

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("nestedFolderData res***", res);
        setData(res?.data?.data);
        setDataFiles(res?.data?.fileData);
        setViewBackBtn(true);
        setFolderNameForBackButton(state.folder_name);
        setUserIdForBackButton(state.user_id);
        setShowFilesTable(true);
      } catch (err) {
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      }
    } else if (alignment === "both") {
      try {
        let API_URL =
          GLOBAL_URL_DOMAIN_LIVE +
          `/file-access/shared-by-me-child-folders-aws-atom
          `;

        const data = {
          company_id: companyID,
          sender_user_id: userID,

          bucket_id: state?.awsData?.bucket_id
            ? state.awsData.bucket_id
            : bucketIdForChildFolders,
          aws_folder_name: state?.awsData?.folder_name
            ? state.awsData.folder_name
            : state.awsFolder,

          atom_folder_name: state?.atomData?.folder_name
            ? state.atomData.folder_name
            : state.atomFolder,
        };

        let res = await axios.post(API_URL, data, headerConfig);
        // console.log("nestedFolderData res***", res);
        setData(res?.data?.folderData);
        setBucketIdForChildFolders(res?.data?.idData?.bucket_id);
        setBucketNameForChildFolders(res?.data?.idData?.bucket_name);

        setDataFiles(res?.data?.fileData);
        setShowFilesTable(true);
        setViewBackBtn(true);
        setFolderNameForBackButton(
          state?.awsData?.folder_name
            ? state.awsData.folder_name
            : state.awsFolder
        );
        setFolderNameForBackButtonIpfs(
          state?.atomData?.folder_name
            ? state.atomData.folder_name
            : state.atomFolder
        );
      } catch (err) {
        // console.log(err);
        setLoaderPopup(false);
        if (err?.response && err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      }
    }
  };
  useEffect(() => {
    // console.log("Updated folderHistory:", folderNameForBackButton);
    if (
      (folderNameForBackButton !== "" ||
        folderNameForBackButton !== undefined ||
        folderNameForBackButton !== null) &&
      bck === true
    ) {
      setTimeout(async () => {
        // console.log(folderNameForBackButton);
        const slashCount = (folderNameForBackButton.match(/\//g) || []).length;
        const slashCountIpfs = (folderNameForBackButtonIpfs.match(/\//g) || [])
          .length;
        // console.log(slashCount);
        if (slashCount === 2 || slashCountIpfs === 2) {
          setBck(false);
          setViewBackBtn(false);
          setDataFiles([]);
          setShowFilesTable(false);
          shared_by_me_folders_func(alignment);
        } else if (slashCount > 2) {
          var companyID = sessionStorage.getItem("companyID");
          let headerConfig = {
            headers: {
              authorization: "Bearer " + sessionStorage.getItem("userToken"),
            },
          };
          if (alignment === "aws") {
            try {
              let API_URL =
                GLOBAL_URL_DOMAIN_LIVE +
                `/file-access/shared-by-me-child-folders
                `;

              const data = {
                folder_name: folderNameForBackButton,
                company_id: companyID,
                bucket_id: bucketId,
                sender_user_id: userID,
                user_id: userIdForBackButton,
              };

              let res = await axios.post(API_URL, data, headerConfig);
              // console.log("nestedFolderData res***", res);
              setData(res?.data?.data);
              setDataFiles(res?.data?.fileData);
            } catch (err) {
              setLoaderPopup(false);
              if (err?.response && err?.response?.status === 427) {
                sessionStorage.clear();
                localStorage.clear();
                Cookie.remove("fName");
                Cookie.remove("lName");
                Cookie.remove("userID");
                Cookie.remove("userToken");
                Cookie.remove("token_start_time");
                navigate("/");
              }
            }
          } else if (alignment === "ipfs") {
            try {
              let API_URL =
                GLOBAL_URL_DOMAIN_LIVE +
                `/ipfs-access/shared-by-me-child-folders
                `;

              const data = {
                folder_name: folderNameForBackButton,
                company_id: companyID,
                bucket_id: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
                sender_user_id: userID,
                user_id: userIdForBackButton,
              };

              let res = await axios.post(API_URL, data, headerConfig);
              // console.log("nestedFolderData res***", res);
              setData(res?.data?.data);
              setDataFiles(res?.data?.fileData);
            } catch (err) {
              setLoaderPopup(false);
              if (err?.response && err?.response?.status === 427) {
                sessionStorage.clear();
                localStorage.clear();
                Cookie.remove("fName");
                Cookie.remove("lName");
                Cookie.remove("userID");
                Cookie.remove("userToken");
                Cookie.remove("token_start_time");
                navigate("/");
              }
            }
          } else if (alignment === "both") {
            try {
              let API_URL =
                GLOBAL_URL_DOMAIN_LIVE +
                `/file-access/shared-by-me-child-folders-aws-atom
                `;

              const data = {
                company_id: companyID,
                sender_user_id: userID,
                bucket_id: bucketIdForChildFolders,
                aws_folder_name: folderNameForBackButton,
                atom_folder_name: folderNameForBackButtonIpfs,
              };

              let res = await axios.post(API_URL, data, headerConfig);
              // console.log("nestedFolderData res***", res);
              setData(res?.data?.folderData);
              setDataFiles(res?.data?.fileData);
            } catch (err) {
              setLoaderPopup(false);
              if (err?.response && err?.response?.status === 427) {
                sessionStorage.clear();
                localStorage.clear();
                Cookie.remove("fName");
                Cookie.remove("lName");
                Cookie.remove("userID");
                Cookie.remove("userToken");
                Cookie.remove("token_start_time");
                navigate("/");
              }
            }
          }
        }
      }, 0);
    }
  }, [folderNameForBackButton]);

  const handleBack = async () => {
    setBck(true);
    setFolderNameForBackButton((prevFolderName) => {
      const pathParts = prevFolderName.split("/");
      if (pathParts.length > 2) {
        pathParts.splice(-2, 2);
        const newPath = pathParts.join("/") + "/";
        return newPath;
      }
      return prevFolderName;
    });
    setFolderNameForBackButtonIpfs((prevFolderName) => {
      const pathParts = prevFolderName.split("/");
      if (pathParts.length > 2) {
        pathParts.splice(-2, 2);
        const newPath = pathParts.join("/") + "/";
        return newPath;
      }
      return prevFolderName;
    });
  };
  return (
    <>
      <div className="yf-ipfs-main mt-5">
        {isLoading === true ? (
          <>
            <div className="text-center">
              <Loader3 />
            </div>
          </>
        ) : (
          <div>
            {showContent && (
              <div>
                <div className="d-flex justify-content-between mobile-responsive-container">
                  <div>
                    <ToggleButtonGroup
                      value={alignment}
                      exclusive
                      onChange={handButtonToggle}
                      aria-label="Platform"
                      className="m-2 "
                    >
                      <ToggleButton
                        style={{
                          borderStartStartRadius: "20px",
                          borderEndStartRadius: "20px",
                          color: alignment === "aws" ? "#009087" : "inherit",
                          bgcolor: alignment === "aws" ? "#dadada" : "inherit",
                        }}
                        value="aws"
                      >
                        {text_amazons3}
                      </ToggleButton>
                      <ToggleButton
                        value="ipfs"
                        style={{
                          color: alignment === "ipfs" ? "#009087" : "inherit",
                          bgcolor: alignment === "ipfs" ? "#dadada" : "inherit",
                        }}
                      >
                        {text_atomstorage}
                      </ToggleButton>
                      <ToggleButton
                        style={{
                          borderStartEndRadius: "20px",
                          borderEndEndRadius: "20px",
                          color: alignment === "both" ? "#009087" : "inherit",
                          bgcolor: alignment === "both" ? "#dadada" : "inherit",
                        }}
                        value="both"
                      >
                        {text_both}
                      </ToggleButton>
                    </ToggleButtonGroup>

                    <span className="verical-Line">|</span>
                    <ToggleButtonGroup
                      value={alignmentFolder}
                      exclusive
                      onChange={handButtonToggleFolder}
                      aria-label="Platform"
                      className="m-2"
                    >
                      <ToggleButton
                        style={{
                          borderRadius: "10px",
                          margin: "2px",
                          height: "40px",
                          borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                          color:
                            alignmentFolder === "file" ? "#009087" : "inherit",
                          bgcolor:
                            alignmentFolder === "file" ? "#dadada" : "inherit",
                        }}
                        value="file"
                      >
                        {text_file}
                      </ToggleButton>
                      <ToggleButton
                        style={{
                          borderRadius: "10px",
                          margin: "2px",
                          height: "40px",
                          borderLeft: "1px solid rgba(0, 0, 0, 0.12)",
                          color:
                            alignmentFolder === "folder"
                              ? "#009087"
                              : "inherit",
                          bgcolor:
                            alignmentFolder === "folder"
                              ? "#dadada"
                              : "inherit",
                        }}
                        value="folder"
                      >
                        {text_folder}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>
                  <div> {subHeaderComponent}</div>
                </div>

                {alignment === "aws" ? (
                  <div className="d-flex mt-3 mb-3" id="res-awsfolder-main2">
                    <select
                      className="form-select ms-2"
                      style={{ width: "250px" }}
                      onChange={(e) => [handleSelectBucket(e.target.value)]}
                      value={bucketId}
                    >
                      <>
                        <option disabled>{text_selectbucket}</option>
                        {allBucket.map((v, i) => (
                          <option value={v.bucketId} key={i}>
                            {v.bucketName === "atom-storage"
                              ? `${sessionStorage.getItem("fName")}'s Bucket`
                              : v.bucketName === "atom-storage-private"
                              ? `${sessionStorage.getItem("fName")}'s Bucket`
                              : v.bucketName}{" "}
                            ({v.bucketACLOption})
                          </option>
                        ))}
                      </>
                    </select>
                  </div>
                ) : null}

                {folderTable === true ? (
                  <div className="">
                    <div className="d-flex justify-content-end mx-4">
                      {viewBackBtn && (
                        <div className="">
                          <button
                            className="back-btn-view"
                            style={{ marginTop: "0px" }}
                            onClick={() => {
                              handleBack();
                            }}
                          >
                            <span>
                              <MdOutlineKeyboardBackspace className="back-btn-icon back-icon" />
                            </span>
                            <span>{text_back}</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <h5 className="mx-4">{text_folder}</h5>
                    <div className="folder-file-datatable mb-2">
                      <DataTable
                        columns={
                          alignment === "both" ? ColumnFolderBoth : ColumnFolder
                        }
                        pointerOnHover
                        data={filteredItems}
                        pagination
                        fixedHeader
                        selectableRowsHighlight
                        highlightOnHover
                        customStyles={
                          alignment === "both"
                            ? customTableStylesFileBoth
                            : customTableStylesFile
                        }
                        onRowClicked={handleChangeFolder}
                      />
                    </div>
                    {showFilesTable === true ? (
                      <>
                        <h5 className="mx-4 mt-2">{text_file}</h5>
                        <div className="folder-file-datatable mb-2">
                          <DataTable
                            columns={columns}
                            pointerOnHover
                            data={filteredFileItems}
                            pagination
                            fixedHeader
                            selectableRowsHighlight
                            highlightOnHover
                            customStyles={customTableStylesFile}
                            onRowClicked={handleChangeOnFileRowClick}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <div className="folder-file-datatable mb-2">
                    <DataTable
                      columns={columns}
                      pointerOnHover
                      data={filteredItems}
                      pagination
                      fixedHeader
                      selectableRowsHighlight
                      highlightOnHover
                      customStyles={customTableStyles}
                      onRowClicked={handleChangeOnFileRowClick}
                    />
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        {accessPopup && (
          <Dialog
            open={accessPopup}
            onClose={() => {
              setAccessPopup(false);
              setDatavalueArray([]);
              setDatavalueArrayAws([]);
              setDatavalueArrayIpfs([]);
              setDataIdArray([]);
              setDataIdArrayAws([]);
              setDataIdArrayIpfs([]);
            }}
            aria-labelledby="responsive-dialog-title"
            classes={{ paper: "custom-dialog" }}
          >
            <DialogTitle id="responsive-dialog-title" className="text-center">
              <div className="d-flex justify-content-between">
                <h5 className="mt-1">{text_peoplewithaccess}</h5>
                <h3>
                  <CgCloseO
                    onClick={() => {
                      setAccessPopup(false);
                      setDatavalueArray([]);
                      setDatavalueArrayAws([]);
                      setDatavalueArrayIpfs([]);
                      setDataIdArray([]);
                      setDataIdArrayAws([]);
                      setDataIdArrayIpfs([]);
                    }}
                  />
                </h3>
              </div>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  {alignment === "both" ? (
                    <div className="container ">
                      <h6 className="mt-1 fw-bold" style={{ color: "#009087" }}>
                        {text_amazons3}
                      </h6>
                      <div className="row scroll-container">
                        <p className="text-center ">
                          {noRecordsFoundaws !== "" ? noRecordsFoundaws : <></>}
                        </p>
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table">
                              {combinedDataAws?.map((val, i) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td>{val?.userEmail}</td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            onChange={(event) => {
                                              handleChange(
                                                val.accessUserId,
                                                event.target.value,
                                                "aws",
                                                val.folderId
                                              );
                                            }}
                                            className="form-select selectOption"
                                          >
                                            <option
                                              value="read"
                                              selected={
                                                val?.accessDataread && true
                                              }
                                            >
                                              {text_read}
                                            </option>
                                            <option
                                              value="write"
                                              selected={
                                                val?.accessDatawrite && true
                                              }
                                            >
                                              {text_read}/{text_write}
                                            </option>
                                            <option
                                              value="download"
                                              selected={
                                                val?.accessDatadownload && true
                                              }
                                            >
                                              {text_read}/{text_write}/
                                              {text_download}
                                            </option>
                                            <option className="hr" disabled>
                                              -----------------------
                                            </option>
                                            <option
                                              value="remove"
                                              style={{ color: "red" }}
                                            >
                                              {text_removeaccess}
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h6 className="mt-1 fw-bold" style={{ color: "#009087" }}>
                        {text_atomstorage}
                      </h6>
                      <div className="row scroll-container">
                        <p className="text-center ">
                          {noRecordsFoundIpfs !== "" ? (
                            noRecordsFoundIpfs
                          ) : (
                            <></>
                          )}
                        </p>
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table">
                              {combinedDataIpfs?.map((val, i) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td>{val?.userEmail}</td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            onChange={(event) => {
                                              handleChange(
                                                val.accessUserId,
                                                event.target.value,
                                                "ipfs",
                                                val.folderId
                                              );
                                            }}
                                            className="form-select selectOption"
                                          >
                                            <option
                                              value="read"
                                              selected={
                                                val?.accessDataread && true
                                              }
                                            >
                                              {text_read}
                                            </option>
                                            <option
                                              value="write"
                                              selected={
                                                val?.accessDatawrite && true
                                              }
                                            >
                                              {text_read}/{text_write}
                                            </option>
                                            <option
                                              value="download"
                                              selected={
                                                val?.accessDatadownload && true
                                              }
                                            >
                                              {text_read}/{text_write}/
                                              {text_download}
                                            </option>
                                            <option className="hr" disabled>
                                              -----------------------
                                            </option>
                                            <option
                                              value="remove"
                                              style={{ color: "red" }}
                                            >
                                              {text_removeaccess}
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="container ">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table">
                              {combinedData?.map((val, i) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td>{val?.userEmail}</td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            onChange={(event) => {
                                              handleChange(
                                                val.accessUserId,
                                                event.target.value,
                                                alignment,
                                                val.folderId
                                              );
                                            }}
                                            className="form-select selectOption"
                                          >
                                            <option
                                              value="read"
                                              selected={
                                                val?.accessDataread && true
                                              }
                                            >
                                              {text_read}
                                            </option>
                                            <option
                                              value="write"
                                              selected={
                                                val?.accessDatawrite && true
                                              }
                                            >
                                              {text_read}/{text_write}
                                            </option>
                                            <option
                                              value="download"
                                              selected={
                                                val?.accessDatadownload && true
                                              }
                                            >
                                              {text_read}/{text_write}/
                                              {text_download}
                                            </option>
                                            <option className="hr" disabled>
                                              -----------------------
                                            </option>
                                            <option
                                              value="remove"
                                              style={{ color: "red" }}
                                            >
                                              {text_removeaccess}
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <div className="d-flex justify-content-center  ">
              <button
                className="box-btn m-2"
                onClick={() => {
                  setAccessPopup(false);
                  setDatavalueArray([]);
                  setDataIdArray([]);
                }}
              >
                {text_cancel}
              </button>
              <button onClick={() => handleEdit()} className="box-btn-save m-2">
                {DataVlaue.length === 0 && DataId.length === 0
                  ? text_done
                  : text_save}
              </button>
            </div>
          </Dialog>
        )}
        {accessPopupFolder && (
          <Dialog
            open={accessPopupFolder}
            onClose={() => {
              setAccessPopupFolder(false);
              setDatavalueArrayFolderAws([]);
              setDatavalueArrayFolderIpfs([]);
              setDataIdArrayFolderAws([]);
              setDataIdArrayFolderIpfs([]);
            }}
            aria-labelledby="responsive-dialog-title"
            classes={{ paper: "custom-dialog" }}
          >
            <DialogTitle id="responsive-dialog-title" className="text-center">
              <div className="d-flex justify-content-between">
                <h5 className="mt-1">{text_peoplewithaccess}</h5>
                <h3>
                  <CgCloseO
                    onClick={() => {
                      setAccessPopupFolder(false);
                      setDatavalueArrayFolderAws([]);
                      setDatavalueArrayFolderIpfs([]);
                      setDataIdArrayFolderAws([]);
                      setDataIdArrayFolderIpfs([]);
                    }}
                  />
                </h3>
              </div>
              <Divider />
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  {alignment === "both" && alignmentFolder === "folder" ? (
                    <div className="container ">
                      <h6 className="mt-1 fw-bold" style={{ color: "#009087" }}>
                        {text_amazons3}
                      </h6>
                      <div className="row scroll-container">
                        <p className="text-center ">
                          {noRecordsFoundaws !== "" ? noRecordsFoundaws : <></>}
                        </p>
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table">
                              {combinedDataFolderAws?.map((val, i) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td>{val?.userEmailaws}</td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            onChange={(event) => {
                                              handleChangeFolderValue(
                                                val.userIdaws,
                                                event.target.value,
                                                val.bucketID,
                                                "aws"
                                              );
                                            }}
                                            className="form-select selectOption"
                                          >
                                            <option
                                              value="read"
                                              selected={
                                                val?.accessDatareadaws && true
                                              }
                                            >
                                              {text_read}
                                            </option>
                                            <option
                                              value="write"
                                              selected={
                                                val?.accessDatawriteaws && true
                                              }
                                            >
                                              {text_read}/{text_write}
                                            </option>
                                            <option
                                              value="download"
                                              selected={
                                                val?.accessDatadownloadaws &&
                                                true
                                              }
                                            >
                                              {text_read}/{text_write}/
                                              {text_download}
                                            </option>
                                            <option className="hr" disabled>
                                              -----------------------
                                            </option>
                                            <option
                                              value="remove"
                                              style={{ color: "red" }}
                                            >
                                              {text_removeaccess}
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <h6 className="mt-1 fw-bold" style={{ color: "#009087" }}>
                        {text_atomstorage}
                      </h6>
                      <div className="row scroll-container">
                        <p className="text-center ">
                          {noRecordsFoundIpfs !== "" ? (
                            noRecordsFoundIpfs
                          ) : (
                            <></>
                          )}
                        </p>
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table">
                              {combinedDataFolderIpfs?.map((val, i) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td>{val?.userEmailipfs}</td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            onChange={(event) => {
                                              handleChangeFolderValue(
                                                val.userIdipfs,
                                                event.target.value,
                                                val.bucketID,
                                                "ipfs"
                                              );
                                            }}
                                            className="form-select selectOption"
                                          >
                                            <option
                                              value="read"
                                              selected={
                                                val?.accessDatareadipfs && true
                                              }
                                            >
                                              {text_read}
                                            </option>
                                            <option
                                              value="write"
                                              selected={
                                                val?.accessDatawriteipfs && true
                                              }
                                            >
                                              {text_read}/{text_write}
                                            </option>
                                            <option
                                              value="download"
                                              selected={
                                                val?.accessDatadownloadipfs &&
                                                true
                                              }
                                            >
                                              {text_read}/{text_write}/
                                              {text_download}
                                            </option>
                                            <option className="hr" disabled>
                                              -----------------------
                                            </option>
                                            <option
                                              value="remove"
                                              style={{ color: "red" }}
                                            >
                                              {text_removeaccess}
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="container ">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="table-responsive">
                            <table className="table">
                              {combinedDataFolder?.map((val, i) => {
                                return (
                                  <tbody>
                                    <tr>
                                      <td>{val?.userEmail}</td>
                                      <td>
                                        <div className="form-group">
                                          <select
                                            onChange={(event) => {
                                              handleChangeFolderValue(
                                                val.accessUserId,
                                                event.target.value,
                                                val.bucketID,
                                                alignment
                                              );
                                            }}
                                            className="form-select selectOption"
                                          >
                                            <option
                                              value="read"
                                              selected={
                                                val?.accessDataread && true
                                              }
                                            >
                                              {text_read}
                                            </option>
                                            <option
                                              value="write"
                                              selected={
                                                val?.accessDatawrite && true
                                              }
                                            >
                                              {text_read}/{text_write}
                                            </option>
                                            <option
                                              value="download"
                                              selected={
                                                val?.accessDatadownload && true
                                              }
                                            >
                                              {text_read}/{text_write}/
                                              {text_download}
                                            </option>
                                            <option className="hr" disabled>
                                              -----------------------
                                            </option>
                                            <option
                                              value="remove"
                                              style={{ color: "red" }}
                                            >
                                              {text_removeaccess}
                                            </option>
                                          </select>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })}
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </DialogContentText>
            </DialogContent>
            <div className="d-flex justify-content-center  ">
              <button
                className="box-btn m-2"
                onClick={() => {
                  setAccessPopupFolder(false);
                  setDatavalueArrayFolderAws([]);
                  setDatavalueArrayFolderIpfs([]);
                  setDataIdArrayFolderAws([]);
                  setDataIdArrayFolderIpfs([]);
                }}
              >
                {text_cancel}
              </button>
              <button
                onClick={() => handleEditFolder()}
                className="box-btn-save m-2"
              >
                {loaderPopup === true ? (
                  <Loader2 />
                ) : (DataVlaueFolderAws.length === 0 ||
                    DataVlaueFolderIpfs.length === 0) &&
                  (DataIdFolderAws.length === 0 ||
                    DataIdFolderIpfs.length === 0) ? (
                  text_done
                ) : (
                  text_save
                )}
              </button>
            </div>
          </Dialog>
        )}
      </div>
      <ToastContainer />
    </>
  );
}
export default ShareByMe;
