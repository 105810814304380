import React, {
  useState,
  useEffect,
  useCallback,
  useContext,
  useRef,
} from "react";
import "./UploadFile.css";
import DescImg from "../../utils/images/desc_4.png";
import FileImg from "../../utils/images/file_1.png";
import HashImg from "../../utils/images/hash.png";
import keywordImg from "../../utils/images/kw_10.png";
import axios from "axios";
import { partial } from "filesize";
import DatePicker from "react-datepicker";
import F1Img from "../../utils/images/file.png";
import EmailImg from "../../utils/images/email.png";
import OrgImg from "../../utils/images/organization (3).png";
import UserImg from "../../utils/images/user (5).png";
import GenderImg from "../../utils/images/gender.png";
import AgeImg from "../../utils/images/age.png";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";
import { EMAIL_REGEX } from "../../utils/Files/validation";
import PhoneInput from "react-phone-input-2";
import { GLOBAL_URL_DOMAIN_LIVE } from "../../utils/Files/url";
import { MdCloudUpload, MdOutlineKeyboardBackspace } from "react-icons/md";
import FolImg from "../../utils/images/folder (1).png";
import { LuUpload } from "react-icons/lu";
import Cookie from "js-cookie";
import { InfoToast } from "../../utils/Files/toasterMsgs";
import { useDropzone } from "react-dropzone";
import { GlobalConstants } from "../../utils/globalconstant";
import ReactFlagsSelect from "react-flags-select";
import Loader3, { countriesList } from "../../utils/Files/data";
import UploadMultipleAtomStorage from "./UploadMultipleAtomStorage";
import UploadMultipleBothStorage from "./UploadMultipleBothStorage";
import ProgressBoxContext from "../../Context/ProgressBoxContext";
import { useDispatch, useSelector } from "react-redux";
import { MultipleUploadMetaData } from "../../redux/MetaDataSlice";
import { getBucketData } from "../../redux/GetBucketdataSlice";
import Form from "react-bootstrap/Form";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const TestMultiple = () => {
  const inputRef = useRef(null);
  const {
    handleuploadLocation,
    handleHashMulti,
    newCreatedFolder,

    handleUploadStatusMulti,
    handleFileFolderData,
    uploadFromdata,
    handleDataMulti,
    handleKeyFolder,
  } = useContext(ProgressBoxContext);
  //variable Satrt
  const abortControllerRef = useRef(null);
  const showuploadbtn = useSelector((state) => state.metadata.disableupload);
  const atomS3DataMultiple = useSelector(
    (state) => state.getBucketData.AtomS3Data
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [fileName, setFileName] = useState("");
  const userID = sessionStorage.getItem("userID");
  const [fileTag, setFileTag] = useState("");
  const [fileDesc, setFileDesc] = useState("");
  const [fileKeyWord, setFileKeyWord] = useState("");
  const [selectedFiles, setSelectedFiles] = useState({});
  const [fileSize, setFileSize] = useState("");
  const [fileType, setFileType] = useState("");
  const [orgName, setOrgName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showCreateFolderInput, setShowCreateFolderInput] = useState(false);
  const [folderNameInput, setFolderNameInput] = useState("");
  const [checkAWS, setCheckAWS] = useState(false);
  const [checkIPFS, setCheckIPFS] = useState(false);
  const [formvisible, setFormvisible] = useState(false);
  const [awsformvisible, setAwsFormvisible] = useState(false);
  const [medicalformvisible, setMedicalformvisible] = useState(false);
  const [reqUrlCreate, setReqUrlCreate] = useState("");
  const [urlToShow, setUrlToShow] = useState("");
  const [folderApiState, setFolderApiState] = useState([]);
  const [sfvErr, setSfvErr] = useState("");
  const [existingFolders, setExistingFolders] = useState([]);
  const [viewBackBtn, setViewBackBtn] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const [FolderId, setFolderId] = useState("");
  const [ipfsBucketName, setIpfsBucketName] = useState("atom-storage");
  // const [sameFollderError, setSameFolderError] = useState("");
  // const [progress, setProgress] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  // const [uploadImgUrl, setUploadImgUrl] = useState("");
  // const [ipfsCID, setIpfsCID] = useState("");
  // const [healthId, setHealthId] = useState("");
  const [checkEncyption, setCheckEncyption] = useState(false);
  const [encryptiontype, setEncryptiontype] = useState("");
  const [showSelectedfile, SetShowSelectedFile] = useState([]);
  const [validEmailErr, setValidEmailErr] = useState("");
  const [validAgeErr, setValidAgeErr] = useState("");
  const [ipfsMulFiles, setipfsMulFiles] = useState([]);
  const [FolderIDIPFS, setFolderIdIPFS] = useState("");
  const [FolderNameIPFS, setFolderNameIPFs] = useState("");
  const [allBucket, setAllBucket] = useState([]);
  const [selectedFilesArrAws, setSelectedFilesArrAws] = useState([]);
  const [selectIDCard, setSelectIDCard] = useState([]);
  const [bucketId, setBucketId] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedcardID1, setSelectedCardID1] = useState("");
  const [selectedcardID2, setSelectedCardID2] = useState("");
  const [selectedcardID3, setSelectedCardID3] = useState("");
  const [selectedName1, setSelectedCardName1] = useState("");
  const [selectedName2, setSelectedCardName2] = useState("");
  const [selectedName3, setSelectedCardName3] = useState("");
  const [selectedID1, setSelectedID1] = useState("");
  const [selectedID2, setSelectedID2] = useState("");
  const [selectedID3, setSelectedID3] = useState("");
  const [CountryCode, setCountryCode] = useState("US");
  const [country, setCountry] = useState("United States");
  const [enterId1, setenterId1] = useState("");
  const [enterId2, setenterId2] = useState("");
  const [enterId3, setenterId3] = useState("");
  const [uploadFolder, setUploadFolder] = useState([]);
  const [showFolderFileCommon, setShowfolderfilecommon] = useState(true);
  const [showbackforFile, setShowBackForFile] = useState(false);
  const [bucketIdIpfs, setbucketIdIpfs] = useState("");
  const [awsBucketName, setAwsBucketName] = useState("");
  const [atomBucket, setAtomBucket] = useState(false);
  const [exitingUser, setExitingUser] = useState(false);
  const [urlForIPFS, setUrlForIPFS] = useState("");
  const [urlfordragdrop, setUrlfordragdrop] = useState("");
  const [storageIPFS, setStorageIPFS] = useState("");
  const [storageIPFSTotal, setStorageIPFSTotal] = useState("");
  const [storageAWS, setStorageAWS] = useState("");
  const [totalStorageAWS, setTotalStorageAWS] = useState("");
  const [erruploadFile, setErruploadFile] = useState("");
  const [publicOrPrivate, setPublicOrPrivate] = useState("");
  const [fileHashes, setFileHashes] = useState(null);
  const [urlForIPFS_both, setUrlForIPFS_both] = useState("");
  const [folderList, setFolderList] = useState([]);
  //variable End
  const [deleteSelectDate, setDeleteSelectDate] = useState(false);
  const [deleteDays, setDeleteDays] = useState(false);
  const [deleteMonths, setDeleteMonths] = useState(false);
  const [deleteYears, setDeleteYears] = useState(false);
  // const [dateToSend, setDateToSend] = useState(null);
  const [uploadMultiple, setUploadMultiple] = useState(false);
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);

  const [Daysvalue, setDaysvalue] = useState("");
  const [Monthsvalue, setMonthsvalue] = useState("");
  const [SelectDatevalue, setSelectDatevalue] = useState(tomorrow);
  // const [Yearvalue, setYearvalue] = useState(new Date());
  const [Yearsvalue, setYearsvalue] = useState("");
  const [NeverDelete, setNeverDelete] = useState(true);
  const [typedDate, setTypedDate] = useState(
    tomorrow.toISOString().split("T")[0]
  );
  // const [dateErrorMessage, setDateErrorMessage] = useState("");

  const calculateFutureDate = (currentDate, value, type) => {
    const newDate = new Date(currentDate);
    if (type === "days") {
      newDate.setDate(newDate.getDate() + parseInt(value));
    } else if (type === "months") {
      newDate.setMonth(newDate.getMonth() + parseInt(value));
    } else if (type === "years") {
      newDate.setFullYear(newDate.getFullYear() + parseInt(value));
    }
    return newDate;
  };

  const formatDate = (date) => {
    // console.log("date:", date);
    // setDateToSend(finalDate);
    if (date !== null) {
      sessionStorage.setItem("deleteAt", date.toISOString());
      return date.toLocaleDateString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    }
  };
  const handleTypedDateChange = (e) => {
    const inputDate = new Date(e.target.value);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    // console.log(inputDate);
    if (inputDate >= today) {
      setTypedDate(e.target.value);
      setSelectDatevalue(inputDate);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("deleteAt");
  }, []);
  //UploadAtomStoarge Component url push parent to child and child to parent
  const handleUrl = (data) => {
    // console.log(data);
    setUrlForIPFS(data);
  };
  //UploadBothStoarge Component IPFS url push parent to child and child to parent
  const handleUrl_both = (data) => {
    setUrlForIPFS_both(data);
  };
  //UploadBothStoarge Component AWS url push parent to child and child to parent
  const handleUrl_aws = (data) => {
    setUrlfordragdrop(data);
  };
  const CompanyName =
    sessionStorage.getItem("companyName") === null ||
    sessionStorage.getItem("companyName") === undefined ||
    sessionStorage.getItem("companyName") === "null"
      ? ""
      : sessionStorage.getItem("companyName");
  const setCountyData = (d) => {
    var countryName = countriesList[d];
    setCountry(countryName);
    setCountryCode(d);
  };
  //handle Medical ID For Country (india Only)
  const handleId1 = (e) => {
    var HelthId1 = selectIDCard.filter((val) => val._id === e);
    setSelectedCardID1(HelthId1[0]?.medicalIdCode);
    setSelectedCardName1(HelthId1[0]?.medicalIdName);
    setSelectedID1(e);
  };
  const handleId2 = (e) => {
    var HelthId2 = selectIDCard.filter((val) => val._id === e);
    setSelectedCardID2(HelthId2[0]?.medicalIdCode);
    setSelectedCardName2(HelthId2[0]?.medicalIdName);
    setSelectedID2(e);
  };
  const handleId3 = (e) => {
    var HelthId3 = selectIDCard.filter((val) => val._id === e);
    setSelectedCardID3(HelthId3[0]?.medicalIdCode);
    setSelectedCardName3(HelthId3[0]?.medicalIdName);
    setSelectedID3(e);
  };

  const GetFolderID = (F_id) => {
    setFolderIdIPFS(F_id); //IPFS folder ID from Atom storage and both storage component
  };
  const GetBucketNameIPFS = (Bucket_name) => {
    setIpfsBucketName(Bucket_name); //IPFS Bucket name from Atom storage and both storage component
  };
  const GetBucketID = (Bucket_id) => {
    setbucketIdIpfs(Bucket_id); //IPFS bucket ID from Atom storage and both storage component
  };
  const GetFolderName = (F_name) => {
    setFolderNameIPFs(F_name); //IPFS folder Name from Atom storage and both storage component
  };
  const GetFolderIDaws = (F_id) => {
    setFolderId(F_id); //AWS folder id from both storage component
  };

  const GetFolderNameaws = (F_name) => {
    // console.log(F_name);
    setReqUrlCreate(F_name); //AWS folder name from both storage component
  };

  // language const
  const [text_uploadfile, setText_uploadfile] = useState("Upload File");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_enterphno, setText_enterphno] = useState("Enter Phone Number");
  const [text_phoneno, setText_phoneno] = useState("Phone Number");
  const [text_email, setText_email] = useState("Email");
  const [text_prefernottodesclose, setText_prefernototodisclose] = useState(
    "Prefer not to disclose"
  );
  const [text_other, setText_other] = useState("Other");
  const [text_male, setText_male] = useState("Male");
  const [text_female, setText_female] = useState("Female");
  const [text_selectgender, setText_selectgender] = useState("Select Gender");
  const [text_patient, setText_patient] = useState("Patient");
  const [text_country, setText_country] = useState("Country");

  const [text_enterage, setText_enterage] = useState("Enter Age");
  const [text_age, setText_age] = useState("Age");
  const [text_dob1, setText_dob1] = useState("Date Of Birth");
  const [text_enterfirstname, setText_enterfirstname] =
    useState("Enter First Name");
  const [text_enterlastname, setText_enterlastname] =
    useState("Enter Last Name");
  const [text_encryptyourfiledata, setText_encryptyourfiledata] = useState(
    "Encrypt Your File Data"
  );
  const [text_selectencryptiontype, setText_encryptiontype] = useState(
    "Select Encryption Type"
  );
  const [text_sha256, setText_sha256] = useState("SHA-256");
  const [text_aes, setText_aes] = useState("AES-256");
  const [text_rsa, setText_rsa] = useState("RSA");
  const [text_selectstoragelocation, setText_selectstoragelocation] = useState(
    "Select Storage Location"
  );
  const [text_amazons3, setText_amazons3] = useState("Amazon S3");
  const [text_asn, setText_asn] = useState("Atom Storage Network");
  const [text_selectbucket, setText_selectbucket] = useState(
    "Select Amazon S3 Bucket"
  );
  const [text_currentlocation, setText_currentlocstion] =
    useState("Current Location :");
  const [text_back, setText_back] = useState("Back");
  const [text_enterfoldername, setText_enterfoldername] =
    useState("Enter Folder Name");
  const [text_createnewfolder, setText_createnewfolder] =
    useState("Create Folder");
  const [text_nofolder, setText_nofolder] = useState("No Folders Here");
  const [text_totalfile, setText_totalfile] = useState("Total File :");
  const [text_desofallfiles, setText_desofallfiles] = useState(
    "Description For All Files"
  );
  const [text_enterdes, setText_enterdes] = useState("Enter Description");
  const [text_filetag, setText_filetag] = useState("File Tag(s) For All Files");
  const [text_enterfiletags, setText_enterfiletags] = useState(
    "Enter File Tags Separated By Spaces."
  );
  const [text_keywords, setText_keywords] = useState(
    "Keyword(s) For All Files"
  );
  const [text_enterkeysepbyspaces, setText_enterkeysepbyspaces] = useState(
    "Enter keywords Separated By Spaces."
  );
  const [text_org1, setText_org1] = useState("Organization");
  const [text_enterorgname, setText_enterorgname] = useState(
    "Enter Organization Name"
  );
  const [text_medicaldata, setText_medicaldata] = useState("Medical Data");
  const [text_personalhealthdata, setText_personalhealthdata] = useState(
    "Personal Health Data"
  );
  const [text_selectdob, setText_selectdob] = useState("Select Date Of Birth");
  const [text_enteremail, setText_enteremail] = useState("Enter Email");
  const [text_enterkeywords, setText_enterkeywords] =
    useState("Enter Keyword(s)");
  const [text_save, setText_save] = useState("Create");

  const [text_firstname1, setText_firstname1] = useState("First Name");
  const [text_lastname1, setText_lastname1] = useState("Last Name");
  const [text_err_folder_name, setText_err_folder_name] = useState(
    "Folder name can not be empty"
  );
  const [text_id, setText_id] = useState("ID");
  const [text_enterid, setText_enterid] = useState("Enter Id");
  const [text_dropfilorfolderehere, setText_dropfileorfolderhere] = useState(
    "Drop the file or folder here ..."
  );
  const [text_dragandselectfolder, setText_dragandselectfolder] = useState(
    "Drag and drop file(s) or folder(s) here or click to select file(s)"
  );
  const [text_select_File_folder, setText_select_File_folder] = useState(
    "Please select atleast one file"
  );
  const [text_select_amazon_bkt, setText_select_bkt] = useState(
    "Please select Amazon S3 bucket"
  );
  const [text_gender1, setTextgender1] = useState("Gender");
  const [text_err_select_storage, setText_err_select_storage] = useState(
    "Please select at least one storage location"
  );

  const [text_validation_folder, set_validation_folder] = useState(
    "A folder name can't contain any of the following characters:"
  );
  const [text_upload_location, setText_upload_location] =
    useState("Upload Location");

  const [text_upload_aws_location, setText_upload_aws_location] = useState(
    "Upload location for Amazon S3"
  );
  const [text_upload_atom_location, setText_upload_atom_location] = useState(
    "Upload location for Atom storage Network"
  );
  const [not_enough_space, set_not_enough_space] = useState(
    "Not Enough Space Available"
  );
  const [text_selectbucket1, setText_selectbucket1] = useState("Select Bucket");
  const [text_noticefordelete, setText_noticefordelete] = useState(
    "Delete this file and its metadata after"
  );
  const [text_neverdelete, setText_neverdelete] = useState("Never Delete");
  const [text_years, setText_years] = useState("Years");
  const [text_months, setText_months] = useState("Months");
  const [text_days, setText_days] = useState("Days");
  const [text_selectdate, setText_selectdate] = useState("Select Date");
  const [text_deletedon, setText_deletedon] = useState(
    "Selected file and its metadata will be deleted on"
  );
  const [text_noofyears, setText_noofyears] = useState("Enter Number Of Years");
  const [text_enternoofmonths, setText_enternoofmonths] = useState(
    "Enter Number Of Months"
  );
  const [text_enternoofdays, setText_enternoofdays] = useState(
    "Enter Number Of Days"
  );

  // ************************************************** OTHER STARTS **************************************************

  useEffect(() => {
    setSfvErr("");
  }, []);
  ///**** get Bucket for AWS ****/
  // const getBucket = () => {
  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/company/get-buckets?userId=${userID}`;

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then((res) => {
  //       console.log("read file res***", res.data.data);
  //       var Val = res.data.data;
  //       if (Val.length > 0) {
  //         setTimeout(() => {
  //           setAllBucket(Val);
  //           // handleSelectBucket(Val[0]?.bucketId);
  //           setBucketId(Val[0]?.bucketId)
  //           setAwsBucketName(Val[0].bucketName);
  //           setErruploadFile("");
  //           getStorageAWS(
  //             Val[0]?.bucketName,
  //             Val[0].bucketId,
  //             Val[0]?.atom_bucket,
  //             Val[0]?.users_existing_bucket
  //           );
  //         }, 2000);
  //         handleRootFolder(Val[0].bucketId, Val[0].bucketName);
  //         setPublicOrPrivate(Val[0]?.bucketACLOption);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("read file err***", err);
  //       if (err?.response?.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         navigate("/");
  //       }
  //     })
  //     .then(function () {});
  // };

  useEffect(() => {
    getBucket();
  }, [atomS3DataMultiple?.length, uploadMultiple]);
  const getBucket = () => {
    var Val = atomS3DataMultiple;
    // console.log(uploadMultiple);
    if (Val.length > 0 && uploadMultiple === true) {
      setAllBucket(Val);
      // handleSelectBucket(Val[0]?.bucketId);
      setBucketId(Val[0].bucketId);
      setAwsBucketName(Val[0].bucketName);
      setErruploadFile("");
      getStorageAWS(
        Val[0]?.bucketName,
        Val[0].bucketId,
        Val[0]?.atom_bucket,
        Val[0]?.users_existing_bucket
      );

      handleRootFolder(Val[0].bucketId, Val[0].bucketName);
      setPublicOrPrivate(Val[0]?.bucketACLOption);
    }
  };
  ///**** craete root folder for AWS ****/
  const handleRootFolder = (bucket_id, bucket_name) => {
    var data = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: bucket_name + "/" + userID + "/",
      bucketId: bucket_id,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        var DataFinal = res?.data?.data;
        // console.log("res***", res);
        if (res?.data?.root_folder_added === true) {
          getAllDir_new(bucket_id, bucket_name);
          setFolderId(DataFinal.folderId);
        } else {
          getAllDir_new(bucket_id, bucket_name);
          setFolderId(DataFinal.folderId);
        }
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ///**** get used and total Storage for AWS ****/
  const getStorageAWS = (bucketname, bucket_id, isAtom, isExitingUser) => {
    var userID = sessionStorage.getItem("userID");
    var data = {
      userId: userID,
      bucketName: bucketname,
      bucketId: bucket_id,
      users_existing_bucket: isExitingUser,
      atom_bucket: isAtom,
    };
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-aws`;

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("read file res***", res);
        // getAllDir_new(bucket_id, bucketname);
        var Data = res?.data?.sizeInBytes;
        if (Data !== "") {
          var TotalusedStorage = Data;
          setStorageAWS(TotalusedStorage);
        }
        if (isExitingUser === true || isAtom === false) {
          var TotalStorage =
            res?.data?.total_storage_size?.aws_package_size_created;
          setTotalStorageAWS(TotalStorage);
        }
        if (isAtom === true) {
          if (bucketname === "atom-storage-private") {
            setTotalStorageAWS(res?.data?.total_size[1]?.pack_size);
          } else {
            setTotalStorageAWS(res?.data?.total_size[0]?.pack_size);
          }
        }
      })
      .catch((err) => {
        // console.log("read file err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ///**** get used and total Storage for IPFS ****/
  const getStorageIPFS = (bucketname) => {
    var userID = sessionStorage.getItem("userID");
    var data = { userId: userID, bucketName: bucketname };
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file/storage-limit-ipfs`;

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("read file res***", res);
        var Value = res?.data?.sizeInBytes;
        if (Value.length === 0) {
          setStorageIPFSTotal(res?.data?.total_size);
        } else {
          var Data = res?.data?.sizeInBytes[0]?.totalSize;

          setStorageIPFS(Data);
          setStorageIPFSTotal(res?.data?.total_size);
        }
      })
      .catch((err) => {
        // console.log("read file err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  const handleSelectBucket = (val) => {
    setBucketId(val);
    var BucketFinalName = allBucket.filter((e, i) => e.bucketId === val);
    setFolderApiState([]);
    if (BucketFinalName.length > 0) {
      setAwsBucketName(BucketFinalName[0].bucketName);
      setPublicOrPrivate(BucketFinalName[0].bucketACLOption);
      handleRootFolder(
        BucketFinalName[0].bucketId,
        BucketFinalName[0].bucketName
      );
      setErruploadFile("");
      setAtomBucket(BucketFinalName[0]?.atom_bucket);
      setExitingUser(BucketFinalName[0]?.users_existing_bucket);
      getStorageAWS(
        BucketFinalName[0].bucketName,
        val,
        BucketFinalName[0]?.atom_bucket,
        exitingUser
      );
      setViewBackBtn(false);
      setUrlToShow("");
      setUrlfordragdrop("");
    }
  };
  const size = partial({ base: 2, standard: "jedec" });

  const checkNumberOfFiles = (selected_files, i) => {
    var data = [];
    data.push(selected_files);
    SetShowSelectedFile(data);
    setSelectedFiles(selected_files);
    setErruploadFile("");
    setFileName(selected_files[0]?.name);
    setFileSize(selected_files[0]?.size);
    setFileType(selected_files[0].type);
    mulFileIPFSFunc(selected_files);
    selectedFilesArrFunc(selected_files);
    // }
  };

  const selectedFilesArrFunc = (all_files) => {
    let tempSelectedFileArr = [];
    for (let i = 0; i < all_files?.length; i++) {
      tempSelectedFileArr.push({
        name: all_files[i]?.name,
        type: all_files[i]?.type,
        size: size(all_files[i]?.size),
      });
    }
    setSelectedFilesArrAws(tempSelectedFileArr);
  };

  ///**** Create Folder Validation AWS ****/
  const createSubFolderValidation = () => {
    var sfv = true;
    const disallowedChars = /[#\\/:*?"<>|]/; // Regular expression to match disallowed characters
    if (folderNameInput === "") {
      sfv = false;
      setSfvErr(<>*{text_err_folder_name}!</>);
    } else if (disallowedChars.test(folderNameInput)) {
      sfv = false;
      setSfvErr(<>*{text_validation_folder + '#\\ / : * ? " < > |'} </>);
    } else {
      for (let i = 0; i < folderApiState?.length; i++) {
        const str_aws = folderApiState[i]?.folderName;
        const ind_aws = str_aws.lastIndexOf("/");
        const new_str_aws = str_aws.slice(0, ind_aws);
        const ind_aws_data = new_str_aws.lastIndexOf("/");
        const new_str_new = new_str_aws.slice(ind_aws_data + 1);
        existingFolders.push(new_str_new);
      }
      for (let j = 0; j < existingFolders?.length; j++) {
        if (
          existingFolders[j]?.toLowerCase() === folderNameInput.toLowerCase()
        ) {
          sfv = false;
          setSfvErr("Folder already exists!");
          break;
        }
      }
    }
    return sfv;
  };

  const add_folder_id_func = (folderPath, Create_folder) => {
    var folderName =
      reqUrlCreate === "" || reqUrlCreate === null
        ? awsBucketName + "/" + userID + "/" + folderPath
        : awsBucketName + "/" + folderPath;

    var data = {
      companyId: sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: folderName,
      bucketId: bucketId,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/add-folder";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("add_folder_id_func res***", res);
        setFolderNameInput("");
        getAllSubDir_new(
          reqUrlCreate === "" || reqUrlCreate === null
            ? `${awsBucketName}/${userID}/`
            : reqUrlCreate
        );
        setShowCreateFolderInput(false);
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  const handleCreateSubFolder = (folder) => {
    let Folder_name = folderNameInput.trim();
    const ind_aws = reqUrlCreate.indexOf("/");
    const New_key = reqUrlCreate.slice(ind_aws + 1);
    if (createSubFolderValidation()) {
      setFolderNameInput("");
      setShowCreateFolderInput(false);

      getAllSubDir_new(
        reqUrlCreate === "" || reqUrlCreate === null
          ? `${awsBucketName}/${userID}/`
          : reqUrlCreate
      );
      const folderPath = New_key + Folder_name + "/";
      add_folder_id_func(folderPath);
    }
  };

  ///**** get Root-folder AWS ****/
  const getAllDir_new = (bucketID, bucketname) => {
    // setLoader(true);
    var folder_name = `${bucketname}/${userID}/`;
    if (bucketID !== "") {
      var data = {
        companyId: sessionStorage.getItem("companyID"),
        userId: userID,
        folderName: folder_name,
        bucketId: bucketID,
      };

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/all-child-folders-aws";

      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          // console.log("add_folder_id_func res***", res);
          var Data = res?.data?.data;
          if (res?.status === 200) {
            setFolderApiState(Data);
            setLoader(false);
            setAwsBucketName(bucketname);
            setErruploadFile("");
          }
        })
        .catch((err) => {
          setLoader(false);
          // console.log("add_folder_id_func err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        })
        .then(function () {});
    }
  };
  ///**** get Child-folder AWS ****/
  const getAllSubDir_new = (folderName) => {
    const str_aws = folderName;
    const ind_aws = str_aws.indexOf("/");
    const new_str_aws = str_aws.slice(ind_aws + 1);

    var data = {
      companyId: sessionStorage.getItem("companyID"),
      userId: userID,
      folderName: folderName,
      bucketId: bucketId,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/all-child-folders-aws";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("add_folder_id_func res***", res);
        var Data = res?.data?.data;
        if (res?.status === 200) {
          setFolderApiState(Data);
          // console.log(folderName);
          setReqUrlCreate(folderName);
          addSpaceInUrl(new_str_aws);
        }
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  const addSpaceInUrl = (url) => {
    var newUrl = url.split("/");

    const urlWithImages = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {" "}
            {index > 1 && " > "}{" "}
            <img src={F1Img} alt="" style={{ height: "22px", width: "22px" }} />{" "}
            {folder}
          </>
        )}
      </span>
    ));
    const url_dragdrop = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID ? (
          ""
        ) : (
          <>
            {" "}
            {index > 1 && " > "} {folder}
          </>
        )}
      </span>
    ));
    if (newUrl.length > 0) {
      urlWithImages.push(newUrl[newUrl.length - 1]);
      url_dragdrop.push(newUrl[newUrl.length - 1]);
    }
    setUrlToShow(urlWithImages);
    setUrlfordragdrop(url_dragdrop);
  };

  const handleBack = () => {
    const str_aws = reqUrlCreate;
    const ind_aws = str_aws.lastIndexOf("/");
    const new_str_aws = str_aws.slice(0, ind_aws);
    const str_data = new_str_aws.lastIndexOf("/");
    const back_path = new_str_aws.slice(0, str_data + 1);
    setSfvErr("");
    setShowCreateFolderInput(false);
    let handleBackArr = reqUrlCreate.split("/");
    if (handleBackArr.length > 4) {
      getAllSubDir_new(back_path);
      setUploadFolder([]);
      setShowfolderfilecommon(false);
    } else {
      getAllSubDir_new(`${awsBucketName}/${userID}/`);
      setViewBackBtn(false);
    }
  };

  const mulFileIPFSFunc = (f) => {
    var mulFilesArr = [];
    for (let i = 0; i < f.length; i++) {
      mulFilesArr.push({
        file_name: f.name,
        file_type: f.type,
        file_size: f.size,
      });
    }
    setipfsMulFiles(mulFilesArr);
  };

  //** UPLOAD FILE VALIDARION ****/
  const uploadValidation = () => {
    let validUpload = true;
    if (fileFolderCommon.length === 0 && d_rootFiles.length === 0) {
      validUpload = false;
      setErruploadFile(<>*{text_select_File_folder}!</>, 5000);
    }
    if (checkAWS === true && awsBucketName === "") {
      validUpload = false;
      setErruploadFile(<>*{text_select_amazon_bkt}!</>, 5000); //
    }
    if (
      (checkAWS === true && totalStorageAWS - storageAWS < fileSize) ||
      (checkIPFS === true && storageIPFSTotal - storageIPFS < fileSize) ||
      (checkAWS === true && totalStorageAWS - storageAWS < totalSize) ||
      (checkIPFS === true && storageIPFSTotal - storageIPFS < totalSize) ||
      (checkAWS === true && size(storageAWS) === size(totalStorageAWS)) ||
      (checkIPFS === true && size(storageIPFS) === size(storageIPFSTotal))
      //  ||
      // size(storageAWS) === size(totalStorageAWS) ||
      // size(storageIPFS) === size(storageIPFSTotal)
    ) {
      validUpload = false;
      // console.log(fileSize);
      setErruploadFile(not_enough_space);
    }
    // if (dateErrorMessage !== "") {
    //   validUpload = false;
    //   setErruploadFile(textDateErrorMessage);
    // }
    // if (medicalformvisible === true) {
    //   if (email === "") {
    //     validUpload = false;
    //     setValidEmailErr(<>{text_validemailval}</>);
    //   }
    //   if (email !== "" && EMAIL_REGEX.test(email) === false) {
    //     validUpload = false;
    //     setValidEmailErr(<>{text_validemailval}</>);
    //   }
    //   if (age === "") {
    //     validUpload = false;
    //     setValidAgeErr(<>*{text_err_age}!</>);
    //   }
    //   if (age !== "" && (Number(age) > 120 || Number(age) < 1)) {
    //     validUpload = false;
    //     setValidAgeErr(<>*{text_err_age}!</>);
    //   }
    // }
    return validUpload;
  };
  //****** Final Upload File or Folder Function ************/
  const handleUpload = () => {
    const multi_progress_temp_arr = [];

    for (let i = 0; i < d_rootFiles[0]?.length; i++) {
      multi_progress_temp_arr.push({
        index: i,
        progress: 0,
        filename: d_rootFiles[0][i]?.name,
        message: "",
        Cancel_id: "",
        abortController: new AbortController(),
        uploading: "not started",
      });
    }
    UploadFileOnebyOne(multi_progress_temp_arr);
  };
  const UploadFileOnebyOne = (multi_progress_temp_arr) => {
    const abortController = new AbortController();
    const dateToSend = sessionStorage.getItem("deleteAt");
    for (let i = 0; i < d_rootFiles.length; i++) {
      if (checkAWS === true && checkIPFS === true) {
        if (uploadValidation()) {
          // console.log(multi_progress_temp_arr);
          dispatch(
            MultipleUploadMetaData(
              "multiple",
              "both",
              {
                awsFolder:
                  reqUrlCreate === "" || reqUrlCreate === null
                    ? `${userID}/`
                    : reqUrlCreate,
                ipfsfoldername: FolderNameIPFS,
                awsBucketName: awsBucketName,
                FolderId: FolderId,
                FolderNameIPFS: FolderNameIPFS,
                FolderIDIPFS: FolderIDIPFS,
                ipfsID: FolderIDIPFS,
                isIPFS: checkIPFS,
                isAmazon: checkAWS,
                fileDesc: fileDesc,
                bucketId: bucketId,
                fileTag: fileTag,
                fileKeyWord: fileKeyWord,
                // orgName: CompanyName,
                orgName: orgName,
                medicalformvisible: medicalformvisible,
                firstName: firstName,
                lastName: lastName,
                selectedDate: selectedDate,
                age: age,
                gender: gender,
                email: email.toLowerCase(),
                phone: phone,
                h_id_1: {
                  code:
                    selectedcardID1 === null || selectedcardID1 === ""
                      ? 0
                      : selectedcardID1,
                  name: selectedName1,
                  value: enterId1,
                },
                h_id_2: {
                  code:
                    selectedcardID2 === null || selectedcardID2 === ""
                      ? 0
                      : selectedcardID2,
                  name: selectedName2,
                  value: enterId2,
                },
                h_id_3: {
                  code:
                    selectedcardID3 === null || selectedcardID3 === ""
                      ? 0
                      : selectedcardID3,
                  name: selectedName3,
                  value: enterId3,
                },
                CountryCode: CountryCode,
                bucketAclOption: publicOrPrivate,
                bucketIdIpfs:
                  bucketIdIpfs === "" || bucketIdIpfs === null
                    ? "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
                    : bucketIdIpfs,
                dateToSend: dateToSend,
              },
              d_rootFiles ? d_rootFiles : "",
              multi_progress_temp_arr,
              allUploadFolder,
              abortController,
              0
            )
          );
          // sessionStorage.removeItem;
          handleHashMulti(fileHashes ? fileHashes : "");
        }
      } else if (checkAWS === true && checkIPFS === false) {
        if (uploadValidation()) {
          dispatch(
            MultipleUploadMetaData(
              "multiple",
              "aws",
              {
                awsFolder:
                  reqUrlCreate === "" || reqUrlCreate === null
                    ? `${userID}/`
                    : reqUrlCreate,
                ipfsfoldername: FolderNameIPFS,
                awsBucketName: awsBucketName,
                FolderId: FolderId,
                FolderNameIPFS: FolderNameIPFS,
                FolderIDIPFS: FolderIDIPFS,
                ipfsID: FolderIDIPFS,
                isIPFS: checkIPFS,
                isAmazon: checkAWS,
                fileDesc: fileDesc,
                bucketId: bucketId,
                fileTag: fileTag,
                fileKeyWord: fileKeyWord,
                // orgName: CompanyName,
                orgName: orgName,
                medicalformvisible: medicalformvisible,
                firstName: firstName,
                lastName: lastName,
                selectedDate: selectedDate,
                age: age,
                gender: gender,
                email: email.toLowerCase(),
                phone: phone,
                h_id_1: {
                  code:
                    selectedcardID1 === null || selectedcardID1 === ""
                      ? 0
                      : selectedcardID1,
                  name: selectedName1,
                  value: enterId1,
                },
                h_id_2: {
                  code:
                    selectedcardID2 === null || selectedcardID2 === ""
                      ? 0
                      : selectedcardID2,
                  name: selectedName2,
                  value: enterId2,
                },
                h_id_3: {
                  code:
                    selectedcardID3 === null || selectedcardID3 === ""
                      ? 0
                      : selectedcardID3,
                  name: selectedName3,
                  value: enterId3,
                },
                CountryCode: CountryCode,
                bucketAclOption: publicOrPrivate,
                bucketIdIpfs:
                  bucketIdIpfs === "" || bucketIdIpfs === null
                    ? "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
                    : bucketIdIpfs,
                dateToSend: dateToSend,
              },
              d_rootFiles ? d_rootFiles : "",
              multi_progress_temp_arr,
              allUploadFolder,
              abortController,
              0
            )
          );
        }
      } else if (checkAWS === false && checkIPFS === true) {
        if (uploadValidation()) {
          dispatch(
            MultipleUploadMetaData(
              "multiple",
              "ipfs",
              {
                awsFolder:
                  reqUrlCreate === "" || reqUrlCreate === null
                    ? `${userID}/`
                    : reqUrlCreate,
                ipfsfoldername: FolderNameIPFS,
                awsBucketName: awsBucketName,
                FolderId: FolderId,
                FolderNameIPFS: FolderNameIPFS,
                FolderIDIPFS: FolderIDIPFS,
                ipfsID: FolderIDIPFS,
                isIPFS: checkIPFS,
                isAmazon: checkAWS,
                fileDesc: fileDesc,
                bucketId: bucketId,
                fileTag: fileTag,
                fileKeyWord: fileKeyWord,
                // orgName: CompanyName,
                orgName: orgName,
                medicalformvisible: medicalformvisible,
                firstName: firstName,
                lastName: lastName,
                selectedDate: selectedDate,
                age: age,
                gender: gender,
                email: email.toLowerCase(),
                phone: phone,
                h_id_1: {
                  code:
                    selectedcardID1 === null || selectedcardID1 === ""
                      ? 0
                      : selectedcardID1,
                  name: selectedName1,
                  value: enterId1,
                },
                h_id_2: {
                  code:
                    selectedcardID2 === null || selectedcardID2 === ""
                      ? 0
                      : selectedcardID2,
                  name: selectedName2,
                  value: enterId2,
                },
                h_id_3: {
                  code:
                    selectedcardID3 === null || selectedcardID3 === ""
                      ? 0
                      : selectedcardID3,
                  name: selectedName3,
                  value: enterId3,
                },
                CountryCode: CountryCode,
                bucketAclOption: publicOrPrivate,
                bucketIdIpfs:
                  bucketIdIpfs === "" || bucketIdIpfs === null
                    ? "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
                    : bucketIdIpfs,
                dateToSend: dateToSend,
              },
              d_rootFiles ? d_rootFiles : "",
              multi_progress_temp_arr,
              allUploadFolder,
              abortController,
              0
            )
          );
          handleHashMulti(fileHashes ? fileHashes : "");
        }
      } else if (checkAWS === false && checkIPFS === false) {
        if (uploadValidation()) {
          setErruploadFile(<>*{text_err_select_storage}!</>, 5000); //
        }
      }
    }
  };
  // ***** folder-file required state starts *****
  const [dragdropFiles, setDragdropFiles] = useState([]);
  const [fileFolderCommon, setFileFolderCommon] = useState([]);
  const [d_rootFiles, set_d_rootFiles] = useState([]);
  const [d_rootFilesUpload, set_d_rootFilesUpload] = useState([]);
  const [allUploadFolder, setAllUploadFolder] = useState();
  //**Drag and drop file or folder *//
  const onDrop = useCallback(
    async (acceptedFiles) => {
      // console.log(acceptedFiles);

      const contentBetweenSlashes = acceptedFiles.map((file) => {
        const parts = file.path.split("/");
        return parts[1];
      });
      setFolderList(contentBetweenSlashes);
      const dragdropFolderName = folderApiState?.map((v, i) => {
        const parts = v.folderName.split("/");
        return parts[parts.length - 2];
      });
      // console.log(contentBetweenSlashes, "", dragdropFolderName);
      const commonElements = contentBetweenSlashes.filter((element) =>
        dragdropFolderName.includes(element)
      );

      // console.log(commonElements);
      if (commonElements.length !== 0) {
        // console.log("notEmpty");
        // setSameFolderError("Folder already exists!");
        InfoToast("Folder Already Exists In Amazon s3");
      }
      if (acceptedFiles.length !== 0) {
        setTotalSize(acceptedFiles.reduce((acc, file) => acc + file.size, 0));

        checkNumberOfFiles(acceptedFiles);
        setDragdropFiles(acceptedFiles);
        set_d_rootFiles([acceptedFiles]);
        const updatedFileFolderCommon = [...fileFolderCommon];
        const AllFiles = [];
        const AllFolder = [];
        for (let i = 0; i < acceptedFiles?.length; i++) {
          if (acceptedFiles[i].path?.indexOf("/") !== -1) {
            var FinalFolder = acceptedFiles[i]?.path
              .split("/")
              .slice(0, -1)
              .join("/");
            const new_str = reqUrlCreate;
            const data = new_str.lastIndexOf("/");
            const str_data = new_str.slice(0, data);
            const dataIPFS =
              FolderNameIPFS === "" ||
              FolderNameIPFS === null ||
              FolderNameIPFS === undefined
                ? ""
                : FolderNameIPFS.lastIndexOf("/");
            const str_data_ipfs =
              FolderNameIPFS === "" ||
              FolderNameIPFS === null ||
              FolderNameIPFS === undefined
                ? ""
                : FolderNameIPFS.slice(0, dataIPFS);
            var FolderForCreate =
              checkAWS === true && checkIPFS === true
                ? FinalFolder + "/"
                : reqUrlCreate === "" || reqUrlCreate === null
                ? str_data_ipfs + FinalFolder + "/"
                : awsBucketName + "/" + str_data + FinalFolder + "/";
            AllFolder.push(FolderForCreate);
          }
        }
        var FinalFolderAdd = AllFolder.filter(
          (val, id, array) => array.indexOf(val) === id
        );
        setAllUploadFolder(FinalFolderAdd);

        // Filter out duplicate folders by folderName
        const uniqueFileFolderCommon = updatedFileFolderCommon.reduce(
          (acc, current) => {
            const x = acc.find(
              (item) => item.folderName === current.folderName
            );
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          },
          []
        );
        setFileFolderCommon(uniqueFileFolderCommon);

        // Probe folders for hierarchy details
        const promises = acceptedFiles.map(async (item) => {
          if (
            item.type === "application/x-moz-file" ||
            item.type === "inode/directory"
          ) {
            // If the dropped item is a folder, probe its hierarchy
            const heirarchyDetails = await probeFolders(item);
            return { heirarchyDetails };
          }
          // If it's a file, return null
          return null;
        });
      }
    },
    [fileFolderCommon, setFileFolderCommon, checkNumberOfFiles, probeFolders]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: true,
    directory: true,
  });
  // ***** folder-file required state ends *****

  //*** Language Start ***/
  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      // console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_enterkeywords(
        xpath.select1("/resources/string[@name='text_enterkeywords']", doc)
          .firstChild.data
      );
      setText_enteremail(
        xpath.select1("/resources/string[@name='text_enteremail']", doc)
          .firstChild.data
      );

      setText_selectdob(
        xpath.select1("/resources/string[@name='text_selectdob']", doc)
          .firstChild.data
      );

      setText_personalhealthdata(
        xpath.select1("/resources/string[@name='text_personalhealthdata']", doc)
          .firstChild.data
      );

      setText_medicaldata(
        xpath.select1("/resources/string[@name='text_medicaldata']", doc)
          .firstChild.data
      );
      setText_enterorgname(
        xpath.select1("/resources/string[@name='text_enterorgname']", doc)
          .firstChild.data
      );
      setText_org1(
        xpath.select1("/resources/string[@name='text_enterorgname']", doc)
          .firstChild.data
      );
      setText_enterkeysepbyspaces(
        xpath.select1(
          "/resources/string[@name='text_enterkeysepbyspaces']",
          doc
        ).firstChild.data
      );
      setText_keywords(
        xpath.select1("/resources/string[@name='text_keywords']", doc)
          .firstChild.data
      );
      setText_enterfiletags(
        xpath.select1("/resources/string[@name='text_enterfiletags']", doc)
          .firstChild.data
      );
      setText_filetag(
        xpath.select1("/resources/string[@name='text_filetag']", doc).firstChild
          .data
      );
      setText_enterdes(
        xpath.select1("/resources/string[@name='text_enterdes']", doc)
          .firstChild.data
      );
      setText_desofallfiles(
        xpath.select1("/resources/string[@name='text_desofallfiles']", doc)
          .firstChild.data
      );
      setText_totalfile(
        xpath.select1("/resources/string[@name='text_totalfile']", doc)
          .firstChild.data
      );

      setText_nofolder(
        xpath.select1("/resources/string[@name='text_nofolder']", doc)
          .firstChild.data
      );
      setText_createnewfolder(
        xpath.select1("/resources/string[@name='text_createnewfolder']", doc)
          .firstChild.data
      );
      setText_enterfoldername(
        xpath.select1("/resources/string[@name='text_enterfoldername']", doc)
          .firstChild.data
      );
      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
      setText_currentlocstion(
        xpath.select1("/resources/string[@name='text_currentlocation']", doc)
          .firstChild.data
      );

      setText_selectbucket(
        xpath.select1("/resources/string[@name='text_selectbucket']", doc)
          .firstChild.data
      );
      setText_asn(
        xpath.select1("/resources/string[@name='text_asn']", doc).firstChild
          .data
      );
      setText_amazons3(
        xpath.select1("/resources/string[@name='text_amazons3']", doc)
          .firstChild.data
      );
      setText_selectstoragelocation(
        xpath.select1(
          "/resources/string[@name='text_selectstoragelocation']",
          doc
        ).firstChild.data
      );
      setText_rsa(
        xpath.select1("/resources/string[@name='text_rsa']", doc).firstChild
          .data
      );
      setText_aes(
        xpath.select1("/resources/string[@name='text_aes']", doc).firstChild
          .data
      );
      setText_sha256(
        xpath.select1("/resources/string[@name='text_sha256']", doc).firstChild
          .data
      );
      setText_encryptiontype(
        xpath.select1(
          "/resources/string[@name='text_selectencryptiontype']",
          doc
        ).firstChild.data
      );
      setText_encryptyourfiledata(
        xpath.select1(
          "/resources/string[@name='text_encryptyourfiledata']",
          doc
        ).firstChild.data
      );

      setText_enterlastname(
        xpath.select1("/resources/string[@name='text_enterlastname']", doc)
          .firstChild.data
      );
      setText_enterfirstname(
        xpath.select1("/resources/string[@name='text_enterfirstname']", doc)
          .firstChild.data
      );
      setText_dob1(
        xpath.select1("/resources/string[@name='text_dob1']", doc).firstChild
          .data
      );
      setText_age(
        xpath.select1("/resources/string[@name='text_age']", doc).firstChild
          .data
      );
      setText_enterage(
        xpath.select1("/resources/string[@name='text_enterage']", doc)
          .firstChild.data
      );
      setText_selectgender(
        xpath.select1("/resources/string[@name='text_selectgender']", doc)
          .firstChild.data
      );
      setText_female(
        xpath.select1("/resources/string[@name='text_female']", doc).firstChild
          .data
      );
      setText_male(
        xpath.select1("/resources/string[@name='text_male']", doc).firstChild
          .data
      );
      setText_other(
        xpath.select1("/resources/string[@name='text_other']", doc).firstChild
          .data
      );
      setText_prefernototodisclose(
        xpath.select1(
          "/resources/string[@name='text_prefernottodesclose']",
          doc
        ).firstChild.data
      );
      setText_email(
        xpath.select1("/resources/string[@name='text_email']", doc).firstChild
          .data
      );
      setText_phoneno(
        xpath.select1("/resources/string[@name='text_phoneno']", doc).firstChild
          .data
      );
      setText_enterphno(
        xpath.select1("/resources/string[@name='text_enterphno']", doc)
          .firstChild.data
      );

      setText_save(
        xpath.select1("/resources/string[@name='text_save']", doc).firstChild
          .data
      );

      setText_firstname1(
        xpath.select1("/resources/string[@name='text_firstname1']", doc)
          .firstChild.data
      );
      setText_lastname1(
        xpath.select1("/resources/string[@name='text_lastname1']", doc)
          .firstChild.data
      );
      setText_uploadfile(
        xpath.select1("/resources/string[@name='text_uploadfile']", doc)
          .firstChild.data
      );
      setText_patient(
        xpath.select1("/resources/string[@name='text_patient']", doc).firstChild
          .data
      );
      setText_country(
        xpath.select1("/resources/string[@name='text_country']", doc).firstChild
          .data
      );

      setText_err_folder_name(
        xpath.select1("/resources/string[@name='text_err_folder_name']", doc)
          .firstChild.data
      );
      setText_id(
        xpath.select1("/resources/string[@name='text_id']", doc).firstChild.data
      );
      setText_enterid(
        xpath.select1("/resources/string[@name='text_enterid']", doc).firstChild
          .data
      );
      setText_dropfileorfolderhere(
        xpath.select1(
          "/resources/string[@name='text_dropfilorfolderehere']",
          doc
        ).firstChild.data
      );
      setText_dragandselectfolder(
        xpath.select1(
          "/resources/string[@name='text_dragandselectfolder']",
          doc
        ).firstChild.data
      );
      setText_select_File_folder(
        xpath.select1("/resources/string[@name='text_select_File_folder']", doc)
          .firstChild.data
      );
      setText_select_bkt(
        xpath.select1("/resources/string[@name='text_select_amazon_bkt']", doc)
          .firstChild.data
      );
      setText_err_select_storage(
        xpath.select1("/resources/string[@name='text_err_select_storage']", doc)
          .firstChild.data
      );
      setTextgender1(
        xpath.select1("/resources/string[@name='text_gender1']", doc).firstChild
          .data
      );
      set_validation_folder(
        xpath.select1("/resources/string[@name='text_validation_folder']", doc)
          .firstChild.data
      );
      setText_upload_location(
        xpath.select1("/resources/string[@name='text_upload_location']", doc)
          .firstChild.data
      );
      setText_upload_aws_location(
        xpath.select1(
          "/resources/string[@name='text_upload_aws_location']",
          doc
        ).firstChild.data
      );
      setText_upload_atom_location(
        xpath.select1(
          "/resources/string[@name='text_upload_atom_location']",
          doc
        ).firstChild.data
      );
      setText_selectbucket1(
        xpath.select1("/resources/string[@name='text_selectbucket1']", doc)
          .firstChild.data
      );
      set_not_enough_space(
        xpath.select1("/resources/string[@name='not_enough_space']", doc)
          .firstChild.data
      );
      setText_noticefordelete(
        xpath.select1("/resources/string[@name='text_noticefordelete']", doc)
          .firstChild.data
      );
      setText_neverdelete(
        xpath.select1("/resources/string[@name='text_neverdelete']", doc)
          .firstChild.data
      );
      setText_years(
        xpath.select1("/resources/string[@name='text_years']", doc).firstChild
          .data
      );
      setText_months(
        xpath.select1("/resources/string[@name='text_years']", doc).firstChild
          .data
      );
      setText_days(
        xpath.select1("/resources/string[@name='text_days']", doc).firstChild
          .data
      );
      setText_selectdate(
        xpath.select1("/resources/string[@name='text_selectdate']", doc)
          .firstChild.data
      );
      setText_deletedon(
        xpath.select1("/resources/string[@name='text_deletedon']", doc)
          .firstChild.data
      );
      setText_noofyears(
        xpath.select1("/resources/string[@name='text_noofyears']", doc)
          .firstChild.data
      );
      setText_enternoofmonths(
        xpath.select1("/resources/string[@name='text_enternoofmonths']", doc)
          .firstChild.data
      );
      setText_enternoofdays(
        xpath.select1("/resources/string[@name='text_enternoofdays']", doc)
          .firstChild.data
      );
      // setTextDateErrorMessage(
      //   xpath.select1("/resources/string[@name='textDateErrorMessage']", doc)
      //     .firstChild.data
      // );
    } catch (ert) {}
  };
  //*** Language End ***/

  //****Clear IPFS Check data *****/
  const handleAwsCheck = () => {
    setLoader(true);
    setCheckAWS(!checkAWS);
    // setAwsBucketName("");
    setBucketId("");
    setAwsFormvisible(false);
    setReqUrlCreate("");
    setAwsFormvisible(!awsformvisible);
    setErruploadFile("");
    setViewBackBtn(false);

    if (checkAWS === false) {
      if (atomS3DataMultiple?.length === 0) {
        dispatch(getBucketData());
      } else {
        getBucket();
      }
    } else {
      // setBucketId("");
      setPublicOrPrivate("");
      // setAllBucket([]);
      setFolderId("");
      setAwsBucketName("");
      setFolderApiState([]);
      setFolderNameInput("");
      setSfvErr("");
      setReqUrlCreate("");
      setFileDesc("");
      setFileKeyWord("");
      setFileName("");
      setFileSize("");
      setFileTag("");
      setFileType("");
      setLastName("");
      setFirstName("");
      setAge("");
      setGender("");
      setenterId1("");
      setenterId2("");
      setenterId3("");
      setEmail("");
      setCountryCode("US");
      setPhone("");
      setMedicalformvisible(false);
      setSelectedDate(null);
      setSelectedCardID1("");
      setSelectedCardID2("");
      setSelectedCardID3("");
      setenterId1("");
      setenterId2("");
      setenterId3("");
      setCountry("United states");
      setSelectedID1("");
      setSelectedID2("");
      setSelectedID3("");
      setValidAgeErr("");
      setValidEmailErr("");
      setOrgName("");
      set_d_rootFiles([]);
      setFileFolderCommon([]);
      setViewBackBtn(false);
      setUrlToShow("");
      setUrlfordragdrop("");
    }
  };

  //****Clear AWS Check data *****/
  const handleCheckIPFS = () => {
    setCheckIPFS(!checkIPFS);
    setFormvisible(!formvisible);
    setErruploadFile("");
    setViewBackBtn(false);
    if (checkIPFS === false) {
      setFolderIdIPFS("");
      // setUrlToShow("");
      setViewBackBtn(false);
      setFolderNameIPFs("");
      // setAwsBucketName("");
      // setBucketId("");
      setFileDesc("");
      setFileKeyWord("");
      setFileName("");
      setFileSize("");
      setFileTag("");
      setFileType("");
      setLastName("");
      setFirstName("");
      setAge("");
      setSelectedDate(null);
      setGender("");
      setenterId1("");
      setenterId2("");
      setenterId3("");
      setEmail("");
      setCountryCode("US");
      setPhone("");
      setMedicalformvisible(false);
      setSelectedCardID1("");
      setSelectedCardID2("");
      setSelectedCardID3("");
      setenterId1("");
      setenterId2("");
      setenterId3("");
      setCountry("United states");
      setSelectedID1("");
      setSelectedID2("");
      setSelectedID3("");
      setValidAgeErr("");
      setValidEmailErr("");
      setOrgName("");
      set_d_rootFiles([]);
      setFileFolderCommon([]);
    }
  };

  //*** get country vise id data Found *****/
  const GetDocumentForID = (countryname) => {
    var FinalCountry =
      countryname === "" || countryname === null ? CountryCode : countryname;
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE + `/medical-id/get-all?country=${FinalCountry}`;

    axios
      .get(API_URL, headerConfig)
      .then((res) => {
        // console.log("read file res***", res);
        setSelectIDCard(res.data.data);
        setSelectedCardID1(res?.data?.data[0]?.medicalIdCode);
        setSelectedCardID2(res?.data?.data[0]?.medicalIdCode);
        setSelectedCardID3(res?.data?.data[0]?.medicalIdCode);
        setSelectedID1(res?.data?.data[0]?._id);
        setSelectedID2(res?.data?.data[0]?._id);
        setSelectedID3(res?.data?.data[0]?._id);
        setSelectedCardName1(res?.data?.data[0]?.medicalIdName);
        setSelectedCardName2(res?.data?.data[0]?.medicalIdName);
        setSelectedCardName3(res?.data?.data[0]?.medicalIdName);
      })
      .catch((err) => {
        // console.log("read file err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };

  const handleMedicalFillData = () => {
    setMedicalformvisible(!medicalformvisible);
    setFirstName("");
    setCountryCode("US");
    setLastName("");
    setEmail("");
    setPhone("");
    setAge("");
    setSelectedCardID1("");
    setSelectedCardID2("");
    setSelectedCardID3("");
    setSelectedCardName1("");
    setSelectedCardName2("");
    setSelectedCardName3("");
    setSelectedID1("");
    setSelectedID2("");
    setSelectedID3("");
    setSelectedDate(null);
    setGender("");
    setValidAgeErr("");
    setValidEmailErr("");
  };
  function truncate(string, n) {
    if (string === undefined) {
      // console.log(string);
    } else {
      return string?.length > n
        ? string.substr(0, n - 1) + ". . . " + string.slice(string.length - 3)
        : string;
    }
  }

  async function probeFolders(item) {
    const heirarchyDetails = {
      emptyFolders: [],
      allFolders: [],
      files: [],
    };

    const rootHandle = await item.getAsFileSystemHandle();
    const path = `/${rootHandle.name}`;

    if (rootHandle.kind === "directory") {
      await traverseDirectory(rootHandle, path, heirarchyDetails);
    } else if (rootHandle.kind === "file") {
      const file = { name: rootHandle.name, kind: rootHandle.kind, path };
      heirarchyDetails.files.push(file);
    }

    return heirarchyDetails;
  }

  async function traverseDirectory(dirHandle, currentPath, heirarchyDetails) {
    const folderDetails = {
      name: dirHandle.name,
      kind: dirHandle.kind,
      path: currentPath,
      children: [],
    };

    for await (const [name, handle] of dirHandle.entries()) {
      const path = `${currentPath}/${name}`;

      if (handle.kind === "file") {
        const file = { path, name: handle.name, kind: handle.kind };
        heirarchyDetails.files.push(file);
        folderDetails.children.push(file);
      } else if (handle.kind === "directory") {
        const childDetails = await traverseDirectory(
          handle,
          path,
          heirarchyDetails
        );

        if (childDetails.children.length === 0) {
          heirarchyDetails.emptyFolders.push(childDetails);
        }

        folderDetails.children.push(childDetails);
      }
    }

    heirarchyDetails.allFolders.push(folderDetails);
    return folderDetails;
  }
  useEffect(() => {
    // console.log(reqUrlCreate);
    sessionStorage.setItem("requrlAws", reqUrlCreate);
  }, [reqUrlCreate]);
  return (
    <>
      <div className="uf-main">
        <div className="uf-main-container test-single-container">
          <div className="ts-content">
            <hr className="encypt-hr-1" />
            {/***** Encryption data*******/}
            {/* <div className="gap-4 d-flex encrypt-file">
              <div className="d-flex select-dropdown-myfile">
                <input
                  type="checkbox"
                  id="encypt"
                  name="encypt"
                  onClick={(e) =>
                    e.target.checked === true
                      ? setCheckEncyption(true)
                      : [setCheckEncyption(false), setEncryptiontype("")]
                  }
                  className="uf-checkbox-input"
                />
                <label className="uf-phd-text-encrypt mx-2">
                  {text_encryptyourfiledata}
                </label>
              </div>

              {checkEncyption && (
                <>
                  <div>
                    <div className="uf-inputs-main">
                      <div className="inputForm  uf-input-encrypt">
                        <select
                          className="uf-inputs encrypt-input-select uploadselectdrop select-dropdown-myfile"
                          onChange={(e) => setEncryptiontype(e.target.value)}
                        >
                          <option disabled selected>
                            {text_selectencryptiontype}
                          </option>
                          
                          <option value="aes">{text_aes}</option>
                          
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    {encryptiontype === "aes" ? (
                      <>
                        <div className="d-flex">
                          <label className="uf-phd-text-encrypt mx-2">
                            Key
                          </label>
                          <div className="inputForm  uf-input-encrypt  overflow-hidden">
                            <input
                              disabled
                              className="uf-inputs encrypt-input-select uploadselectdrop encrypKeyInput"
                              type="text"
                              value="********************************"
                            />
                          </div>
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div> */}
            <div className="encrypt-file">
              <div className="d-flex select-dropdown-myfile">
                <input
                  type="checkbox"
                  id="encypt"
                  name="encypt"
                  onClick={(e) => {
                    if (e.target.checked) {
                      setCheckEncyption(true);
                      setEncryptiontype("aes");
                    } else {
                      setCheckEncyption(false);
                      setEncryptiontype("");
                    }
                  }}
                  className="uf-checkbox-input"
                />
                <label className="uf-phd-text-encrypt mx-2">
                  {text_encryptyourfiledata}
                </label>
              </div>
              {checkEncyption && (
                <>
                  <div>
                    <div className="uf-inputs-main">
                      <div className="inputForm uf-input-encrypt">
                        <select
                          className="uf-inputs encrypt-input-select uploadselectdrop select-dropdown-myfile"
                          onChange={(e) => setEncryptiontype(e.target.value)}
                          value={encryptiontype}
                        >
                          <option disabled value="">
                            {text_selectencryptiontype}
                          </option>
                          <option value="aes">{text_aes}</option>
                          {/* <option value="">{text_sha256}</option> */}
                          {/* <option value="">{text_rsa}</option> */}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div>
                    {encryptiontype === "aes" && (
                      <div className="d-flex">
                        <label className="uf-phd-text-encrypt mx-2">Key</label>
                        <div className="inputForm uf-input-encrypt overflow-hidden">
                          <input
                            className="uf-inputs encrypt-input-select uploadselectdrop encrypKeyInput"
                            type="text"
                            value="********************************"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
            <hr className="encypt-hr-1" />
            {/* ################################################################################3 */}

            <h5 className="text-center uf-phd-text text-select-storage">
              {text_selectstoragelocation}
            </h5>

            <div className="row mt-3">
              <div className="col-md-2"></div>
              <div className="col-md-4 ">
                <div className="uf-checkbox-inner">
                  <input
                    type="checkbox"
                    id="amazons3"
                    name="amazons3"
                    onChange={(e) => {
                      setUploadMultiple(true);
                      setFolderList([]);
                      handleAwsCheck();
                    }}
                    className="uf-checkbox-input uf-check-top"
                  />
                  <label
                    htmlFor="amazons3"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    {text_amazons3}
                  </label>
                </div>
                {/*****AWS Bucket storage total and used*******/}
                {checkAWS === true && (
                  <>
                    <h6 style={{ marginLeft: "22px" }}>{text_selectbucket1}</h6>
                    <div style={{ marginLeft: "19px" }}>
                      <div className="select-bucket justify-content-center mt-2">
                        <select
                          className="uf-inputs uploadselectdrop-select"
                          onChange={(e) => [
                            setAwsFormvisible(!awsformvisible),
                            handleSelectBucket(e.target.value),
                          ]}
                          value={bucketId}
                        >
                          {allBucket.length > 0 ? (
                            <>
                              <option disabled>{text_selectbucket}</option>
                              {allBucket.map((v, i) => {
                                return (
                                  <>
                                    <option value={v.bucketId}>
                                      {v.bucketName === "atom-storage"
                                        ? `${sessionStorage.getItem(
                                            "fName"
                                          )}'s Bucket`
                                        : v.bucketName ===
                                          "atom-storage-private"
                                        ? `${sessionStorage.getItem(
                                            "fName"
                                          )}'s Bucket`
                                        : v.bucketName}{" "}
                                      ({v.bucketACLOption})
                                    </option>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              <option disabled selected>
                                {text_selectbucket}
                              </option>
                            </>
                          )}
                        </select>
                      </div>
                    </div>
                    <div className="mx-4">
                      <ProgressBar
                        className="mt-3 mb-3"
                        completed={storageAWS}
                        bgColor="#008A82"
                        height="10px"
                        width="72%"
                        isLabelVisible={false}
                        labelColor="#1a91ec"
                        maxCompleted={totalStorageAWS}
                      />
                      <p style={{ marginTop: "-11px" }}>
                        {size(storageAWS)} of {size(totalStorageAWS)} used
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-1"></div>
              <div className="col-md-4 ">
                <div className="uf-checkbox-inner">
                  <input
                    type="checkbox"
                    id="ipfs"
                    name="ipfs"
                    onChange={(e) => {
                      if (e.target.checked === true) {
                        handleCheckIPFS();
                        setFolderList([]);
                        getStorageIPFS("atom-storage");
                      } else {
                        setCheckIPFS(false);
                      }
                    }}
                    className="uf-checkbox-input uf-check-top"
                  />
                  <label
                    htmlFor="ipfs"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    {text_asn}
                  </label>
                </div>
                {/***** Atom storage Bucket storage total and used*******/}
                {checkIPFS === true && (
                  <>
                    <div className="mx-4">
                      <ProgressBar
                        className="mt-3 mb-3"
                        completed={storageIPFS}
                        bgColor="#008A82"
                        height="10px"
                        width="72%"
                        isLabelVisible={false}
                        labelColor="#1a91ec"
                        maxCompleted={storageIPFSTotal}
                      />
                      <p style={{ marginTop: "-11px" }}>
                        {size(storageIPFS)} of {size(storageIPFSTotal)} used
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* ---------- */}
            <hr className="uf-hr-3" />

            {/***** current Folder Location show for AWS S3 start*******/}
            {checkAWS === true && checkIPFS === false ? (
              <>
                {awsBucketName === "" ? (
                  <div className="uf-folders-main-wrapper">
                    {" "}
                    <div className="uf-folders-main">
                      <div className="text-center">
                        <Loader3 />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="uf-folders-main-wrapper">
                    <div className="uf-folders-main">
                      {reqUrlCreate !== "" ? (
                        <div className="uf-url">
                          <span className="uf-url-s1">
                            <b>{text_currentlocation}</b>
                          </span>
                          <span className="uf-url-s2">
                            <img
                              src={F1Img}
                              alt=""
                              style={{ height: "22px", width: "22px" }}
                            />
                            {awsBucketName === "atom-storage"
                              ? `${sessionStorage.getItem(
                                  "fName"
                                )}'s Bucket (Public) >`
                              : awsBucketName === "atom-storage-private"
                              ? `${sessionStorage.getItem(
                                  "fName"
                                )}'s Bucket (Private) >`
                              : awsBucketName +
                                " " +
                                "(" +
                                publicOrPrivate +
                                ")" +
                                ">"}
                            {urlToShow}
                          </span>
                        </div>
                      ) : (
                        <div className="uf-url">
                          <span className="uf-url-s1">
                            <b>{text_currentlocation}</b>
                          </span>
                          <span className="uf-url-s2">
                            <img
                              src={F1Img}
                              alt=""
                              style={{ height: "22px", width: "22px" }}
                            />
                            {awsBucketName === "atom-storage"
                              ? `${sessionStorage.getItem(
                                  "fName"
                                )}'s Bucket (Public) >`
                              : awsBucketName === "atom-storage-private"
                              ? `${sessionStorage.getItem(
                                  "fName"
                                )}'s Bucket (Private) >`
                              : awsBucketName +
                                " " +
                                "(" +
                                publicOrPrivate +
                                ")" +
                                ">"}
                            {urlToShow}
                          </span>
                        </div>
                      )}
                      <div
                        className={
                          viewBackBtn === true || showbackforFile === true
                            ? "uf-folders-back-create"
                            : "uf-folders-notback-create"
                        }
                      >
                        {/*****Back Button show *******/}
                        {viewBackBtn === true && (
                          <div className="uf-folders-back">
                            <button
                              className="back-btn"
                              onClick={handleBack}
                              style={{ marginTop: "-10px" }}
                            >
                              <span>
                                <MdOutlineKeyboardBackspace className="back-btn-icon" />
                              </span>
                              <span>{text_back}</span>
                            </button>
                          </div>
                        )}
                        {/*****Create New   folder*******/}
                        <div className="uf-folders-create">
                          {showCreateFolderInput === true ? (
                            <div className="uf-folders-in f-input">
                              {/* -----------  */}
                              <div className="uf-inputs-main">
                                <div className="inputForm uf-inputs mt-1">
                                  <img
                                    src={FolImg}
                                    alt=""
                                    className="login-emailphone"
                                  />
                                  <input
                                    type="text"
                                    className="input login-input"
                                    style={{ backgroundColor: "transparent" }}
                                    onChange={(e) => [
                                      setFolderNameInput(e.target.value),
                                      setSfvErr(""),
                                    ]}
                                    placeholder={text_enterfoldername}
                                    value={folderNameInput}
                                  />
                                </div>
                              </div>
                              {/* ---------- */}

                              <button
                                className="box-btn-save"
                                onClick={handleCreateSubFolder}
                              >
                                {text_save}
                              </button>
                              <button
                                onClick={() => [
                                  setShowCreateFolderInput(false),
                                  setSfvErr(""),
                                  setFolderNameInput(""),
                                ]}
                                className="box-btn"
                                value={folderNameInput}
                              >
                                {text_cancel}
                              </button>
                            </div>
                          ) : (
                            <button
                              onClick={() => setShowCreateFolderInput(true)}
                              className="create-btn"
                            >
                              {text_createnewfolder}
                            </button>
                          )}
                          <p className="sfv-err">{sfvErr}</p>
                        </div>
                      </div>
                      {/*****All Get Folders Show   *******/}
                      <div className="uf-folders-outer">
                        <div className="uf-folders">
                          {folderApiState?.map((v, i) => {
                            return (
                              <div
                                key={i}
                                className="uf-folder text-center"
                                onClick={() => [
                                  getAllSubDir_new(v?.folderName),
                                  setFolderId(v?.folderId),
                                  setSfvErr(""),
                                  setShowCreateFolderInput(false),
                                  setTimeout(() => {
                                    setViewBackBtn(true);
                                  }, 1000),
                                ]}
                                title={v?.folderName.slice(
                                  v?.folderName.lastIndexOf(
                                    "/",
                                    v?.folderName.lastIndexOf("/") - 1
                                  ) + 1,
                                  v?.folderName?.length - 1
                                )}
                              >
                                <img
                                  src={F1Img}
                                  alt=""
                                  className="uf-folder-img"
                                />
                                <p className="uf-folder-name">
                                  {v?.folderName.slice(
                                    v?.folderName.lastIndexOf(
                                      "/",
                                      v?.folderName.lastIndexOf("/") - 1
                                    ) + 1,
                                    v?.folderName?.length - 1
                                  )}
                                </p>
                              </div>
                            );
                          })}
                          {fileFolderCommon.length === 0 &&
                          folderApiState?.length === 0 ? (
                            <>
                              {loader === true ? (
                                <div className="uf-emptyfolder">
                                  <Loader3 />
                                </div>
                              ) : (
                                <>
                                  {uploadFolder.length === 0 &&
                                  folderApiState?.length === 0 ? (
                                    <>
                                      <div className="uf-emptyfolder">
                                        <h3 className="as">{text_nofolder}</h3>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              )}
                            </>
                          ) : (
                            <></>
                          )}

                          {showFolderFileCommon === true ? (
                            <>
                              {fileFolderCommon?.map((v, i) => {
                                return (
                                  <>
                                    <div
                                      key={i}
                                      className="uf-folder text-center"
                                      onClick={() => [
                                        setUploadFolder(v?.files),
                                        setShowfolderfilecommon(false),
                                        setViewBackBtn(false),
                                        setShowBackForFile(true),
                                      ]}
                                      title={v?.folderName}
                                    >
                                      <img
                                        src={F1Img}
                                        alt=""
                                        className="uf-folder-img"
                                      />
                                      <p className="uf-folder-name">
                                        {truncate(v?.folderName, 4)}
                                      </p>
                                    </div>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <>
                              {uploadFolder.length > 0 &&
                                uploadFolder.map((e, i) => {
                                  return (
                                    <div
                                      key={i}
                                      className="uf-folder text-center"
                                      title={e}
                                    >
                                      <img
                                        src={FileImg}
                                        alt=""
                                        className="uf-folder-img"
                                      />
                                      <p className="uf-folder-name">
                                        {truncate(e, 5)}
                                      </p>
                                    </div>
                                  );
                                })}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              ""
            )}
            {/*****Atom storage selected folder component  *******/}
            {checkIPFS === true && checkAWS === false ? (
              <UploadMultipleAtomStorage
                folderIDIPFS={GetFolderID}
                folderNameIPFS={GetFolderName}
                bucketName={GetBucketNameIPFS}
                bucketId={GetBucketID}
                FileData={d_rootFilesUpload}
                FolderData={fileFolderCommon}
                urlForIpfs={handleUrl}
                folderList={folderList}
              />
            ) : (
              ""
            )}
            {/*****Amazon s3 and Atom storage selected folder component  *******/}
            {checkIPFS === true && checkAWS === true ? (
              <UploadMultipleBothStorage
                folderIDIPFS={GetFolderID}
                folderNameIPFS={GetFolderName}
                bucketName={GetBucketNameIPFS}
                bucketId={GetBucketID}
                folderIDaws={GetFolderIDaws}
                folderNameaws={GetFolderNameaws}
                bucketNameaws={awsBucketName}
                bucketIdaws={bucketId}
                FileData={d_rootFilesUpload}
                FolderData={fileFolderCommon}
                bucketACLOption={publicOrPrivate}
                urlforIPFS={handleUrl_both}
                urlforaws={handleUrl_aws}
                folderList={folderList}
              />
            ) : (
              ""
            )}
            {/***** current Folder Location show for both start*******/}
            <p>
              {checkAWS === true && checkIPFS === true ? (
                <>
                  <p>
                    {" "}
                    <b>{text_upload_aws_location}: </b>{" "}
                    {awsBucketName === "atom-storage"
                      ? `${sessionStorage.getItem("fName")}'s Bucket (Public) >`
                      : awsBucketName === "atom-storage-private"
                      ? `${sessionStorage.getItem("fName")}'s Bucket (Private)`
                      : awsBucketName + " " + "(" + publicOrPrivate + ")" + ">"}
                    {urlfordragdrop}
                  </p>
                  <p>
                    <b>{text_upload_atom_location}: </b>{" "}
                    {urlForIPFS_both === "" || urlForIPFS_both === null
                      ? "atom-storage >"
                      : urlForIPFS_both}
                  </p>
                </>
              ) : (
                <>
                  {checkIPFS === true && urlForIPFS !== "" ? (
                    <>
                      <b>{text_upload_location} : </b>{" "}
                      {urlForIPFS === "atom-storage"
                        ? "atom-storage >"
                        : urlForIPFS}
                    </>
                  ) : awsBucketName === "" || awsBucketName === null ? (
                    ""
                  ) : (
                    <>
                      <b>{text_upload_location} :</b>{" "}
                      {awsBucketName === "atom-storage"
                        ? `${sessionStorage.getItem(
                            "fName"
                          )}'s Bucket (Public) >`
                        : awsBucketName === "atom-storage-private"
                        ? `${sessionStorage.getItem(
                            "fName"
                          )}'s Bucket (Private) >`
                        : awsBucketName +
                          " " +
                          "(" +
                          publicOrPrivate +
                          ")" +
                          ">"}
                      {urlfordragdrop}
                    </>
                  )}
                </>
              )}
            </p>
            {/***** current Folder Location end*******/}
            {/***** Drag and Drop Or Select File start *******/}
            <div className="uf-left-right">
              <div>
                <div
                  className="uf-left uf-dragdrop-responsive kb-file-upload file-upload-box"
                  {...getRootProps()}
                  style={{
                    border: "2px dashed #ddd",
                    padding: "20px",
                    textAlign: "center",
                    height: "200px",
                    fontSize: "14px",
                    cursor: "pointer",
                  }}
                >
                  <input {...getInputProps()} />
                  {/***** Drop or Select File Count *******/}
                  {isDragActive ? (
                    <>
                      <p className="drop-here">{text_dropfilorfolderehere}</p>
                    </>
                  ) : (
                    <>
                      <MdCloudUpload
                        className={
                          fileSize !== "" && fileName !== ""
                            ? "upload-icon upload-icon-top"
                            : "upload-icon "
                        }
                      />
                      <p
                        className={
                          fileSize !== "" && fileName !== ""
                            ? "upload-icon-top"
                            : ""
                        }
                      >
                        {text_dragandselectfolder}
                      </p>
                    </>
                  )}
                  {fileSize !== "" && fileName !== "" ? (
                    <>
                      <div className="file-atc-box">
                        {showSelectedfile.map((v, i) => {
                          return (
                            <>
                              <p>
                                <b>{text_totalfile}</b> {v.length}
                              </p>
                            </>
                          );
                        })}
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              {/***** File Data  *******/}
              <div className="container-fluid">
                <div className="row mt-2 mx-auto">
                  <div className="col-md-3 ">
                    <div className="flex-column">
                      <label className="login-labels">
                        {text_desofallfiles}
                      </label>
                    </div>
                    <div className="input-group mt-1">
                      <span className="input-group-text">
                        <img
                          src={DescImg}
                          alt=""
                          className="login-emailphone"
                        />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={text_enterdes}
                        onChange={(e) => setFileDesc(e.target.value)}
                        value={fileDesc}
                      />
                    </div>
                  </div>
                  <div className="col-md-3" id="">
                    <div className="flex-column">
                      <label className="login-labels">{text_filetag}</label>
                    </div>
                    <div className="input-group mt-1">
                      <span className="input-group-text">
                        <img
                          src={HashImg}
                          alt=""
                          className="login-emailphone"
                        />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={text_enterfiletags}
                        onChange={(e) => setFileTag(e.target.value)}
                        value={fileTag}
                      />
                    </div>
                    <p className="uf-subtext mt-1">{text_enterfiletags}</p>
                  </div>

                  <div className="col-md-3" id="">
                    <div className="flex-column">
                      <label className="login-labels">{text_keywords}</label>
                    </div>
                    <div className="input-group mt-1">
                      <span className="input-group-text">
                        <img
                          src={keywordImg}
                          alt=""
                          className="login-emailphone"
                        />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={text_enterkeywords}
                        onChange={(e) => setFileKeyWord(e.target.value)}
                        value={fileKeyWord}
                      />
                    </div>
                    <p className="uf-subtext mt-1">
                      {text_enterkeysepbyspaces}
                    </p>
                  </div>

                  <div className="col-md-3">
                    <div className="flex-column">
                      <label className="login-labels">{text_org1}</label>
                    </div>
                    <div className="input-group mt-1">
                      <span className="input-group-text">
                        <img src={OrgImg} alt="" className="login-emailphone" />
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder={text_enterorgname}
                        onChange={(e) => setOrgName(e.target.value)}
                        value={orgName}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/***** Drag and Drop Or Select File end *******/}
            {/***** Medical Data Filing *******/}
            <div className="uf-health-data">
              <hr className="uf-hr" />
              <div className="gap-3 d-flex">
                <input
                  type="checkbox"
                  id="amazons3"
                  name="amazons3"
                  onChange={(e) => [
                    handleMedicalFillData(),
                    setValidEmailErr(""),
                    setAwsFormvisible(false),
                    GetDocumentForID(CountryCode),
                  ]}
                  checked={medicalformvisible}
                  className="uf-checkbox-input"
                />
                <label className="uf-phd-text">{text_medicaldata}</label>
              </div>

              {medicalformvisible === true ? (
                <>
                  {" "}
                  <div className="text-center uf-phd-text text-select-storage">
                    {text_personalhealthdata}
                  </div>
                  <div className="container-fluid">
                    <div className="row mt-3 mx-auto">
                      <div className="col-md-1 mx-2"></div>
                      <div className="col-md-3 mx-1">
                        <h6>
                          {" "}
                          {text_patient} {text_firstname1}
                        </h6>
                        <div className="input-group mt-1">
                          <span className="input-group-text">
                            <img
                              src={UserImg}
                              alt=""
                              className="login-emailphone"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={text_enterfirstname}
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mx-1" id="">
                        <h6>
                          {" "}
                          {text_patient} {text_lastname1}
                        </h6>
                        <div className="input-group mt-1">
                          <span className="input-group-text">
                            <img
                              src={UserImg}
                              alt=""
                              className="login-emailphone"
                            />
                          </span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder={text_enterlastname}
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-md-3 mx-1" id="">
                        <h6> {text_country}</h6>
                        <div className="input-group mt-1">
                          <ReactFlagsSelect
                            className="form-control multiple-country-input"
                            selected={CountryCode}
                            onSelect={(code) => [
                              setCountyData(code),
                              GetDocumentForID(code),
                            ]}
                            searchable={true}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 mx-2"></div>
                    </div>
                    <div className="row mt-3 mx-auto">
                      <div className="col-md-1 mx-2"></div>
                      <div className="col-md-3 mx-1">
                        <h6> {text_id} 1</h6>
                        <div className="d-flex mt-1">
                          <select
                            className="form-select w-auto"
                            onChange={(e) => handleId1(e.target.value)}
                            value={selectedID1}
                          >
                            {selectIDCard.map((e) => (
                              <option key={e._id} value={e._id}>
                                {e.medicalIdName}
                              </option>
                            ))}
                          </select>
                          <input
                            className="form-control w-100"
                            placeholder={text_enterid}
                            onChange={(e) => setenterId1(e.target.value)}
                            value={enterId1}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mx-1" id="">
                        <h6> {text_id} 2</h6>
                        <div className="d-flex mt-1">
                          <select
                            className="form-select w-auto"
                            onChange={(e) => handleId2(e.target.value)}
                            value={selectedID2}
                          >
                            {selectIDCard.map((e) => (
                              <option key={e._id} value={e._id}>
                                {e.medicalIdName}
                              </option>
                            ))}
                          </select>
                          <input
                            className="form-control"
                            placeholder={text_enterid}
                            onChange={(e) => setenterId2(e.target.value)}
                            value={enterId2}
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mx-1" id="">
                        <h6> {text_id} 3</h6>
                        <div className="d-flex mt-1">
                          <select
                            className="form-select w-auto"
                            onChange={(e) => handleId3(e.target.value)}
                            value={selectedID3}
                          >
                            {selectIDCard.map((e) => (
                              <option key={e._id} value={e._id}>
                                {e.medicalIdName}
                              </option>
                            ))}
                          </select>
                          <input
                            className="form-control"
                            placeholder={text_enterid}
                            onChange={(e) => setenterId3(e.target.value)}
                            value={enterId3}
                          />
                        </div>
                      </div>
                      <div className="col-md-1 mx-2"></div>
                    </div>
                    <div className="row mt-3 mx-auto">
                      <div className="col-md-1 mx-2"></div>
                      <div className="col-md-3 mx-1">
                        <h6>{text_dob1}</h6>
                        <div className="input-group">
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="MMM dd, yyyy"
                            className="form-control "
                            placeholderText={text_selectdob}
                            maxDate={Date.now()}
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                          />
                        </div>
                      </div>
                      <div className="col-md-3 mx-1" id="">
                        <h6> {text_age}</h6>
                        <div className="input-group mt-1">
                          <span className="input-group-text">
                            <img
                              src={AgeImg}
                              alt=""
                              className="login-emailphone"
                            />
                          </span>
                          <input
                            type="number"
                            min={0}
                            max={120}
                            className="form-control"
                            placeholder={text_enterage}
                            onChange={(e) => [
                              setAge(e.target.value),
                              setValidAgeErr(""),
                            ]}
                            value={age}
                            onFocus={(e) =>
                              e.target.addEventListener(
                                "wheel",
                                function (e) {
                                  e.preventDefault();
                                },
                                { passive: false }
                              )
                            }
                          />
                        </div>
                        <span className="errMsg">{validAgeErr}</span>
                      </div>
                      <div className="col-md-3 mx-1" id="">
                        <h6> {text_gender1}</h6>
                        <div className="input-group mt-1">
                          <span className="input-group-text">
                            <img
                              src={GenderImg}
                              alt=""
                              className="login-emailphone"
                            />
                          </span>
                          <select
                            className="form-select"
                            onChange={(e) => [setGender(e.target.value)]}
                            value={gender}
                          >
                            <option selected value="" disabled>
                              {text_selectgender}
                            </option>
                            <option value="Male">{text_male}</option>
                            <option value="Female">{text_female}</option>
                            <option value="Other">{text_other}</option>
                            <option value="Prefer not to disclose">
                              {text_prefernottodesclose}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-1 mx-2"></div>
                    </div>
                    <div className="row mt-3 mx-auto">
                      <div className="col-md-1 mx-2"></div>
                      <div className="col-md-3 mx-1">
                        <h6> {text_email}</h6>
                        <div className="input-group mt-1">
                          <span className="input-group-text">
                            <img
                              src={EmailImg}
                              alt=""
                              className="login-emailphone"
                            />
                          </span>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={text_enteremail}
                            onChange={(e) => [
                              setEmail(e.target.value),
                              setValidEmailErr(""),
                            ]}
                            value={email.toLowerCase()}
                          />
                        </div>
                        <span className="errMsg">{validEmailErr}</span>
                      </div>
                      <div className="col-md-3 mx-1" id="">
                        <h6> {text_phoneno}</h6>
                        <div className="input-group mt-1">
                          <PhoneInput
                            country={CountryCode.toLowerCase()}
                            onChange={(e) => [setPhone(e)]}
                            inputStyle={{
                              background: "transparent",
                              height: "25px",
                              width: "231px",
                              border: "none",
                            }}
                            inputclassName="form-control"
                            placeholder={text_enterphno}
                            value={phone}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-1 mx-2"></div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              <hr className="uf-hr" />
            </div>
            {/* delete date start */}
            <div className="row">
              <h6 className="text-start text-black fs-5 uf-phd-text">
                {text_noticefordelete}:
              </h6>
              <Form>
                <div className="row">
                  <div className="col-12 col-md-6 col-lg-2 mb-2">
                    <Form.Check
                      label={text_neverdelete}
                      name="group1"
                      type="radio"
                      id="inline-radio-1"
                      style={{ fontSize: "large" }}
                      checked={NeverDelete}
                      onChange={(e) => {
                        setNeverDelete(e.target.checked);
                        setDeleteSelectDate(false);
                        setDeleteDays(false);
                        setDeleteMonths(false);
                        setDeleteYears(false);
                        setDaysvalue("");
                        setMonthsvalue("");
                        setSelectDatevalue(tomorrow);
                        setYearsvalue("");
                        sessionStorage.setItem("deleteAt", null);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-2 mb-2">
                    <Form.Check
                      label={text_years}
                      name="group1"
                      type="radio"
                      id="inline-radio-2"
                      style={{ fontSize: "large" }}
                      checked={deleteYears}
                      onChange={() => {
                        setDeleteYears(!deleteYears);
                        setDeleteSelectDate(false);
                        setDeleteDays(false);
                        setDeleteMonths(false);
                        setDaysvalue("");
                        setMonthsvalue("");
                        setNeverDelete(false);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-2 mb-2">
                    <Form.Check
                      label={text_months}
                      name="group1"
                      type="radio"
                      id="inline-radio-3"
                      style={{ fontSize: "large" }}
                      checked={deleteMonths}
                      onChange={() => {
                        setDeleteMonths(!deleteMonths);
                        setDeleteSelectDate(false);
                        setDeleteDays(false);
                        setDeleteYears(false);
                        setDaysvalue("");
                        setMonthsvalue("");
                        setNeverDelete(false);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-2 mb-2">
                    <Form.Check
                      label={text_days}
                      name="group1"
                      type="radio"
                      id="inline-radio-4"
                      style={{ fontSize: "large" }}
                      checked={deleteDays}
                      onChange={() => {
                        setDeleteDays(!deleteDays);
                        setDeleteSelectDate(false);
                        setDeleteMonths(false);
                        setDeleteYears(false);
                        setMonthsvalue("");
                        setNeverDelete(false);
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6 col-lg-2 mb-2">
                    <Form.Check
                      label={text_selectdate}
                      name="group1"
                      type="radio"
                      id="inline-radio-5"
                      style={{ fontSize: "large" }}
                      checked={deleteSelectDate}
                      onChange={() => {
                        setDeleteSelectDate(!deleteSelectDate);
                        setDeleteDays(false);
                        setDeleteMonths(false);
                        setDeleteYears(false);
                        setDaysvalue("");
                        setMonthsvalue("");
                        setNeverDelete(false);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2">
                  {deleteSelectDate && (
                    <>
                      {/* <DatePicker
                        selected={SelectDatevalue}
                        minDate={tomorrow}
                        onChange={(date) => setSelectDatevalue(date)}
                        dateFormat="MMM dd, yyyy"
                        className="form-control w-25"
                        placeholderText={text_selectdate}
                      /> */}

                      <input
                        type="date"
                        className="form-control w-25 w-md-50  mt-2 inputDateField"
                        value={typedDate}
                        min={tomorrow.toISOString().split("T")[0]}
                        onChange={handleTypedDateChange}
                        ref={inputRef}
                        onClick={() => {
                          if (inputRef.current) {
                            inputRef.current.showPicker();
                          }
                        }}
                      />
                      <div>
                        {text_deletedon}{" "}
                        <span className="fw-bold">
                          {formatDate(SelectDatevalue)}
                        </span>
                        .
                      </div>
                    </>
                  )}
                  {deleteDays && (
                    <>
                      <input
                        type="number"
                        className="form-control w-25 w-md-25 inputDateField"
                        placeholder={text_enternoofdays}
                        value={Daysvalue}
                        onChange={(e) => setDaysvalue(e.target.value)}
                      />
                      {Daysvalue && !isNaN(Daysvalue) && (
                        <div>
                          {text_deletedon}{" "}
                          <span className="fw-bold">
                            {formatDate(
                              calculateFutureDate(new Date(), Daysvalue, "days")
                            )}
                          </span>
                          .
                        </div>
                      )}
                    </>
                  )}
                  {deleteMonths && (
                    <>
                      <input
                        type="number"
                        className="form-control w-25 w-md-25 inputDateField"
                        placeholder={text_enternoofmonths}
                        value={Monthsvalue}
                        onChange={(e) => setMonthsvalue(e.target.value)}
                      />
                      {Monthsvalue && !isNaN(Monthsvalue) && (
                        <div>
                          {text_deletedon}{" "}
                          <span className="fw-bold">
                            {formatDate(
                              calculateFutureDate(
                                new Date(),
                                Monthsvalue,
                                "months"
                              )
                            )}
                          </span>
                          .
                        </div>
                      )}
                    </>
                  )}
                  {deleteYears && (
                    <>
                      <input
                        type="number"
                        className="form-control w-25 w-md-25 inputDateField"
                        placeholder={text_noofyears}
                        value={Yearsvalue}
                        onChange={(e) => setYearsvalue(e.target.value)}
                      />
                      {Yearsvalue && !isNaN(Yearsvalue) && (
                        <div>
                          {text_deletedon}{" "}
                          <span className="fw-bold">
                            {formatDate(
                              calculateFutureDate(
                                new Date(),
                                Yearsvalue,
                                "years"
                              )
                            )}
                          </span>
                          .
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Form>
            </div>
            {/* delete date end */}
            {/* ------Upload File Button---- */}
            <p className="errMsg text-center">{erruploadFile}</p>

            <div className="uf-btns">
              <div>
                <button
                  onClick={() => navigate(-1)}
                  className="uf-btn-cancel box-btn"
                >
                  {text_cancel}
                </button>
              </div>

              <div>
                <button
                  className=" upload-file-btn upload-file-btn-test"
                  onClick={handleUpload}
                  disabled={showuploadbtn}
                >
                  <span className="ts-lu-upload">
                    <LuUpload />
                  </span>
                  <span className="upload-file-btn-s2">
                    <>{text_uploadfile}</>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestMultiple;
