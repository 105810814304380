import React, { useState, useEffect, useMemo } from "react";
import folderImg from "../../utils/images/folderAWS.png";
import peopleimg from "../../utils/images/peopleimg.png";
import fileImg from "../../utils/images/file_1.png";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import {
  GLOBAL_URL_DOMAIN_File_Upload_Live,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../../utils/Files/url";
import axios from "axios";
import { FaShareAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Cookie from "js-cookie";
import { CgCloseO } from "react-icons/cg";
import { LuDownload } from "react-icons/lu";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  useTheme,
} from "@mui/material";
import {
  errorToast,
  successToast,
  InfoToast,
} from "../../utils/Files/toasterMsgs";
import { ToastContainer, toast } from "react-toastify";
import Loader3 from "../../utils/Files/data";
import { GlobalConstants } from "../../utils/globalconstant";
import Loader2 from "../../Components/Loader2/Loader2";
import { EMAIL_REGEX } from "../../utils/Files/validation";
import DataTable from "react-data-table-component";
import { customTableStylesFile } from "../../utils/Files/tableStyle";
import moment from "moment";
import FilterComponent from "../../utils/Files/FilterComponent";
import { getBucketData } from "../../redux/GetBucketdataSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  foderwiseAwsFiless,
  getAwsRootData,
} from "../../redux/AwsRootFolderSlice";
import { getAllUserData } from "../../redux/UserListSlice";
import { IoReload } from "react-icons/io5";
import Tooltip from "@mui/material/Tooltip";
import { resetUploadInfo } from "../../redux/UploadSlice";
import { rootApiCall } from "../../redux/MetaDataSlice";
import {
  fetchSharedByMeFiles,
  fetchSharedByMeFolders,
} from "../../redux/SharedByMeSlice";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;

const YourFilesAWS2 = () => {
  const atomS3Data = useSelector((state) => state.getBucketData.AtomS3Data);
  const conditionalRowStyles = [
    {
      when: (row) => row.calories < 300,
      style: {
        backgroundColor: "green",
        color: "white",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];
  //variable
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const awsrootdata = useSelector((state) => state.awsRootData.folderData);
  const awsrootdataFiles = useSelector((state) => state.awsRootData.filesData);
  const userData = useSelector((state) => state.comapanyUserList.userdata);
  const awsrootLoaderdata = useSelector(
    (state) => state.awsRootData.isLoadingRoot
  );
  const callAwsRootApi = useSelector((state) => state.metadata.callAwsRootApi);
  // console.log("jsxxxxxxxxxxxxxxxxxxxx 1", awsrootdata);
  // console.log("jsxxxxxxxxxxxxxxxxxxxxxx 2", awsrootdataFiles);

  var userID = sessionStorage.getItem("userID");
  var UserF_Name = sessionStorage.getItem("fName");
  var UserL_name = sessionStorage.getItem("lName");
  var CompanyID = sessionStorage.getItem("companyID");
  var UserEmailId = sessionStorage.getItem("email");
  const CompanyName =
    sessionStorage.getItem("companyName") === null ||
    sessionStorage.getItem("companyName") === undefined ||
    sessionStorage.getItem("companyName") === "null"
      ? ""
      : sessionStorage.getItem("companyName");
  const [awsFolderList, setAwsFolderList] = useState([]);
  // console.log(awsFolderList);
  const [viewBackBtn, setViewBackBtn] = useState(false);

  const [reqUrlCreate, setReqUrlCreate] = useState("");
  const [urlToShow, setUrlToShow] = useState("");

  const [filesData, setFilesData] = useState([]);

  const [leftFolders, setLeftFolders] = useState([]);
  const [enterUsername, setEnterUsername] = useState("");
  const [emailexits, setEmailexits] = useState(null);
  const [inviteuserPopup, setInviteuserPopup] = useState(false);
  const [fileOrfolder, setFileOrFolder] = useState("");
  const [bucketId, setBucketId] = useState("");
  const [awsBucketName, setAwsBucketName] = useState("");
  const [folderName, setFolderName] = useState("");
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [accessErr, setAccessErr] = useState("");

  const [Filename, setFileName] = useState("");
  const [accesspopup, setAccesspopup] = useState(false);
  const [allBucket, setAllBucket] = useState([]);
  const [loader, setLoader] = useState(false);
  const [loaderAccess, setLoaderAccess] = useState(false);

  const [loaderFolder, setLoaderFolder] = useState(false);

  const [errInviteuser, setErrInviteuser] = useState("");
  const [fileOrfolderData, setFileOrFolderData] = useState("");
  const [shareFileUniqID, setShareFileUniqID] = useState();
  const [folderId, setFolderId] = useState("");
  const [readAccess, setReadAccess] = useState(true);
  const [writeAccess, setWriteAccess] = useState(true);
  const [downloadAccess, setDownloadAccess] = useState(true);
  const [other_data_2, setother_data_2] = useState([]);
  const [folderArr, setfolderArr] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [shareDataPopup, setSharedDataPopup] = useState(false);
  const [errEmail, setErrEmail] = useState("");
  const [combinedData, setCombinedData] = useState([]);
  const [accessEmail, setAccessEmail] = useState([]);
  const [shareFileLoader, setShareFileLoader] = useState(false);
  // const [userData, setUserData] = useState([]);
  const [folderPrefix, setFolderPrefix] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [filterTextFile, setFilterTextFile] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [resetPaginationToggleFile, setResetPaginationToggleFile] =
    useState(false);
  const [aclOption, setAclOption] = useState("");
  const [folderNameForInsertRecord, setFolderNameForInsertRecord] =
    useState("");
  // console.log(folderPrefix);
  const closeAccessPopup = () => {
    setAccesspopup(false);
    setInviteuserPopup(false);
    setEnterUsername("");
    setAccessErr("");
    setErrInviteuser("");
    setFileName("");
    setEmailData([]);
    setInviteuserPopup(false);
    setFname("");
    setLname("");
    setEnterUsername("");
    setAccessErr("");
    setErrInviteuser("");
    setFileName("");
    setEmailData([]);
    setReadAccess(true);
    setWriteAccess(true);
    setDownloadAccess(true);
    setFilteredSuggestions([]);
  };

  // -----language starts ----- //
  const [text_read, setText_read] = useState("Read");
  const [text_write, setText_write] = useState("Write");
  const [text_download, setText_download] = useState("Download");
  const [text_validationemail, setText_validationemail] = useState(
    "Please Enter User Email"
  );
  const [text_currentlocation, setText_currentlocation] =
    useState("Current Location");
  const [text_selectbucket, setText_selectbucket] = useState(
    "Select Amazon S3 Bucket"
  );
  const [text_accesstype, setText_selectaccesstype] =
    useState("Select Access Type");
  const [text_back, setText_back] = useState("Back");
  const [text_nofolderorfiles, setText_nofolderorfiles] = useState(
    "No Folders or Files Here"
  );
  const [text_select_any_option, setText_select_any_option] = useState(
    "Please Select Any one Option"
  );
  const [text_Own_email_err, setText_Own_email_err] = useState(
    "You can not give access to your own email"
  );
  const [text_searchhere, setText_searchhere] = useState("Search Here...");
  const [text_done, setText_done] = useState("Done");
  const [text_peoplewithaccess, setText_peoplewithaccess] =
    useState("People With Access");
  const [text_skip, setText_skip] = useState("Skip");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_enteruseremail, setText_enteruseremail] =
    useState("Enter User Email");
  const [text_share, setText_share] = useState("Share");
  const [text_nofileshere, setText_nofileshere] = useState("No File Here");
  const [text_file, setText_File] = useState("File");
  const [text_nofolderhere, setText_nofolderhere] = useState("No Folder Here");
  const [text_folder, setText_folder] = useState("Folder");
  const [text_view, setText_view] = useState("View");
  const [text_dateofupload, setText_dateofupload] = useState("Date Of Upload");
  const [text_filename1, setText_filename1] = useState("File Name");
  const [text_foldername, setText_foldername] = useState("Folder Name");
  const [text_valemail, setText_valemail] = useState("*Enter valid email Id!");
  const [text_alemail, setText_alemail] = useState(
    "*Email has already been added!"
  );
  const [text_noticeemail, settext_noticeemail] = useState(
    "*You cannot give access to more than 50 emails at once!"
  );
  const [Web_server_text, setWeb_server_text] = useState(
    "Upload to Web Server in progress"
  );
  const [AWS_text, setAWS_text] = useState("Upload to AWS S3 in progress");
  const [upload_complete_text, setUpload_complete_text] =
    useState("Upload completed");
  const [text_Refresh, setText_Refresh] = useState("Refresh");

  // -----language end ----- //

  // ********** UploadInfo Starts **********
  // UI = Upload Info
  const UI_file_unique_id = useSelector(
    (state) => state.metadata.file_unique_id
  );
  const UI_chunkNo = useSelector((state) => state.singleUploadData.chunkNo);
  const UI_initialText = useSelector(
    (state) => state.singleUploadData.initialText
  );
  const [UI_ind, setUI_ind] = useState(null);
  const [UI_file, setUI_file] = useState({});

  const [UI_text, setUI_text] = useState("");

  const [UI_uploadingToServer, setUI_UI_uploadingToServer] = useState(true);
  const [UI_serverUploadDone, setUI_serverUploadDone] = useState(false);

  const [UI_uploadingToIpfs, setUI_uploadingToIpfs] = useState(false);
  const [UI_ipfsUploadDone, setUI_ipfsUploadDone] = useState(false);

  const [UI_RefreshVisibility, setUI_RefreshVisibility] = useState(false);
  useEffect(() => {
    sessionStorage.removeItem("uploadLocation");
  });
  useEffect(() => {
    if (UI_initialText === true) {
      setUI_text(`${Web_server_text}...`);
    }

    if (UI_chunkNo > 0) {
      setUI_RefreshVisibility(true);

      var uploadInfoFunc = async () => {
        try {
          const headerConfig = {
            headers: {
              authorization: "Bearer " + sessionStorage.getItem("userToken"),
            },
          };

          const API_URL =
            GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

          const data = {
            file_unique_id: UI_file_unique_id,
          };

          const res = await axios.post(API_URL, data, headerConfig);

          // console.log("uploadInfoFunc res***", res);

          if (
            res?.data?.success === true &&
            res?.data?.data?.isUploadDone_WebServer === true &&
            res?.data?.data?.uploadEndTime_WebServer !== null
          ) {
            // file uploaded to web server
            setUI_serverUploadDone(true);
            setUI_UI_uploadingToServer(false);
            dispatch(resetUploadInfo());
          }
        } catch (err) {
          // console.log("uploadInfoFunc catch block:", err);
        }
      };
      uploadInfoFunc();
    }
  }, [UI_chunkNo, UI_file_unique_id]);

  const handleRefresh = async () => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: UI_file_unique_id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      // console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        setUI_text(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_AWSS3 === false
      ) {
        // server upload is complete and file is uploading on ipfs
        setUI_text(`${AWS_text}...`);
        setUI_UI_uploadingToServer(false);
        setUI_serverUploadDone(true);
        setUI_uploadingToIpfs(true);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_AWSS3 === true &&
        res?.data?.data?.uploadEndTime_AWSS3 !== null
      ) {
        // ipfs upload is complete
        setUI_text(`${upload_complete_text}`);
        setUI_RefreshVisibility(false);
        setUI_UI_uploadingToServer(false);
        setUI_serverUploadDone(true);
        setUI_uploadingToIpfs(false);
        setUI_ipfsUploadDone(true);
      }
    } catch (err) {
      // console.log("handleRefresh catch block:", err);
    }
  };
  const checkStatusOnShare = async (id) => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      // console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_AWSS3 === false
      ) {
        // server upload is complete and file is uploading on ipfs
        setAccesspopup(true);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_AWSS3 === true &&
        res?.data?.data?.uploadEndTime_AWSS3 !== null
      ) {
        // ipfs upload is complete
        setAccesspopup(true);
      }
    } catch (err) {
      // console.log("handleRefresh catch block:", err);
    }
  };
  const checkStatusOnDownload = async (row, index) => {
    // console.log(row);
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: row.file_unique_id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      // console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_AWSS3 === false
      ) {
        // server upload is complete and file is uploading on ipfs
        handleDownload(row.fileType, row.fileNameWithUuid, index, row._id);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_AWSS3 === true &&
        res?.data?.data?.uploadEndTime_AWSS3 !== null
      ) {
        // ipfs upload is complete
        if (row.bucketACLOption === "public") {
          handleDownloadFile(
            row.amazonKey,
            row.fileName,
            index,
            row._id,
            row.fileType,
            row.bucketName
          );
        } else if (row.bucketACLOption === "private") {
          downloadPrivateS3File(
            row.bucketName,
            row.amazonKey,
            row.fileName,
            index,
            row._id,
            row.fileType
          );
        }
      }
    } catch (err) {
      // console.log("handleRefresh catch block:", err);
    }
  };
  const handleDownload = async (File_type, File_Name, row_id) => {
    const filename = File_Name;
    const fileType = File_type;
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-server`;

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        fileName: filename,
        fileType: fileType,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("stream_ipfs_server_download res***", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (res?.status === 200) {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      // console.log("stream_ipfs_server_download error***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };

  // ********** UploadInfo Ends **********

  // -------------------- AWS Starts --------------------

  var MainFolder = reqUrlCreate.split("/");
  // console.log(MainFolder);

  const handleSelectBucket = (val) => {
    // console.log(allBucket);
    setUrlToShow("");
    setReqUrlCreate("");
    setViewBackBtn("");
    setBucketId(val);
    if (allBucket.length > 0) {
      var BucketFinalName = allBucket.filter((e, i) => e.bucketId === val);
      // console.log(allBucket[0].bucketACLOption);
      setAclOption(allBucket[0].bucketACLOption);
      setLeftFolders([]);
      setAwsFolderList([]);
      setFilesData([]);
      if (BucketFinalName.length > 0) {
        setAwsBucketName(BucketFinalName[0].bucketName);

        // getAllDir_new(
        //   BucketFinalName[0].bucketId,
        //   BucketFinalName[0].bucketName
        // );
        dispatch(foderwiseAwsFiless(BucketFinalName[0]?.bucketId));
        dispatch(
          getAwsRootData(
            BucketFinalName[0]?.bucketId,
            BucketFinalName[0]?.bucketName
          )
        );
      }
    }
  };
  /////***** BACK to root-folder */
  const settingFolderData = async (
    folders,
    bucketname,
    bucketId,
    folder_name
  ) => {
    var foldername = "";
    // console.log(reqUrlCreate, "prefix", folders);
    if (
      reqUrlCreate === "" ||
      reqUrlCreate === undefined ||
      folder_name === undefined ||
      folder_name === ""
    ) {
      foldername = `${bucketname}/${userID}/`;
    } else {
      const ind_aws = folder_name.indexOf("/");
      const new_str_aws = folder_name.slice(ind_aws + 1);
      foldername = new_str_aws;
    }
    // console.log("settingFolderData folders***", foldername);
    // console.log("settingFolderData bucketname***", bucketname);

    // calling the childfolders API
    try {
      const API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/folder/child-folders?companyId=${CompanyID}&userId=${userID}&bucketId=${bucketId}&folderName=${foldername}`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const childFoldersAws = await axios.get(API_URL, headerConfig);

      // console.log("settingFolderData child folders***", childFoldersAws);
      const sortedData = folders.sort((a, b) => {
        // Assuming you want to sort by a property called "createdAt"
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setAwsFolderList(sortedData);
    } catch (err) {
      // console.log("child folders err***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  ////******Get Root Folder ******/
  const getAllDir_new = (bucketID, bucketname) => {
    setLoader(true);
    // console.log(bucketID, reqUrlCreate);

    const ind_aws = reqUrlCreate.lastIndexOf("/");
    const new_str_aws = reqUrlCreate.slice(0, ind_aws);
    const data_new = new_str_aws.lastIndexOf("/");
    const final_data = new_str_aws.slice(0, data_new + 1);
    // console.log(final_data);

    var folder_name = `${bucketname}/${userID}/`;
    if (bucketID !== "") {
      var data = {
        companyId: CompanyID,
        userId: userID,
        folderName: folder_name,
        bucketId: bucketID,
      };

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/all-child-folders-aws";

      axios
        .post(API_URL, data, headerConfig)
        .then((res) => {
          // console.log("add_folder_id_func res***", res);
          var Data = res?.data?.data;
          if (res?.status === 200) {
            settingFolderData(Data, bucketname, bucketID, folder_name);
            setLoader(false);
            setAwsBucketName(bucketname);
            setLeftFolders(Data);
            foderwiseAwsFiles(bucketID);
          }
        })
        .catch((err) => {
          setLoader(false);
          // console.log("add_folder_id_func err***", err);
          if (err?.response?.status === 427) {
            sessionStorage.clear();
            localStorage.clear();
            Cookie.remove("fName");
            Cookie.remove("lName");
            Cookie.remove("userID");
            Cookie.remove("userToken");
            Cookie.remove("token_start_time");
            navigate("/");
          }
        })
        .then(function () {});
    }
  };
  ////******Create Default folder******/
  const handleCreateDefaultFolder = (userId) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userId,
      folderName: `atom-storage/${userId}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("reg res***", res);
      })
      .catch((err) => {
        // console.log("reg err***", err);

        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  // Function to create a root folder for the user on AWS S3
  const handleCreateRootFolderS3 = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage/${userID}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("root folder aws res***", res);
      })
      .catch((err) => {
        // console.log("root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };

  // Function to create a private root folder for the user on AWS S3
  const handleCreateRootFolderS3_Private = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage-private/${userID}/`,
      bucketId: "d969487f-09fc-41b6-be90-2af9a16bd804",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-private-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("private root folder aws res***", res);
      })
      .catch((err) => {
        // console.log("private root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };
  ////******Folder vise file data******/
  const foderwiseAwsFiles = async (Bucket_ID) => {
    // console.log("test");
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      setLoader(true);
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/filedata-aws-folderwise?userId=${sessionStorage.getItem(
          "userID"
        )}&folderName=${userID}/&bucketId_aws=${Bucket_ID}`;

      let res = await axios.get(API_URL, headerConfig);
      // console.log("foderwiseAwsFiles res***", res);
      // Sort the data in descending order based on a specific property
      const sortedData = res?.data?.data?.sort((a, b) => {
        // Assuming you want to sort by a property called "createdAt"
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setFilesData(sortedData);
      setLoader(false);
    } catch (err) {
      // console.log("foderwiseAwsFiles err", err);
      setLoader(false);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        navigate("/");
      }
    }
  };
  ////******Current location show logic ******/
  const ReqUrlCreateToShow = (url) => {
    var newUrl = url.split("/");
    // console.log(newUrl);
    setFolderPrefix(url);
    const urlWithImages = newUrl.slice(0, -1).map((folder, index) => (
      <span key={index}>
        {folder === userID || folder === awsBucketName ? (
          ""
        ) : (
          <>
            {index > 1 && " > "}{" "}
            <img
              src={folderImg}
              alt=""
              style={{ height: "22px", width: "22px" }}
            />{" "}
            {folder}
          </>
        )}
      </span>
    ));

    if (newUrl.length > 0) {
      urlWithImages.push(newUrl[newUrl.length - 1]);
    }
    setUrlToShow(urlWithImages);
  };
  ////******Get child Folder ******/
  const getAllSubDir_new = (folderName) => {
    // console.log(folderName);
    const str_aws = folderName;
    const ind_aws = str_aws.indexOf("/");
    const new_str_aws = str_aws.slice(ind_aws + 1);

    var data = {
      companyId: CompanyID,
      userId: userID,
      folderName: folderName,
      bucketId: bucketId,
    };

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/all-child-folders-aws";

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("add_folder_id_func res***", res);
        var Data = res?.data?.data;
        if (res?.status === 200) {
          // console.log(Data, awsBucketName, bucketId, folderName);
          setReqUrlCreate(folderName);
          settingFolderData(Data, awsBucketName, bucketId, folderName);

          setViewBackBtn(true);
          ReqUrlCreateToShow(new_str_aws);
        }
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ////******back button logic ******/
  const handleBack = () => {
    // console.log("reqUrlCreate in back*****", reqUrlCreate);
    const str_aws = reqUrlCreate;
    const ind_aws = str_aws.lastIndexOf("/");
    const new_str_aws = str_aws.slice(0, ind_aws);
    const str_data = new_str_aws.lastIndexOf("/");
    const back_path = new_str_aws.slice(0, str_data + 1);
    // console.log("handleBackArr", reqUrlCreate.split("/"));
    const handleBackArr = reqUrlCreate.split("/");
    // console.log(handleBackArr.length > 4);
    if (handleBackArr.length > 4) {
      getAllSubDir_new(back_path);

      // console.log(back_path);
      foderwiseAwsFilesRight2(back_path);
    } else {
      setReqUrlCreate("");
      setUrlToShow("");

      setLeftFolders([]);
      setViewBackBtn(false);
      setAwsFolderList(awsrootdata);
      setLeftFolders(awsrootdata);
      setFilesData(awsrootdataFiles);
      // getAllDir_new(bucketId, awsBucketName);
    }
  };
  ////******Folder vise file data rigthside folder ******/
  const foderwiseAwsFilesRight2 = async (foldername) => {
    const ind_data = foldername.indexOf("/");
    const Final_data = foldername.slice(ind_data + 1);
    const F_name =
      foldername === "" || foldername === null ? foldername : Final_data;
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      setLoader(true);
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/filedata-aws-folderwise?userId=${sessionStorage.getItem(
          "userID"
        )}&folderName=${F_name}&bucketId_aws=${bucketId}`;

      let res = await axios.get(API_URL, headerConfig);
      // console.log("foderwiseAwsFilesRight2 res***", res);
      const sortedData = res?.data?.data?.sort((a, b) => {
        // Assuming you want to sort by a property called "createdAt"
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setFilesData(sortedData);
      setLoader(false);
    } catch (err) {
      // console.log("foderwiseAwsFilesRight2 err***", err);
      setLoader(false);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  ////******Folder vise file data rigthside folder ******/
  const foderwiseAwsFilesRight = async (v) => {
    const ind_data = v?.folderName.indexOf("/");
    const Final_data = v?.folderName.slice(ind_data + 1);
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };
      setLoader(true);
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file/filedata-aws-folderwise?userId=${sessionStorage.getItem(
          "userID"
        )}&folderName=${Final_data}&bucketId_aws=${bucketId}`;

      let res = await axios.get(API_URL, headerConfig);
      // console.log("foderwiseAwsFilesRight res***", res);
      const sortedData = res?.data?.data?.sort((a, b) => {
        // Assuming you want to sort by a property called "createdAt"
        return new Date(b.createdAt) - new Date(a.createdAt);
      });

      setFilesData(sortedData);
      setLoader(false);
    } catch (err) {
      // console.log("foderwiseAwsFilesRight err***", err);
      setLoader(false);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        navigate("/");
      }
    }
  };
  // -------------------- Access popup start --------------------
  const handleSingleUser = () => {
    if (emailexits === "") {
      setErrEmail(<>{text_validationemail}</>);
    } else {
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);
        setEmailexits("");
        handleGetUser(value);
      }
    }
  };
  ////******give access enter email is register or not */
  const handleGetUser = (value) => {
    if (alreadyemail.length > 0) {
      setErrEmail(<>{text_alemail}</>);
    } else {
      var FinalEmail = [];
      if (emailData.length === 0) {
        FinalEmail.push({ email: [value] });
      } else {
        FinalEmail.push({ email: emailData });
      }
      // console.log(FinalEmail[0].email);
      if (FinalEmail.length === 0) {
        setErrEmail(<>{text_validationemail}</>);
      } else {
        // for (let i = 0; FinalEmail[0].email.length > i; i++) {
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        // var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/emailexist`;
        // var data = {
        //   EmailOrPhone: FinalEmail[0].email[i].toLowerCase(),
        // };
        var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/email-exists`;
        var data = {
          emails: FinalEmail[0].email,
        };
        axios
          .post(API_URL, data, headerConfig)
          .then((res) => {
            // var val = res?.data?.data;
            if (res?.status === 200) {
              // if (fileOrfolder === "File") {
              //   GiveAccessToUser(FinalEmail[0].email[i], val._id);
              // } else {
              //   give_aws_folder_access(FinalEmail[0].email[i], val._id);
              // }
              if (res?.data.data.found.length > 0) {
                for (let i = 0; res?.data.data.found.length > i; i++) {
                  if (fileOrfolder === "File") {
                    GiveAccessToUser(
                      res?.data.data.found[i].email,
                      res?.data.data.found[i].userId
                    );
                  } else {
                    give_aws_folder_access(
                      res?.data.data.found[i].email,
                      res?.data.data.found[i].userId
                    );
                  }
                }
              }
              if (res?.data.data.notFound.length > 0) {
                for (let i = 0; res?.data.data.notFound.length > i; i++) {
                  if (fileOrfolder === "File") {
                    handleInviteUser(res?.data.data.notFound[i]);
                  } else {
                    give_aws_invite_folder_access(res?.data.data.notFound[i]);
                  }
                }
              }
            }
          })
          .catch((err) => {
            // console.log("get data ipfs err***", err);
            // if (fileOrfolder === "File") {
            //   handleInviteUser(FinalEmail[0].email[i]);
            // } else {
            //   give_aws_invite_folder_access(FinalEmail[0].email[i]);
            // }
            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          })
          .then(function () {});
        // }
      }
    }
  };
  ////******invite not register user access for file */
  const handleInviteUser = (email, lastIndex) => {
    setShareFileLoader(true);
    var otherDataAWS = [
      [fileOrfolderData.file_unique_id, fileOrfolderData.folderId],
    ];
    var otherDataIPFS = [
      [fileOrfolderData.file_unique_id, fileOrfolderData.ipfsFolderId],
    ];
    var AWSBucketId = fileOrfolderData.bucketId_aws;
    var IPFSbucketId = fileOrfolderData.bucketId_ipfs;
    var isIPFS = fileOrfolderData.isIPFS;
    var isAws = fileOrfolderData.isAmazon;
    var FileName = fileOrfolderData.fileName;
    var companyID = sessionStorage.getItem("companyID");
    // console.log(otherDataAWS);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/inviteuser-for-access`;

    var data = {
      firstName: Fname,
      lastName: Lname,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      company_id: companyID, // company that is giving the access (send null when no company_id)
      bucket_id: AWSBucketId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      is_aws: true,
      is_ipfs: false,
      other_data_aws: otherDataAWS, // [ [file_unique_id, folder_id], [file_unique_id, folder_id], [file_unique_id, folder_id] ]

      bucket_id_ipfs: IPFSbucketId,
      other_data_ipfs: otherDataIPFS, // [ [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs] ]
      sender_user_id: sessionStorage.getItem("userID"),
      share_location_aws: "10",
      share_location_ipfs: null,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        var val = res?.data?.data;
        if (res?.status === 200) {
          handleCreateDefaultFolder(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3_Private(res?.data?.data?.registered_user_id);
          if (emailData.length === 0) {
            setShareFileLoader(false);
            successToast(res?.data?.message);
            closeAccessPopup();
            if (reqUrlCreate === "" || reqUrlCreate === undefined) {
              getAllDir_new(bucketId, awsBucketName);
            } else {
              getAllSubDir_new(reqUrlCreate);
            }
          }
          if (emailData.slice(-1)[0] === email) {
            setShareFileLoader(false);
            successToast(res?.data?.message);
            closeAccessPopup();
            if (reqUrlCreate === "" || reqUrlCreate === undefined) {
              getAllDir_new(bucketId, awsBucketName);
            } else {
              getAllSubDir_new(reqUrlCreate);
            }
          }
          dispatch(fetchSharedByMeFiles("aws", AWSBucketId));
        }
      })
      .catch((err) => {
        // console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  ////******invite not register user access for folder */
  const give_aws_invite_folder_access = async (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    // console.log(other_data_2);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/inviteuser-for-folder-access`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        firstName: "",
        lastName: "",
        email: email,
        companyName: CompanyName,
        sender_firstname: UserF_Name,
        sender_lastname: UserL_name,
        sender_user_id: userID,
        is_aws: true,
        is_ipfs: false,
        company_id: CompanyID,
        db_version: "v1",
        bucket_id: bucketId,
        other_data_aws: other_data_2,
        folderArr_aws: folderArr,
        bucket_id_ipfs: "",
        other_data_ipfs: [],
        folderArr_ipfs: [],
        read_access_aws: readAccess,
        write_access_aws: writeAccess,
        download_access_aws: downloadAccess,
        read_access_ipfs: false,
        write_access_ipfs: false,
        download_access_ipfs: false,
        share_location_aws: "10",
      };

      let res = await axios.post(API_URL, data, headerConfig);

      // console.log("give_ipfs_folder_access res***", res);
      // console.log(reqUrlCreate);
      if (res.status === 200) {
        handleCreateDefaultFolder(res?.data?.data?.registered_user_id);
        handleCreateRootFolderS3(res?.data?.data?.registered_user_id);
        handleCreateRootFolderS3_Private(res?.data?.data?.registered_user_id);
      }
      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();
        successToast(res?.data?.message);
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getAllDir_new(bucketId, awsBucketName);
        } else {
          getAllSubDir_new(reqUrlCreate);
        }
      }
      if (emailData.length === 0) {
        closeAccessPopup();
        successToast(res?.data?.message);
        setShareFileLoader(false);
        // console.log(reqUrlCreate);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getAllDir_new(bucketId, awsBucketName);
        } else {
          getAllSubDir_new(reqUrlCreate);
        }
      }
      dispatch(fetchSharedByMeFiles("aws", bucketId));
      dispatch(fetchSharedByMeFolders("aws", bucketId, awsBucketName));
    } catch (err) {
      // console.log(
      //   "give_ipfs_folder_access err***",
      //   err?.response?.data?.message
      // );
      if (emailData.slice(-1)[0] === email) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
      }
    }
  };
  ////******give register user access for file */
  const GiveAccessToUser = (email, userID_to, lastIndex) => {
    // console.log(readAccess);
    // console.log(folderNameForInsertRecord);

    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/file-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: shareFileUniqID,
      folder_id: folderId,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: bucketId,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: Fname,
      lastName: Lname,
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      to_both: false,
      sender_user_id: sessionStorage.getItem("userID"),
      folder_name: folderNameForInsertRecord,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("Access Given", res);
        if (res?.data?.success === true) {
          if (emailData.length === 0) {
            successToast(res?.data?.message);
            closeAccessPopup();
            setShareFileLoader(false);
            setFolderId("");
          }
          if (emailData.slice(-1)[0] === email) {
            successToast(res?.data?.message);
            closeAccessPopup();
            setShareFileLoader(false);
            setFolderId("");
          }
          dispatch(fetchSharedByMeFiles("aws", bucketId));
        }
      })
      .catch((err) => {
        // console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  ////*****Select Access To give [Read, Write, download] */
  const AccessValueHandle = (val) => {
    if (val === "read") {
      setReadAccess(true);
      setWriteAccess(false);
      setDownloadAccess(false);
    } else if (val === "write") {
      setWriteAccess(true);
      setReadAccess(true);
      setDownloadAccess(false);
    } else if (val === "download") {
      setDownloadAccess(true);
      setWriteAccess(true);
      setReadAccess(true);
    }
  };
  ////******Give access to AWS folder[register user] */
  const give_aws_folder_access = async (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/give-folder-access-aws`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        company_id: CompanyID,
        company_user_id: userID,
        user_id: userID_to,
        db_version: "v1",
        other_data_2: other_data_2,
        bucketId: bucketId,
        read_access: readAccess,
        write_access: writeAccess,
        download_access: downloadAccess,
        email: email,
        companyName: CompanyName,
        firstName: "",
        lastName: "",
        sender_firstname: UserF_Name,
        sender_lastname: UserL_name,
        folderArr: folderArr,
        share_location: "10",
      };

      let res = await axios.post(API_URL, data, headerConfig);

      // console.log("give_aws_folder_access res***", res);
      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();
        successToast(res?.data?.message);
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getAllDir_new(bucketId, awsBucketName);
        } else {
          getAllSubDir_new(reqUrlCreate);
        }
      }
      if (emailData.length === 0) {
        closeAccessPopup();
        successToast(res?.data?.message);
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getAllDir_new(bucketId, awsBucketName);
          // getAllDir_new(bucketId, awsBucketName);
        } else {
          getAllSubDir_new(reqUrlCreate);
        }
      }
      dispatch(fetchSharedByMeFiles("aws", bucketId));
      dispatch(fetchSharedByMeFolders("aws", bucketId, awsBucketName));
    } catch (err) {
      // console.log(
      //   "give_aws_folder_access err***",
      //   err?.response?.data?.message
      // );
      if (emailData.slice(-1)[0] === email) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
      }
    }
  };
  ////******add email in to emailData Array */
  const EmailHandler = async (event) => {
    const value = event.target.value;
    setEmailexits(value.toLowerCase());
    setErrEmail("");
    if (value === "") {
      setFilteredSuggestions([]);
      setDisplaySuggestions(false);
      return;
    }
    const filteredSuggestions = userData.filter(
      (suggestion, i) =>
        suggestion.userEmail.toLowerCase().includes(value.toLowerCase()) &&
        suggestion.userEmail.toLowerCase() !== UserEmailId
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };
  ////******delet email from array list*/
  const handleDelete = (participants1) => {
    setEmailData(emailData.filter((item, i) => i !== participants1));
  };
  ////** Push email on array using key func */
  const handleKeyDown = (e) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);
        setEmailexits("");
      }
    }
  };
  ////******validation fro email start*/
  const alreadyemail = emailData.filter(
    (item, index) => emailData.indexOf(item) !== index
  );
  function isInList(value) {
    // console.log(emailData.includes(value));
    return emailData.includes(value);
  }
  function isInList_ownemail(value) {
    return emailData.includes(value);
  }
  function isValid(value) {
    if (!EMAIL_REGEX.test(emailexits)) {
      setErrEmail(<>{text_valemail}</>);
      return false;
    } else {
      setErrEmail("");
    }
    if (emailexits == UserEmailId || isInList_ownemail(UserEmailId)) {
      setErrEmail(<>*{text_Own_email_err}!</>);
      return false;
    } else {
      setErrEmail("");
    }
    if (isInList(emailexits)) {
      setErrEmail(<>{text_alemail}</>);
      return false;
    } else {
      setErrEmail("");
    }
    if (emailData.length > 51) {
      setErrEmail(<>{text_noticeemail}</>);
      return false;
    } else {
      setErrEmail("");
    }
    return true;
  }
  ////******validation fro email end*/
  // -------------------- Access popup end --------------------
  /////***** Get Folder data to share button click*////
  const get_required_folder_data = async (folder_name) => {
    // console.log(folder_name);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/folder/all-child-folders-files-aws`;

      const data = {
        companyId: CompanyID,
        userId: userID, // user who is logged in currently
        folderName: [`${folder_name}`],
        bucketId: bucketId,
        bucketName: awsBucketName, // atom-storage
      };

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let res = await axios.post(API_URL, data, headerConfig);

      // console.log("get_required_folder_data res***", res);
      setother_data_2(res?.data?.other_data_2);
      setfolderArr(res?.data?.folderArr);
    } catch (err) {
      // console.log("get_required_folder_data err***", err);
    }
  };
  ////***** Get All AWS Bucket */////
  useEffect(() => {
    if (awsrootdata.length !== 0) {
      // Sort the data in descending order based on a specific property
      const testArr = [...awsrootdata];
      const sortedData = testArr.sort((a, b) => {
        //   // Assuming you want to sort by a property called "createdAt"
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setAwsFolderList(sortedData);
      setLeftFolders(sortedData);
    }
  }, [awsrootdata]);
  useEffect(() => {
    if (awsrootdataFiles.length !== 0) {
      setFilesData(awsrootdataFiles);
    }
  }, [awsrootdataFiles]);

  useEffect(() => {
    getBucket();
  }, [atomS3Data?.length, callAwsRootApi]);

  const getBucket = () => {
    if (atomS3Data?.length === 0) {
      dispatch(getBucketData());
    } else {
      var Val = atomS3Data;
      // console.log("read file res***", Val[0]?.bucketId);
      // console.log(callAwsRootApi);
      if (callAwsRootApi === true) {
        dispatch(foderwiseAwsFiless(Val[0]?.bucketId));
        dispatch(getAwsRootData(Val[0]?.bucketId, Val[0]?.bucketName));
        dispatch(rootApiCall());
      }

      if (Val.length > 0) {
        setAllBucket(Val);
        setBucketId(Val[0]?.bucketId);
        setAwsBucketName(Val[0]?.bucketName);
        // console.log("aws", awsrootdata);
        if (awsrootdata?.length === 0) {
          dispatch(
            getAwsRootData(Val[0]?.bucketId, Val[0]?.bucketName, reqUrlCreate)
          );
        }

        // getAllDir_new(Val[0]?.bucketId, Val[0]?.bucketName);
        setAwsBucketName(Val[0]?.bucketName);
        setLeftFolders(awsrootdata);
        if (awsrootdataFiles?.length === 0) {
          dispatch(foderwiseAwsFiless(Val[0]?.bucketId));
        }
        // foderwiseAwsFiles(Val[0]?.bucketId);
        else {
          setFilesData(awsrootdataFiles);
        }
      }
    }
  };
  // const getBucket = () => {

  //   let headerConfig = {
  //     headers: {
  //       authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //     },
  //   };

  //   var API_URL =
  //     GLOBAL_URL_DOMAIN_LIVE + `/company/get-buckets?userId=${userID}`;

  //   axios
  //     .get(API_URL, headerConfig)
  //     .then((res) => {
  //       var Val = res.data.data;
  //       console.log("read file res***", Val[0]?.bucketId);
  //       if (Val.length > 0) {
  //         setAllBucket(Val);
  //         setBucketId(Val[0]?.bucketId);
  //         setAwsBucketName(Val[0]?.bucketName);

  //         getAllDir_new(Val[0]?.bucketId, Val[0]?.bucketName);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("read file err***", err);
  //       if (err?.response?.status === 427) {
  //         sessionStorage.clear();
  //         localStorage.clear();
  //         Cookie.remove("fName");
  //         Cookie.remove("lName");
  //         Cookie.remove("userID");
  //         Cookie.remove("userToken");
  //         Cookie.remove("token_start_time");
  //         navigate("/");
  //       }
  //     })
  //     .then(function () {});
  // };

  ///***** user with access data for file*////
  const userAccessData = async (fileUniqueId, row_id) => {
    setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/file-access/users-with-file-access`;

      const data = {
        file_unique_id: fileUniqueId,
        sender_user_id: userID,
      };

      let res = await axios.post(API_URL, data, headerConfig);
      // console.log("userAccessData res***", res);

      if (res?.data?.data !== null) {
        setSharedDataPopup(true);
        setCombinedData(res?.data?.data);
        setAccessEmail(res?.data?.userData);
      }
      setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    } catch (err) {
      setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  ///***** user with access data for folder*////
  const userAccessData_folder = async (folderID, row_id) => {
    setLoaderFolder((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/file-access/users-with-folder-access?f_id=${folderID}`;

      let res = await axios.get(API_URL, headerConfig);
      // console.log("userAccessData res***", res);
      if (res?.data?.data !== null) {
        setSharedDataPopup(true);

        setCombinedData(res?.data?.data_2);
        setAccessEmail(res?.data?.userData_2);
      }
      setLoaderFolder((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    } catch (err) {
      setLoaderFolder((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  ////******Language start */
  useEffect(() => {
    try {
      SetLanguageText();
      setLoader(false);
    } catch (err) {
      console.log("" + err);
      setLoader(false);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setText_currentlocation(
        xpath.select1("/resources/string[@name='text_currentlocation']", doc)
          .firstChild.data
      );
      setText_read(
        xpath.select1("/resources/string[@name='text_read']", doc).firstChild
          .data
      );
      setText_write(
        xpath.select1("/resources/string[@name='text_write']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );

      setText_validationemail(
        xpath.select1("/resources/string[@name='text_validationemail']", doc)
          .firstChild.data
      );
      setText_selectbucket(
        xpath.select1("/resources/string[@name='text_selectbucket']", doc)
          .firstChild.data
      );

      setText_selectaccesstype(
        xpath.select1("/resources/string[@name='text_accesstype']", doc)
          .firstChild.data
      );

      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
      setText_select_any_option(
        xpath.select1("/resources/string[@name='text_select_any_option']", doc)
          .firstChild.data
      );
      setText_nofolderorfiles(
        xpath.select1("/resources/string[@name='text_nofolderorfiles']", doc)
          .firstChild.data
      );
      setText_Own_email_err(
        xpath.select1("/resources/string[@name='text_Own_email_err']", doc)
          .firstChild.data
      );
      setText_searchhere(
        xpath.select1("/resources/string[@name='text_searchhere']", doc)
          .firstChild.data
      );
      setText_done(
        xpath.select1("/resources/string[@name='text_done']", doc).firstChild
          .data
      );
      setText_peoplewithaccess(
        xpath.select1("/resources/string[@name='text_peoplewithaccess']", doc)
          .firstChild.data
      );
      setText_skip(
        xpath.select1("/resources/string[@name='text_skip']", doc).firstChild
          .data
      );
      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_enteruseremail(
        xpath.select1("/resources/string[@name='text_enteruseremail']", doc)
          .firstChild.data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
      setText_nofileshere(
        xpath.select1("/resources/string[@name='text_nofileshere']", doc)
          .firstChild.data
      );
      setText_File(
        xpath.select1("/resources/string[@name='text_file']", doc).firstChild
          .data
      );
      setText_nofolderhere(
        xpath.select1("/resources/string[@name='text_nofolderhere']", doc)
          .firstChild.data
      );
      setText_folder(
        xpath.select1("/resources/string[@name='text_folder']", doc).firstChild
          .data
      );
      setText_view(
        xpath.select1("/resources/string[@name='text_view']", doc).firstChild
          .data
      );
      setText_dateofupload(
        xpath.select1("/resources/string[@name='text_dateofupload']", doc)
          .firstChild.data
      );
      setText_filename1(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );
      setText_foldername(
        xpath.select1("/resources/string[@name='text_foldername']", doc)
          .firstChild.data
      );
      setText_valemail(
        xpath.select1("/resources/string[@name='text_valemail']", doc)
          .firstChild.data
      );
      setText_alemail(
        xpath.select1("/resources/string[@name='text_alemail']", doc).firstChild
          .data
      );
      settext_noticeemail(
        xpath.select1("/resources/string[@name='text_noticeemail']", doc)
          .firstChild.data
      );
      setWeb_server_text(
        xpath.select1("/resources/string[@name='Web_server_text']", doc)
          .firstChild.data
      );
      setAWS_text(
        xpath.select1("/resources/string[@name='AWS_text']", doc).firstChild
          .data
      );
      setUpload_complete_text(
        xpath.select1("/resources/string[@name='upload_complete_text']", doc)
          .firstChild.data
      );
      setText_Refresh(
        xpath.select1("/resources/string[@name='text_Refresh']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };
  ////******Language end */
  ////******get all company added user */
  // const getAllUserData = () => {
  //   if (
  //     CompanyID === undefined ||
  //     CompanyID === "null" ||
  //     CompanyID === "" ||
  //     CompanyID === null
  //   ) {
  //     console.log("Not A Company");
  //   } else {
  //     var API_URL =
  //       GLOBAL_URL_DOMAIN_LIVE + `/company/user-count?companyId=${CompanyID}`;

  //     let headerConfig = {
  //       headers: {
  //         authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //       },
  //     };

  //     axios
  //       .get(API_URL, headerConfig)
  //       .then(function (response) {
  //         var data = response.data.companyUsers;
  //         console.log("User Count", data);
  //         var FinalData = data.filter((val) => {
  //           if (val.userID === undefined || val.userID === null) {
  //             console.log(val.userID);
  //           } else {
  //             return val.userID.email !== UserEmailId;
  //           }
  //         });
  //         setUserData(FinalData);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         if (error.response.status === 427) {
  //           sessionStorage.clear();
  //           localStorage.clear();
  //           Cookie.remove("fName");
  //           Cookie.remove("lName");
  //           Cookie.remove("userID");
  //           Cookie.remove("userToken");
  //           Cookie.remove("token_start_time");
  //           setTimeout(() => {
  //             navigate("/");
  //           });
  //         }
  //         errorToast(error.response.data.message);
  //       })
  //       .then(function () {});
  //   }
  // };
  useEffect(() => {
    if (userData?.length === 0) {
      dispatch(getAllUserData());
    }
    // getAllUserData();
  }, [userData?.length]);

  const onSelectSuggestion = (index) => {
    // console.log(index);
    setEmailData([...emailData, filteredSuggestions[index].userEmail]);
    setEmailexits("");
    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };
  ////******Datatable shown data for folder */
  const ColumnFolder = [
    {
      name: <>{text_foldername}</>,
      selector: (row) => (
        <>
          <div className="d-flex">
            {/* {console.log(row)} */}
            <div
              className="show-view-div test__table-icon-selected uf-folder-aws"
              title={row?.folderName.slice(
                row?.folderName.lastIndexOf(
                  "/",
                  row?.folderName.lastIndexOf("/") - 1
                ) + 1,
                row?.folderName?.length - 1
              )}
              onClick={() => handleChangeFolder(row)}
            >
              <img src={folderImg} alt="" className="yf-aws2-folderImg" />{" "}
              {row?.folderName.slice(
                row?.folderName.lastIndexOf(
                  "/",
                  row?.folderName.lastIndexOf("/") - 1
                ) + 1,
                row?.folderName?.length - 1
              )}
            </div>
            <span className="mx-2">
              {row?.shared_count > 0 && (
                <button
                  onClick={() => [
                    userAccessData_folder(row?.folderId, row._id),
                  ]}
                  className="shared-data-email"
                  title="Shared"
                >
                  {loaderFolder[row._id] ? (
                    <Loader2 className="mx-5" />
                  ) : (
                    <img src={peopleimg} alt="" className="peopleimg" />
                  )}
                </button>
              )}
            </span>
          </div>
        </>
      ),
      width: "400px",
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => (
        <>
          <div className="show-view-div">
            {moment(row?.createdAt).format("MMM DD, yyyy")}
          </div>
        </>
      ),
      width: "380px",
    },

    {
      name: <>{text_share}</>,
      selector: (row) => (
        <>
          <button
            className="share-icon-table mx-3"
            onClick={() => [
              setAccesspopup(true),
              get_required_folder_data(row?.folderName),
              setFileOrFolder("Folder"),
              // console.log(row?.folderName),
              setFolderName(
                row?.folderName.slice(
                  row?.folderName.lastIndexOf(
                    "/",
                    row?.folderName.lastIndexOf("/") - 1
                  ) + 1,
                  row?.folderName?.length - 1
                )
              ),
            ]}
            title="Share"
          >
            <FaShareAlt className="share-sty" />
          </button>
        </>
      ),
    },
  ];
  ////******On Folder click view folder func */
  const handleChangeFolder = (state) => {
    // console.log(state);
    var folder = state?.folderName.indexOf("/");
    var Final_f = state?.folderName.slice(folder + 1);
    // getAllSubDir(state?.Prefix, "view");
    getAllSubDir_new(state?.folderName);
    foderwiseAwsFilesRight(state);
    ReqUrlCreateToShow(Final_f);
  };
  const filteredItems = awsFolderList.filter(
    (item) =>
      JSON.stringify(item.folderName)
        .toLowerCase()
        .indexOf(filterText.toLowerCase()) !== -1
  );
  ////******Datatable shown data for file */
  const ColumnFile = [
    {
      name: <>{text_filename1}</>,
      selector: (row, i) => (
        <>
          <div className="d-flex">
            <img src={fileImg} alt="" className="yf-aws2-fileImg" />
            <div
              className="folder-name-sty uf-folder-aws"
              title={row?.fileName}
              onClick={() => handleChange(row)}
            >
              {row?.fileName}
            </div>
            <span className="mx-3">
              {Number(row?.shared_count) !== 0 && (
                <button
                  onClick={() => [
                    userAccessData(row?.file_unique_id, row._id),
                    setFileName(row?.fileName),
                  ]}
                  className="shared-data-email"
                  title="Shared"
                >
                  {loaderAccess[row?._id] ? (
                    <Loader2 className="mx-5" />
                  ) : (
                    <img src={peopleimg} alt="" className="peopleimg" />
                  )}
                </button>
              )}
            </span>
            <span>
              {i === UI_ind && (
                <b>
                  {UI_text}{" "}
                  {UI_RefreshVisibility && (
                    <Tooltip title={text_Refresh} arrow onClick={handleRefresh}>
                      <button className="refreshtooltip" title={text_Refresh}>
                        <IoReload />
                      </button>
                    </Tooltip>
                  )}
                </b>
              )}
            </span>
          </div>
        </>
      ),
      width: "400px",
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => (
        <>
          <div className="show-view-div">
            {moment(row?.createdAt).format("MMM DD, yyyy")}
          </div>
        </>
      ),
      width: "380px",
    },

    {
      name: <>{text_share}</>,
      selector: (row) => (
        <>
          <button
            className="share-icon-table mx-3"
            onClick={() => [
              checkStatusOnShare(row?.file_unique_id),
              // setAccesspopup(true),
              setFileName(row?.fileName),
              setShareFileUniqID(row?.file_unique_id),
              setFolderId(row?.folderId),
              setFileOrFolder("File"),
              setBucketId(row?.bucketId_aws),
              setFileOrFolderData(row),
              setFolderNameForInsertRecord(
                `${row?.bucketName}/${row?.folderName}`
              ),
            ]}
            title="Share"
          >
            <FaShareAlt className="share-sty" />
          </button>
        </>
      ),
      width: "80px",
    },
    {
      name: <>{text_download}</>,
      selector: (row, index) => (
        <>
          <button
            className="share-icon-table mx-3"
            // onClick={() => [
            //   row.bucketACLOption === "public"
            //     ? handleDownloadFile(
            //         row.amazonKey,
            //         row.fileName,
            //         index,
            //         row._id,
            //         row.fileType,
            //         row.bucketName
            //       )
            //     : downloadPrivateS3File(
            //         row.bucketName,
            //         row.amazonKey,
            //         row.fileName,
            //         index,
            //         row._id,
            //         row.fileType
            //       ),
            // ]}
            onClick={() => {
              checkStatusOnDownload(row, index);
            }}
          >
            {loader[row?._id] ? (
              <Loader2 className="mx-5" />
            ) : (
              <LuDownload
                className=""
                style={{
                  color: "#009087",
                  width: "25px",
                  height: "25px",
                  paddingLeft: "-70px",
                }}
              />
            )}
          </button>
        </>
      ),
    },
  ];
  ////******On file click view file func */
  const handleChange = (state) => {
    navigate("/view-file", {
      state: {
        fileData: state,
        selecttab: 1,
      },
    });
  };
  ////******download file public bucket */
  const handleDownloadFile = async (
    File_key,
    FileFinalName,
    i,
    row_id,
    file_type,
    bucket_aws
  ) => {
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucket_aws; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("response****", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (link.href !== "") {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      // console.log("stream_s3_download error:", err);
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };
  ////******download file private bucket */
  const downloadPrivateS3File = async (
    bucketName,
    File_key,
    FileFinalName,
    i,
    row_id,
    file_type
  ) => {
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    const key = File_key; // variable 1
    const fileType = file_type; // variable 2 (send "" when no fileType)
    const fileName = FileFinalName; // variable 3
    const bucket = bucketName; // variable 4

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-awss3-private`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        key: key,
        fileType: fileType,
        bucket: bucket,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("response****", res);

      const blobData = new Blob([res?.data], { type: fileType });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (link.href !== "") {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      // console.log("stream_s3_download error:", err);
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };
  const filteredItemsFiles = filesData.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterTextFile.toLowerCase()) !== -1
  );

  useEffect(() => {
    const ind = filteredItemsFiles.findIndex(
      (v) => v.file_unique_id === UI_file_unique_id
    );
    if (ind !== -1) {
      setUI_ind(ind);
      setUI_file(filteredItemsFiles[ind]);
    }
  }, [filteredItemsFiles, UI_file_unique_id]);

  ////******search file or folder */
  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          PlaceHolder={text_searchhere}
          className="fc-input-aws-ipfs res-search-input"
          clearClass="fc-x-aws-ipfs"
        />
      </>
    );
  }, [filterText, resetPaginationToggle, text_searchhere]);

  const subHeaderComponentFile = useMemo(() => {
    const handleClear = () => {
      if (filterTextFile) {
        setResetPaginationToggleFile(!resetPaginationToggleFile);
        setFilterTextFile("");
      }
    };

    return (
      <>
        <FilterComponent
          onFilter={(e) => setFilterTextFile(e.target.value)}
          onClear={handleClear}
          filterText={filterTextFile}
          PlaceHolder={text_searchhere}
          className="fc-input-aws-ipfs res-search-input"
          clearClass="fc-x-aws-ipfs"
        />
      </>
    );
  }, [filterTextFile, resetPaginationToggleFile, text_searchhere]);

  return (
    <>
      {/* <div
        className="folder-home d-flex uf-inputs bucket-myfile select-dropdown-myfile mt-3 bucket-select-width"
        id="res-awsfolder-main"
      >
        <img src={folderImg} alt="" className="yf-aws2-folderImg mt-1" />{" "}
        <select
          className="uploadselectdrop-select"
          onChange={(e) => [
            handleSelectBucket(e.target.value),
            setReqUrlCreate(""),
          ]}
        >
          {allBucket.length > 0 ? (
            <>
              <option disabled>{text_selectbucket}</option>
              {allBucket.map((v, i) => {
                return (
                  <>
                    <option value={v.bucketId} key={i}>
                      {v.bucketName === "atom-storage"
                        ? `${sessionStorage.getItem("fName")}'s Bucket`
                        : v.bucketName === "atom-storage-private"
                        ? `${sessionStorage.getItem("fName")}'s Bucket`
                        : v.bucketName}{" "}
                      ({v.bucketACLOption})
                    </option>
                  </>
                );
              })}
            </>
          ) : (
            <>
              <option disabled selected>
                {text_selectbucket}
              </option>
            </>
          )}
        </select>
      </div> */}
      <div id="res-awsfolder-main" className="d-flex mt-3">
        {/* <img src={folderImg} alt="" className="mt-1" /> */}
        <select
          className="form-select ms-2"
          onChange={(e) => [
            handleSelectBucket(e.target.value),
            setReqUrlCreate(""),
          ]}
        >
          {allBucket.length > 0 ? (
            <>
              <option disabled>{text_selectbucket}</option>
              {allBucket.map((v, i) => (
                <option value={v.bucketId} key={i}>
                  {v.bucketName === "atom-storage" ||
                  v.bucketName === "atom-storage-private"
                    ? `${sessionStorage.getItem("fName")}'s Bucket`
                    : v.bucketName}{" "}
                  ({v.bucketACLOption})
                </option>
              ))}
            </>
          ) : (
            <option disabled selected>
              {text_selectbucket}
            </option>
          )}
        </select>
      </div>

      <div className="yf-ipfs-main mt-5 yf-aws2-main">
        {/* --------------------------------------------------- */}
        <div
          className={
            accesspopup || inviteuserPopup || shareDataPopup
              ? "bgblur yf-aws2-1"
              : "yf-aws2-1"
          }
        >
          <div className="yf-aws2-11">
            <span
              style={{ fontWeight: "500px", fontSize: "18px", color: "white" }}
            >
              <b>{text_currentlocation} : </b>
            </span>
            <span
              style={{ fontSize: "18px", marginLeft: "15px", color: "white" }}
            >
              <img src={folderImg} alt="" className="yf-aws2-folderImg" />{" "}
              <button
                className="folder-home text-white"
                onClick={() => {
                  if (viewBackBtn === true) {
                    // getAllDir_new(bucketId, awsBucketName);
                    setAwsFolderList(awsrootdata);
                    setLeftFolders(awsrootdata);
                    setUrlToShow("");
                    setReqUrlCreate("");
                    setViewBackBtn(false);
                    setFilesData(awsrootdataFiles);
                  }
                }}
              >
                {awsBucketName === "atom-storage"
                  ? `${sessionStorage.getItem("fName")}'s Bucket (Public) >`
                  : awsBucketName === "atom-storage-private"
                  ? `${sessionStorage.getItem("fName")}'s Bucket (Private) >`
                  : awsBucketName + " " + "(" + aclOption + ")"}
              </button>
              {urlToShow}
            </span>
          </div>
          {viewBackBtn && (
            <div className="yf-aws2-12">
              <button
                className="back-btn-view"
                style={{ marginTop: "0px" }}
                onClick={handleBack}
              >
                <span>
                  <MdOutlineKeyboardBackspace className="back-btn-icon back-icon" />
                </span>
                <span>{text_back}</span>
              </button>
            </div>
          )}
        </div>
        {/* --------------------------------------------------- */}
        <div
          className={
            accesspopup || inviteuserPopup ? "bgblur yf-aws2-2" : "yf-aws2-2"
          }
        >
          <div className="yf-aws2-21 px-2 " id="res-awsfolder">
            <div className="folder-home">
              <div className="folder-home d-flex uf-inputs bucket-myfile select-dropdown-myfile">
                <img
                  src={folderImg}
                  alt=""
                  className="yf-aws2-folderImg mt-1"
                />{" "}
                <select
                  className="uploadselectdrop-select"
                  onChange={(e) => [
                    handleSelectBucket(e.target.value),
                    setReqUrlCreate(""),
                  ]}
                >
                  {allBucket.length > 0 ? (
                    <>
                      <option disabled>{text_selectbucket}</option>
                      {allBucket.map((v, i) => {
                        return (
                          <>
                            <option value={v.bucketId} key={i}>
                              {v.bucketName === "atom-storage"
                                ? `${sessionStorage.getItem("fName")}'s Bucket`
                                : v.bucketName === "atom-storage-private"
                                ? `${sessionStorage.getItem("fName")}'s Bucket`
                                : v.bucketName}{" "}
                              ({v.bucketACLOption}){console.log(v.bucketName)}
                            </option>
                          </>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <option disabled selected>
                        {text_selectbucket}
                      </option>
                    </>
                  )}
                </select>
              </div>
            </div>
            <hr className="hr-sty-yourfileaws" />
            {/* left  */}
            {leftFolders?.map((v, index) => {
              return (
                <>
                  <div
                    className={
                      MainFolder[2] ===
                      v?.folderName.slice(
                        v?.folderName.lastIndexOf(
                          "/",
                          v?.folderName.lastIndexOf("/") - 1
                        ) + 1,
                        v?.folderName?.length - 1
                      )
                        ? "yf-aws2-211 mainfolder-sty"
                        : "yf-aws2-211"
                    }
                    key={index}
                    id={index}
                    onClick={() => [
                      getAllSubDir_new(v?.folderName),
                      setTimeout(() => {
                        setViewBackBtn(true);
                      }, 1000),
                      foderwiseAwsFilesRight(v),
                    ]}
                    title={v?.folderName.slice(
                      v?.folderName.lastIndexOf(
                        "/",
                        v?.folderName.lastIndexOf("/") - 1
                      ) + 1,
                      v?.folderName?.length - 1
                    )}
                  >
                    <img src={folderImg} alt="" className="yf-aws2-folderImg" />
                    {v?.folderName.slice(
                      v?.folderName.lastIndexOf(
                        "/",
                        v?.folderName.lastIndexOf("/") - 1
                      ) + 1,
                      v?.folderName?.length - 1
                    )}
                  </div>
                </>
              );
            })}
          </div>
          <div className="yf-aws2-22">
            {/* folders */}
            {awsFolderList === undefined ? (
              <div className="uf-emptyfolder">
                <h3 className="as">{text_nofolderorfiles}</h3>
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between flex-wrap">
                  <h5 className="mx-4">{text_folder}</h5>
                  <div className="mx-4">{subHeaderComponent}</div>
                </div>
                <div className="folder-file-datatable">
                  {filteredItems?.length === 0 &&
                  filteredItemsFiles.length === 0 ? (
                    <>
                      {awsrootLoaderdata ? (
                        <div className="uf-emptyfolder">
                          <Loader3 />
                        </div>
                      ) : (
                        <div className="uf-emptyfolder">
                          <h3 className="as">{text_nofolderhere}</h3>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <DataTable
                        columns={ColumnFolder}
                        className="p-3 "
                        onRowClicked={handleChangeFolder}
                        data={filteredItems}
                        pagination
                        fixedHeader
                        selectableRowsHighlight
                        highlightOnHover
                        pointerOnHover
                        customStyles={customTableStylesFile}
                        conditionalRowStyles={conditionalRowStyles}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
                      />
                    </>
                  )}
                </div>
                <div className="d-flex justify-content-between mt-4 flex-wrap">
                  <h5 className="mx-4">{text_file}</h5>
                  <div className="mx-4">{subHeaderComponentFile}</div>
                </div>
                <div className="folder-file-datatable">
                  {filteredItems?.length === 0 &&
                  filteredItemsFiles.length === 0 ? (
                    <>
                      {awsrootLoaderdata ? (
                        <div className="uf-emptyfolder">
                          <Loader3 />
                        </div>
                      ) : (
                        <div className="uf-emptyfolder">
                          <h3 className="as">{text_nofileshere}</h3>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {" "}
                      <DataTable
                        columns={ColumnFile}
                        className="p-3 "
                        pointerOnHover
                        onRowClicked={handleChange}
                        data={filteredItemsFiles}
                        pagination
                        fixedHeader
                        selectableRowsHighlight
                        highlightOnHover
                        customStyles={customTableStylesFile}
                        conditionalRowStyles={conditionalRowStyles}
                        paginationPerPage={5}
                        paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
                      />
                    </>
                  )}
                </div>
                {filteredItems.length > 0 && <></>}
                {/* right files */}
                {filteredItems?.length === 0 &&
                filteredItemsFiles.length === 0 ? (
                  ""
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>

        <Dialog
          open={accesspopup}
          onClose={closeAccessPopup}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="text-center w-100"
          >
            <div className="d-flex justify-content-between">
              <h5 className="mt-1">
                {text_share}{" "}
                {Filename === "" ? (
                  <>
                    <img
                      src={folderImg}
                      alt=""
                      style={{
                        height: "21px",
                        width: "21px",
                        marginBottom: "3px",
                      }}
                    />{" "}
                    {folderName}
                  </>
                ) : (
                  <>
                    <img
                      src={fileImg}
                      alt=""
                      style={{
                        height: "19px",
                        width: "19px",
                        marginBottom: "3px",
                      }}
                    />{" "}
                    {Filename}
                  </>
                )}{" "}
                To
              </h5>
              <h3>
                <CgCloseO onClick={() => closeAccessPopup()} />
              </h3>
            </div>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="d-flex flex-wrap">
                <div>
                  <div className="participants_box">
                    <div className="tags-input">
                      {emailData &&
                        emailData.map((dataitem, i) => (
                          <li key={i} className="tag">
                            <span className="tag-title">{dataitem}</span>
                            <span
                              className="tag-close-icon"
                              onClick={() => handleDelete(i)}
                            >
                              x
                            </span>
                          </li>
                        ))}

                      <input
                        className="user-input"
                        type="text"
                        onKeyPress={handleKeyDown}
                        onChange={EmailHandler}
                        value={emailexits}
                        placeholder={
                          emailData.length === 0 && text_enteruseremail
                        }
                      />
                    </div>
                  </div>
                  <div
                    className={
                      filteredSuggestions.length === 0 ? "" : "suggestion-list"
                    }
                  >
                    {displaySuggestions && (
                      <>
                        {filteredSuggestions.map((suggestion, index) => {
                          return (
                            <div className="suggestion">
                              <li
                                key={index}
                                className="li-suggestion"
                                onClick={() => onSelectSuggestion(index)}
                              >
                                {suggestion.userEmail}
                              </li>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <select
                  className="accessinput access-select mx-4 "
                  onChange={(e) => [
                    AccessValueHandle(e.target.value),
                    setAccessErr(""),
                  ]}
                >
                  <option value="" disabled>
                    {text_accesstype}
                  </option>
                  <option value="read">{text_read}</option>
                  <option value="write">
                    {text_read}/{text_write}
                  </option>
                  <option value="download" selected>
                    {text_read}/{text_write}/{text_download}
                  </option>
                </select>
              </div>
            </DialogContentText>

            <p className="mx-3 errMsg">{errEmail}</p>

            <Divider />
          </DialogContent>
          <div className="d-flex justify-content-center gap-4 mt-2 mb-3">
            <button className="box-btn" onClick={() => closeAccessPopup()}>
              {text_cancel}
            </button>
            <button
              className="box-btn-save"
              onClick={() =>
                emailData.length === 0 ? handleSingleUser() : handleGetUser()
              }
              disabled={shareFileLoader ? true : false}
            >
              {shareFileLoader ? <Loader2 /> : text_share}
            </button>
          </div>
        </Dialog>

        <Dialog
          open={shareDataPopup}
          onClose={() => setSharedDataPopup(false)}
          aria-labelledby="responsive-dialog-title"
          classes={{ paper: "custom-dialog" }} // Apply custom class to the dialog box
        >
          <DialogTitle id="responsive-dialog-title" className="text-center">
            <div className="d-flex justify-content-between">
              <h5 className="mt-1">{text_peoplewithaccess}</h5>
              <h3>
                <CgCloseO onClick={() => setSharedDataPopup(false)} />
              </h3>
            </div>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="row">
                {/* For large screens, maintain original layout */}
                <div className="col-md-6 d-none d-md-block">
                  {accessEmail?.map((emailItem, i) => (
                    <div
                      key={i}
                      style={{
                        width: "325px",
                        paddingTop: "20px",
                        paddingBottom: "8px",
                      }}
                    >
                      <span>{emailItem?.email}</span>
                    </div>
                  ))}
                </div>
                <div className="col-md-6 d-none d-md-block">
                  {combinedData.map((item, i) => (
                    <div key={i} className="access-box">
                      <span className="d-flex">
                        {item?.read_access &&
                          !item?.write_access &&
                          !item?.download_access &&
                          "Read"}
                        {item?.read_access &&
                          item?.write_access &&
                          !item?.download_access &&
                          "Read/Write"}
                        {item?.read_access &&
                          item?.write_access &&
                          item?.download_access &&
                          "Read/Write/Download"}
                      </span>
                    </div>
                  ))}
                </div>
                {/* For small screens, switch to stacked layout */}
                <div className="col-md-6 d-md-none">
                  {accessEmail?.map((emailItem, i) => (
                    <div
                      key={i}
                      style={{
                        width: "325px",
                        paddingTop: "20px",
                        paddingBottom: "8px",
                      }}
                    >
                      <span>{emailItem?.email}</span>
                      {/* Render combinedData corresponding to this accessEmail */}
                      <div className="access-box">
                        <span className="d-flex">
                          {combinedData[i]?.read_access &&
                            !combinedData[i]?.write_access &&
                            !combinedData[i]?.download_access &&
                            "Read"}
                          {combinedData[i]?.read_access &&
                            combinedData[i]?.write_access &&
                            !combinedData[i]?.download_access &&
                            "Read/Write"}
                          {combinedData[i]?.read_access &&
                            combinedData[i]?.write_access &&
                            combinedData[i]?.download_access &&
                            "Read/Write/Download"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
                          
              </div>
            </DialogContentText>
          </DialogContent>
          <div className="d-flex justify-content-center mb-2 ">
            <button
              className="box-btn-save"
              onClick={() => setSharedDataPopup(false)}
            >
              {text_done}
            </button>
          </div>
        </Dialog>

        <ToastContainer />
      </div>
    </>
  );
};

export default YourFilesAWS2;
