import React, { useMemo, useState } from "react";
import "./YourFiles.css";
import DataTable from "react-data-table-component";
import { customTableStylesFile } from "../../utils/Files/tableStyle";
import { useEffect } from "react";
import axios from "axios";
import FilterComponent from "../../utils/Files/FilterComponent";
import moment from "moment";
import { LuDownload } from "react-icons/lu";
import { IoReload } from "react-icons/io5";
import {
  GLOBAL_URL_DOMAIN_File_Upload_Live,
  GLOBAL_URL_DOMAIN_LIVE,
} from "../../utils/Files/url";
import { useNavigate } from "react-router-dom";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import fileImg from "../../utils/images/file_1.png";
import folderImg from "../../utils/images/folderAWS.png";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";
import { CgCloseO } from "react-icons/cg";
import Cookie from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import {
  errorToast,
  successToast,
  InfoToast,
} from "../../utils/Files/toasterMsgs";
import { GlobalConstants } from "../../utils/globalconstant";
import Loader2 from "../../Components/Loader2/Loader2";
import Loader3 from "../../utils/Files/data";
import peopleimg from "../../utils/images/peopleimg.png";
import { EMAIL_REGEX } from "../../utils/Files/validation";
import { FaShareAlt } from "react-icons/fa";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getIPFSFileData,
  getIPFSRootData,
} from "../../redux/IPFSRootFolderSlice";
import { getAllUserData } from "../../redux/UserListSlice";
import Tooltip from "@mui/material/Tooltip";
import { resetUploadInfo } from "../../redux/UploadSlice";
import { rootApiCall } from "../../redux/MetaDataSlice";
import {
  fetchSharedByMeFiles,
  fetchSharedByMeFolders,
} from "../../redux/SharedByMeSlice";
const xpath = require("xpath");
const dom = require("xmldom").DOMParser;
const YourFilesIPFS = () => {
  //variable
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const ipfsrootdata = useSelector((state) => state.ipfsRootData.folderData);
  const callIpfsRootApi = useSelector(
    (state) => state.metadata.callIpfsRootApi
  );
  // console.log(ipfsrootdata);
  const ipfsrootLoaderdata = useSelector(
    (state) => state.ipfsRootData.isLoadingFolder
  );
  const ipfsfile = useSelector((state) => state.ipfsRootData.fileData);
  // console.log(ipfsfile);
  const userData = useSelector((state) => state.comapanyUserList.userdata);
  const CompanyName =
    sessionStorage.getItem("companyName") === null ||
    sessionStorage.getItem("companyName") === undefined ||
    sessionStorage.getItem("companyName") === "null"
      ? ""
      : sessionStorage.getItem("companyName");
  var UserF_Name = sessionStorage.getItem("fName");
  var UserL_name = sessionStorage.getItem("lName");
  const useremail = sessionStorage.getItem("email");
  const [Data, setData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterTextFolder, setFilterTextFolder] = useState("");
  const [resetPaginationTogglefolder, setResetPaginationTogglefolder] =
    useState(false);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [enterUsername, setEnterUsername] = useState("");
  const [emailexits, setEmailexits] = useState("");
  const [accessErr, setAccessErr] = useState("");
  const [other_folder_data, setOther_folder_data] = useState();
  const [allFileData, setAllFileData] = useState("");
  const [accesspopup, setAccesspopup] = useState(false);
  const [inviteuserPopup, setInviteuserPopup] = useState(false);
  const [loader, setLoader] = useState(false + "");
  const [loaderAccess, setLoaderAccess] = useState(false + "");
  const [folderNameForUnpin, setFolderNameForUnpin] = useState("");
  const [loaderFolder, setLoaderFolder] = useState(false);
  const [Fname, setFname] = useState("");
  const [Lname, setLname] = useState("");
  const [folderID, setFolderID] = useState("");
  const [loaderdata, setLoaderData] = useState(false);
  const [Filename, setFileName] = useState("");
  const [bucketId, setBucketID] = useState("");
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [shareDataPopup, setSharedDataPopup] = useState(false);
  const [combinedData, setCombinedData] = useState([]);
  const [accessEmail, setAccessEmail] = useState([]);
  const [emailData, setEmailData] = useState([]);
  const [errEmail, setErrEmail] = useState("");
  const [folderName, setFolderName] = useState("");
  const [shareFileLoader, setShareFileLoader] = useState(false);
  var userID = sessionStorage.getItem("userID");
  var UserEmailId = sessionStorage.getItem("email");
  var CompanyID = sessionStorage.getItem("companyID");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [selectedSuggestion, setSelectedSuggestion] = useState(0);
  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  // const [userData, setUserData] = useState([]);
  const [allFolderData, setAllFolderData] = useState([]);
  const [leftFolders, setLeftFolders] = useState([]);
  const [reqUrlCreate, setReqUrlCreate] = useState("");
  const [urlToShow, setUrlToShow] = useState("");
  const [viewBackBtn, setViewBackBtn] = useState(false);
  const [other_data_2, setother_data_2] = useState([]);
  const [folderArr, setfolderArr] = useState([]);
  const [fileOrfolder, setFileOrFolder] = useState("");
  const [folderName_share, setFolderName_share] = useState("");
  const [folderNameForInsertRecord, setFolderNameForInsertRecord] =
    useState("");
  //language variable start
  const [text_srno, setText_srno] = useState("SR NO.");
  const [text_filename1, setText_filename1] = useState("FILE NAME");
  const [text_filesize1, setText_filesize1] = useState("FILE SIZE");
  const [text_dateofupload, setText_dateofupload] = useState("Date Of Upload");
  const [text_atomfileid, setText_atomfileid] = useState("ATOM FILE ID");
  const [text_ipfsurl, setTextipfsurl] = useState("Copy Atom URL");
  const [text_viewdetails, setText_viewdetails] = useState("VIEW DETAILS");
  const [text_view, setText_view] = useState("View");
  const [text_edit, setText_edit] = useState("EDIT");
  const [text_download, setText_download] = useState("Download");
  const [text_delete, setText_delete] = useState("Delete");
  const [buttonText, setButtonText] = useState("Atom URL");
  const [text_share, setText_share] = useState("Share");
  const [text_searchhere, setText_searchhere] = useState("Search Here...");
  const [text_read, setText_read] = useState("Read");
  const [text_write, setText_write] = useState("Write");
  const [text_validationemail, setText_validationemail] = useState(
    "Please Enter User Email!"
  );
  const [text_back, setText_back] = useState("Back");
  const [text_currentlocation, setText_currentlocation] =
    useState("Current Location");
  const [text_enteruseremail, setText_enteruseremail] =
    useState("Enter User Email");
  const [text_selectaccesstype, setText_selectaccesstype] =
    useState("Select Access Type");
  const [text_Own_email_err, setText_Own_email_err] = useState(
    "You can not give access to your own email"
  );
  const [text_nofileshere, setText_nofileshere] = useState("No File Here");
  const [text_to, setText_to] = useState("To");
  const [text_cancel, setText_cancel] = useState("Cancel");
  const [text_peoplewithaccess, setText_peoplewithaccess] =
    useState("People With Access");
  const [text_done, setText_done] = useState("Done");
  const [text_valemail, setText_valemail] = useState("*Enter valid email Id!");
  const [text_alemail, setText_alemail] = useState(
    "*Email has already been added!"
  );
  const [text_noticeemail, settext_noticeemail] = useState(
    "*You cannot give access to more than 50 emails at once!"
  );
  const [text_foldername, setText_foldername] = useState("Folder Name");
  const [text_nofolderhere, setText_nofolderhere] = useState("No Folder Here");
  const [text_folder, setText_folder] = useState("Folder");
  const [text_file, setText_File] = useState("File");

  const [Web_server_text, setWeb_server_text] = useState(
    "Upload to Web Server in progress"
  );
  const [Atom_text, setAtom_text] = useState(
    "Upload to Atom Storage in progress"
  );
  const [upload_complete_text, setUpload_complete_text] =
    useState("Upload completed");
  const [text_Refresh, setText_Refresh] = useState("Refresh");

  //language variable end

  // ********** UploadInfo Starts **********
  // UI = Upload Info
  const UI_file_unique_id = useSelector(
    (state) => state.metadata.file_unique_id
  );
  const UI_chunkNo = useSelector((state) => state.singleUploadData.chunkNo);
  const UI_initialText = useSelector(
    (state) => state.singleUploadData.initialText
  );
  const [UI_ind, setUI_ind] = useState(null);
  const [UI_file, setUI_file] = useState({});

  const [UI_text, setUI_text] = useState("");

  const [UI_uploadingToServer, setUI_UI_uploadingToServer] = useState(true);
  const [UI_serverUploadDone, setUI_serverUploadDone] = useState(false);

  const [UI_uploadingToIpfs, setUI_uploadingToIpfs] = useState(false);
  const [UI_ipfsUploadDone, setUI_ipfsUploadDone] = useState(false);

  const [UI_RefreshVisibility, setUI_RefreshVisibility] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("uploadLocation") === "aws") {
      navigate("/my-files", {
        state: { selectedTab: 1 },
      });
    }
    setTimeout(() => {
      if (sessionStorage.getItem("uploadLocation") === "both") {
        navigate("/my-files", {
          state: { selectedTab: 2 },
        });
      }
    }, 500);
  }, []);
  useEffect(() => {
    // console.log(callIpfsRootApi);
    if (callIpfsRootApi === true) {
      dispatch(
        getIPFSFileData(
          `atom-storage/${userID}/`,
          "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
        )
      );
      dispatch(getIPFSRootData(`atom-storage/${userID}/`));
      dispatch(rootApiCall());
    }
  }, [callIpfsRootApi]);

  useEffect(() => {
    if (UI_initialText === true) {
      setUI_text(`${Web_server_text}...`);
    }

    if (UI_chunkNo > 0) {
      setUI_RefreshVisibility(true);

      var uploadInfoFunc = async () => {
        try {
          const headerConfig = {
            headers: {
              authorization: "Bearer " + sessionStorage.getItem("userToken"),
            },
          };

          const API_URL =
            GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

          const data = {
            file_unique_id: UI_file_unique_id,
          };

          const res = await axios.post(API_URL, data, headerConfig);

          // console.log("uploadInfoFunc res***", res);

          if (
            res?.data?.success === true &&
            res?.data?.data?.isUploadDone_WebServer === true &&
            res?.data?.data?.uploadEndTime_WebServer !== null
          ) {
            // file uploaded to web server
            setUI_serverUploadDone(true);
            setUI_UI_uploadingToServer(false);
            dispatch(resetUploadInfo());
          }
        } catch (err) {
          console.log("uploadInfoFunc catch block:", err);
        }
      };
      uploadInfoFunc();
    }
  }, [UI_chunkNo, UI_file_unique_id]);

  const handleRefresh = async () => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: UI_file_unique_id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      // console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        setUI_text(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_IPFS === false
      ) {
        // server upload is complete and file is uploading on ipfs
        setUI_text(`${Atom_text}...`);

        setUI_UI_uploadingToServer(false);
        setUI_serverUploadDone(true);
        setUI_uploadingToIpfs(true);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_IPFS === true &&
        res?.data?.data?.uploadEndTime_IPFS !== null
      ) {
        // ipfs upload is complete
        setUI_text(`${upload_complete_text}`);
        setUI_RefreshVisibility(false);
        setUI_UI_uploadingToServer(false);
        setUI_serverUploadDone(true);
        setUI_uploadingToIpfs(false);
        setUI_ipfsUploadDone(true);
      }
    } catch (err) {
      console.log("handleRefresh catch block:", err);
    }
  };

  const checkStatusOnShare = async (id) => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      // console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_IPFS === false
      ) {
        // server upload is complete and file is uploading on ipfs
        setAccesspopup(true);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_IPFS === true &&
        res?.data?.data?.uploadStartTime_IPFS !== null
      ) {
        // ipfs upload is complete
        setAccesspopup(true);
      }
    } catch (err) {
      console.log("handleRefresh catch block:", err);
    }
  };
  const checkStatusOnDownload = async (row, index) => {
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: row?.file_unique_id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      // console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_IPFS === false
      ) {
        // server upload is complete and file is uploading on ipfs
        handleDownload(row?.fileType, row?.fileNameWithUuid, index, row?._id);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_IPFS === true &&
        res?.data?.data?.uploadStartTime_IPFS !== null
      ) {
        // ipfs upload is complete
        // console.log(row);
        ipfs_download(
          row?.fileCId,
          row?.fileNameWithUuid,
          row?.fileType,
          row?.fileName,
          index,
          row?._id
        );
      }
    } catch (err) {
      console.log("handleRefresh catch block:", err);
    }
  };
  const handleDownload = async (File_type, File_Name, row_id) => {
    const filename = File_Name;
    const fileType = File_type;
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));

    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-server`;

      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const data = {
        fileName: filename,
        fileType: fileType,
      };

      const res = await axios.post(url, data, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("stream_ipfs_server_download res***", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (res?.status === 200) {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      // console.log("stream_ipfs_server_download error***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };
  const checkStatusOnUnpin = async (row, index) => {
    // console.log(row);
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/file/upload-info`;

      const data = {
        file_unique_id: row?.file_unique_id,
      };

      const res = await axios.post(API_URL, data, headerConfig);

      // console.log("refreshFunc res***", res);

      if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === false &&
        res?.data?.data?.uploadEndTime_WebServer === null
      ) {
        // file is uploading on server
        toast.info(`${Web_server_text}...`);
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_WebServer === true &&
        res?.data?.data?.uploadEndTime_WebServer !== null &&
        res?.data?.data?.isUploadDone_IPFS === false
      ) {
        // server upload is complete and file is uploading on ipfs
        handleUnpin(
          row?.fileNameWithUuid,
          row?.file_unique_id,
          row?._id,
          row?.isAmazon,
          row?.isIPFS
        );
      } else if (
        res?.data?.success === true &&
        res?.data?.data?.isUploadDone_IPFS === true &&
        res?.data?.data?.uploadStartTime_IPFS !== null
      ) {
        // ipfs upload is complete
        handleUnpinFile(row?.fileCId, row?.file_unique_id, row?.isAmazon);
      }
    } catch (err) {
      console.log("handleRefresh catch block:", err);
    }
  };
  const handleUnpin = async (
    fileName,
    file_unique_id,
    fileId,
    isAmazon,
    isIPFS
  ) => {
    // console.log(fileName, file_unique_id, fileId, isAmazon, isIPFS);
    try {
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const API_URL =
        GLOBAL_URL_DOMAIN_File_Upload_Live + `/delete/delete-file-from-server`;

      const data = {
        fileName: fileName,
        isAmazon: isAmazon,
        isIPFS: isIPFS,
        file_unique_id: file_unique_id,
        fileId: fileId,
      };

      const res = await axios.delete(API_URL, data, headerConfig);

      successToast(res?.data?.message);
    } catch (err) {
      // console.log("handleUnpin err***", err);
      setLoader(false);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("fName");
        Cookie.remove("lName");
        Cookie.remove("userID");
        Cookie.remove("userToken");
        Cookie.remove("token_start_time");
        navigate("/");
      }
    }
  };

  // ********** UploadInfo Ends **********

  const closeAccessPopup = () => {
    setAccesspopup(false);
    setInviteuserPopup(false);
    setEmailexits("");
    setEnterUsername("");
    setAccessErr("");
    setFileName("");
    setEmailData([]);
    setFname("");
    setEmailexits("");
    setLname("");
    setEnterUsername("");
    setAccessErr("");
    setFileName("");
    setEmailData([]);
    setDownloadAccess(true);
    setWriteAccess(true);
    setDisplaySuggestions(false);
    setReadAccess(true);
    setErrEmail("");
    setFilteredSuggestions([]);
  }; ///*****Get File Data *****/
  const getFileData = (folder_name, bucket_id) => {
    setLoaderData(true);
    setFolderNameForUnpin(folder_name);
    var Folder_name_Final =
      folder_name === "" ? `atom-storage/${userID}/` : folder_name;
    // console.log(Folder_name_Final);

    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE +
      `/file/filedata-ipfs-folderwise?userId=${userID}&folderName=${Folder_name_Final}&bucketId_ipfs=${bucket_id}`;

    axios
      .get(API_URL, headerConfig)
      .then((res) => {
        // console.log("get data ipfs res***", res);
        const sortedData = res?.data?.data?.sort((a, b) => {
          // Assuming you want to sort by a property called "createdAt"
          return new Date(b.createdAt) - new Date(a.createdAt);
        });

        setLoaderData(false);
        if (sortedData.length > 0) {
          setData(sortedData);
        } else {
          setData([]);
        }
      })
      .catch((err) => {
        // console.log("get data ipfs err***", err);
        setLoader(false);
        setLoaderData(false);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ///******Get user list for access popup suggestion */
  // const getAllUserData = () => {
  //   if (
  //     CompanyID === undefined ||
  //     CompanyID === "null" ||
  //     CompanyID === "" ||
  //     CompanyID === null
  //   ) {
  //     console.log("Not A Company");
  //   } else {
  //     var API_URL =
  //       GLOBAL_URL_DOMAIN_LIVE + `/company/user-count?companyId=${CompanyID}`;

  //     let headerConfig = {
  //       headers: {
  //         authorization: "Bearer " + sessionStorage.getItem("userToken"),
  //       },
  //     };

  //     axios
  //       .get(API_URL, headerConfig)
  //       .then(function (response) {
  //         var data = response.data.companyUsers;
  //         console.log("User Count", data);
  //         var FinalData = data.filter((val) => {
  //           if (val.userID === undefined || val.userID === null) {
  //             console.log(val.userID);
  //           } else {
  //             return val.userID.email !== useremail;
  //           }
  //         });

  //         setUserData(FinalData);
  //       })
  //       .catch(function (error) {
  //         console.log(error);
  //         if (error.response.status === 427) {
  //           sessionStorage.clear();
  //           localStorage.clear();
  //           Cookie.remove("fName");
  //           Cookie.remove("lName");
  //           Cookie.remove("userID");
  //           Cookie.remove("userToken");
  //           Cookie.remove("token_start_time");
  //           setTimeout(() => {
  //             navigate("/");
  //           });
  //         }
  //         errorToast(error.response.data.message);
  //       })
  //       .then(function () {});
  //   }
  // };

  useEffect(() => {
    if (userData?.length === 0) {
      dispatch(getAllUserData());
    }
  }, [userData?.length]);

  ////**** get Child Folder */
  const getSubFolder = (folder_name, bucketId) => {
    setLoaderData(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE +
      `/ipfs-folder/child-folders?companyId=${CompanyID}&userId=${userID}&folderName=${folder_name}&bucketId=${bucketId}`;

    axios
      .get(API_URL, headerConfig)
      .then((res) => {
        // console.log("get data ipfs Folders***", res);
        setLoaderData(false);
        getFileData(folder_name, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        setViewBackBtn(true);
        setAllFolderData(res?.data?.data);
        setReqUrlCreate(folder_name);
      })
      .catch((err) => {
        // console.log("get data ipfs err***", err);
        setLoader(false);
        setLoaderData(false);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ////**** Unpin file data */
  const handleUnpinFile = (atomId, fileId, isAWSS3) => {
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    // var API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/ipfs-file/unpin?arg=${cid}`;
    var API_URL = GLOBAL_URL_DOMAIN_File_Upload_Live + `/ipfs-file/unpin`;

    const data = {
      atomId: atomId,
      fileId: fileId,
      isAWSS3: isAWSS3,
    };

    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("get data ipfs res***", res);
        successToast(res?.data?.message);
        // call IPFS files function here
        if (viewBackBtn === false) {
          dispatch(
            getIPFSFileData(
              `atom-storage/${userID}/`,
              "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
            )
          );
        }
        if (viewBackBtn === true) {
          getFileData(
            folderNameForUnpin,
            "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
          );
        }
      })
      .catch((err) => {
        // console.log("get data ipfs err***", err);
        setLoader(false);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  ////**** get root Folder */
  const getFolder = (Folder_name) => {
    // console.log(Folder_name);
    setLoaderData(true);
    var MainFolderData = {
      companyId:
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === undefined
          ? ""
          : sessionStorage.getItem("companyID"),
      userId: sessionStorage.getItem("userID"),
      folderName: `atom-storage/${userID}/`,
      bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
    };
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };
    var API_URL =
      GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/all-child-folders-ipfs";

    axios
      .post(API_URL, MainFolderData, headerConfig)
      .then((res) => {
        // console.log("add_folder_IPFS res***", res);
        const sortedData = res?.data?.data?.sort((a, b) => {
          // Assuming you want to sort by a property called "createdAt"
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setAllFolderData(sortedData);
        setLoaderData(false);
        setLeftFolders(sortedData);
        if (ipfsfile?.length === 0) {
          dispatch(
            getIPFSFileData(
              `atom-storage/${userID}/`,
              "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
            )
          );
        }
        // getFileData(
        //   `atom-storage/${userID}/`,
        //   "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
        // );
      })
      .catch((err) => {
        // console.log("add_folder_id_func err***", err);
        setLoaderData(false);
        if (err.response.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  // useEffect(() => {
  //   getFolder();
  // }, [ipfsrootdata?.length]);
  useEffect(() => {
    if (ipfsrootdata?.length === 0) {
      dispatch(getIPFSRootData());
    }

    setAllFolderData(ipfsrootdata);
    setLeftFolders(ipfsrootdata);
    setLoaderData(false);
    setLeftFolders(ipfsrootdata);
    if (ipfsfile?.length === 0) {
      dispatch(
        getIPFSFileData(
          `atom-storage/${userID}/`,
          "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
        )
      );
    } else {
      setData(ipfsfile);
      // getFileData(
      //   `atom-storage/${userID}/`,
      //   "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
      // );
    }
    // console.log("*************", ipfsrootdata);
  }, [ipfsrootdata?.length, ipfsfile?.length]);
  ////**** Give ipfs folder access */
  const give_ipfs_folder_access = async (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    // console.log("userID_to", userID_to);
    // console.log(other_data_2);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/give-folder-access-ipfs`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      var Folder_arr = [other_folder_data];
      let data = {
        company_id: CompanyID, // company that is giving the access
        company_user_id: userID, // user that is giving the access
        user_id: userID_to, // user whom access is being given
        db_version: "v1",
        other_data_2: other_data_2, // data of existing file in the folders which access is being given (files only) (you won't get data about an empty folder here)
        bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        folderArr: Folder_arr,
        read_access: readAccess,
        write_access: writeAccess,
        download_access: downloadAccess,

        // ----- data for email starts -----
        email: email,
        companyName: CompanyName, // null when no companyName
        firstName: "", // don't add validation now
        lastName: "", // don't add validation now
        sender_firstname: UserF_Name, // don't add validation now
        sender_lastname: UserL_name, // don't add validation now
        share_location: "01",
      };
      let res = await axios.post(API_URL, data, headerConfig);

      // console.log("give_ipfs_folder_access res***", res);
      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getFolder("atom-storage", "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          getSubFolder(folderName, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      }
      if (emailData.length === 0) {
        closeAccessPopup();
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getFolder("atom-storage", "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          getSubFolder(folderName, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      }
      dispatch(fetchSharedByMeFiles("ipfs"));
      dispatch(fetchSharedByMeFolders("ipfs"));
    } catch (err) {
      // console.log(
      //   "give_ipfs_folder_access err***",
      //   err?.response?.data?.message
      // );
      if (emailData.slice(-1)[0] === email) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getFolder("atom-storage", "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          getSubFolder(folderName, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      }
      if (emailData.length === 0) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getFolder("atom-storage", "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          getSubFolder(folderName, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      }
    }
  };
  ////**** invite user [not register user] ipfs folder access */
  const give_ipfs_invite_folder_access = async (
    email,
    userID_to,
    lastIndex
  ) => {
    setShareFileLoader(true);
    // console.log(other_data_2);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/pg-folder/inviteuser-for-folder-access`;

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let data = {
        firstName: "",
        lastName: "",
        email: email,
        companyName: CompanyName,
        sender_firstname: UserF_Name,
        sender_lastname: UserL_name,
        sender_user_id: userID,
        is_aws: false,
        is_ipfs: true,
        company_id: CompanyID,
        db_version: "v1",
        bucket_id: "",
        other_data_aws: [],
        folderArr_aws: [],
        bucket_id_ipfs: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
        other_data_ipfs: other_data_2,
        folderArr_ipfs: folderArr,
        read_access_aws: false,
        write_access_aws: false,
        download_access_aws: false,
        read_access_ipfs: readAccess,
        write_access_ipfs: writeAccess,
        download_access_ipfs: downloadAccess,
        share_location_ipfs: "01",
        share_location_aws: null,
      };

      let res = await axios.post(API_URL, data, headerConfig);

      // console.log("give_ipfs_folder_access res***", res);
      if (res.status === 200) {
        handleCreateDefaultFolder(res?.data?.data?.registered_user_id);
        handleCreateRootFolderS3(
          // res.data.data.companyId,
          res?.data?.data?.registered_user_id
        );
        handleCreateRootFolderS3_Private(
          // res.data.data.companyId,
          res?.data?.data?.registered_user_id
        );
      }
      if (emailData.slice(-1)[0] === email) {
        closeAccessPopup();
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getFolder("atom-storage", "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          getSubFolder(folderName, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      }
      if (emailData.length === 0) {
        closeAccessPopup();
        setShareFileLoader(false);
        if (reqUrlCreate === "" || reqUrlCreate === undefined) {
          getFolder("atom-storage", "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        } else {
          getSubFolder(folderName, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
        }
      }
      dispatch(fetchSharedByMeFiles("ipfs"));
      dispatch(fetchSharedByMeFolders("ipfs"));
    } catch (err) {
      // console.log(
      //   "give_ipfs_folder_access err***",
      //   err?.response?.data?.message
      // );
      if (emailData.slice(-1)[0] === email) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
      }
      if (emailData.length === 0) {
        setAccessErr(err?.response?.data?.message);
        setShareFileLoader(false);
      }
    }
  };
  ////**** Get Folder Data for Share folder */
  const get_required_folder_data = async (folder_name) => {
    // console.log(folder_name);
    try {
      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/ipfs-folder/all-child-folders-files-ipfs`;

      const data = {
        companyId: CompanyID,
        userId: userID, // user who is logged in currently
        folderName: [folder_name],
        bucketId: "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55",
      };

      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let res = await axios.post(API_URL, data, headerConfig);

      // console.log("get_required_folder_data res***", res);
      setother_data_2(res?.data?.other_data_2);
      setfolderArr(res?.data?.folderArr);
    } catch (err) {
      console.log("get_required_folder_data err***", err);
    }
  };
  ////**** Folder with access data [shared folder with whom] */
  const userAccessData_folder = async (folderId, row_id) => {
    setLoaderFolder((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE +
        `/ipfs-access/users-with-folder-access?f_id=${folderId}`;

      let res = await axios.get(API_URL, headerConfig);
      // console.log("userAccessData res***", res);
      if (res?.data?.data !== null) {
        setSharedDataPopup(true);
        setCombinedData(res?.data?.data_2);
        setAccessEmail(res?.data?.userData_2);
      }
      setLoaderFolder((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    } catch (err) {
      setLoaderFolder((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  ////**** coulmn for folder datatable */
  const ColumnFolder = [
    {
      name: <>{text_foldername}</>,
      selector: (row) => (
        <>
          <div className="d-flex">
            <div
              className="show-view-div test__table-icon-selected uf-folder-aws"
              title={row?.folderName.slice(
                row?.folderName.lastIndexOf(
                  "/",
                  row?.folderName.lastIndexOf("/") - 1
                ) + 1,
                row?.folderName?.length - 1
              )}
              onClick={() => handleChangeFolder(row)}
            >
              <img src={folderImg} alt="" className="yf-aws2-folderImg" />{" "}
              {row?.folderName.slice(
                row?.folderName.lastIndexOf(
                  "/",
                  row?.folderName.lastIndexOf("/") - 1
                ) + 1,
                row?.folderName?.length - 1
              )}
            </div>
            <span className="mx-2">
              {row?.shared_count !== 0 && (
                <button
                  onClick={() => [
                    userAccessData_folder(row?.folderId, row._id),
                    setFolderName_share(row?.fileName),
                  ]}
                  className="shared-data-email"
                  title="Shared"
                >
                  {loaderFolder[row._id] ? (
                    <Loader2 className="mx-5" />
                  ) : (
                    <img src={peopleimg} alt="" className="peopleimg" />
                  )}
                </button>
              )}
            </span>
          </div>
        </>
      ),
      width: "465px",
    },
    {
      name: <>{text_dateofupload}</>,
      selector: (row) => (
        <>
          <div className="show-view-div">
            {moment(row?.createdAt).format("MMM DD, yyyy")}
          </div>
        </>
      ),
      width: "380px",
    },

    {
      name: <>{text_share}</>,
      selector: (row) => (
        <>
          <button
            className="share-icon-table"
            onClick={() => [
              setAccesspopup(true),
              get_required_folder_data(row?.folderName),
              setFolderNameForInsertRecord(row?.ipfsFolderName),
              setFileOrFolder("Folder"),
              setOther_folder_data(row?.folderName),
              // console.log(row),
              setFolderName(
                row?.folderName.slice(
                  row?.folderName.lastIndexOf(
                    "/",
                    row?.folderName.lastIndexOf("/") - 1
                  ) + 1,
                  row?.folderName?.length - 1
                )
              ),
            ]}
            title="Share"
          >
            <FaShareAlt className="share-sty" />
          </button>
        </>
      ),
    },
  ];
  // console.log(allFolderData);
  const filteredItemsFolder = allFolderData.filter(
    (item) =>
      JSON.stringify(item)
        .toLowerCase()
        .indexOf(filterTextFolder.toLowerCase()) !== -1
  );
  ////**** current location show */
  const ReqUrlCreateToShow = (url) => {
    var newUrl = url.split("/");
    // console.log(url);
    const urlWithImages = newUrl.slice(1, -1).map((folder, index) => (
      <span key={index}>
        {/* {console.log(folder)} */}
        {folder === userID ? (
          ""
        ) : (
          <>
            {index > 2 && " > "}{" "}
            <img
              src={folderImg}
              alt=""
              style={{ height: "22px", width: "22px" }}
            />{" "}
            {folder} {`>`}
          </>
        )}
      </span>
    ));

    if (newUrl.length > 0) {
      urlWithImages.push(newUrl[newUrl.length - 1]);
    }
    setUrlToShow(urlWithImages);
  };
  ////**** access popup email handle */
  const EmailHandler = async (event) => {
    const value = event.target.value;
    // console.log(value);
    setEmailexits(value.toLowerCase());
    setErrEmail("");
    if (value === "") {
      setFilteredSuggestions([]);
      setDisplaySuggestions(false);
      return;
    }
    if (value == useremail) {
      isValid();
    }
    const filteredSuggestions = userData.filter(
      (suggestion, i) =>
        suggestion.userEmail.toLowerCase().includes(value.toLowerCase()) &&
        suggestion.userEmail.toLowerCase() !== UserEmailId
    );

    setFilteredSuggestions(filteredSuggestions);
    setDisplaySuggestions(true);
  };
  ////**** access email suggestion */
  const onSelectSuggestion = (index) => {
    setSelectedSuggestion(index);
    // console.log(index);

    setEmailData([...emailData, filteredSuggestions[index].userEmail]);
    setEmailexits("");

    setFilteredSuggestions([]);
    setDisplaySuggestions(false);
  };
  const onSelectSuggestionKey = (e, index) => {
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      // console.log(index);
    }
  };
  ////**** delete added email on array list */
  const handleDelete = (participants1) => {
    setEmailData(emailData.filter((item, i) => i !== participants1));
  };
  ////**** key press push data on email array */
  const handleKeyDown = (e) => {
    // console.log(e);
    if (["Enter", "Tab", ",", " "].includes(e.key)) {
      e.preventDefault();
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);

        setEmailexits("");
      }
    }
  };
  ////**** accesspopup validation */
  const alreadyemail = emailData.filter(
    (item, index) => emailData.indexOf(item) !== index
  );
  function isInList(value) {
    return emailData.includes(value);
  }

  function isValid() {
    if (!EMAIL_REGEX.test(emailexits)) {
      setErrEmail(<>{text_valemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (isInList(emailexits)) {
      setErrEmail(<>{text_alemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (emailData.length > 51) {
      setErrEmail(<>{text_noticeemail}</>);
      return false;
    } else {
      setErrEmail("");
    }

    if (emailexits == useremail) {
      setErrEmail(<>*{text_Own_email_err}!</>);
      return false;
    } else {
      setErrEmail("");
    }

    return true;
  }

  useEffect(() => {
    try {
      SetLanguageText();
    } catch (err) {
      console.log("" + err);
    }
  }, []);
  const SetLanguageText = () => {
    try {
      var xml = localStorage.getItem(GlobalConstants.session_lang_xml);
      var doc = new dom().parseFromString(xml);
      setTextipfsurl(
        xpath.select1("/resources/string[@name='text_ipfsurl']", doc).firstChild
          .data
      );
      setText_srno(
        xpath.select1("/resources/string[@name='text_srno']", doc).firstChild
          .data
      );
      setText_filename1(
        xpath.select1("/resources/string[@name='text_filename1']", doc)
          .firstChild.data
      );

      setText_filesize1(
        xpath.select1("/resources/string[@name='text_filesize1']", doc)
          .firstChild.data
      );

      setText_dateofupload(
        xpath.select1("/resources/string[@name='text_dateofupload']", doc)
          .firstChild.data
      );

      setText_edit(
        xpath.select1("/resources/string[@name='text_edit']", doc).firstChild
          .data
      );
      setText_atomfileid(
        xpath.select1("/resources/string[@name='text_atomfileid']", doc)
          .firstChild.data
      );
      setText_viewdetails(
        xpath.select1("/resources/string[@name='text_viewdetails']", doc)
          .firstChild.data
      );
      setText_view(
        xpath.select1("/resources/string[@name='text_view']", doc).firstChild
          .data
      );
      setButtonText(
        xpath.select1("/resources/string[@name='buttonText']", doc).firstChild
          .data
      );
      setText_delete(
        xpath.select1("/resources/string[@name='text_delete']", doc).firstChild
          .data
      );
      setText_download(
        xpath.select1("/resources/string[@name='text_download']", doc)
          .firstChild.data
      );
      setText_searchhere(
        xpath.select1("/resources/string[@name='text_searchhere']", doc)
          .firstChild.data
      );
      setText_read(
        xpath.select1("/resources/string[@name='text_read']", doc).firstChild
          .data
      );
      setText_write(
        xpath.select1("/resources/string[@name='text_write']", doc).firstChild
          .data
      );
      setText_validationemail(
        xpath.select1("/resources/string[@name='text_validationemail']", doc)
          .firstChild.data
      );
      setText_share(
        xpath.select1("/resources/string[@name='text_share']", doc).firstChild
          .data
      );
      setText_enteruseremail(
        xpath.select1("/resources/string[@name='text_enteruseremail']", doc)
          .firstChild.data
      );
      setText_selectaccesstype(
        xpath.select1("/resources/string[@name='text_selectaccesstype']", doc)
          .firstChild.data
      );
      setText_Own_email_err(
        xpath.select1("/resources/string[@name='text_Own_email_err']", doc)
          .firstChild.data
      );
      setText_back(
        xpath.select1("/resources/string[@name='text_back']", doc).firstChild
          .data
      );
      setText_currentlocation(
        xpath.select1("/resources/string[@name='text_currentlocation']", doc)
          .firstChild.data
      );
      setText_nofileshere(
        xpath.select1("/resources/string[@name='text_nofileshere']", doc)
          .firstChild.data
      );
      setText_to(
        xpath.select1("/resources/string[@name='text_to']", doc).firstChild.data
      );

      setText_cancel(
        xpath.select1("/resources/string[@name='text_cancel']", doc).firstChild
          .data
      );
      setText_peoplewithaccess(
        xpath.select1("/resources/string[@name='text_peoplewithaccess']", doc)
          .firstChild.data
      );
      setText_done(
        xpath.select1("/resources/string[@name='text_done']", doc).firstChild
          .data
      );
      setText_valemail(
        xpath.select1("/resources/string[@name='text_valemail']", doc)
          .firstChild.data
      );
      setText_alemail(
        xpath.select1("/resources/string[@name='text_alemail']", doc).firstChild
          .data
      );
      settext_noticeemail(
        xpath.select1("/resources/string[@name='text_noticeemail']", doc)
          .firstChild.data
      );
      setText_foldername(
        xpath.select1("/resources/string[@name='text_foldername']", doc)
          .firstChild.data
      );
      setText_nofolderhere(
        xpath.select1("/resources/string[@name='text_nofolderhere']", doc)
          .firstChild.data
      );
      setText_folder(
        xpath.select1("/resources/string[@name='text_folder']", doc).firstChild
          .data
      );
      setText_File(
        xpath.select1("/resources/string[@name='text_file']", doc).firstChild
          .data
      );
      setUpload_complete_text(
        xpath.select1("/resources/string[@name='upload_complete_text']", doc)
          .firstChild.data
      );
      setWeb_server_text(
        xpath.select1("/resources/string[@name='Web_server_text']", doc)
          .firstChild.data
      );
      setAtom_text(
        xpath.select1("/resources/string[@name='Atom_text']", doc).firstChild
          .data
      );
      setText_Refresh(
        xpath.select1("/resources/string[@name='text_Refresh']", doc).firstChild
          .data
      );
    } catch (ert) {}
  };

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <>
        <FilterComponent
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
          PlaceHolder={text_searchhere}
          className="fc-input-aws-ipfs res-search-input"
          clearClass="fc-x-aws-ipfs"
        />
      </>
    );
  }, [filterText, resetPaginationToggle, text_searchhere]);

  const subHeaderComponentfolder = useMemo(() => {
    const handleClear = () => {
      if (filterTextFolder) {
        setResetPaginationTogglefolder(!resetPaginationTogglefolder);
        setFilterTextFolder("");
      }
    };

    return (
      <>
        <FilterComponent
          onFilter={(e) => setFilterTextFolder(e.target.value)}
          onClear={handleClear}
          filterText={filterTextFolder}
          PlaceHolder={text_searchhere}
          className="fc-input-aws-ipfs res-search-input"
          clearClass="fc-x-aws-ipfs"
        />
      </>
    );
  }, [filterTextFolder, resetPaginationTogglefolder, text_searchhere]);

  ////**** download file ipfs */
  const ipfs_download = async (
    folder_cid,
    file_format,
    File_type,
    File_Name,
    i,
    row_id
  ) => {
    const filecid = folder_cid;
    const filename = File_Name;
    const fileType = File_type;
    setLoader((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      const url =
        GLOBAL_URL_DOMAIN_File_Upload_Live +
        `/download-file/download-from-ipfs?filecid=${filecid}&filename=${filename}`;
      const headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      const res = await axios.get(url, {
        responseType: "blob",
        ...headerConfig,
      });

      // console.log("stream_ipfs_download res***", res);

      const blobData = new Blob([res?.data], {
        type: fileType || "application/octet-stream",
      });

      const tempUrl = window.URL.createObjectURL(blobData);
      const link = document.createElement("a");
      link.href = tempUrl;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      link.remove();
      if (res?.status === 200) {
        setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      }
    } catch (err) {
      // console.log("stream_ipfs_download error***", err);
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
      setLoader((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    }
  };
  ////**** file with access user data  */
  const userAccessData = async (fileUniqueId, row_id) => {
    setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: true }));
    try {
      let headerConfig = {
        headers: {
          authorization: "Bearer " + sessionStorage.getItem("userToken"),
        },
      };

      let API_URL =
        GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/users-with-file-access`;

      const data = {
        file_unique_id: fileUniqueId,
        sender_user_id: userID,
      };

      let res = await axios.post(API_URL, data, headerConfig);
      // console.log("userAccessData res***", res);
      if (res?.data?.data !== null) {
        setSharedDataPopup(true);

        setCombinedData(res?.data?.data);
        setAccessEmail(res?.data?.userData);
      }
      setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
    } catch (err) {
      setLoaderAccess((prevLoader) => ({ ...prevLoader, [row_id]: false }));
      if (err?.response?.status === 427) {
        sessionStorage.clear();
        localStorage.clear();
        Cookie.remove("username");
        Cookie.remove("user_id");
        Cookie.remove("AdminFName");
        Cookie.remove("AdminLName");
        Cookie.remove("token");
        window.location.replace("/");
      }
    }
  };
  ////**** coulmn for file datatable */
  const columns = [
    {
      name: <>{text_filename1}</>,
      selector: (row, i) => (
        <>
          <div className="d-flex">
            <img src={fileImg} alt="" className="yf-aws2-fileImg" />{" "}
            <div
              className="uf-folder-aws"
              title={row?.fileName}
              onClick={() => handleChange(row)}
            >
              {row?.fileName}
            </div>
            <span>
              {row?.shared_count_ipfs !== 0 && (
                <button
                  onClick={() => [
                    userAccessData(row?.file_unique_id, row._id),
                    setFileName(row?.fileName),
                  ]}
                  className="shared-data-email"
                  title="Shared"
                >
                  {loaderAccess[row._id] ? (
                    <Loader2 className="mx-5" />
                  ) : (
                    <img src={peopleimg} alt="" className="peopleimg" />
                  )}
                </button>
              )}{" "}
            </span>
            {i === UI_ind && (
              <b>
                {UI_text}{" "}
                {UI_RefreshVisibility && (
                  <Tooltip title={text_Refresh} arrow onClick={handleRefresh}>
                    <button className="refreshtooltip" title={text_Refresh}>
                      <IoReload />
                    </button>
                  </Tooltip>
                )}
              </b>
            )}
          </div>
        </>
      ),
      width: "460px",
    },

    {
      name: <>{text_dateofupload}</>,
      selector: (row) => moment(row?.uploadDateAndTime).format("MMM DD, YYYY"),
      width: "380px",
    },
    {
      name: <>{text_share}</>,
      selector: (row, index) => (
        <button
          className="share-icon-table mx-3"
          onClick={() => [
            // setAccesspopup(true),
            checkStatusOnShare(row?.file_unique_id),
            setEmailexits(""),
            // console.log(row),
            setShareFileUniqID(row?.file_unique_id),
            setFileName(row?.fileName),
            setFolderID(row?.ipfsFolderId),
            setBucketID(row?.bucketId_ipfs),
            setAllFileData(row),
            setFileOrFolder("File"),
          ]}
        >
          <FaShareAlt className="share-sty" />
        </button>
      ),
      width: "80px",
    },
    {
      name: <>{text_download}</>,
      selector: (row, index) => (
        <button
          className="share-icon-table"
          onClick={() => {
            checkStatusOnDownload(row, index);
          }}
        >
          {loader[row._id] ? (
            <Loader2 className="mx-5" />
          ) : (
            <LuDownload
              className="mx-4"
              style={{
                color: "#009087",
                width: "25px",
                height: "25px",
                paddingLeft: "-70px",
              }}
            />
          )}
        </button>
      ),
    },
    {
      name: <>{text_delete}</>,
      selector: (row, index) => (
        <button
          className="unpin-file-btn-table"
          onClick={() => {
            checkStatusOnUnpin(row, index);
          }}
        >
          <DeleteOutlineIcon className="yf-ed-icon" />
        </button>
      ),
    },
  ];
  ////**** On Datatble row click view folder data */
  const handleChangeFolder = (state) => {
    getSubFolder(state?.folderName, state?.bucketId);
    setFolderName(state?.folderName);
    ReqUrlCreateToShow(state?.folderName);
    setReqUrlCreate(state?.folderName);
    getFileData(state?.folderName, state?.bucketId);
    // console.log(state);
  };
  ///**** On Datatble row click view file data */
  const handleChange = (state) => {
    navigate("/view-file", {
      state: {
        fileData: state,
        selecttab: 0,
      },
    });
  };

  const filteredItems = Data.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  useEffect(() => {
    const ind = filteredItems.findIndex(
      (v) => v.file_unique_id === UI_file_unique_id
    );
    if (ind !== -1) {
      setUI_ind(ind);
      setUI_file(filteredItems[ind]);
    }
  }, [filteredItems, UI_file_unique_id]);

  ///**** Access popup start */
  ///**** less than one email call this func and than call handlegetuser */
  const handleSingleUser = () => {
    if (emailexits === "") {
      setErrEmail(<>*{text_validationemail}</>);
    } else {
      var value = emailexits.trim();
      if (value && isValid(value)) {
        setEmailData([...emailData, emailexits]);
        setEmailexits("");
        handleGetUser(value);
      }
    }
  };
  ///**** More than one email call this func directly */
  const handleGetUser = (value) => {
    if (alreadyemail.length > 0) {
      setErrEmail(<>{text_alemail}</>);
    } else {
      var FinalEmail = [];
      if (emailData.length === 0) {
        FinalEmail.push({ email: [value] });
      } else {
        FinalEmail.push({ email: emailData });
      }
      // console.log(FinalEmail[0].email);
      setAccessErr("");
      if (FinalEmail.length === 0) {
        setErrEmail(<>*{text_validationemail}</>);
      } else {
        // for (let i = 0; FinalEmail[0].email.length > i; i++) {
        setVerifyBtn(true);
        let headerConfig = {
          headers: {
            authorization: "Bearer " + sessionStorage.getItem("userToken"),
          },
        };

        // var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/emailexist`;
        // var data = {
        //   EmailOrPhone: FinalEmail[0].email[i].toLowerCase(),
        // };
        var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/email-exists`;
        var data = {
          emails: FinalEmail[0].email,
        };
        axios
          .post(API_URL, data, headerConfig)
          .then((res) => {
            // console.log("userDetails:-", res?.data?.data?._id);
            var val = res?.data?.data;
            if (res?.status === 200) {
              // if (fileOrfolder === "File") {
              //   GiveAccessToUser(FinalEmail[0].email[i], val._id);
              // } else {
              //   give_ipfs_folder_access(FinalEmail[0].email[i], val._id);
              // }
              if (res?.data.data.found.length > 0) {
                for (let i = 0; res?.data.data.found.length > i; i++) {
                  if (fileOrfolder === "File") {
                    GiveAccessToUser(
                      res?.data.data.found[i].email,
                      res?.data.data.found[i].userId
                    );
                  } else {
                    give_ipfs_folder_access(
                      res?.data.data.found[i].email,
                      res?.data.data.found[i].userId
                    );
                  }
                }
              }
              if (res?.data.data.notFound.length > 0) {
                for (let i = 0; res?.data.data.notFound.length > i; i++) {
                  if (fileOrfolder === "File") {
                    handleInviteUser(FinalEmail[0].email[i]);
                  } else {
                    give_ipfs_invite_folder_access(FinalEmail[0].email[i]);
                  }
                }
              }
            }
          })
          .catch((err) => {
            // console.log("get data ipfs err***", err);
            // if (fileOrfolder === "File") {
            //   handleInviteUser(FinalEmail[0].email[i]);
            // } else {
            //   give_ipfs_invite_folder_access(FinalEmail[0].email[i]);
            // }
            setVerifyBtn(false);
            if (err?.response?.status === 427) {
              sessionStorage.clear();
              localStorage.clear();
              Cookie.remove("fName");
              Cookie.remove("lName");
              Cookie.remove("userID");
              Cookie.remove("userToken");
              Cookie.remove("token_start_time");
              navigate("/");
            }
          })
          .then(function () {});
        // }
      }
    }
  };
  ///**** Invite user default folder create by user ID */
  const handleCreateDefaultFolder = (userId) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userId,
      folderName: `atom-storage/${userId}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/ipfs-folder/create-root-folder";

    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("reg res***", res);
      })
      .catch((err) => {
        // console.log("reg err***", err);

        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
  };
  // Function to create a root folder for the user on AWS S3
  const handleCreateRootFolderS3 = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage/${userID}/`,
      bucketId: "4bb41a28-c110-42bd-9389-350a8607cb02",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("root folder aws res***", res);
      })
      .catch((err) => {
        // console.log("root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };

  // Function to create a private root folder for the user on AWS S3
  const handleCreateRootFolderS3_Private = (userID) => {
    var companyId = sessionStorage.getItem("companyID");
    var data = {
      companyId: companyId,
      userId: userID,
      folderName: `atom-storage-private/${userID}/`,
      bucketId: "d969487f-09fc-41b6-be90-2af9a16bd804",
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + "/folder/create-private-root-folder";
    // Send POST request to create root folder on AWS S3
    axios
      .post(API_URL, data)
      .then((res) => {
        // console.log("private root folder aws res***", res);
      })
      .catch((err) => {
        // console.log("private root folder aws err***", err);
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          // navigate("/");
        }
      });
  };
  const handleInviteUser = (email, lastIndex) => {
    setShareFileLoader(true);
    var companyID = sessionStorage.getItem("companyID");
    const otherDataIPFS = [
      [allFileData.file_unique_id, allFileData.ipfsFolderId],
    ];

    var AWSBucketId = allFileData.bucketId_aws;
    var IPFSbucketId = allFileData.bucketId_ipfs;
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/user/inviteuser-for-access`;

    var data = {
      firstName: Fname,
      lastName: Lname,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      company_id: companyID, // company that is giving the access (send null when no company_id)
      bucket_id: AWSBucketId,

      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      is_aws: false,
      is_ipfs: true,
      other_data_aws: [], // [ [file_unique_id, folder_id], [file_unique_id, folder_id], [file_unique_id, folder_id] ]

      bucket_id_ipfs: IPFSbucketId,
      other_data_ipfs: otherDataIPFS, // [ [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs], [file_unique_id, folder_id_ipfs] ]
      sender_user_id: sessionStorage.getItem("userID"),
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        if (res?.status === 200) {
          handleCreateDefaultFolder(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3(res?.data?.data?.registered_user_id);
          handleCreateRootFolderS3_Private(res?.data?.data?.registered_user_id);
          if (emailData.length === 0) {
            setShareFileLoader(false);
            successToast(res?.data?.message);
            closeAccessPopup();
            if (reqUrlCreate === "" || reqUrlCreate === undefined) {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            } else {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            }
          }
          if (emailData.slice(-1)[0] === email) {
            setShareFileLoader(false);
            successToast(res?.data?.message);
            closeAccessPopup();
            if (reqUrlCreate === "" || reqUrlCreate === undefined) {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            } else {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            }
          }
          dispatch(fetchSharedByMeFiles("ipfs"));
        }
      })
      .catch((err) => {
        // console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
          if (reqUrlCreate === "" || reqUrlCreate === undefined) {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          } else {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          }
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
          if (reqUrlCreate === "" || reqUrlCreate === undefined) {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          } else {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          }
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  const [shareFileUniqID, setShareFileUniqID] = useState();
  const GiveAccessToUser = (email, userID_to, lastIndex) => {
    setShareFileLoader(true);
    let headerConfig = {
      headers: {
        authorization: "Bearer " + sessionStorage.getItem("userToken"),
      },
    };

    var API_URL = GLOBAL_URL_DOMAIN_LIVE + `/ipfs-access/insert-record`;
    var data = {
      company_id:
        sessionStorage.getItem("companyID") === undefined ||
        sessionStorage.getItem("companyID") === null ||
        sessionStorage.getItem("companyID") === ""
          ? null
          : sessionStorage.getItem("companyID"),
      user_id: userID_to,
      file_unique_id: shareFileUniqID,
      folder_id: folderID,
      db_version: "v1",
      read_access: readAccess,
      write_access: writeAccess,
      download_access: downloadAccess,
      bucket_id: bucketId,
      email: email,
      companyName: CompanyName === "" ? null : CompanyName,
      firstName: Fname,
      lastName: Lname,
      sender_firstname: sessionStorage.getItem("fName"),
      sender_lastname: sessionStorage.getItem("lName"),
      sender_user_id: sessionStorage.getItem("userID"),
      folder_name: folderNameForInsertRecord,
    };
    axios
      .post(API_URL, data, headerConfig)
      .then((res) => {
        // console.log("Access Given", res);
        if (res?.data?.success === true) {
          if (emailData.length === 0) {
            successToast(res?.data?.message);
            closeAccessPopup();
            setShareFileLoader(false);
            if (reqUrlCreate === "" || reqUrlCreate === undefined) {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            } else {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            }
          }
          if (emailData.slice(-1)[0] === email) {
            successToast(res?.data?.message);
            closeAccessPopup();
            setShareFileLoader(false);
            if (reqUrlCreate === "" || reqUrlCreate === undefined) {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            } else {
              getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
            }
          }
          dispatch(fetchSharedByMeFiles("ipfs"));
        }
      })
      .catch((err) => {
        // console.log("get data ipfs err***", err);
        if (emailData.slice(-1)[0] === email) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
          if (reqUrlCreate === "" || reqUrlCreate === undefined) {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          } else {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          }
        }
        if (emailData.length === 0) {
          setErrEmail(err?.response?.data?.message);
          setShareFileLoader(false);
          if (reqUrlCreate === "" || reqUrlCreate === undefined) {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          } else {
            getFileData(reqUrlCreate, "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55");
          }
        }
        if (err?.response?.status === 427) {
          sessionStorage.clear();
          localStorage.clear();
          Cookie.remove("fName");
          Cookie.remove("lName");
          Cookie.remove("userID");
          Cookie.remove("userToken");
          Cookie.remove("token_start_time");
          navigate("/");
        }
      })
      .then(function () {});
    // }
  };
  const [readAccess, setReadAccess] = useState(true);
  const [writeAccess, setWriteAccess] = useState(true);
  const [downloadAccess, setDownloadAccess] = useState(true);
  const AccessValueHandle = (val) => {
    if (val === "read") {
      setReadAccess(true);
      setWriteAccess(false);
      setDownloadAccess(false);
    } else if (val === "write") {
      setWriteAccess(true);
      setReadAccess(true);
      setDownloadAccess(false);
    } else if (val === "download") {
      setDownloadAccess(true);
      setWriteAccess(true);
      setReadAccess(true);
    }
  };
  ///**** Access popup end */

  ///**** back button logic */
  const handleBack = () => {
    // console.log("handleBackArr", reqUrlCreate.split("/"));
    const handleBackArr = reqUrlCreate.split("/");
    // console.log(handleBackArr.length > 3);

    if (handleBackArr.length > 4) {
      getSubFolder(
        reqUrlCreate.slice(
          0,
          reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) + 1
        ),
        "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
      );
      ReqUrlCreateToShow(
        reqUrlCreate.slice(
          0,
          reqUrlCreate.lastIndexOf("/", reqUrlCreate.lastIndexOf("/") - 1) + 1
        ),
        "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
      );
    } else {
      setAllFolderData(ipfsrootdata);
      setLeftFolders(ipfsrootdata);
      setLoaderData(false);
      if (ipfsfile?.length === 0) {
        dispatch(
          getIPFSFileData(
            `atom-storage/${userID}/`,
            "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
          )
        );
      } else {
        setData(ipfsfile);
        // getFileData(
        //   `atom-storage/${userID}/`,
        //   "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
        // );
      }
      setReqUrlCreate("");
      setUrlToShow("");
      setViewBackBtn(false);
      // if (ipfsrootdata?.length === 0) {
    }
  };
  ///**** logic for left side folder */
  var MainFolder = reqUrlCreate.split("/");
  var MainFolderPath =
    MainFolder[0] + "/" + MainFolder[1] + "/" + MainFolder[2] + "/";
  return (
    <>
      <div className="yf-ipfs-main mt-5 yf-aws2-main">
        <div className={accesspopup === true ? "bgblur" : ""}>
          <div
            className={
              accesspopup || inviteuserPopup || shareDataPopup
                ? "bgblur yf-aws2-1"
                : "yf-aws2-1"
            }
          >
            {/* Current location and back button*/}
            <div className="yf-aws2-11">
              <span
                style={{
                  fontWeight: "500px",
                  fontSize: "18px",
                  color: "white",
                }}
              >
                <b>{text_currentlocation}: </b>
              </span>
              <span
                style={{ fontSize: "18px", marginLeft: "15px", color: "white" }}
              >
                <img src={folderImg} alt="" className="yf-aws2-folderImg" />{" "}
                <button
                  className="folder-home text-white"
                  onClick={() => [
                    // getFolder(),
                    setAllFolderData(ipfsrootdata),
                    setData(ipfsfile),
                    setUrlToShow(""),
                    setReqUrlCreate(""),
                    setViewBackBtn(false),
                  ]}
                >
                  {`atom-storage >`}
                </button>
                {urlToShow}
              </span>
            </div>
            {viewBackBtn && (
              <div className="yf-aws2-12">
                <button
                  className="back-btn-view"
                  style={{ marginTop: "0px" }}
                  onClick={handleBack}
                >
                  <span>
                    <MdOutlineKeyboardBackspace className="back-btn-icon back-icon" />
                  </span>
                  <span>{text_back}</span>
                </button>
              </div>
            )}
          </div>
          <div className="yf-aws2-2">
            <div
              className={
                leftFolders.length === 0
                  ? "yf-aws2-border"
                  : "yf-aws2-21 px-2 ipfs-folder"
              }
              id="res-awsfolder"
            >
              {/* Left Side folder*/}
              {leftFolders?.map((v, index) => {
                return (
                  <>
                    <div
                      className={
                        MainFolderPath === v?.folderName
                          ? "yf-aws2-211 mainfolder-sty"
                          : "yf-aws2-211"
                      }
                      key={index}
                      id={index}
                      onClick={() => [
                        getSubFolder(
                          v?.folderName,
                          "6e2f3b90-1dd9-4b7c-9be8-904bd4801e55"
                        ),
                        setTimeout(() => {
                          setViewBackBtn(true);
                        }, 1000),
                        getFileData(v?.folderName, v?.bucketId),
                        ReqUrlCreateToShow(v?.folderName),
                      ]}
                    >
                      <img
                        src={folderImg}
                        alt=""
                        className="yf-aws2-folderImg"
                      />

                      {v?.folderName.slice(
                        v?.folderName.lastIndexOf(
                          "/",
                          v?.folderName.lastIndexOf("/") - 1
                        ) + 1,
                        v?.folderName?.length - 1
                      )}
                    </div>
                  </>
                );
              })}
            </div>
            <div className="yf-aws2-22">
              <div className="d-flex justify-content-between flex-wrap">
                <h5 className="mx-4">{text_folder}</h5>
                <div className="mx-4">{subHeaderComponentfolder}</div>
              </div>
              {/* Rigth Side folder start*/}
              <div className="folder-file-datatable">
                {filteredItemsFolder.length === 0 ? (
                  <>
                    {ipfsrootLoaderdata ? (
                      <div className="uf-emptyfolder">
                        <Loader3 />
                      </div>
                    ) : (
                      <div className="uf-emptyfolder">
                        <h3 className="as">{text_nofolderhere}</h3>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <DataTable
                      columns={ColumnFolder}
                      className="p-3 "
                      pointerOnHover
                      onRowClicked={handleChangeFolder}
                      data={filteredItemsFolder}
                      pagination
                      fixedHeader
                      selectableRowsHighlight
                      highlightOnHover
                      customStyles={customTableStylesFile}
                      paginationPerPage={5}
                      paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
                    />
                  </>
                )}
              </div>
              {/* Rigth Side folder end*/}

              <div className="d-flex justify-content-between mt-4 flex-wrap">
                <h5 className="mx-4">{text_file}</h5>
                <div className="mx-4">{subHeaderComponent}</div>
              </div>
              {/* Rigth Side file start*/}
              <div className="folder-file-datatable">
                {filteredItems.length === 0 ? (
                  <>
                    {ipfsrootLoaderdata ? (
                      <div className="uf-emptyfolder">
                        <Loader3 />
                      </div>
                    ) : (
                      <div className="uf-emptyfolder">
                        <h3 className="as">{text_nofileshere}</h3>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {" "}
                    <DataTable
                      columns={columns}
                      className="p-3 "
                      pointerOnHover
                      onRowClicked={handleChange}
                      data={filteredItems}
                      pagination
                      fixedHeader
                      selectableRowsHighlight
                      highlightOnHover
                      customStyles={customTableStylesFile}
                      paginationPerPage={5}
                      paginationRowsPerPageOptions={[5, 10, 20, 30, 40, 50]}
                    />
                  </>
                )}
              </div>
              {/* Rigth Side file end*/}
            </div>
          </div>
        </div>
        <Dialog
          open={accesspopup}
          onClose={closeAccessPopup}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            id="responsive-dialog-title"
            className="text-center w-100"
          >
            <div className="d-flex justify-content-between">
              <h5 className="mt-1">
                {text_share}{" "}
                {Filename === "" ? (
                  <>
                    <img
                      src={folderImg}
                      alt=""
                      style={{
                        height: "21px",
                        width: "21px",
                        marginBottom: "3px",
                      }}
                    />{" "}
                    {folderName}
                  </>
                ) : (
                  <>
                    <img
                      src={fileImg}
                      alt=""
                      style={{
                        height: "19px",
                        width: "19px",
                        marginBottom: "3px",
                      }}
                    />{" "}
                    {Filename}
                  </>
                )}{" "}
                {text_to}
              </h5>
              <h3>
                <CgCloseO onClick={() => closeAccessPopup()} />
              </h3>
            </div>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div className="d-flex flex-wrap">
                <div>
                  <div className="participants_box">
                    <div className="tags-input">
                      {/* {console.log(emailData)} */}
                      {/* Email data push and show*/}

                      {emailData &&
                        emailData.map((dataitem, i) => (
                          <li key={i} className="tag">
                            <span className="tag-title">{dataitem}</span>
                            <span
                              className="tag-close-icon"
                              onClick={() => handleDelete(i)}
                            >
                              x
                            </span>
                          </li>
                        ))}
                      <input
                        className="user-input"
                        type="text"
                        onKeyPress={handleKeyDown}
                        onChange={EmailHandler}
                        value={emailexits}
                        placeholder={
                          emailData.length === 0 && text_enteruseremail
                        }
                      />
                    </div>
                  </div>
                  {/* Email data push and show using suggestion*/}
                  <div
                    className={
                      filteredSuggestions.length === 0 ? "" : "suggestion-list"
                    }
                    onKeyDown={(e) => onSelectSuggestionKey(e)}
                  >
                    {displaySuggestions && (
                      <>
                        {filteredSuggestions.map((suggestion, index) => {
                          return (
                            <div className="suggestion" key={index}>
                              <li
                                className="li-suggestion"
                                onClick={() => onSelectSuggestion(index)}
                              >
                                {suggestion.userEmail}
                              </li>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                </div>
                <select
                  className="accessinput access-select mx-4 "
                  onChange={(e) => [
                    AccessValueHandle(e.target.value),
                    setAccessErr(""),
                  ]}
                >
                  <option value="" disabled>
                    {text_selectaccesstype}
                  </option>
                  <option value="read">{text_read}</option>
                  <option value="write">
                    {text_read}/{text_write}
                  </option>
                  <option value="download" selected>
                    {text_read}/{text_write}/{text_download}
                  </option>
                </select>
              </div>
              <p className="mx-3 errMsg">{errEmail}</p>
            </DialogContentText>
            <Divider />
          </DialogContent>
          <div className="d-flex justify-content-center gap-4 mt-2 mb-3">
            <button className="box-btn" onClick={() => closeAccessPopup()}>
              {text_cancel}
            </button>
            <button
              className="box-btn-save"
              onClick={() =>
                emailData.length === 0 ? handleSingleUser() : handleGetUser()
              }
              disabled={shareFileLoader || errEmail !== "" ? true : false}
            >
              {shareFileLoader ? <Loader2 /> : text_share}
            </button>
          </div>
        </Dialog>
      </div>
      {/* User With access data*/}
      <Dialog
        open={shareDataPopup}
        onClose={() => setSharedDataPopup(false)}
        aria-labelledby="responsive-dialog-title"
        classes={{ paper: "custom-dialog" }} // Apply custom class to the dialog box
      >
        <DialogTitle id="responsive-dialog-title" className="text-center">
          <div className="d-flex justify-content-between">
            <h5 className="mt-1">{text_peoplewithaccess}</h5>
            <h3>
              <CgCloseO onClick={() => setSharedDataPopup(false)} />
            </h3>
          </div>
          <Divider />
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <div className="row">
              <div className="col-md-6 d-none d-md-block">
                {accessEmail?.map((emailItem, i) => (
                  <div
                    key={i}
                    style={{
                      width: "325px",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span>{emailItem?.email}</span>
                  </div>
                ))}
              </div>
              <div className="col-md-6 d-none d-md-block">
                {combinedData.map((item, i) => (
                  <div key={i} className="access-box">
                    <span className="d-flex">
                      {item?.read_access &&
                        !item?.write_access &&
                        !item?.download_access &&
                        "Read"}
                      {item?.read_access &&
                        item?.write_access &&
                        !item?.download_access &&
                        "Read/Write"}
                      {item?.read_access &&
                        item?.write_access &&
                        item?.download_access &&
                        "Read/Write/Download"}
                    </span>
                  </div>
                ))}
              </div>
              {/* For small screens, switch to stacked layout */}
              <div className="col-md-6 d-md-none">
                {accessEmail?.map((emailItem, i) => (
                  <div
                    key={i}
                    style={{
                      width: "325px",
                      paddingTop: "20px",
                      paddingBottom: "8px",
                    }}
                  >
                    <span>{emailItem?.email}</span>
                    {/* Render combinedData corresponding to this accessEmail */}
                    <div className="access-box">
                      <span className="d-flex">
                        {combinedData[i]?.read_access &&
                          !combinedData[i]?.write_access &&
                          !combinedData[i]?.download_access &&
                          "Read"}
                        {combinedData[i]?.read_access &&
                          combinedData[i]?.write_access &&
                          !combinedData[i]?.download_access &&
                          "Read/Write"}
                        {combinedData[i]?.read_access &&
                          combinedData[i]?.write_access &&
                          combinedData[i]?.download_access &&
                          "Read/Write/Download"}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
                        
            </div>
          </DialogContentText>
        </DialogContent>
        <div className="d-flex justify-content-center mb-2 ">
          <button
            className="box-btn-save"
            onClick={() => setSharedDataPopup(false)}
          >
            {text_done}
          </button>
        </div>
      </Dialog>
      <ToastContainer />
    </>
  );
};

export default YourFilesIPFS;
